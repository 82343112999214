import React from 'react'
import styled, { css } from 'styled-components'
import BaseModule from 'modules/DBBaseModule'
import OptionsWrapper from 'components/OptionsWrapper'
import { default as media } from 'assets/styles/Breakpoints'
import Colors from '../../assets/styles/Colors'


const round = (n, i) => Math.ceil(n/i)*i

const numberRounding = (num, labels) => {
    if(labels[labels.length - 1] <= 100) {
        return round(num, 5)
    }
    if(labels[labels.length - 1] <= 1000) {
        return round(num, 25)
    }
    if(labels[labels.length - 1] <= 10000) {
        return round(num, 100)
    }
    if(labels[labels.length - 1] <= 100000) {
        return round(num, 1000)
    }
    if(labels[labels.length - 1] <= 1000000) {
        return round(num, 5000)
    }
    return round(num, 10000)
}

const numberFormat = (n) => {
    if(Intl && Intl.NumberFormat) {
        return new Intl.NumberFormat().format(n)
    }
    return n
}

class ProgressBar extends BaseModule {

    constructor (props) {
        super(props)

        this.state = {
            fields: {},
        }
    }

    /**
     * Module name. Displayed in page builder.
     * @return {String}
     */
    static getName () { return 'Progress Bar' }

    static options () {
        return super.options().concat([
            {
                name: 'min',
                label: 'Start of the progress-bar',
                type: 'input',
                inputType: 'number',
                defaultValue: 0,
            },
            {
                name: 'max',
                label: 'End of the progress-bar',
                type: 'input',
                inputType: 'number',
                defaultValue: 100,
            },
            {
                name: 'value',
                label: 'Value of the progress-bar',
                type: 'input',
                inputType: 'number',
                defaultValue: 50,
            },
            {
                name: 'forcedLabels',
                label: 'Override automatic labels, comma seperate the labels that are required',
                type: 'input',
            },
        ])
    }

    /**
     * Renderer
     */
    render () {
        let { options, editMode } = this.props
        let { min, max, value, forcedLabels } = options
        if(isNaN(min)) min = 0

        // Normalizing the value to be a percent between min and max instead of an absolute number
        const maxMinDiff = max - min
        const relativeValue = value - min
        const progress = relativeValue / maxMinDiff * 100;
        const labelCount = 4

        let labels = []

        if(forcedLabels && forcedLabels.length !== 0) {
            labels = forcedLabels.split(',')
        } else {
            for (let i = 0; i < labelCount; i++) {
                let labelValue = maxMinDiff / (labelCount + 1) * (i + 1)
                labels.push(parseInt(min) + labelValue)
            }
        }

        return (
            <OptionsWrapper options={ options } editMode={ editMode }>
                <Wrapper>
                    <Progress value={ progress } max="100"/>
                    <LabelsWrapper>
                        <Label key="start" value={options.min}>{numberFormat(min)}</Label>
                        { labels.sort((a,b) => a > b).map((l, i) => {
                            const lValue = numberFormat(numberRounding(l, labels));
                            return <Label key={ i } absolute progress={ (numberRounding(l, labels) - min) / maxMinDiff * 100 } value={ lValue }>{ lValue }</Label>
                        }) }
                        <Label key="end" value={options.max}>{numberFormat(max)}</Label>
                    </LabelsWrapper>
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default ProgressBar

const Wrapper = styled.div`
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${ media.lessThan('1260px')`
        width: calc(100% - 180px);
    ` }

  ${ media.lessThan('1200px')`
        width: calc(100% - 160px);
    ` }

  ${ media.lessThan('992px')`
        width: calc(100% - 100px);
    ` }

  ${ media.lessThan('small')`
        width: calc(100% - 30px); 
        display: block;
    ` }
`

const Progress = styled.progress`
  margin-top: 10px;
  border-radius: 3px;
  width: 100%;
  height: 12px;

  &::-webkit-progress-bar {
    background-color: ${ Colors.lightGray };
    border-radius: 100px;
  }

  &::-webkit-progress-value {
    border-radius: 100px;
    background-color: ${ Colors.darkGreen };
  }
`

const LabelsWrapper = styled.ul`
  list-style: none;
  position: relative;
  width: 100%;
  display: flex;
  padding: 0;
  margin: .8em 0;
`

const Label = styled.li`
  list-style: none;
  padding: 0;
  font-family: 'Circular Pro Book', sans-serif;
  margin: 0 auto;
  font-size: 14px;

  &:first-of-type {
    margin-right: auto;
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
    margin-left: auto;
  }
  
  ${p => p.absolute && css`
    position: absolute;
    left: ${p.progress}%;
    transform: translateX(-50%);
  `}
`