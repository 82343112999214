import isServer from '../isServer'

const env = {
  ...process.env,
  ...{
    RAZZLE_PUBLIC_URL: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : `https://${ isServer ? '' : window.location.hostname}/`
  }
}

export default env

export const testEnvs = ['test', 'development', 'staging']