import Base from './Base';
import Fonts from './Fonts';
import Colors from './Colors';
import Media from 'assets/styles/Breakpoints'
import { css } from 'styled-components';
import Normalize from 'styled-normalize';

const Globals = css`
    ${Normalize};
    ${Fonts};
    ${Base};

    body {
        color: ${Colors.black};
        font-family: 'Lyon Text';
        font-size: 16px;
        line-height: 25px;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        padding-top: 89px;
        
        &.callout-visible {
            padding-top: 114px;
            .takeover-zone-wrapper {
                height: calc(100vh - 114px);
                height: calc(calc(var(--vh, 1vh) * 100) - 114px);
            }
        }
    }

    html {
        overflow-y: scroll;
        ${Media.lessThan('medium')`
            scroll-behavior: smooth;
        `}
    }    
`

export default Globals;
