import Cookies from 'js-cookie'
import isServer from '../../../isServer'

const getIdentity = () => {
    const fbp = Cookies.get('_fbp')
    const fbc = Cookies.get('_fbc')
    return {
        fbp: typeof fbp !== 'undefined' ? fbp : null,
        fbc: typeof fbc !== 'undefined' ? fbc : null,
    }
}

const FacebookConversionAPI = (events) => {
    if(isServer || !window.ApiInstance) return

    events.forEach(event => {
        switch (event.type) {
            case 'completeCheckout':
                window.ApiInstance.sendAnalyticsEvent({
                    ...{
                        _identity: getIdentity(),
                    },
                    ...event
                })
                return
            default:
                break
        }
    });
};

export default () => {
    return FacebookConversionAPI
}