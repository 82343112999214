import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  IoMdVolumeOff,
  IoMdVolumeHigh,
  IoIosContract,
  IoIosExpand,
  IoMdRepeat,
  IoMdReturnRight,
  IoIosRadioButtonOff,
  IoIosRadioButtonOn,
  IoMdPlay,
  IoMdSquare,
  IoMdJet,
  IoMdQrScanner,
} from 'react-icons/io'
import Colors from 'assets/Colors';
import IconButton from 'cms/components/Form/IconButton';

const VideoButtons = ({
  onToggleNativeAspectRatio,
  forceNativeVideoAspectRatio,
  onToggleMute,
  muted,
  onToggleLooping,
  looping,
  onToggleShowControls,
  showControls,
  onToggleAutoplay,
  autoplay,
  onToggleBackground,
  background,
   }) => {
    return (
        <React.Fragment>

            {onToggleNativeAspectRatio !== undefined && <StyledIconButton warning={false} onClick={onToggleNativeAspectRatio}>
                <IconWrapper>
                    {!forceNativeVideoAspectRatio ? <IoIosContract/> : <IoIosExpand/>}
                </IconWrapper>
            </StyledIconButton>}

            {!background && onToggleMute !== undefined && <StyledIconButton warning={false} onClick={onToggleMute}>
                <IconWrapper>
                    {muted ? <IoMdVolumeOff/> : <IoMdVolumeHigh/>}
                </IconWrapper>
            </StyledIconButton>}

            {!background && onToggleLooping !== undefined && <StyledIconButton warning={false} onClick={onToggleLooping}>
              <IconWrapper>
                {looping ? <IoMdRepeat/> : <IoMdReturnRight/>}
              </IconWrapper>
            </StyledIconButton>}

            {!background && onToggleShowControls !== undefined && <StyledIconButton warning={false} onClick={onToggleShowControls}>
                <IconWrapper>
                    {showControls ? <IoIosRadioButtonOn/> : <IoIosRadioButtonOff/>}
                </IconWrapper>
            </StyledIconButton>}

            {!background && onToggleAutoplay !== undefined && <StyledIconButton warning={false} onClick={onToggleAutoplay}>
                <IconWrapper>
                    {autoplay ? <IoMdPlay/> : <IoMdSquare/>}
                </IconWrapper>
            </StyledIconButton>}

            {onToggleBackground !== undefined && <StyledIconButton warning={false} onClick={onToggleBackground}>
                <IconWrapper>
                    {background ? <IoMdJet/> : <IoMdQrScanner/>}
                </IconWrapper>
            </StyledIconButton>}

        </React.Fragment>
    )
}

const StyledIconButton = styled(IconButton)`
  margin-bottom: 6px;
  svg {
      fill: ${Colors.grey}
  }
`

const IconWrapper = styled.div`
    background-color: ${Colors.white};
    border: 1px solid ${Colors.dividers};
    width: 30px;
    height: 30px;
    margin-bottom: 6px;
`
export default VideoButtons
