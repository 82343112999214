import React from 'react'
import ApiManager from 'ApiManager';

const Api = new ApiManager();

/**
 * Nifty functions that are reusable for all modules.
 * @extends React
 */
class BaseModule extends React.Component {

    /**
     * constructor
     */
    constructor(props) {
        super(props)

        this.registerModule = this.registerModule.bind(this)
        this.getKey = this.getKey.bind(this)
        this.getFields = this.getFields.bind(this)
    }

    /**
     * Returns the modules unique ID
     */
    getKey() {
        return this.props.id
    }

    /**
     * Returns the modules fields
     */
    getFields() {
        const { fields } = this.state;
        if (fields.hasOwnProperty('products')) {
            const { products } = fields;
            if (products.entryType === 'products' && products.hasOwnProperty('filter') && products.hasOwnProperty('data')) {
                products.data = [];
            }
        }
        return fields
    }

    /**
     * Return an empty array in case no global base module on project setting.
     */
    static options() {
        return [];
    }

    /**
     * Gets value of a field by provided field. Supports . separator
     * to get a specific index in an array.
     *
     * @param field
     * @returns {*}
     */
    getField = (field) =>  {

        if(field.includes('.')) {
            let a, n;
            let o = this.state.fields;
            let path = field.replace(/\[(\w+)\]/g, '.$1');
            path = path.replace(/^\./, '');
            a = path.split('.');
            while (a.length) {
                n = a.shift();
                if (n in o) {
                    o = o[n];
                } else {
                    return;
                }
            }
            return o;
        }

        return this.state.fields[field];
    }

    /**
     * When content editable field is changed
     */
    setField = (name, value, callback) => {
        let fields = {...{}, ...this.state.fields};

        if(!name.includes('.')) {
            fields[name] = value;
        }

        if(name.includes('.')) {
            let i, n;
            let a = name.split('.');
            let o = fields;
            for (i = 0; i < a.length - 1; i++) {
                n = a[i];
                if (n in o) {
                    o = o[n];
                } else {
                    o[n] = {};
                    o = o[n];
                }
            }
            o[a[a.length - 1]] = value;
        }


        if(typeof callback === 'function') {
            this.setState({
                fields: fields,
            }, callback());
        } else {
            this.setState({
                fields: fields,
            });
        }
    }

    /**
     * Adds a item to a field.
     *
     * @param field
     * @param newItem
     * @param callback
     */
    addItemToArrayField = (field, newItem, callback) => {
        let { fields } = this.state;

        if(typeof callback === 'undefined') callback = () => {};

        this.setState({
            fields: {
                ...{}, ...fields,
                ...{
                    [field]: [...fields[field], ...[{...{}, ...newItem}]],
                }
            }
        }, callback);
    };

    /**
     * Removes a item from a field.
     *
     * @param field
     * @param index
     * @param callback
     */
    removeItemFromArrayField = (field, index, callback) => {
        let { fields } = this.state;

        if(typeof callback === 'undefined') callback = () => {};

        this.setState({
            fields: {
                ...{}, ...fields,
                ...{
                    [field]: [
                        ...fields[field].slice(0, index),
                        ...fields[field].slice(index + 1),
                    ]
                }
            }
        }, callback);
    }

    /**
     * Registers the module in the module zone so the zone can read
     * data from the module.
     */
    registerModule(module) {
        this.props.onRegisterModule(module)
    }

    prepareData = (force) => {
        let { fields } = this.state;
        let self = this;

        if(typeof force == 'undefined') force = false;

        return new Promise(function(resolve, reject) {
            let fetchData = false;

            Object.keys(fields).forEach((index) => {

                if (fields[index] !== null) {
                    Object.keys(fields[index]).forEach((subIndex) => {
                        if (subIndex === 'entryType'
                            && (
                                force === true
                                || typeof fields[index]['data'] === 'undefined'
                                || fields[index]['data'].length === 0
                            )) {
                            fetchData = true;
                        }
                    });
                }
            });

            if (self.props.editMode === false) {
                fetchData = false;
            }

            if (fetchData) {
                Api.getModuleData(self.state).then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject();
                });
            } else {
                resolve(self.state);
            }
        });
    }
}

export default BaseModule
