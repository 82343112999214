import store from '../store'
import ApiManager from "../ApiManager";

const Api = new ApiManager();

const defaultState = {
    modalOpen: false,
    aspectRatio: 16/9,
    file: null,
    files: [],
    callback: null,
};

const OPEN_CROPPER_MODAL = 'OPEN_CROPPER_MODAL';
const CLOSE_CROPPER_MODAL = 'CLOSE_CROPPER_MODAL';
const CROPPER_CROP = 'CROPPER_CROP';


export const CropperReducer = (state = defaultState, action) => {
    switch (action.type) {
        case OPEN_CROPPER_MODAL:
            return {
                ...{}, ...state,
                ...{
                    modalOpen: true,
                    aspectRatio: action.aspectRatio,
                    file: action.file,
                    files: action.files,
                    callback: action.callback,
                }
            };
        case CLOSE_CROPPER_MODAL:
            return {
                ...{}, ...state,
                ...{
                    modalOpen: false,
                    file: null,
                    files: [],
                    callback: null,
                }
            };
        case CROPPER_CROP:
            return {
                ...{}, ...state,
                ...{
                    modalOpen: false,
                    file: null,
                    files: [],
                    callback: null,
                }
            };
        default:
            return state;
    }
};

const CropperActions = {
    openModal: (aspectRatio, file, callback) => {
        Api.getEarlierCrops(file.id, aspectRatio).then((result) => {
            store.dispatch({
                type: OPEN_CROPPER_MODAL,
                aspectRatio: aspectRatio,
                file: file,
                files: result.data.files,
                callback: callback,
            });
        });
    },
    closeModal: () => {
        store.dispatch({
            type: CLOSE_CROPPER_MODAL,
        });
    },
    crop: (data) => {
        let state = store.getState();
        let file = store.getState().cropper.file;

        Api.cropImage(file, data).then((result) => {
            if(typeof state.cropper.callback === 'function') {
                state.cropper.callback(result.data);
            }
            store.dispatch({
                type: CROPPER_CROP,
            });
        });
    },
    chosePreviousCrop: (fileId) => {
        let state = store.getState();
        let files = state.cropper.files;
        let file = null;

        files.map((f) => {
            if(f.id === fileId) {
                file = f;
            }
        });

        state.cropper.callback(file);

        store.dispatch({
            type: CROPPER_CROP,
        });
    }
};

export default CropperActions;