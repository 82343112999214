import React from 'react';
import BaseModule from 'modules/DBBaseModule';
import { Editor, Linker, NewMedia } from '../../cms';
import styled, { css } from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';
import OptionsWrapper from 'components/OptionsWrapper';
import fontOptions from 'assets/styles/FontOptions';

class ImageAndText extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Media and text" }


    /**
     * Constructor
     */
    constructor(props) {
        super(props)

        this.onChangeField = this.onChangeField.bind(this)

        this.state = {
            fields: {
                ...{}, ...{
                    title: 'Edit this title',
                    text: 'Edit this text',
                    forceNativeVideoAspectRatio: false,
                    muted: false,
                    looping: false,
                    showControls: true,
                    autoplay: false,
                    background: false,
                    imageCaption: 'Edit the image caption',
                    imageArray: [
                        null,
                        null,
                        null,
                        null,
                    ],
                    image: {}
                },
                ...props.fields,
            }
        }
    }


    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'flipped',
                label: 'Flip content direction',
                type: 'switch',
            },
            {
                name: 'size',
                label: 'Module size',
                type: 'dropdown',
                options: [
                    { name: 'Small', value: '2' },
                    { name: 'Medium', value: '1.5' },
                    { name: 'Large', value: '1' },
                ]
            },
            {
                name: 'lockAspect',
                label: 'Lock media at 16:9 aspect ratio',
                type: 'switch'
            },
            {
                name: 'smallerWidth',
                label: 'Smaller width',
                type: 'switch'
            },
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    toggleField = (field) => {
        const state = this.state

        this.setState({
            ...state,
            fields: {
                ...state.fields,
                [field]: !state.fields[field],
            }
        })
    }


    /**
     * When content editable field is changed
     * @return {Void}
     */
    onChangeField = (name, value) => {
        this.setState({ fields: Object.assign(this.state.fields, { [name]: value }) })
    }

     getFileType = (file = {}) => {
        if (file.mime) {
            if (file.mime.includes('image')) {
                return 'image'
            } else if(file.mime.includes('video')) {
                return 'video'
            }
        }
        return 'none'
    }

    renderStyledMedia = (editPos) => {
        let { options, editMode } = this.props;
        let { fields } = this.state;
        const { muted, forceNativeVideoAspectRatio, looping, showControls, autoplay, background } = fields;
        return (
            <StyledMedia
                module={this}
                overlay={options.overlay}
                field={'image'}
                editPosition={editPos}
                types={['images', 'videos']}
                fill
                videoOptions={{
                    onToggleNativeAspectRatio: () => this.toggleField('forceNativeVideoAspectRatio'),
                    forceNativeVideoAspectRatio: forceNativeVideoAspectRatio,

                    onToggleMute: () => this.toggleField('muted'),
                    muted: muted,

                    onToggleLooping: () => this.toggleField('looping'),
                    looping: looping,

                    onToggleShowControls: () => this.toggleField('showControls'),
                    showControls: showControls,

                    onToggleAutoplay: () => this.toggleField('autoplay'),
                    autoplay: autoplay,

                    onToggleBackground: () => this.toggleField('background'),
                    background: background,
                }}
                aspectRatio={options.lockAspect ? 16/9 : options.size}
            />
        )
    }

    /**
     * Renderer
     */
    render() {
        let { options, editMode } = this.props;
        let { fields } = this.state;
        const fileType = this.getFileType(fields.image);
        const { muted, forceNativeVideoAspectRatio } = fields;
        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper smaller={options.smallerWidth} flipped={options.flipped}>
                    {fileType === 'image' && (
                        <StyledMediaLinker module={this} field={'image_link_url'}>
                            {this.renderStyledMedia('c')}
                        </StyledMediaLinker>
                    )}
                    {fileType === 'video' && (
                        <StyledVideoWrapper>
                            {this.renderStyledMedia('e')}
                        </StyledVideoWrapper>
                    )}
                    {fileType === 'none' && (
                        <StyledMediaLinker module={this} field={'image_link_url'}>
                            {this.renderStyledMedia('c')}
                        </StyledMediaLinker>
                    )}
                    <StyledContentArea width={1/2} alignSelf="center">
                        <Editor
                            field={'text'}
                            data={fields.text}
                            disabled={!editMode}
                            module={this}
                            fonts={fontOptions}
                            defaultFont='Lyon Display'
                        />
                    </StyledContentArea>
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default ImageAndText;

const Wrapper = styled.section`
    max-width: 1660px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    ${p => p.smaller === true && css`
        max-width: 1260px;
        padding: 8px;

        ${media.lessThan('huge')`
            width: calc(100% - 180px);
        `}

        ${media.lessThan('large')`
            width: calc(100% - 160px);
        `}

        ${media.lessThan('medium')`
            width: calc(100% - 100px);
        `}

        ${media.lessThan('small')`
            flex-direction: column;
            width: calc(100% - 30px);
        `}

    `}

    ${props => props.flipped === true && css`
        flex-direction: row-reverse;
    `}

    ${media.lessThan('small')`
        flex-direction: column-reverse;
    `}
`

const StyledContentArea = styled.div`
    padding: 60px 130px;
    margin: auto;
    text-align: center;
    width: 50%;

    ${media.lessThan('small')`
        width: 100%;
        padding: 15px;
    `}
`

const StyledMediaLinker = styled(Linker)`
    width: 50%;
    padding: 0;
    margin: 0;

    ${media.lessThan('small')`
        width: 100%;
    `}
`
const StyledVideoWrapper = styled.div`
    width: 50%;
    padding: 0;
    margin: 0;

    ${media.lessThan('small')`
        width: 100%;
    `}
`

const StyledMedia = styled(NewMedia)`
   width: 100%;
   height: 100%;
`


