import store from '../store'
import Cookie from 'js-cookie'

const defaultState = {
    marketId: null,
    pricelistId: null,
    country: null,
    lang: null,
};

const SET_SESSION = 'SET_SESSION';
const SET_COUNTRY = 'SET_COUNTRY';
const SET_LANG = 'SET_LANG';


export const SessionReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_SESSION:
            Cookie.set('db-market-id', action.marketId)
            Cookie.set('db-pricelist-id', action.pricelistId)
            Cookie.set('db-country', action.country)
            return {
                ...{},
                ...state,
                ...{ marketId: action.marketId, pricelistId: action.pricelistId, country: action.country }
            };
        case SET_COUNTRY:
            Cookie.remove('db-market-id')
            Cookie.remove('db-pricelist-id')
            Cookie.remove('db-lang')
            Cookie.set('db-country', action.country)
            return {
                ...{},
                ...state,
                ...{ country: action.country, pricelistId: null, marketId: null, lang: null }
            };
        case SET_LANG:
            Cookie.set('db-lang', action.lang)
            return {
                ...{},
                ...state,
                ...{ lang: action.lang }
            };
        default:
            return state;
    }
};

const SessionActions = {
    setSession: (marketId, pricelistId, country) => {
        store.dispatch({
            type: SET_SESSION,
            marketId: marketId,
            pricelistId: pricelistId,
            country: country
        });
    },
    setCountry: country => {
        store.dispatch({
            type: SET_COUNTRY,
            country: country
        });
    },
    setLang: lang => {
        store.dispatch({
            type: SET_LANG,
            lang: lang
        });
    },
    getCurrentSession: () => {
        let { session } = store.getState();

        return session;
    },
};

export default SessionActions;