import React, { Component } from 'react';
import loadable from '@loadable/component'
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import Logo from 'images/db_symbol_web.svg';
import Colors from 'assets/styles/Colors';
import Hamburger from 'hamburger-react'
import { CartButton, Globals, withCart, Reservation, Cart } from 'kbcms-react';
import CartIcon from 'images/db-cart.svg';
import Cookies from 'js-cookie';
import Drawer from 'components/Drawer/index';
import { testEnvs } from '../helpers/env'
import isServer from '../isServer'
import Escape from './Escape'
import ReservationCountdown from './ReservationCountdown'
import breakpoints from './../assets/styles/Breakpoints'

const CartListing  = loadable(() => import('./CheckoutPage/CartListing'), {
    fallback: (() => <div/>)(),
})

const Callout = loadable(() => import(/* webpackChunkName: "callout" */'components/Callout'), {
    fallback: (() => <div/>)(),
});

class Header extends Component {

    constructor(props) {
        super(props);

        this.windowOffset = 0;
        this.offset = 0;

        const additionalMenuItems = Globals.get('additionalMobileMenu', []);

        const collections = additionalMenuItems.length > 0 ? additionalMenuItems.map(x => ({
            name: x.label.value,
            route: [{url: x.link.value ? x.link.value.url : null}]
        })) : []

        this.state = {
            isOpen: false,
            calloutOpen: !Cookies.get('exit'),
            calloutEnabled: Globals.get('callout_enabled') === 1,
            calloutLinks: Globals.get('callout_links'),
            isSub: false,
            categories : Globals.get('categories'),
            collections: collections,
            drawerCartOpen: false,
            largeLogo: this.props.sticky ? false : true,
        }

        this.mentionMeTimeout = null
        this.mentionMeRoute = null
    }


    componentDidMount() {
        this.validateCartReservations()
        if(!this.props.sticky) window.addEventListener('scroll', this.onScrollHandler)

        this.initMentionMe()
    }

    /**
     * Open drawer when product is added to cart
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        if((this.props.data && !prevProps.data) ||
            (
                (this.props.data && prevProps.data) &&
                (this.props.data && prevProps.data) &&
                (this.props.data.selection && prevProps.data.selection && this.props.data.selection.totals.totalQuantity > prevProps.data.selection.totals.totalQuantity)) &&
                !window.location.href.includes('/checkout')
            ){
            this.setState({drawerCartOpen: true});
        }

        // Restart Mention Me setTimeout when the path changes
        if(window.location.pathname !== this.mentionMeRoute) {
            this.initMentionMe()
        }
    }

    componentWillUnmount(){
        if(!this.props.sticky) window.removeEventListener('scroll', this.onScrollHandler);

        clearTimeout(this.mentionMeTimeout)
    }

    onScrollHandler = (e) => {
        let { largeLogo } = this.state;
        if(e.target.documentElement.scrollTop === 0 && !largeLogo){
            this.setState({ largeLogo: true });
        } else if (e.target.documentElement.scrollTop > 0 && largeLogo){
            this.setState({ largeLogo: false });
        }
    }

    /**
     * Sets the subState
     * @param {*} event
     * @return {Void}
     */
    setSubState(key){
        this.setState({ isSub: key === this.state.isSub ? false : key });
    }

    validateCartReservations() {
        const { cart } = this.props;

        if(cart && cart.selection && cart.selection.items && cart.selection.items.length > 0) {
            cart.selection.items.forEach(async i => {
                if(Reservation.isSizeIdProtected(i.item) && !Reservation.haveReservationForSizeId(i.item)) {
                    const reservationResult = await Reservation.reserveItem(i.item, i.quantity, true);
                    if(reservationResult.status === "failed") await Cart.removeLine(i.line);
                }
            })
        }
    }

    /**
     * Handles when pressing close button on callout
     * @return {Void}
     */
    closeCallout = () => {
        Cookies.set('exit', JSON.stringify(Globals.get('callout_links')), { expires: 30 });
        this.setState({
            calloutOpen: false
        });
    }

    /**
     * Check if callout_text has changed
     * @return {Boolean}
     */
    calloutCookieChange = () => {
        const { calloutEnabled } = this.state;

        if(!calloutEnabled) return false;

        if (Cookies.get('exit') === JSON.stringify(this.state.calloutLinks)) {
            return false;
        } else {
            return true;
        }
    }

    toggleOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });

        if(this.state.isSub){
            this.setState({
                isSub : false
            })
        }

        let url = window.location.href;
        if(url.includes("shop") || url.includes("category")){
            this.setSubState();
        }

        // Disable background scroll when nav is open
        // window.addEventListener('touchmove', (e) => { if (this.state.isOpen) { e.preventDefault() } }, {
        //     passive: false
        // });

        let linkList = document.querySelectorAll('#nav a');
        let windowOffset = this.windowOffset;
        let offset = this.offset;
        let header = document.getElementById('header');


        document.getElementsByTagName('html')[0].style.overflowY = 'hidden';

        if (!this.state.isOpen) {
            if(document.getElementById('header') && document.getElementById('header').offsetParent) {
                offset = document.getElementById('header').offsetParent.offsetTop;
            }
            windowOffset = window.scrollY;

            //Scroll to header if position sticky
            if (offset > windowOffset) {
                /* window.scrollTo(0, offset); */
                header.scrollIntoView({ behavior: 'smooth', block: 'start' });
                //Scroll to top if header position fixed
            } else if (offset === 0) {
                window.scrollTo(0, 0);
            }

            setTimeout(() => {
                header.style.position = 'fixed';
            }, 1000);

            for (let i = 0; i < linkList.length; i++) {
                linkList[i].addEventListener('click', this.clickHandler)
            }

        } else {
            // Remove listeners and styles when closing nav
             document.getElementsByTagName('html')[0].removeAttribute('style');
             header.removeAttribute('style');
        }
    }


    clickHandler = () => {
        let linkList = document.querySelectorAll('#nav a');

        //Remove listeners and styles when closing nav
        document.getElementsByTagName('html')[0].removeAttribute('style');
        document.getElementById('header').removeAttribute('style');

        for (let i = 0; i < linkList.length; i++) {
            linkList[i].removeEventListener('click', this.clickHandler)
        }
    }

    /**
     * Renders shop categories
     * @return {Array}
     */
    renderCategories = () => {
        let categories = [];
        let data = this.state.categories;

        categories.push(
            <Category key="all">
                <Link className="shopCategory" onClick={this.toggleOpen} to="/shop">All categories</Link>
            </Category>
        );

        data.sort((a,b) => {
            return a.sort > b.sort ? -1 : 1;
        }).forEach((item, key) => {
            if (item.route.length > 0) {   //If category is published, it has a route
                return categories.push(
                    <Category key={key}>
                        <Link className="shopCategory" onClick={this.toggleOpen} to={"/" + item.route[0].url}>{item.name}</Link>
                    </Category>
                );
            }
        });
        return categories;
    }

    /**
     * Renders collection categories from globals
     * @return {Array}
     */
    renderCollections = () => {
        let categories = [];
        const data = this.state.collections;

        return data.map((item, key) => {
            if (item.route.length > 0 ) {
                return (
                    <Category key={key}>
                        <Link className="shopCategory" onClick={this.toggleOpen} to={"/" + item.route[0].url}>{item.name}</Link>
                    </Category>
                )
            }
            return null;
        })
    }

    toggleDrawerCart = (e) => {
        if(e && typeof e.preventDefault === 'function') {
            e.preventDefault()
        }

        this.setState({ drawerCartOpen: !this.state.drawerCartOpen }, () => {
            if(typeof this.props.toggleDrawerCart === 'function') this.props.toggleDrawerCart(this.state.drawerCartOpen);
        });
    }

    initMentionMe() {
        clearTimeout(this.mentionMeTimeout)

        this.mentionMeRoute = window.location.pathname
        this.mentionMeTimeout = setTimeout(() => {
            this.openMentionMe()
        }, 12500)
    }

    openMentionMe() {
        const blacklistedPaths = [
            '/',
            '/db-black',
            '/db-black-sign-up',
            '/refer-a-friend',
        ]

        if(
          !window.mmreferrerloaded
          && !blacklistedPaths.find(path => path === window.location.pathname)
          && !window.location.pathname.includes('checkout')
          && !window.location.pathname.includes('cms')
        ) {
            const demo = testEnvs.includes(process.env.RAZZLE_STAGE)
            window.mmreferrerloaded = true

            const mentionMeScript = document.createElement('script')
            mentionMeScript.async = true
            mentionMeScript.src = `https://tag${ demo ? '-demo' : '' }.mention-me.com/api/v2/referreroffer/mm3cc06962?situation=homepagenewsletter&locale=en_GB&implementation=popup`
            document.body.appendChild(mentionMeScript);
        }
    }

    render() {
        const { drawerCartOpen, largeLogo } = this.state
        let { props } = this;
        const { hideCallout } = props

        const showCallout = !isServer && ((this.state.calloutEnabled && this.state.calloutOpen && !Cookies.get('exit')) || this.calloutCookieChange()) && !hideCallout

        return (
            <HeaderWrapper sticky={ props.sticky }>

                { drawerCartOpen && <Drawer
                    visible={ drawerCartOpen }
                    onClose={ () => this.toggleDrawerCart() }
                    noLayout={ true }>
                    { !window.location.href.includes('checkout') && <CartListing drawerMode={ true } toggle={ (e) => this.toggleDrawerCart(e) }/> }
                </Drawer> }

                <StyledHeader
                    className={ props.className }
                    fixed={ props.fixed }
                    showCallout={ showCallout }
                    id="header">

                    { showCallout && <Callout
                        onClickCalloutClose={ this.closeCallout }
                        calloutLinks={ this.state.calloutLinks }
                    /> }

                    <LogoBranding>
                        <Link to="/"><StyledLogo src={ Logo } alt="Db" large={ props.withNavigation && props.largeLogo && largeLogo }/></Link>
                    </LogoBranding>
                    { props.withNavigation && (
                        <React.Fragment>
                            <Navigation>
                                <MainNavigation>
                                    { Globals.get('mainMenu').map((item, key) => {
                                        // Make sure item is a valid object for output before attempting to render
                                        if (!item || !item.label || !item.label.value || !item.link || !item.link.value || !item.link.value.url)return null

                                        if(item.link.value.type === 'external') {
                                            return (
                                              <li key={ key }><a target="_blank" href={ item.link.value.url }>{ item.label.value }</a></li>
                                            )
                                        }

                                        return (
                                            <li key={ key }><Link to={ '/' + item.link.value.url }>{ item.label.value }</Link></li>
                                        )
                                    }) }
                                </MainNavigation>
                            </Navigation>
                            <RightWrapper>
                                <ReservationCountdown/>
                                <DbCartButton onClick={ (e) => this.toggleDrawerCart(e) } icon={ CartIcon }/>
                            </RightWrapper>
                            <MobileNavigation id="nav">
                                <Hamburger
                                    toggled={ this.state.isOpen }
                                    toggle={ this.toggleOpen }
                                    size={ 24 }
                                    direction="right"
                                    color={ Colors.black }/>

                                <StyledContent open={ this.state.isOpen }>
                                    <li><Link onClick={ this.toggleOpen } to="/">Home</Link></li>

                                    { Globals.get('mainMenu').map((item, key) => {
                                        // Make sure item is a valid object for output before attempting to render
                                        if (!item || !item.label || !item.label.value || !item.link || !item.link.value || !item.link.value.url) {
                                            return null
                                        }

                                        // Special handler for /shop and listing out the categories
                                        if (item.link.value.url === 'shop') {
                                            return (
                                                <React.Fragment key={key}>
                                                    <li key={ key }>
                                                        <div className="shopMenuItem" onClick={ (event) => this.setSubState('shop') }>{ item.label.value }</div>
                                                    </li>
                                                    { this.state.isSub === 'shop' && <Categories key={key + "shop"} className="shopCategories">{ this.renderCategories() }</Categories> }
                                                </React.Fragment>
                                            )
                                        }
                                        // Special handler for /collections and listing out the collections
                                        if (item.link.value.url === 'collections' && this.state.collections.length) {
                                            return (
                                                <React.Fragment key={key}>
                                                    <li key={key}>
                                                        <div className="shopMenuItem" onClick={ (event) => this.setSubState('collections') }>{ item.label.value }</div>
                                                    </li>
                                                    { this.state.isSub === 'collections' && <Categories key={ key + "cat" } className="shopCategories">{ this.renderCollections() }</Categories> }
                                                </React.Fragment>
                                            )
                                        }

                                        if(item.link.value.type === 'external') {
                                            return (
                                              <li key={ key }><a target="_blank" href={ item.link.value.url }>{ item.label.value }</a></li>
                                            )
                                        }

                                        return (
                                            <li onClick={ this.toggleOpen } key={ key }><Link to={ '/' + item.link.value.url }>{ item.label.value }</Link></li>
                                        )
                                    }) }
                                    <FooterLinks>
                                        <li><a href={ 'https://dbjourney.zendesk.com' } onClick={ this.toggleOpen }>Help Center</a></li>
                                        <li><a href={ 'https://dbjourney.zendesk.com/hc/en-us/requests/new' } onClick={ this.toggleOpen }>Contact</a></li>
                                        <li><Link onClick={ this.toggleOpen } to="/retailers">Retailers</Link></li>
                                        <li><Link onClick={ this.toggleOpen } to="/warranty">Warranty</Link></li>
                                    </FooterLinks>
                                </StyledContent>
                            </MobileNavigation>
                        </React.Fragment>
                    ) }
                    { (!isServer && window.location.href.includes("checkout")) && (<MobileReservationCountdown/>) }
                </StyledHeader>
            </HeaderWrapper>

        )
    }
}


export default withCart(Header)

const HeaderWrapper = styled.div`

    ${p => p.sticky && css`
        position: sticky;
        top: 0;
        z-index: 89;
    `}
`

const RightWrapper = styled.div`
    display: flex;
    flex: 2;
`

const DbCartButton = styled(CartButton)`
    text-decoration: none;
    cursor: pointer;
    text-align: right;
    white-space: nowrap;
    margin-left: auto;

    span {
        font-family: 'Circular Pro';
        font-size: 16px;
        color: ${Colors.black};
        margin-left: 5px;
        position: relative;
        top: -6px;
    }
`

const LogoBranding = styled.div`
    flex: 2;
    position: relative;

    a {
        position: absolute; 
        top: -23px;
        left: 0px;
        margin-left: 20px;
        width: 100%;
        display: block;
        min-width: 150px;
        display: flex;

        ${media.lessThan('medium')`
            margin-left: 8px;
        `}
    }
`

const StyledLogo = styled.img`
    width: 100%;
    max-width: 100%; 
    transition: max-width 0.2s;
    height: 100%; 

    transform: scale(1.5);
    transform-origin: top left;

    ${p => !p.large && css`
        max-width: 47px;
        margin-top: 0;
        transition: max-width 0.2s;
    `}

    ${media.lessThan('992px')`
        max-width: 47px;
        margin-top: 0;  
    `}
 `

const Navigation = styled.div`
    flex: 5;
    flex-grow: 10;
    text-align: center;
`

const LanguageSwitcherWrapper = styled.div`

    div {
        text-transform: uppercase;
        font-family: 'Circular Pro';
        font-size: 11px;
        flex: 2;
        color: ${Colors.gray};
        text-align: right;
        padding-right: 20px;

        span {
            margin-right: 2px;
            cursor: pointer;
        }

        span:hover {
            color: ${Colors.black};
            text-decoration: underline;
        }

        span.active {
            color: ${Colors.black};
            text-decoration: none;
        }

        span~span:before {
            pointer-events: none;
            position: relative;
            margin-right: 2px;
            content: '/';
            display: inline-block;
            color: ${Colors.gray};
        }
    }
`

const MainNavigation = styled.ul`
    font-family: 'Circular Pro';
    margin-left: 0;
    padding-left: 0;
    position: relative;
    top: -3px;

    li {
        display: inline;

        a {
            color: ${Colors.black};
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    li~li:before {
        position: relative;
        content: ' ';
        display: inline-block;
        margin: 0 8px 0 6px;
        width: 16px;
        height: 2px;
        top: -4px;
        background-color: ${Colors.black};
    }
`

const StyledHeader = styled.div`
    position: relative;
    display: flex;
    padding: 30px 20px 21px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.97);
    align-items: center;
    height: 89px;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    z-index: 89;
    
    ${p => p.showCallout && css`
        padding: 55px 20px 21px;
        height: 114px;
    `}

    ${p => p.fixed && css`
        position: fixed;
    `}

    ${media.lessThan('medium')`
        padding: 15px 15px 11px;

        ${Navigation}, ${LanguageSwitcherWrapper} {
            display: none;
        }
    `}
`

const MobileNavigation = styled.div`

    ${LanguageSwitcherWrapper} {
        display: block;
        margin-top: 32px;
        div {
            text-align: center;
            padding-right: 0;
        }
    }

    ${media.greaterThan('medium')`
        display: none;
    `}
    
    .hamburger-react {
        position: relative;
        top: -4px;
    }
`

const MobileReservationCountdown = styled(ReservationCountdown)`
    ${breakpoints.greaterThan('medium')`
        display: none;
    `}
`

const StyledContent = styled.ul`

    list-style-type: none;
    margin: 0;
    padding: 30px 0 30px 0;
    position: fixed;
    width: 100%;
    height: calc(100vh - 89px);
    top: 89px;
    left: 100%;
    transition: left .5s ease;
    transition-delay: .4s;
    overflow: auto;
    background: #fff;
    list-style-type: none;
    z-index: 100; /* IE fix */
     -webkit-overflow-scrolling: touch;

    ${p => p.open && css`
        left: 0;
    `}

    li { /*:not(.shopCategory)*/
        padding-right: 25px;
        text-align: right;
        list-style-type: none;

        a {
            text-decoration: none;
            font-family: 'Circular Pro Book';
            font-size: 27px;
            line-height: 45px;
            color: ${Colors.black};

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .shopMenuItem{
        text-decoration: none;
        font-family: 'Circular Pro Book';
        font-size: 27px;
        line-height: 45px;
        color: ${Colors.black};

        &:hover {
                text-decoration: underline;
        }
    }

    .shopCategory{
        font-size: 17px;

    }
`
const Categories = styled.ul``
const Category = styled.li``

const FooterLinks = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style-type: none;
    padding: 8px 32px;
    margin-top: 100px;

    li {
        width: calc(50% - 4px);
        padding: 10px;
        margin: 4px 0;
        text-decoration: none;
        background: transparent;
        border: 1px solid #ddd;
        text-align: center;

        a {
            font-family: 'Circular Pro Book';
            font-size: 14px;
            line-height: 25px;
            outline: none;
        }
    }
`
