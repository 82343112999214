import React from 'react';
import styled, { css } from 'styled-components';
import Button from 'components/Button';
import media from 'styled-media-query';
import CloseIcon from 'images/db-close.svg';
import { EditorToHTML, Globals, ImageMedia } from 'kbcms-react';
import ButtonNormalized from './ButtonNormalized'
import Escape from './Escape'

export default class PopupModal extends React.Component {
    constructor (props) {
        super(props)

        this.modal = React.createRef()
        this.closeButtonRef = React.createRef()
    }

    componentDidMount () {
        if(this.closeButtonRef && this.closeButtonRef.current) {
            this.closeButtonRef.current.focus()
        }
    }

    render() {
        const buttonLink = Globals.get('popup_button_link')
        const image = Globals.get('shop_popup_image_id')
        const content = Globals.get('popup_info_content')

        if (content && image && buttonLink) {
            return (
                <Escape onEscape={() => this.props.onCloseModal }>
                    <Modal onClick={ this.props.onCloseModal } ref={ this.modal }>
                        <div onClick={e => e.stopPropagation()} className="modal_content">
                             <div className="left">
                                 {image && <StyledImageMedia file={ image.value }/>}
                             </div>
                             <div className="right">
                                <CloseButton aria-label="Close" onClick={ this.props.onCloseModal } icon={ this.props.icon ? this.props.icon : CloseIcon } ref={ this.closeButtonRef }/>
                                <EditorToHTML data={content} ></EditorToHTML>
                                {buttonLink && buttonLink.map((item, key) => {
                                    if (item.link.value && item.link.value.type && item.link.value.type === 'external') {
                                        return <Button key={key} href={item.link.value.url ? item.link.value.url : ''}>{item.label.value}</Button>
                                    } else {
                                        return <Button key={key} href={`/${item.link.value ? item.link.value.url : ''}`} black>{ item.label.value }</Button>
                                    }
                                })}
                             </div>
                        </div>
                    </Modal>
                </Escape>
            )
        }
        return null
    }
}

const Modal = styled.div`
    background: rgba(0,0,0,0.4);
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

  .modal_content {
      display: flex;
      flex-direction: row;
      max-width: 95%;
      background-color: white;

      ${media.lessThan('medium')`
          width: 85%;
      `}

      p {
        font-family: 'Circular Pro Book';
      }

      .left {
        position: relative;
        width: 50%;
        flex: 1;
        img {
            max-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
        ${media.lessThan('medium')`
            display: none;
            flex: 1.1;
        `}
      }

      .right {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 2.5em 1.5em;
      }

      .close {
          position: relative;
          background: blue;
          top: 10px;
          height: 25px;
          width: 25px;
          right: 10px;
      }
  }
`

const StyledImageMedia = styled(ImageMedia)`
  background-position: 50% 50%;
`

const CloseButton = styled(ButtonNormalized)`
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-block;
    background: url(${p => p.icon ? p.icon : ''}) no-repeat center center;
    width: 25px;
    height: 25px;
    cursor: pointer;
`
