import React from 'react'
import Cookies from 'js-cookie';
import PopupModal from '../PopupModal'
import { SessionActions } from 'cms'

class DbBlackPopup extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            display: false,
            exited: false,
        }

        this.timeout = null
        this.currentPath = null
    }

    componentDidMount () {
        const exited = Cookies.get('dbBlackPopupExited')
        const session = SessionActions.getCurrentSession()

        this.setState({
            exited: session.country !== "US" ? exited : true,
        }, () => {
            if (!this.state.exited) {
                this.startTimer();
            }
        })
    }

    componentWillUnmount () {
        clearTimeout(this.timeout)
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if(this.currentPath !== window.location.pathname) {
            this.startTimer()
        }
    }

    startTimer = () => {
        const { exited } = this.state

        if (!exited) {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.open();
            }, 8000)
        }
    }

    open = () => {
        let exited = Cookies.get('dbBlackPopupExited')
        const session = SessionActions.getCurrentSession()

        exited = session.country !== "US" ? exited : true

        const blacklistedPaths = [
            '/',
            '/db-black',
            '/db-black-sign-up',
            '/refer-a-friend',
            '/refer-and-join',
        ]

        let cartListingExists = !!document.getElementById("cart-listing");

        if (!exited
            && !cartListingExists
            && !blacklistedPaths.find(path => path === window.location.pathname)
            && !window.location.pathname.includes('checkout')
            && !window.location.pathname.includes('cms')) {
            this.setState({
                display: true,
            })
        }
    }

    shopPopupExited = () => {
        Cookies.set('dbBlackPopupExited', true, { expires: 14 });

        this.setState({
            display: false,
            exited: true,
        });
    }

    render() {
        const { display } = this.state

        return <React.Fragment>
            {display && <PopupModal onCloseModal={this.shopPopupExited}/>}
        </React.Fragment>
    }
}

export default DbBlackPopup;