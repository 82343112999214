import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import isServer from '../../isServer'

class Head extends React.Component {
    constructor(props) {
        super(props);
    }

    getImageUrl = (image) => {
        if (typeof image === 'object') {
           return image.thumbnail
        }
        return image
    }

    render() {
        let { title, description, image, settings, hidden, jsonLD = null } = this.props;
        let { seo_title_prefix, seo_title, seo_description, seo_image } = settings;

        // Use fallback if not provided.
        if(!title) title = seo_title;
        if(!description) description = seo_description;
        if(!image && seo_image !== undefined) image = seo_image.original;

        if(hidden){
            return (
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
            )
        }

        if(!isServer && window && window.location && window.location.pathname && window.location.pathname === '/douchebags') {
          seo_title_prefix = 'Douchebags'
        }

        return (
            <Helmet titleTemplate={seo_title_prefix + " – %s"}>
                <title>{ title }</title>

                <meta property="og:site_name" content={ seo_title_prefix }/>

                <meta name="og:url" content={ isServer ? '' : window.location.href}/>
                <meta name="title" content={ title }/>
                <meta property="og:title" content={ title }/>
                <meta name="twitter:title" content={ title }/>

                <meta name="description" content={ description }/>
                <meta property="og:description" content={ description }/>
                <meta name="twitter:description" content={ description }/>
                <meta property="og:image" content={ this.getImageUrl(image) }/>
                <meta name="twitter:image" content={ this.getImageUrl(image) }/>
                {jsonLD !== null && (
                    <script type="application/ld+json">
                        {`${jsonLD}`}
                    </script>
                )}
            </Helmet>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.settings,
        ...ownProps
    };
};
export default connect(mapStateToProps)(Head);
