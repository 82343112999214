import Store from '../../store'
import QueryString from 'query-string'
import Cookies from 'js-cookie'
import isServer from 'isServer'

const Webgains = (events, attempts = 1) => {
    handleEvents(events)
};

const queryString = isServer ? {} : QueryString.parse(window.location.search)


if(typeof queryString.DB !== 'undefined') {
    Cookies.set('webgains-affiliation-code', queryString.DB)
}

const generateWebgainsItemsString= (countryConfig, event) => {
    let rows = [];

    event.items.forEach(product => {
        for (let i = 0; i < product.quantity; i++) {
            rows.push(`${ event.isReturning ? countryConfig.returningEventId : countryConfig.eventId }::${ product.priceWithoutTax / product.quantity }::${ product.name }::${ product.id }::`)
        }
    })

    return rows.join('|')
}


const webgainConfig = {
    us: {
        programId: 280805,
        eventId: 1061445,
        returningEventId: 1061445,
        langCode: 'US_en',
    },
    fr: {
        programId: 283635,
        eventId: 1067345,
        returningEventId: 1067345,
        langCode: 'FR_fr',
    },
    gb: {
        programId: 279785,
        eventId: 1059215,
        returningEventId: 1059215,
        langCode: 'EN_en',
    },
    no: {
        programId: 287085,
        eventId: 1073035,
        returningEventId: 1073025,
        langCode: 'NO_nb',
    },
    se: {
        programId: 287085,
        eventId: 1073035,
        returningEventId: 1073025,
        langCode: 'SE_sv',
    },
    dk: {
        programId: 287085,
        eventId: 1073035,
        returningEventId: 1073025,
        langCode: 'DK_da',
    },
    fi: {
        programId: 287085,
        eventId: 1073035,
        returningEventId: 1073025,
        langCode: 'FI_fi',
    },
    de: {
        programId: 287075,
        eventId: 1073015,
        returningEventId: 1073005,
        langCode: 'DE_de',
    },
    nl: {
        programId: 288165,
        eventId: 1075205,
        returningEventId: 1075215,
        langCode: 'NL_nl',
    },
    be: {
        programId: 288165,
        eventId: 1075205,
        returningEventId: 1075215,
        langCode: 'NL_nl',
    },
    lu: {
        programId: 288165,
        eventId: 1075205,
        returningEventId: 1075215,
        langCode: 'LU_lu',
    }
}

const handleEvents = (events, attempts = 1) => {
    if(isServer === true) return

    const { session, settings } = Store.getState()
    const cookie = Cookies.get('webgains-affiliation-code');

    if(cookie === undefined && session.country === null) return

    const countryCode = (typeof cookie !== 'undefined' && cookie.length > 0) ? cookie.toLowerCase() : session.country.toLowerCase()

    const validCountry = ['no', 'de', 'se', 'dk', 'fi', 'us', 'gb', 'fr', 'nl', 'be', 'lu'].includes(countryCode)

    if(!validCountry) return

    let countryConfig = webgainConfig[countryCode];

    // If blocked by ad-blockers or similar it will never get loaded
    if(attempts > 20) return

    if(typeof window.ITCLKQ === 'undefined') {
        attempts++
        setTimeout(() => {
            handleEvents(events, attempts)
        }, 1000)
        return
    }

    events.forEach(event => {
        switch (event.type) {
            case 'pageView':
                console.log(event);
                console.log(countryCode);
                window.ITCLKQ('set', 'internal.cookie', true)
                window.ITCLKQ('set', 'internal.api', true)
                window.ITCLKQ('click')
                break;
            case 'completeCheckout':
                console.log('Pre load');
                console.log(event);
                console.log(countryCode);
                (function(w,e,b,g,a,i,n,s){w['ITCVROBJ']=a;w[a]=w[a]||function(){
                    (w[a].q=w[a].q||[]).push(arguments)},w[a].l=1*new Date();i=e.createElement(b),
                    n=e.getElementsByTagName(b)[0];i.async=1;i.src=g;n.parentNode.insertBefore(i,n)
                })(window,document,'script','https://analytics.webgains.io/cvr.min.js','ITCVRQ');
                console.log('Post load');
                console.log(event);
                console.log(countryCode);
                ITCVRQ('set', 'trk.programId', countryConfig.programId);
                ITCVRQ('set', 'cvr', {
                    value: event.revenue - event.tax,
                    currency: event.currency,
                    language: countryConfig.langCode,
                    eventId: event.isReturning ? countryConfig.returningEventId : countryConfig.eventId,
                    orderReference: event.id,
                    comment: '',
                    multiple: '',
                    checksum: '',
                    items: generateWebgainsItemsString(countryConfig, event),
                    customerId:'',
                    voucherId: event.coupon !== null ? event.coupon : '',
                    location: document.referrer || document.location.href || 'https://dbjourney.com'
                });
                ITCVRQ('conversion');
            break;
            default:
                break;
        }
    });
}

export default () => {
    return Webgains;
};