import React from 'react';
import DouchebagTemplateStyles from 'components/DouchebagTemplateStyles';
import { BaseTemplate, ModuleZone } from 'kbcms-react'

class StoryTemplate extends BaseTemplate {

    /**
     * Human readable name. Will be displayed in the CMS
     */
    static getName() { return "Story Douchebag template" }

    /**
     * Renderer
    */
    render() {
        const { entry, ssr, frontend, zones, editMode } = this.props

        return (
            <DouchebagTemplateStyles>
                <ModuleZone
                  entry={entry}
                  ssr={ssr}
                  frontend={ frontend }
                  zones={zones}
                  ref={this.registerZone}
                  editMode={editMode}
                />
            </DouchebagTemplateStyles>
        )
    }
}

export default StoryTemplate