import Store from '../../store'
import isServer from '../../../isServer'

const Ometria = (events, attempts = 1) => {
    handleEvents(events)
};

const storeIdMap = {
    'AUD': 'retail1',
    'CAD': 'retail2',
    'DKK': 'retail3',
    'EUR': 'retail4',
    'GBP': 'retail5',
    'NOK': 'retail6',
    'SEK': 'retail7',
    'USD': 'retail8',
}

const handleEvents = (events, attempts = 1) => {
    // If blocked by ad-blockers or similar it will never get loaded
    if(isServer || attempts > 20) return

    const { session, countries } = Store.getState()

    if(typeof window.ometria === 'undefined' || session.country === null || countries.loaded === false) {
        attempts++
        setTimeout(() => {
            handleEvents(events, attempts)
        }, 1000)
        return
    }

    const country = countries.countries.find(c => c.lang === session.country.toLowerCase())

    if(!country) return

    const storeId = `${ storeIdMap[country.currency] }`

    events.forEach(event => {
        switch (event.type) {
            case 'identify':
                window.ometria.identify(event.email)
                break;
            case 'pageView':
                if(event.page === "/") {
                    window.ometria.init('homepage', null, storeId)
                } else {
                    window.ometria.init('listing', null, storeId)
                }
                break;
            case 'productView':
                window.ometria.init('product',{
                    pid: event.id
                }, storeId);
                break;
            case 'addToCart':
                window.ometria.trackAddToBasket(event.id, 1)
                break;
            case 'setCartData':
                let basket = new window.ometria.Basket()

                if(!event || !event.data || !event.data.selection) return

                // Using the Centra token as basket token
                basket.setId(`${event.data.token}`);
                basket.setTotal(`${ event.data.selection.totals.grandTotalPriceAsNumber }`, `${ event.data.selection.currency }`)

                event.data.selection.items.forEach(item => {
                    basket.addLineItem(item.sku, item.quantity, item.priceEachAsNumber)
                })

                window.ometria.setBasket(basket);

                break;
            case 'initiateCheckout':
                window.ometria.init('checkout', null, storeId)
                break;
            case 'completeCheckout':
                window.ometria.trackTransaction(event.id)
                break;
            default:
                break;
        }
    });
}

export default () => {
    return Ometria;
};