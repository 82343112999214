import React from 'react';
import styled, { css } from 'styled-components';
import Colors from 'assets/styles/Colors';

const Input = ({label = null, border = "rounded", name, as, placeholder, description, options, error, errorMessage, onChange, value, inactive, small = false}) => {

    if (typeof value === 'undefined' || value === null || value === true || value === false) value = '';

    return (
        <Field>
            {label !== null && <Label inactive={inactive} htmlFor={name}>{label}</Label>}
            <InputField
                error={error}
                name={name}
                id={name}
                as={as}
                placeholder={placeholder}
                onChange={inactive ? (() => { return null; }) : onChange}
                value={value}
                inactive={inactive}
                border={border}
                small={small}
            >
                {options}
            </InputField>
            {description !== '' &&
                <Description error={error}>{description}</Description>
            }
            {error &&
                <ErrorMessage>{errorMessage}</ErrorMessage>
            }

    </Field>
    );
};

export default Input;

const Field = styled.div`
    position: relative; 
    width: 100%; 
`

const Label = styled.label`
    ${p => p.inactive && css`
        color: ${Colors.lightGray}; 
    `}
`

export const InputField = styled.input`
    font-size: 16px;
    width: 100%;
    border: 0;
    background: white;
    border: 1px solid ${Colors.black};
    border-radius: 5px;
    padding: 10px 0 12px 10px;
    font-family: 'Circular Pro Book';
    outline: none;
    color: ${Colors.black};
    -webkit-appearance: none;
    margin: 5px 0;

    &::placeholder {
        color: ${Colors.darkGray};
    }

    &:hover {
        border: 1px solid ${Colors.blue};
    }
    
    &:focus {
        box-shadow: inset 0px 0px 0px 1px ${Colors.blue};
        box-sizing: border-box; 
    }

    ${p => p.inactive && css`
        border: 1px solid ${Colors.lightGray}; 
        color: ${Colors.lightGray};
        cursor: initial; 
    `}

    ${p => (p.error) && css`
        border: 1px solid red;
    `}
  
    ${p => p.border === "square" && css`
      border-radius: 0px;
    `}
    
    ${p => p.small && css`
        font-size: 14px;
    `}
`

const ErrorMessage = styled.div`
    color: red; 
    font-size: 12px;
    margin-bottom: 15px;
`

const Description = styled.div`
    font-size: 12px;
    margin-bottom: 15px; 
    ${p => (p.error) && css`
        margin-bottom: 5px; 
    `}
`
