import store from '../store'
import ApiManager from "../ApiManager";

const Api = new ApiManager();

const mimeTypes = {
    images: ['image/png', 'image/jpeg', 'image/jpg'],
    videos: ['video/mp4', 'url/video'],
};

const defaultOptions = {
    multiple: false,
    fileTypes: [],
    mimeTypes: [],
};

const defaultState = {
    rootLoaded: false,
    modalOpen: false,
    callback: null,
    options: {},
    currentFolderId: null,
    currentFolder: [],
    folders: [],
    files: [],
    searchInput: '',
    searchFolders: [],
    searchFiles: [],
    selectedFile: null,
    selectedFiles: [],
};

const ROOT_LOADED = 'ROOT_LOADED';
const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const SELECT_FILE = 'SELECT_FILE';
const SET_FOLDERS = 'SET_FOLDERS';
const SET_FILES = 'SET_FILES';
const SET_SEARCH_FILES = 'SET_SEARCH_FILES';
const TOGGLE_EXPAND = 'TOGGLE_EXPAND';
const OPEN_FOLDER = 'OPEN_FOLDER';
const CREATE_FOLDER = 'CREATE_FOLDER';
const MOVE_FILE = 'MOVE_FILE';
const MOVE_FOLDER = 'MOVE_FOLDER';
const DELETE_FILE = 'DELETE_FILE';
const DELETE_FOLDER = 'DELETE_FOLDER';

const findFileById = (files, fileId) => {
    let file = null;

    files.map((f) => {
        if(parseInt(f.id) === parseInt(fileId)) {
            file = f;
        }
    });

    return file;
};

const toggleExpand = (folders, toggleFolder) => {
    return folders.map((folder) => {
        if (folder.id === toggleFolder.id) {
            folder.expanded = !folder.expanded;
        } else if (folder.children) {
            folder.children = toggleExpand(folder.children, toggleFolder);
        }
        return folder;
    })
};

const createFolder = (folders, parentId, newFolder) => {
    return folders.map((folder) => {
        if (folder.id === parentId) {
            folder.children.push(newFolder);
            folder.has_children = true;
        } else if (folder.children) {
            folder.children = createFolder(folder.children, parentId, newFolder);
        }
        return folder;
    });
};

const removeFileById = (files, fileId) => {
    let fileIndex = null;

    // Figure out the file's index.
    files.map((file, key) => {
        if(parseInt(file.id) === parseInt(fileId)){
            fileIndex = key;
        }
    });

    let newArray = files.slice();

    newArray.splice(fileIndex, 1);

    return newArray;
};

const addFolder = (folders, folder) => {
    let newArray = folders.slice();

    if(folder.parent_id == null) {
        newArray.push(folder);
    } else {
        folders.map((f) => {
            if(f.id === folder.parent_id) {
                f.children.push(folder);
                f.has_children = true;
            } else if(f.children.length) {
                f.children = addFolder(f.children, folder);
            }
        })
    }

    return newArray;
};

const removeFolderById = (folders, folderId) => {
    let folderIndex = null;

    // Figure out the folder's index
    folders.map((folder, key) => {
        if(parseInt(folder.id) === parseInt(folderId)){
            folderIndex = key;
        }
    });

    let newArray = folders.slice();

    if(folderIndex !== null) {
        newArray.splice(folderIndex, 1);
    } else {
        folders.map((folder) => {
            if(folder.children.length) {
                folder.children = removeFolderById(folder.children, folderId);
                if(folder.children.length === 0) {
                    folder.has_children = false;
                }
            }
            return folder;
        })
    }

    return newArray;
};

export const FileManagerReducer = (state = defaultState, action) => {
    let selectedFiles = [];

    switch (action.type) {
        case ROOT_LOADED:
            return {
                ...{}, ...state,
                ...{
                    rootLoaded: true,
                }
            };
        case OPEN_MODAL:
            selectedFiles = [];

            if (action.options.selected !== undefined && action.options.selected.length > 0) {
                action.options.selected.map((sf) => {
                    selectedFiles.push(sf);
                });
            }

            return {
                ...{}, ...state,
                ...{
                    modalOpen: true,
                    selectedFile: null,
                    selectedFiles: selectedFiles,
                    callback: action.callback,
                    options: action.options,
                }
            };
        case CLOSE_MODAL:
            return {
                ...{}, ...state,
                ...{
                    modalOpen: false,
                    callback: null,
                    options: {
                        ...{}, ...defaultOptions,
                        ...{
                            fileTypes: [],
                            mimeTypes: [],
                        }
                    },
                }
            };
        case SELECT_FILE:
            let selectedFile = findFileById(state.files, action.fileId);
            selectedFiles = [];

            if (state.options.multiple) {
                let exists = false;
                state.selectedFiles.map((sf) => {
                    if(sf.id === selectedFile.id) {
                        exists = true;
                    } else {
                        selectedFiles.push(sf);
                    }
                });
                if(!exists) {
                    selectedFiles.push(selectedFile);
                }
            }

            return {
                ...{}, ...state,
                ...{
                    selectedFile: selectedFile,
                    selectedFiles: selectedFiles,
                }
            };
        case SET_FOLDERS:
            return {
                ...{}, ...state,
                ...{
                    folders: action.folders,
                }
            };
        case SET_FILES:
            return {
                ...{}, ...state,
                ...{
                    files: action.files,
                }
            };
        case SET_SEARCH_FILES: 
            return {
                ...{}, ...state,
                ...{
                    searchFiles: action.searchFiles,
                    searchFolders: action.searchFolders,
                    searchInput: action.searchInput,
                }
            };
        case TOGGLE_EXPAND:
            return {
                ...{}, ...state,
                ...{
                    folders: toggleExpand(state.folders, action.folder),
                }
            };
        case OPEN_FOLDER:
            return {
                ...{}, ...state,
                ...{
                    currentFolderId: action.folderId,
                    files: action.files,
                    currentFolder: action.currentFolder,
                }
            };
        case CREATE_FOLDER:
            if(action.parentId === null) {
                state.folders.push(action.newFolder);
            }
            return {
                ...{}, ...state,
                ...{
                    folders: createFolder(state.folders, action.parentId, action.newFolder),
                }
            };
        case MOVE_FILE:
            return {
                ...{}, ...state,
                ...{
                    files: removeFileById(state.files, action.fileId),
                }
            };
        case MOVE_FOLDER:
            return {
                ...{}, ...state,
                ...{
                    folders: addFolder(
                        removeFolderById(state.folders, action.folderId),
                        action.folder
                    ),
                }
            };
        case DELETE_FILE:
            return {
                ...{}, ...state,
                ...{
                    files: removeFileById(state.files, action.fileId),
                }
            };
        case DELETE_FOLDER:
            return {
                ...{}, ...state,
                ...{
                    folders: removeFolderById(state.folders, action.folderId),
                }
            };
        default:
            return state;
    }
};

const FileManagerActions = {
    rootLoaded: () => {
        store.dispatch({
            type: ROOT_LOADED,
        });
    },
    openModal: (options, callback) => {
        if(typeof callback === 'undefined' && typeof options === 'function') {
            callback = options;
            options = {};
        }

        options = {...{},...defaultOptions, ...options};

        // Translating provided FileTypes to mimetypes
        if(options.fileTypes.length > 0) {
            options.fileTypes.map((ft) => {
                mimeTypes[ft].map((mt) => {
                    options.mimeTypes.push(mt);
                })
            })
        }

        store.dispatch({
            type: OPEN_MODAL,
            callback: callback,
            options: options,
        });
    },
    closeModal: () => {
        store.dispatch({
            type: CLOSE_MODAL,
        });
    },
    selectFile: (fileId) => {
        store.dispatch({
            type: SELECT_FILE,
            fileId: fileId,
        });
        let file_manager = store.getState().file_manager;

        if (file_manager.options.multiple === false && typeof file_manager.callback === 'function') {
            store.dispatch({
                type: CLOSE_MODAL,
            });
            file_manager.callback(file_manager.selectedFile);
        }
    },
    getSelectedFile: () => {
        return store.getState().file_manager.selectedFile; 
    },
    selectFiles: () => {
        let file_manager = store.getState().file_manager;

        store.dispatch({
            type: CLOSE_MODAL,
        });

        file_manager.callback(file_manager.selectedFiles);
    },
    setFolders: (folders) => {
        store.dispatch({
            type: SET_FOLDERS,
            folders: folders,
        });
    },
    setFiles: (files) => {
        store.dispatch({
            type: SET_FILES,
            files: files,
        });
    },
    setSearchFiles: (input, folders, files) => {
        store.dispatch({
            type: SET_SEARCH_FILES, 
            searchInput: input,
            searchFolders: folders,
            searchFiles: files
        })
    },
    toggleExpand: (folder) => {
        store.dispatch({
            type: TOGGLE_EXPAND,
            folder: folder,
        });
    },
    openFolder: (folderId) => {
        let folders = store.getState().file_manager.folders;

        let currentFolder = folders.filter(function(folder){
            if(folder.id === folderId) return folder;
        });

        Api.getFiles(folderId).then((result) => {
            store.dispatch({
                type: OPEN_FOLDER,
                folderId: folderId,
                files: result.data.files,
                currentFolder: currentFolder
            });
        });
    },
    createFolder: (parentId, name) => {
        Api.createFolder(parentId, name).then((result) => {
            store.dispatch({
                type: CREATE_FOLDER,
                parentId: parentId,
                newFolder: result.data,
            });
            FileManagerActions.openFolder(result.data.id);
        });
    },
    moveFile: (fileId, folderId) => {
        Api.updateFile(fileId, {
            folder_id: folderId,
        }).then((result) => {
            store.dispatch({
                type: MOVE_FILE,
                fileId: fileId,
                folderId: folderId,
            });
        });
    },
    moveFolder: (folderId, parentId) => {
        Api.updateFolder(folderId, {
            parent_id: parentId,
        }).then((result) => {
            store.dispatch({
                type: MOVE_FOLDER,
                folderId: folderId,
                parentId: parentId,
                folder: result.data,
            });
        });
    },
    deleteFile: (fileId) => {
        Api.deleteFile(fileId).then((result) => {
            store.dispatch({
                type: DELETE_FILE,
                fileId: fileId,
            });
        });
    },
    deleteFolder: (folderId) => {
        Api.deleteFolder(folderId).then((result) => {
            store.dispatch({
                type: DELETE_FOLDER,
                folderId: folderId,
            });
        });
    }, 
    renameFile: (fileId, filename) => {
        Api.updateFile(fileId, { display_name: filename }).then(result => {
            console.log(result); 
        }); 
    }
};

export default FileManagerActions;