import React from 'react';
import { Cart, CartActions } from 'kbcms-react';

class MulberryModal extends React.Component {

    constructor(props){
        super(props);
    }

    openModal() {
        this.initializeMulberry().then(() => {
            window.mulberry.modal.open();
        });
    }

    addWarranty = (selectedOffer) => {
        let { product } = this.props;

        let selectedWarrantyVariation = product.warranty_products.find(wp => {
            return wp.id === selectedOffer.id
        })

        selectedWarrantyVariation = {
            ...selectedWarrantyVariation,
            ...{
                parent_sku: product.sku,
            }
        }

        Cart.add(selectedWarrantyVariation.sizes[0].size_id, selectedWarrantyVariation).then(() => {
            window.mulberry.modal.close()
            CartActions.addWarranty(selectedWarrantyVariation, product)
        }).catch(error => {
            console.log(error);
        });
    }

    async loadMulberry() {
        return new Promise((resolve, reject) => {
            let script = document.createElement('script');

            script.type = 'text/javascript'
            script.addEventListener('load', function(event) {
                resolve()
            });
            script.src = 'https://app.getmulberry.com/plugin/static/js/mulberry.js'

            document.getElementsByTagName('head')[0].appendChild(script)
        })
    }

    async initializeMulberry() {
        let { product } = this.props;

        await this.loadMulberry()

        let mulberry = window.mulberry;

        await mulberry.core.init({
            api_endpoint: process.env.RAZZLE_MULBERRY_ENDPOINT,
            publicToken: process.env.RAZZLE_MULBERRY_TOKEN,
        });

        const coverage = window.mulberryCoverageData ? window.mulberryCoverageData : await mulberry.core.getCoverageDetails('503563bc');
        window.mulberryCoverageData = coverage

        const { settings } = mulberry.core;

        const offers = product.warranty_products.map(wp => {
            return {
                id: wp.id,
                cost : wp.prices[0].price_as_number,
                duration_months: wp.sku.slice(wp.sku.length - 2),
            }
        }).sort((a, b) => a.duration_months < b.duration_months ? -1 : 1)

        await mulberry.modal.init({
            offers,
            settings,
            coverage,
            onWarrantySelect: (selectedOffer) => { this.addWarranty(selectedOffer) },
            onWarrantyDecline: () => { mulberry.modal.close() },
        });
    }

    render() {
        return <div/>
    }
}

export default MulberryModal; 