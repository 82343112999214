import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { createGlobalStyle } from 'styled-components';
import Globals from 'assets/styles/Globals';
import NewsletterPopup from 'components/NewsletterPopup';
import Cookies from 'js-cookie';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import env from '../../helpers/env'
import CarbonOffsetPopup from '../CarbonOffsetPopup'
import DbBlackPopup from '../DbBlackPopup'
import ReservationModal from '../ReservationModal'
import SkipToLandmarks, { MAIN_CONTENT_ID } from "../SkipToLandmarks";
import { SessionActions } from 'kbcms-react'

const GlobalStyles = createGlobalStyle`${Globals}`;

class Layout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openNewsletter: false,
            shopPopup: false,
            newsletterExited: false,
            dbBlackPopupExited: false,
            drawerCartOpen: false,
        }

        this.shopPopupTimeout = null
    }

    /**
     * Component did mount
     * Set event listeners and start idle timer
     */
    componentDidMount() {
        const newsletterExited = Cookies.get('newsletterExited')
        const dbBlackPopupExited = Cookies.get('dbBlackPopupExited')
        const session = SessionActions.getCurrentSession()

        this.setState({
            newsletterExited: newsletterExited,
            dbBlackPopupExited: session.country != "US" ? dbBlackPopupExited : true,
        }, () => {
            if (!newsletterExited || !dbBlackPopupExited) {
                this.events = [
                    'load',
                    'mousedown',
                    'click',
                    'scroll',
                    'keypress'
                ];

                for (var i in this.events) {
                    window.addEventListener(this.events[i], this.resetTimer);
                }

                this.startTimer();
            }
        })
    }

    componentWillUnmount() {
        clearTimeout(this.shopPopupTimeout)
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const { shopPopup } = this.state
        const dbBlackPopupExited = Cookies.get('dbBlackPopupExited')

        if (!shopPopup && !dbBlackPopupExited && this.shopPopupTimeout === null && (
            this.props.children && this.props.children.props && this.props.children.props.currentRoute && this.props.children.props.currentRoute.url === 'shop')) {
            this.shopPopupTimeout = setTimeout(() => {
                this.shopPopupTimeout = null
                if (this.props.children && this.props.children.props && this.props.children.props.currentRoute && this.props.children.props.currentRoute.url === 'shop') {
                    this.setState({
                        shopPopup: true,
                        dbBlackPopupExited: true,
                    });
                }

            }, 8000)
        }
    }

    /**
     * Start idle timer
     * @return {Void}
     */
    startTimer = () => {
        const { newsletterExited } = this.state

        // Hide popups on local
        if(window.location.href === env.RAZZLE_PUBLIC_URL && !newsletterExited){
            this.timer = setTimeout(() => {
                this.setState({
                    openNewsletter: true
                });

                for (var i in this.events) {
                    window.removeEventListener(this.events[i], this.resetTimer);
                }
            }, 8000);
        }
    }

    /**
     * Reset timer if an event occurs
     * @return {Void}
     */
    resetTimer = () => {
        clearTimeout(this.timer)
        this.startTimer()
    }

    /**
     * Set states when newsletter popup is exited
     * @return {Void}
     */
    newsletterExited = () => {
        Cookies.set('newsletterExited', true, { expires: 30 });

        this.setState({
            openNewsletter: false,
            newsletterExited: true,
        });
    }

    toggleDrawerCart = (drawerCartOpen) => {
        this.setState({ drawerCartOpen: drawerCartOpen });
    }

    /**
     * Renderer
    */
    render() {
        let { children } = this.props;

        return (
            <React.Fragment>
                <SkipToLandmarks />
                { this.state.openNewsletter && !this.state.newsletterExited && <NewsletterPopup close={this.newsletterExited}/> }
                <ReservationModal/>
                <DbBlackPopup/>
                <CarbonOffsetPopup/>

                <GlobalStyles />

                <Header
                    withNavigation={true}
                    fixed={true}
                    toggleDrawerCart={this.toggleDrawerCart}
                    currentRoute={ children && children.props && children.props.currentRoute ? children.props.currentRoute : null }
                    largeLogo={children && children.props && children.props.currentRoute && children.props.currentRoute.model && children.props.currentRoute.model.large_logo}
                />

                <Wrapper drawerCartOpen={this.state.drawerCartOpen} id={MAIN_CONTENT_ID}>
                    {this.props.children}
                </Wrapper>

                <Wrapper drawerCartOpen={this.state.drawerCartOpen}>
                    <Footer />
                </Wrapper>
            </React.Fragment>
        )
    }
}

export default Layout;

const Wrapper = styled.main`
    ${media.lessThan('medium')`
        ${p => p.drawerCartOpen && css`
            position: fixed;
            overflow-y: hidden;
        `}
    `}
`
