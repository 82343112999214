import isServer from '../isServer'

let yotpoLoaded = false

const loadYotpo = () => {
  if(yotpoLoaded) return

  (function e () {
    let e = document.createElement('script')
    e.type = 'text/javascript', e.defer = true, e.src = '//staticw2.yotpo.com/fdTwIaDTgraO0KoDAaphPvnw5XwzGYPh7k9XlA5m/widget.js'
    let t = document.getElementsByTagName('script')[0]
    t.parentNode.insertBefore(e, t)
  })()

  yotpoLoaded = true
}

const yotpoLoader = () => {
  if(isServer) return

  window.yotpoRefreshInterval = null

  window.yotpoLoader = () => {
    clearInterval(window.yotpoRefreshInterval)

    if(!window.yotpo && !yotpoLoaded) {
      loadYotpo()
    }

    if(!window.yotpo) {
      window.yotpoRefreshInterval = setInterval(() => {
        if(window.yotpo) {
          window.yotpo.refreshWidgets()
          clearInterval(window.yotpoRefreshInterval)
        }
      }, 500)
    }

    if(window.yotpo) {
      clearInterval(window.yotpoRefreshInterval)
      window.yotpo.refreshWidgets()
    }
  }
}

export default yotpoLoader