import React from 'react';
import styled from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';

/**
 * Seperator Plugin
 */
export default class Title extends React.Component {

    /**
     * Component config
     * @return {Object}
     */
    static config() {
        return {
            // Button name. This we can change to Icon name or something.
            name: 'Title',

            // Is this component void? Void means no editable contents within.
            isVoid: false,

            // Should this be rendered as an option in a list?
            isPartOfList: true,

            // Should this plugin insert new block, or replace current.
            insert: false,
        }
    }


    /**
     * Serialize this class to HTML.
     * @return {String}
     */
    static serialize(data, children) {
        return <StyledTitle>{children}</StyledTitle>
    }


    /**
     * Renderer. {this}
     * @return {JSX}
     */
    render() {
        return (
            <StyledTitle {...this.props.attributes}>{this.props.children}</StyledTitle>
        )
    }
}

const StyledTitle = styled.div`
    font-size: 16px !important;
    line-height: 20px !important;
    font-family: 'Circular Pro' !important;
    text-transform: uppercase;
    margin: 0 0 4px !important;

    ${media.lessThan('medium')`
        font-size: 9px !important;
        line-height: 18px !important;
    `}
`
