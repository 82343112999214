import React from 'react';
import BaseModule from 'modules/DBBaseModule';
import styled, { css } from 'styled-components';
import PlayIcon from 'images/db-play.svg';
import { IoMdPlay } from 'react-icons/io';
import { VideoMedia } from 'kbcms-react';
import { default as media } from 'assets/styles/Breakpoints';
import OptionsWrapper from 'components/OptionsWrapper';

class Video extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Video" }


    /**
     * Constructor
     * @return {Void}
     */
    constructor(props) {
        super(props)

        this.state = {
            playing: false,
            fields: {
                ...props.fields,
            }
        }
    }
    /**
     * Component will unmount
     */
    componentWillUnmount(){
        this.setState({ playing : false })
    }

    /**
     * Handles button click
     * @return {Void}
     */
    onClick() {
        if (this.videoPlayerRef !== null) {
            this.setState({ playing: true });
        }
    }

    /**
     * Reset button state if file manager is opened.
     * @return {Void}
     */
    resetButton(){
        this.setState({playing : false})
    }

    /* Handles pause state for video */
    onPause = () => this.setState({ playing: false })

    /* Toggles mute for video*/
    onToggleMute = () => this.setState({ ...this.state, fields: { ...this.state.fields, muted: !this.state.fields.muted }})

    /**
     * Renderer
     * @return {Void}
     */
    render() {
        const { editMode, options } = this.props;
        const { playing } = this.state;

        return (
            <OptionsWrapper  options={options} editMode={editMode}>
                <Wrapper modalOpened={ (e) => this.resetButton(e) } editMode={ editMode }>
                    <VideoMedia
                        id="media"
                        field={'video'}
                        module={this}
                        types={['videos']}
                        playing={playing}
                        muted={this.state.fields.muted}
                        onToggleMute={this.onToggleMute}
                        onPause={this.onPause}
                        editPosition="nw"
                        fileManagerOpened={(e) => this.resetButton(e)}
                        editMode={editMode}
                    />
                    {!playing && (
                        <PlayButton onClick={(e) => this.onClick(e)}>
                            <StyledPlayIcon/>
                        </PlayButton>
                    )}
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default Video;

const Wrapper = styled.div`
    position: relative;
    margin: 0 auto;

    ${props => props.editMode && css`
        min-height: 250px;
    `}
`

const StyledPlayIcon = styled(IoMdPlay)`
    height: 32px;
    width: 32px;
    color: white;

    ${media.lessThan('medium') `
        width: 22px;
        height: 22px;
    `}
`

const PlayButton = styled.div`
    width: 92px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    position: relative;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    &:hover {
        background-color: rgba(255, 255, 255, 0.4);
    }

    ${media.lessThan('medium') `
        width: 72px;
        height: 42px;
    `}

    ${StyledPlayIcon}:hover & {
        color: black;
    }
`

