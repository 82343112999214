import React from 'react'
import { NewMedia } from '../../cms';
import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import BaseModule from 'modules/DBBaseModule';
import OptionsWrapper from 'components/OptionsWrapper';

class PageTitleWithBackground extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Full width page title with background" }

    /**
     * Constructor
     */
    constructor(props) {
        super(props)

        this.onChangeField = this.onChangeField.bind(this)

        this.state = {
            fields: {
                title: 'Edit this title',
                muted: false,
                forceNativeVideoAspectRatio: false,
                image: {}
            }
        }
    }

    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    componentDidMount() {
        if (this.props.fields) {
            this.setState({
                fields: Object.assign({}, this.state.fields, this.props.fields)
            });
        }
    }

    /**
    * When content editable field is changed
    * @return {Void}
    */
    onChangeField = event => {
        this.setState({ fields: Object.assign(this.state.fields, { [event.nativeEvent.target.getAttribute('name')]: event.target.value }) })
    }


    /**
     * Renderer
     */
    render() {
        let { options, editMode } = this.props;
        let { fields } = this.state;
        const { forceNativeVideoAspectRatio, muted } = fields;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <StyledMedia
                    module={this}
                    field={'image'}
                    overlay={options.overlay}
                    types={['images', 'videos']}
                    fill
                    videoOptions={{
                        onToggleMute: () => this.setState({
                            ...this.state,
                            fields: {
                                ...fields,
                                muted: !muted
                            }
                        }),
                        onToggleNativeAspectRatio: () => this.setState({
                            ...this.state,
                            fields: {
                                ...fields,
                                forceNativeVideoAspectRatio: !forceNativeVideoAspectRatio
                            }
                        }),
                        forceNativeVideoAspectRatio,
                        muted,
                        autoplay: muted
                    }}
                    aspectRatio={16/4}
                    editPosition={'s'}
                    editMode={editMode}>
                    <TitleField
                        name="title"
                        tagName="h2"
                        disabled={!editMode}
                        html={fields.title}
                        onChange={this.onChangeField}
                    />
                </StyledMedia>
            </OptionsWrapper>
        );
    }
}

export default PageTitleWithBackground

const StyledMedia = styled(NewMedia)`
    min-height: 250px;
    margin: 0 auto;
`

const TitleField = styled(ContentEditable)`
    outline: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    text-align: center;

    &:focus {
        outline: 1px dashed rgba(0, 89, 175, 0.4);
    }
`
