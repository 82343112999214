import axios from 'axios';
import SessionActions from 'reducers/session';
import Store from 'cms/store';
import AuthActions from 'reducers/auth';
import { getUrlParameter } from 'cms/helpers'
import isServer from '../isServer'

let constructed = false;
let overrideApiUrl = null;

export default class ApiManager {

    /**
     * Constructor
     * @param {void}
     */
    constructor(apiUrl) {
        const overrideApiUrlParam = getUrlParameter('overrideApiUrl');

        if(overrideApiUrlParam.length > 0) {
            overrideApiUrl = overrideApiUrlParam
        }
        if(typeof apiUrl !== 'undefined' && apiUrl.length > 0) {
            overrideApiUrl = apiUrl
        }

        this.apiUrl = overrideApiUrl !== null ? overrideApiUrl : process.env.RAZZLE_API_URL
        this.url = this.apiUrl ? this.apiUrl + '/api/internal/' : 'http://127.0.0.1:8000/api/internal/'

        /**
         * Checks if the response has a market and pricelist, and saves in redux for future requests.
         */
        const headerInteceptor = axios.interceptors.response.use(response => {
            const sessionState = Store.getState().session
            if (sessionState.marketId === null) {
                if(response.headers['x-centra-market']) {
                    SessionActions.setSession(
                      parseInt(response.headers['x-centra-market']),
                      parseInt(response.headers['x-centra-pricelist']),
                      response.headers['x-centra-country'],
                    );
                    if(sessionState.lang === null) {
                        SessionActions.setLang(response.headers['x-language'])
                    }
                } else if(response.data && response.data.market_id && response.data.pricelist_id) {
                    SessionActions.setSession(
                      parseInt(response.data.market_id),
                      parseInt(response.data.pricelist_id),
                      response.data.country,
                    );
                }
            }

            axios.interceptors.response.eject(headerInteceptor);

            return response;
        }, error => {
            return Promise.reject(error);
        });

        const authInterceptor = axios.interceptors.response.use(response => {

            if(response && response.data && response.data.status === 'error' && response.data.error == 'Invalid Auth') {
                AuthActions.logOut();
            }

            axios.interceptors.response.eject(authInterceptor);

            return response;
        }, error => {
            return Promise.reject(error);
        });

    }


    /**
     * Return headers
     * @return {Object}
     */
    getHeaders() {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        let { session, auth, cart } = Store.getState();

        if(auth.jwt) {
            headers['Authorization'] =  `${auth.jwt}`;
        }

        if (session.marketId && session.pricelistId) {
            headers['X-Centra-Market'] = session.marketId;
            headers['X-Centra-Pricelist'] = session.pricelistId;
        }

        if (session.country) {
            headers['X-Centra-Country'] = session.country;
        }

        if (session.lang) {
            headers['X-Language'] = session.lang;
        }

        if(auth.maintenanceCode && auth.maintenanceCode.length > 0) {
            headers['X-Maintenance-Code'] = auth.maintenanceCode;
        }

        if(cart.token) {
            headers['X-Centra-Token'] = cart.token;
        }

        return headers;
    }

    /**
     * generic GET request to the API
     * @param  {String} path
     * @return {Promise}
     */
    get(path) {
        return axios.get(this.url + path, {
            headers: this.getHeaders()
        });
    }

    /**
     * generic POST request to the API
     * @param  {String} path
     * @param  {Object} data
     * @return {Promise}
     */
    post(path, data) {
        return axios.post(this.url + path, data, {
            headers: this.getHeaders()
        });
    }

    /**
     * generic PUT request to the API
     * NOTE: PUR does not work with FormData() use POST instead and append '_method', 'PUT' to form data
     * @param  {String} path
     * @param  {Object} data
     * @return {Promise}
     */
    put(path, data) {
        return axios.put(this.url + path, data, {
            headers: this.getHeaders()
        });
    }

    /**
     * generic DELETE request to the API
     * @param  {String} path
     * @return {Promise}
     */
    delete(path) {
        return axios.delete(this.url + path, {
            headers: this.getHeaders()
        });
    }

    getSession() {
        return this.get('bootstrap/session');
    }

    getBootstrap = (path) => {
        if(path !== undefined && path !== null){
            return this.get('bootstrap?path=' + path);
        }
        return this.get('bootstrap');
    };

    /**
     * Starts the login process for a user.
     *
     * @param email
     * @param password
     * @returns {Promise}
     */
    authenticateUser = (email, password) => {
        return this.post('auth/login', {
            email: email,
            password: password,
        });
    };

    /**
     * Verifies the verification code for provided user.
     *
     * @param email
     * @param code
     * @returns {Promise}
     */
    verifyAuthenticationCode = (email, code) => {
        return this.post('auth/verify', {
            email: email,
            code: code,
        });
    };

    updateUser = (user) => {
        return this.post('auth/updateUser', {
            name: user.name,
            role: user.role,
            email: user.email,
            dial_code: user.dial_code,
            phone_number: user.phone_number,
        })
    }

    updatePassword = (email, password) => {
        return this.post('auth/updatePassword', {
            email: email,
            password: password
        })
    }

    createNewUser = (user) => {
        return this.post('auth/createNewUser', {
            name: user.name,
            email: user.email,
            role: user.role,
            dial_code: user.dial_code,
            phone_number: user.phone_number,
            password: user.password
        })
    }

    deleteUser = email => {
        return this.get('auth/deleteUser/' + email);
    }

    getUsers = () => this.get('auth/getUsers');

    getModuleData = content => this.post('entries/prepare-data', { content: content })

    /**
     * Return all routes
     */
    getRoutes = () => this.get('routes');

    /**
     * Get one specific route, by id.
     *
     * @param routeId
     */
    getRoute = (routeId, query) => {
        if(query !== undefined) {
            return this.get('routes?path=' + query)
        }
        return this.get('routes/' + routeId);
    }

    /**
     * Return all pages
     */
    getPages = () => this.get('pages');


    /**
     * Create a new page
     */
    createPage = fields => this.post('pages', fields);

    /**
     * Return a single page
     * @param  {string} slug [description]
     */
    getPage = (slug) => this.get('pages/' + slug);

    /**
     * Updates a page
     * @param  {int} slug
     * @param  {Object} fields
     */
    savePage = (slug, fields) => this.put('pages/' + slug, fields);


    /**
     * Deletes a topic
     * @param  {int} id
     * @return {Promise}
     */
    deleteTopic = id => this.delete('topics/' + id);

    /**
     * Gets a specific entry type by key.
     *
     * @param entryTypeKey
     * @returns {Promise}
     */
    getEntryType = (entryTypeKey) => {
        return this.get('entry-types/' + entryTypeKey);
    }

    /**
     * Gets an array of all entry types.
     *
     * @returns {Promise}
     */
    getEntryTypes = () => {
        return this.get('entry-types');
    }

    /**
     * Creates a new Entry.
     *
     * @param entryTypeKey
     * @param data
     * @returns {Promise}
     */
    createEntry = (entryTypeKey, data) => {
        return this.post('entries/' + entryTypeKey, data);
    }

    /**
     * Return all entries
     * @param entryTypeKey
     */
    getEntries = (entryTypeKey) => {
        return this.get('entries/' + entryTypeKey);
    }

    /**
     * Return all entries without a parent
     */
    getOrphanEntries = (entryTypeKey = 'product-variations') => {
        return this.get(`entries/${entryTypeKey}/orphans`);
    }

    /*
     * Set parent for selected children variations
     */
    setParent = (entryTypeKey = 'product-variations', parentId, childrenIds) => {
        return this.post(`entries/${entryTypeKey}/set-parent`, {
            parent_id: parentId,
            children_ids: [...childrenIds]
        })
    }

    /*
     * Make variation orphan
     */
    makeOrphan = (entryTypeKey = 'product-variations', childrenId) => {
        return this.get(`entries/${entryTypeKey}/make-orphan/${childrenId}`)
    }

    deleteEntry(entryTypeKey, id) {
        return this.get('entries/delete/' + entryTypeKey + '/' + id);
    }

    deleteContent(entryId, marketId, languageCode){
        return this.get('entries/delete-content/' + entryId + '/' + marketId + '/' + languageCode).then(result => {
        });
    }

    /**
     * Return a single entry type
     * @param entryTypeKey
     * @param entryId
     * @param contentId
     */
    getEntry = (entryTypeKey, entryId, contentId, lang, marketId) => {
        let hasLang = lang !== undefined && lang !== null;
        let hasMarketId = marketId !== undefined && marketId !== null;

        if (contentId === undefined || contentId === null) {
            if (hasLang && hasMarketId) {
                return this.get('entries/' + entryTypeKey + '/' + entryId + '?lang=' + lang + '&marketId=' + marketId);
            } else if (hasLang && !hasMarketId) {
                return this.get('entries/' + entryTypeKey + '/' + entryId + '?lang=' + lang);
            } else if(!hasLang && hasMarketId){
                return this.get('entries/' + entryTypeKey + '/' + entryId + '?marketId=' + marketId);
            } else{
                return this.get('entries/' + entryTypeKey + '/' + entryId);
            }
        } else {
            return this.get('entries/' + entryTypeKey + '/' + entryId + '/' + contentId);
        }
    }

    /**
     * Fetches all historic content for this entry.
     *
     * @param entryTypeKey
     * @param entryId
     * @returns {Promise}
     */
    getEntryContentHistory = (entryTypeKey, entryId) => {
        return this.get(`entries/history/${entryTypeKey}/${entryId}`)
    }

    /**
     * Fetches all other entries of the same type and its content id.
     *
     * @param entryTypeKey
     * @param entryId
     * @returns {Promise}
     */
    getOtherEntriesContents = (entryTypeKey) => {
        return this.get(`entries/content-ids/${entryTypeKey}`)
    }

    /**
     * Fetches content_id for a specific entryId.
     *
     * @param entryTypeKey
     * @param entryId
     * @returns {Promise}
     */
    getContentIdForEntry = (entryTypeKey, entryId) => {
        return this.get(`entries/content-id/${entryTypeKey}/${entryId}`)
    }

    /**
     * Stores a updated entry.
     *
     * @param entryTypeKey
     * @param entryId
     * @param oldContentId
     * @param data
     * @returns {Promise}
     */
    saveEntry = (entryTypeKey, entryId, oldContentId, data) => {
        if (oldContentId === null) {
            return this.post('entries/' + entryTypeKey + '/' + entryId, data);
        }

        return this.post('entries/' + entryTypeKey + '/' + entryId + '/' + oldContentId, data);
    }

    /**
     * Return a single entry type entry
     * @param  {string} key [description]
     */
    getEntryTypeEntry = (entryType, entryId) => this.get('entries/' + entryType + '/entry/' + entryId);


    /**
     * Returns products that are related to product with centra_id
     * @paran {int} centra_id
     */
    getProductRelations = productIds => {
        return this.post('entries/relations', { productIds: productIds });
    }


    /**
     * Get all settings.
     *
     * @returns {Promise}
     */
    getSettings = () => {
        return this.get('settings');
    }

    /**
     * Save a setting.
     *
     * @param key
     * @param value
     * @returns {Promise}
     */
    saveSetting = (key, value) => {
        return this.post('settings/' + key, {
            value: value,
        });
    }


    /**
     * Get all globals.
     *
     * @returns {Promise}
     */
    getGlobals = (market, lang, pricelist) => {
        return this.post('globals', {
            market: market ? market : null,
            lang: lang ? lang : null,
            pricelist: pricelist ? pricelist : null
        });
    };

    /**
     * Save a global.
     *
     * @param key
     * @param value
     * @param market
     * @param lang
     * @returns {Promise}
     */
    saveGlobal = (key, value, market, lang, pricelist) => {
        return this.post('globals/' + key, {
            value: value,
            market: market ? market : null,
            lang: lang ? lang: null,
            pricelist: pricelist ? pricelist : null
        });
    };

    getFileInfo = (id) => {
        return this.get('file-manager/file-info/' + id);
    };

    /**
     * Get files and folders.
     *
     * @param {int} [folderId=null] - Somebody's name.
     */
    getFiles = (folderId) => {
        if (typeof folderId === 'object' && folderId !== null) folderId = folderId.id;
        if (typeof folderId === 'undefined' || folderId === null) {
            return this.get('file-manager')
        }
        return this.get('file-manager/' + folderId)
    };

    getFile = fileId => {
        return this.get('file-manager/get-file/' + fileId);
    }

    /**
     * Create a new folder.
     *
     * @param parentId
     * @param name
     * @returns {Promise}
     */
    createFolder = (parentId, name) => {
        return this.post('file-manager/folder/create', {
            parentId: parentId,
            name: name
        });
    };

    /**
     * Update a folder with provided data.
     *
     * @param id
     * @param data
     * @returns {Promise}
     */
    updateFolder = (id, data) => {
        return this.post('files/folder/' + id, { data: data });
    };

    /**
     * Crops a file.
     *
     * @param file
     * @param data
     * @returns {Promise}
     */
    cropImage = (file, data) => {
        return this.post('file-manager/crop', {
            file: file,
            data: data,
        });
    };

    /**
     * Get earlier crops for provided file and aspectRatio combination.
     *
     * @param fileId
     * @param aspectRatio
     * @returns {Promise}
     */
    getEarlierCrops = (fileId, aspectRatio) => {
        return this.post('file-manager/get-crops', {
            fileId: fileId,
            aspectRatio: aspectRatio,
        });
    }

    /**
     * Upload a file to the file manager.
     *
     * @param file
     * @param folder
     * @returns {Promise}
     */
    uploadFile = (file, folder) => {

        const formData = new FormData();

        formData.append('file', file);

        if (typeof folder !== 'undefined' && folder !== null) {
            formData.append('folder_id', folder);
        }

        return this.post('file-manager/file/upload', formData);

    };

    uploadVideoUrl = (url, folder) => {
        let video = {
            filename : url,
        };

        if (typeof folder !== 'undefined' && folder !== null) {
            video.folder_id = folder;
        }

        return this.post('file-manager/file/video-url', video);
    };

    /**
     * Update a file with provided data.
     *
     * @param id
     * @param data
     * @returns {Promise}
     */
    updateFile = (id, data) => {
        return this.post('file-manager/file/' + id, { data: data });
    };


    /**
     * Delete a file
     *
     * @param id
     * @returns {Promise}
     */
    deleteFile = (id) => {
        return this.get('file-manager/file/delete/' + id);
    }

    searchFiles = (searchInput) => {
        return this.get('file-manager/search/' + searchInput);
    }

    /**
     * Delete a folder
     *
     * @param id
     * @returns {Promise}
     */
    deleteFolder = (id) => {
        return this.get('file-manager/folder/delete/' + id);
    }

    /**
     * Gets the index for the link-selector.
     *
     * @returns {Promise}
     */
    getLinkSelectorIndex = () => {
        return this.get('link-selector');
    }

    /**
     * Gets the index for the link-selector.
     *
     * @param key
     * @returns {Promise}
     */
    getEntrySelectorIndex = (key) => {
        if (typeof key !== 'undefined' && key !== null) {
            return this.get('entry-selector/' + key);
        }
        return this.get('entry-selector');
    }

    /**
     * Starts a newsletter subscription for an email address
     * @returns {Promise}
     */
    newsletterSubscription = fields => {
        return this.post('newsletter', fields);
    }


    /**
     * Starts a manual sync of everything Centra.
     *
     * @returns {Promise}
     */
    startSync = () => {
        return this.get('sync/start');
    }

    clearCache = () => {
        return this.get('cache/clear');
    }

    /**
     * Fetches the current status of the latest sync.
     *
     * @returns {Promise}
     */
    syncStatus = () => {
        return this.get('sync/status');
    }

    /**
     *
     * @returns {Promise}
     */
    getSyncLog = () => {
        return this.get('sync/log');
    }

    /**
     * Returns all languages
     * @returns {Promise}
     */
    getLanguages = () => {
        return this.get('languages');
    };

    getDialCodes = () => {
        return axios.get(this.apiUrl + `/ajax/dial-codes`, {
            headers: this.getHeaders()
        });
    }

    createOrUpdateMetaField = (name, value, productId, lang) => {
        return this.post('meta-fields/' + name + '/' + productId + '/' + lang , {
            value: value,
        })
    }

    getItemAvailability = (sizeId) => {
        return this.get(`reservation/${sizeId}`);
    }

    reserveItem = (sizeId, quantity) => {
        let { cart } = Store.getState()
        const { token } = cart
        return this.put(`reservation/${sizeId}/${quantity}?centraToken=${ token }`,{});
    }

    refreshReservations = () => {
        let { session, auth, cart } = Store.getState()
        const { token } = cart
        return this.put(`reservation?centraToken=${ token }`, {});
    }

    clearReservations = () => {
        let { session, auth, cart } = Store.getState()
        const { token } = cart
        return this.delete(`reservation?centraToken=${ token }`);
    }

    completeReservations = () => {
        let { session, auth, cart } = Store.getState()
        const { token } = cart
        return this.get(`reservation/complete?centraToken=${ token }`);
    }

    addWarrantyMap = (orderId, warrantyMap) => {
        return this.post('order/add-warranty-map/' + orderId, {
            warrantyMap: warrantyMap
        });
    }

    isReturningCustomer = async (orderId) => {
        let res = await this.get('order/is-existing-customer/' + orderId);

        return res.status === 200 ? res.data.existing : false;
    }

    getIngridToken = (resource) => {
        return this.post('ingrid/get-token', {
            resource: resource,
        })
    }

    sendAnalyticsEvent = (data) => {
        return this.post('analytics/event', {
            data: data,
        })
    }

    getIphoneCases = () => {
        return this.get('iphone-cases')
    }
}

const instance = new ApiManager();

if(!isServer) {
    window.ApiInstance = instance
}

export let ApiInstance = instance