import React from 'react'
import ContentEditable from 'react-contenteditable'
import styled, { css } from 'styled-components'
import Colors from '../../assets/styles/Colors'
import { Globals, SessionActions } from 'kbcms-react';

class PreOrderInfo extends React.Component {

  render () {
    let { counts, editMode, dateText, module, sku, options } = this.props

    const session = SessionActions.getCurrentSession()

    // Temporary override for The Bunker and The Shelter
    if (
      (sku === '401A01' || sku === '400A01')
      && options.hasOwnProperty('preorderSoldOut' + session.marketId)
      && options['preorderSoldOut' + session.marketId] === true
    ) {
      counts.stock = 0
      counts.original = counts.sold
    }


    return <StyledPreOrderInfo>
      <progress value={ counts.sold } max={ counts.original }/>
      <InfoWrapper hideShippingDate={ options.hidePreorderShippingDate }>
        { options.hidePreorderShippingDate !== true && <Info>
          <ContentEditable tagName="h4" disabled={ !editMode } html={ dateText } onChange={ e => module.setField('dateText', e.target.value) }/>
          <span>{ iGlobals.get('mod_pb_pre_order_estimated_shipping',
            'Estimated shipping') }</span>
        </Info> }
        <Info>
          <h4>{ counts.sold }</h4>
          <span>{ Globals.get('mod_pb_pre_order_products_sold', 'Products sold') }</span>
        </Info>
        <Info>
          <h4>{ counts.stock }</h4>
          <span>{ Globals.get('mod_pb_pre_order_products_left', 'Products left') }</span>
        </Info>
      </InfoWrapper>
    </StyledPreOrderInfo>
  }
}

export default PreOrderInfo

const StyledPreOrderInfo = styled.div`
    max-width: 470px;
    margin: 0 auto;

    progress {
        margin-top: 10px;
        border-radius: 1px; 
        width: 100%;
        height: 7px;
    }
    progress::-webkit-progress-bar {
        background-color: #E8E4DE;
        border-radius: 100px;
    }
    progress::-webkit-progress-value {
        border-radius: 100px; 
        background-color: ${Colors.green};
    }
`

const InfoWrapper = styled.div`
  display: flex;
  margin: 10px auto;
  ${p => p.hideShippingDate === true && css`
    justify-content: center;
  `}
`

const Info = styled.div`
  display: flex;
  width: calc(33% - 8px);
  border: 1px solid ${Colors.black};
  padding: 10px;
  justify-content: center;
  flex-direction: column;
  
  &:nth-of-type(2), &:nth-of-type(3) {
    margin-left: 12px;
  }
  
  h4{
    margin: 0;
    font-size: 23px;
  }
  
  span {
    margin: 0;
    font-family: 'Circular Pro Book';
    font-size: 11px;
    text-transform: uppercase;
  }
`