import React from 'react';
import { Media, Linker, Editor } from 'kbcms-react';
import styled, { css } from 'styled-components';
import ContentEditable from 'react-contenteditable';
import Colors from '../../assets/styles/Colors';
import BaseModule from 'modules/DBBaseModule';
import OptionsWrapper from 'components/OptionsWrapper';
import fontOptions from 'assets/styles/FontOptions';
import { default as media } from 'assets/styles/Breakpoints';

class TwoImagesWithText extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Two Images with text" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props)

        this.onChangeField = this.onChangeField.bind(this)

        this.state = {
            fields: {
                ...{}, ...{
                    image1: null,
                    image2: null,
                    text1: '',
                    text2: '',
                    imageLink1: null,
                    imageLink2: null,
                },
                ...props.fields,
            }
        }
    }

    /**
    * When content editable field is changed
    * @return {Void}
    */
    onChangeField = (name, value) => {
        this.setState({ fields: Object.assign(this.state.fields, { [name]: value }) })
    }

    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    /**
    * Renderer
    */
    render() {
        const { options, editMode } = this.props;
        const { fields } = this.state;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper>
                    <Slot>
                        <StyledLinker module={this} field={'imageLink1'}>
                            <StyledMedia
                                module={this}
                                overlay={options.overlay}
                                fill
                                aspectRatio={18/11}
                                field={'image1'}/>
                            </StyledLinker>
                        <StyledContentArea>
                            <Editor
                                field={'text1'}
                                data={fields.text1}
                                disabled={!editMode}
                                fonts={fontOptions}
                                defaultFont={'Lyon Display'}
                                module={this}
                            />
                        </StyledContentArea>
                    </Slot>

                    <Slot>
                        <StyledLinker module={this} field={'imageLink2'}>
                            <StyledMedia
                                module={this}
                                overlay={options.overlay}
                                fill
                                aspectRatio={18/11}
                                field={'image2'} />
                        </StyledLinker>
                        <StyledContentArea>
                            <Editor
                                field={'text2'}
                                data={fields.text2}
                                disabled={!editMode}
                                fonts={fontOptions}
                                defaultFont={'Lyon Display'}
                                module={this}
                            />
                        </StyledContentArea>
                    </Slot>
                </Wrapper>
            </OptionsWrapper>
        );
    }
}

export default TwoImagesWithText

const StyledMedia = styled(Media)`
    margin-bottom: 20px;
`

const Wrapper = styled.section`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px 0;
    margin: 0 auto;

    ${p => ( !p.fullWidth && p.length !== 1 ) ? 'max-width: 1180px' : 'max-width: 1660px' };

    ${p => p.length > 2 && css`
        flex-wrap: wrap;
    `}

    ${media.lessThan('huge')`
        width: ${p => p.fullWidth ? '100%' : 'calc(100% - 180px)'};
        ${StyledMedia} {
            width: 100%;
        }
    `}

    ${media.lessThan('large')`
        width: ${p => p.fullWidth ? '100%' : 'calc(100% - 160px)'};
        ${StyledMedia} {
            width: 100%;
        }
    `}

    ${media.lessThan('medium')`
        width: ${p => p.fullWidth ? '100%' : 'calc(100% - 100px)'};
        ${StyledMedia} {
            width: 100%;
        }
    `}

    ${media.lessThan('small')`
        width: ${p => p.fullWidth ? '100%' : 'calc(100% - 30px)'};
        ${StyledMedia} {
            width: 100%;
        }
    `}

    ${media.lessThan('mobile')`
        flex-direction: column;
    `}

`


const Slot = styled.div`
    width: 100%;
    margin: 0 8px;


    ${p => p.editMode && css`
        max-width: 400px;
    `}

    a {
        text-decoration: none;
    }

    ${media.lessThan('small')`
        width: calc(50% - 32px);
        margin-bottom: 30px;
    `}

    ${media.lessThan('mobile')`
        width: 100%;
        margin: 0;
    `}
`

const StyledLinker = styled(Linker)`
    width: 100%;
`

const StyledContentArea = styled.div`
    text-align: center;
    padding: 0 16px;
`
