import React from 'react'
import styled, { css } from 'styled-components'
import pureWhite from '../../assets/images/variation-icons/pure-white.jpg'
import camo from '../../assets/images/variation-icons/camo.jpg'
import blackWhite from '../../assets/images/variation-icons/black-white.jpg'

const overrides = {
  'BUNDLE33': {
    image: pureWhite,
  },
  'BUNDLE34': {
    image: camo,
  },
  'BUNDLE31': {
    color: '#C9292A',
    checkColor: '#fff',
  },
  'BUNDLE32': {
    image: blackWhite,
  },
}

const Variation = ({ variation, active, swatch, onClick, className, }) => {
  let override = null

  if(typeof overrides[variation.sku] !== 'undefined') {
    override = overrides[variation.sku]
  }

  return (
    <Wrapper active={active} swatch={swatch} className={className} onClick={onClick} override={override}/>
  )
}

export default Variation

const Wrapper = styled.div`
    position: relative;
    display: inline-block;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    margin: 1px 3px;
    cursor: pointer;

    ${props => props.active ? `
        &:before {
            position: absolute;
            top: 0px;
            left: 4px;
            content: "✓";
            font-family: "Circular Pro";
            font-size: 9px;
            line-height: 17px;
        }
    `: ''}

    ${p => p.swatch && p.swatch.hasOwnProperty('color_check') && p.swatch.color_check !== '' ? `
        &:before {
            color: ${p.swatch.color_check};
        }
    `: `
        &:before {
            color: #fff;
        }
    `}

    ${p => p.swatch !== null && p.swatch.color_hex === '' && p.swatch.color_img ? `
      background: url(${p.swatch.color_img.url});
      background-size: cover;
      background-position: center center;
    ` : ''}

    ${p => p.swatch !== null && p.swatch.color_hex !== '' ? `
        background: ${p.swatch.color_hex};
    ` : ''}

    ${p => p.swatch === null ? `
        background: black;
    ` : ''}
  
    ${p => p.override !== null && css`
      ${p => typeof p.override.color !== 'undefined' && css`
        background: ${p.override.color};
        &:before {
          color: ${p.override.checkColor};
        }
      `}
      ${p => typeof p.override.image !== 'undefined' && css`
        &.lazyloaded {
          background: url(${p.override.image});
          background-size: cover;
          background-position: center center;
        }
      `}
    `}
`