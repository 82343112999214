import React from 'react';
import FrontendPageRenderer from './FrontendPageRenderer';
import AnalyticsActions from 'reducers/analytics';
import Head from 'cms/components/Head';
import isServer from '../../isServer'

class DynamicPage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pageView();
    }

    pageView() {
        let { currentRoute } = this.props;

        if(isServer) return;

        if(currentRoute && currentRoute.url && currentRoute.url === 'maintenance') {
            AnalyticsActions.pageView({
                page: '/maintenance',
                title: 'Maintenance',
                location: '/maintenance',
            });
        } else {
            AnalyticsActions.pageView({
                page: window.location.pathname,
                title: currentRoute.model.name,
                location: window.location.href,
            });
        }
    }

    renderHead() {
        let { currentRoute, currentPath } = this.props;
        let { model } = currentRoute;
        let { name, seo_title, seo_description, seo_image } = model;

        let title = seo_title !== null && seo_title !== '' && seo_title !== undefined ? seo_title : name;

        // TODO: This is only temporary to test Prerender IO. This have to seperated out to project.
        if (model.content && model.content.template === 'ProductTemplate' && model.content.json) {
            let product = null;
            const modules = model.content.json['ProductTemplate'].zones[0].modules;

            Object.keys(modules).forEach(key => {
                let module = modules[key];

                if (module.name === 'ProductInfo') {
                    product = module.fields.product.data[0]
                }
            });

            if (product !== null && typeof product !== 'undefined' && product.variations.length > 0) {
                seo_image = product.variations[0].images.standard[0].url;
                seo_description = product.variations[0].description;

                const currentVariation = product.variations.find(v => currentPath.includes(v.slug));

                const getCurrencyCodeFromPrice = (priceString) => {
                    const values = priceString.split(" ");
                    return values[values.length - 1];
                }

                let jsonLD = ``;
                if (currentVariation) {
                    jsonLD = `
                        {
                          "@context": "http://schema.org/",
                          "@type": "Product",
                          "sku": "${currentVariation.sku}",
                          "image": "${currentVariation.images.standard[0].url}",
                          "name": "${name}",
                          "description": "${seo_description}",
                          ${(product && product.categories && product.categories.length > 0) ? `
                            "category": "${product.categories[0].name}",
                          ` : ``}
                          "color": "${currentVariation.swatch_text}",
                          "offers": {
                            "@type": "Offer",
                            "priceCurrency": "${getCurrencyCodeFromPrice(currentVariation.prices[0].price)}",
                            "price": "${currentVariation.prices[0].price_as_number}"
                          }
                        }
                    `
                }

                return (
                    <Head
                        jsonLD={jsonLD}
                        title={ title }
                        description={ seo_description }
                        image={ seo_image }
                        hidden={currentRoute.model.hidden}
                    />
                );
            }

        }

        return (
            <Head
                title={ title }
                description={ seo_description }
                image={ seo_image }
                hidden={currentRoute.model.hidden}
            />
        );
    }

    render() {
        let { currentRoute, ssr } = this.props;
        let { model } = currentRoute;


        return (
            <React.Fragment>
                { this.renderHead() }
                <FrontendPageRenderer key={ model.id } route={ currentRoute } entry={ model } ssr={ ssr }/>
            </React.Fragment>
        )
    }
}

export default DynamicPage
