const redirects = {
  '/product/the-base-15l': '/product/the-naer-15l-backpack',
  '/the-base-15l': '/the-naer-15l-backpack',
  '/the-base-15l-black-out': '/the-naer-15l-backpack-black-out',
  '/product/the-base-15l-black-out': '/product/the-naer-15l-backpack-black-out',
  '/the-base-15l-deep-sea-blue': '/the-naer-15l-backpack-deep-sea-blue',
  '/product/the-base-15l-deep-sea-blue': '/product/the-naer-15l-backpack-deep-sea-blue',
  '/the-base-15l-desert-khaki': '/the-naer-15l-backpack-desert-khaki',
  '/product/the-base-15l-desert-khaki': '/product/the-naer-15l-backpack-desert-khaki',
  '/the-base-15l-scarlet-red-aw1920': '/the-naer-15l-backpack-scarlet-red-aw1920',
  '/product/the-base-15l-scarlet-red-aw1920': '/product/the-naer-15l-backpack-scarlet-red-aw1920',
  '/product/the-carryall-40l': '/product/the-naer-40l-duffel',
  '/the-carryall-40l': '/the-naer-40l-duffel',
  '/the-carryall-40l-scarlet-red': '/the-naer-40l-duffel-scarlet-red',
  '/product/the-carryall-40l-scarlet-red': '/product/the-naer-40l-duffel-scarlet-red',
  '/the-carryall-40l-black-out': '/the-naer-40l-duffel-black-out',
  '/product/the-carryall-40l-black-out': '/product/the-naer-40l-duffel-black-out',
  '/product/the-carryall-65l': '/product/the-naer-65l-duffel',
  '/the-carryall-65l': '/the-naer-65l-duffel',
  '/the-carryall-65l-scarlet-red': '/the-naer-65l-duffel-scarlet-red',
  '/product/the-carryall-65l-scarlet-red': '/product/the-naer-65l-duffel-scarlet-red',
  '/the-carryall-65l-black-out': '/the-naer-65l-duffel-black-out',
  '/product/the-carryall-65l-black-out': '/product/the-naer-65l-duffel-black-out',
  '/product/the-voyage-passport-cover': '/product/the-varldsvan-passport-cover',
  '/the-voyage-passport-cover': '/the-varldsvan-passport-cover',
  '/the-voyage-passport-cover-black': '/the-varldsvan-passport-cover-black',
  '/product/the-voyage-passport-cover-black': '/product/the-varldsvan-passport-cover-black',
  '/the-voyage-passport-cover-camo-30': '/the-varldsvan-passport-cover-camo-30',
  '/product/the-voyage-passport-cover-camo-30': '/product/the-varldsvan-passport-cover-camo-30',
  '/product/the-douchebag': '/product/the-djarv-snowroller',
  '/the-douchebag': '/the-djarv-snowroller',
  '/the-douchebag-black-out': '/the-djarv-snowroller-black-out',
  '/product/the-douchebag-black-out': '/product/the-djarv-snowroller-black-out',
  '/the-douchebag-purple': '/the-djarv-snowroller-purple',
  '/product/the-douchebag-purple': '/product/the-djarv-snowroller-purple',
  '/the-douchebag-whiteout': '/the-djarv-snowroller-whiteout',
  '/product/the-douchebag-whiteout': '/product/the-djarv-snowroller-whiteout',
  '/the-douchebag-brightside-yellow': '/the-djarv-snowroller-brightside-yellow',
  '/product/the-douchebag-brightside-yellow': '/product/the-djarv-snowroller-brightside-yellow',
  '/product/the-hugger-30l': '/product/the-strom-30l-backpack',
  '/the-hugger-30l': '/the-strom-30l-backpack',
  '/the-hugger-30l-black-out-eva': '/the-strom-30l-backpack-black-out-eva',
  '/product/the-hugger-30l-black-out-eva': '/product/the-strom-30l-backpack-black-out-eva',
  '/the-hugger-30l-whiteout': '/the-strom-30l-backpack-whiteout',
  '/product/the-hugger-30l-whiteout': '/product/the-strom-30l-backpack-whiteout',
  '/the-hugger-30l-sage-green': '/the-strom-30l-backpack-sage-green',
  '/product/the-hugger-30l-sage-green': '/product/the-strom-30l-backpack-sage-green',
  '/the-hugger-30l-brightside-yellow': '/the-strom-30l-backpack-brightside-yellow',
  '/product/the-hugger-30l-brightside-yellow': '/product/the-strom-30l-backpack-brightside-yellow',
  '/the-hugger-30l-desert-khaki-eva': '/the-strom-30l-backpack-desert-khaki-eva',
  '/product/the-hugger-30l-desert-khaki-eva': '/product/the-strom-30l-backpack-desert-khaki-eva',
  '/the-hugger-30l-scarlet-red-eva-aw1920': '/the-strom-30l-backpack-scarlet-red-eva-aw1920',
  '/product/the-hugger-30l-scarlet-red-eva-aw1920': '/product/the-strom-30l-backpack-scarlet-red-eva-aw1920',
  '/the-hugger-30l-scarlet-red-aw1920': '/the-strom-30l-backpack-scarlet-red-aw1920',
  '/product/the-hugger-30l-scarlet-red-aw1920': '/product/the-strom-30l-backpack-scarlet-red-aw1920',
  '/product/big-bastard-90l': '/product/the-strom-90l-rollerbag',
  '/big-bastard-90l': '/the-strom-90l-rollerbag',
  '/big-bastard-90l-black-out-eva': '/the-strom-90l-rollerbag-black-out-eva',
  '/product/big-bastard-90l-black-out-eva': '/product/the-strom-90l-rollerbag-black-out-eva',
  '/big-bastard-90l-desert-khaki-eva': '/the-strom-90l-rollerbag-desert-khaki-eva',
  '/product/big-bastard-90l-desert-khaki-eva': '/product/the-strom-90l-rollerbag-desert-khaki-eva',
  '/big-bastard-90l-deep-sea-blue-eva': '/the-strom-90l-rollerbag-deep-sea-blue-eva',
  '/product/big-bastard-90l-deep-sea-blue-eva': '/product/the-strom-90l-rollerbag-deep-sea-blue-eva',
  '/big-bastard-90l-scarlet-red-eva-aw1920': '/the-strom-90l-rollerbag-scarlet-red-eva-aw1920',
  '/product/big-bastard-90l-scarlet-red-eva-aw1920': '/product/the-strom-90l-rollerbag-scarlet-red-eva-aw1920',
  '/big-bastard-90l-purple': '/the-strom-90l-rollerbag-purple',
  '/product/big-bastard-90l-purple': '/product/the-strom-90l-rollerbag-purple',
  '/big-bastard-90l-whiteout': '/the-strom-90l-rollerbag-whiteout',
  '/product/big-bastard-90l-whiteout': '/product/the-strom-90l-rollerbag-whiteout',
  '/big-bastard-90l-camo-30': '/the-strom-90l-rollerbag-camo-30',
  '/product/big-bastard-90l-camo-30': '/product/the-strom-90l-rollerbag-camo-30',
  '/big-bastard-90l-brightside-yellow': '/the-strom-90l-rollerbag-brightside-yellow',
  '/product/big-bastard-90l-brightside-yellow': '/product/the-strom-90l-rollerbag-brightside-yellow',
  '/product/the-expeditionist-luggage-tag': '/product/the-varldsvan-luggage-tag',
  '/the-expeditionist-luggage-tag': '/the-varldsvan-luggage-tag',
  '/product/the-vain-wash-bag': '/product/the-varldsvan-washbag',
  '/the-vain-wash-bag': '/the-varldsvan-washbag',
  '/the-vain-wash-bag-black-leather': '/the-varldsvan-washbag-black-leather',
  '/product/the-vain-wash-bag-black-leather': '/product/the-varldsvan-washbag-black-leather',
  '/the-vain-wash-bag-whiteout': '/the-varldsvan-washbag-whiteout',
  '/product/the-vain-wash-bag-whiteout': '/product/the-varldsvan-washbag-whiteout',
  '/the-vain-wash-bag-sage-green': '/the-varldsvan-washbag-sage-green',
  '/product/the-vain-wash-bag-sage-green': '/product/the-varldsvan-washbag-sage-green',
  '/product/the-savage-bike-bag': '/product/the-djarv-bike-bag',
  '/the-savage-bike-bag': '/the-djarv-bike-bag',
  '/the-savage-bike-bag-black': '/the-djarv-bike-bag-black',
  '/product/the-savage-bike-bag-black': '/product/the-djarv-bike-bag-black',
  '/product/the-slim-jim': '/product/the-latt-light-snowroller',
  '/the-slim-jim': '/the-latt-light-snowroller',
  '/the-slim-jim-black-out-aw1920': '/the-latt-light-snowroller-black-out-aw1920',
  '/product/the-slim-jim-black-out-aw1920': '/product/the-latt-light-snowroller-black-out-aw1920',
  '/product/the-avenue': '/product/the-aera-16l-backpack',
  '/the-avenue': '/the-aera-16l-backpack',
  '/the-avenue-black-leather': '/the-aera-16l-backpack-black-leather',
  '/product/the-avenue-black-leather': '/product/the-aera-16l-backpack-black-leather',
  '/the-avenue-whiteout': '/the-aera-16l-backpack-whiteout',
  '/product/the-avenue-whiteout': '/product/the-aera-16l-backpack-whiteout',
  '/the-avenue-deep-sea-blue-leather': '/the-aera-16l-backpack-deep-sea-blue-leather',
  '/product/the-avenue-deep-sea-blue-leather': '/product/the-aera-16l-backpack-deep-sea-blue-leather',
  '/the-avenue-desert-khaki-leather': '/the-aera-16l-backpack-desert-khaki-leather',
  '/product/the-avenue-desert-khaki-leather': '/product/the-aera-16l-backpack-desert-khaki-leather',
  '/the-avenue-scarlet-red': '/the-aera-16l-backpack-scarlet-red',
  '/product/the-avenue-scarlet-red': '/product/the-aera-16l-backpack-scarlet-red',
  '/product/the-wheelie-2-pack': '/product/the-vaxla-wheel-bag',
  '/the-wheelie-2-pack': '/the-vaxla-wheel-bag',
  '/the-wheelie-2-pack-black-out': '/the-vaxla-wheel-bag-black-out',
  '/product/the-wheelie-2-pack-black-out': '/product/the-vaxla-wheel-bag-black-out',
  '/product/the-scholar': '/product/the-varldsvan-17l-backpack',
  '/the-scholar': '/the-varldsvan-17l-backpack',
  '/the-scholar-black-pu-leather': '/the-varldsvan-17l-backpack-black-pu-leather',
  '/product/the-scholar-black-pu-leather': '/product/the-varldsvan-17l-backpack-black-pu-leather',
  '/the-scholar-black-out': '/the-varldsvan-17l-backpack-black-out',
  '/product/the-scholar-black-out': '/product/the-varldsvan-17l-backpack-black-out',
  '/the-scholar-scarlet-red-aw1920': '/the-varldsvan-17l-backpack-scarlet-red-aw1920',
  '/product/the-scholar-scarlet-red-aw1920': '/product/the-varldsvan-17l-backpack-scarlet-red-aw1920',
  '/the-scholar-purple': '/the-varldsvan-17l-backpack-purple',
  '/product/the-scholar-purple': '/product/the-varldsvan-17l-backpack-purple',
  '/the-scholar-whiteout': '/the-varldsvan-17l-backpack-whiteout',
  '/product/the-scholar-whiteout': '/product/the-varldsvan-17l-backpack-whiteout',
  '/the-scholar-camo-30': '/the-varldsvan-17l-backpack-camo-30',
  '/product/the-scholar-camo-30': '/product/the-varldsvan-17l-backpack-camo-30',
  '/the-scholar-sage-green': '/the-varldsvan-17l-backpack-sage-green',
  '/product/the-scholar-sage-green': '/product/the-varldsvan-17l-backpack-sage-green',
  '/the-scholar-brightside-yellow': '/the-varldsvan-17l-backpack-brightside-yellow',
  '/product/the-scholar-brightside-yellow': '/product/the-varldsvan-17l-backpack-brightside-yellow',
  '/the-scholar-deep-sea-blue': '/the-varldsvan-17l-backpack-deep-sea-blue',
  '/product/the-scholar-deep-sea-blue': '/product/the-varldsvan-17l-backpack-deep-sea-blue',
  '/the-scholar-desert-khaki': '/the-varldsvan-17l-backpack-desert-khaki',
  '/product/the-scholar-desert-khaki': '/product/the-varldsvan-17l-backpack-desert-khaki',
  '/product/little-bastard-60l': '/product/the-strom-60l-rollerbag',
  '/little-bastard-60l': '/the-strom-60l-rollerbag',
  '/little-bastard-60l-black-out-eva': '/the-strom-60l-rollerbag-black-out-eva',
  '/product/little-bastard-60l-black-out-eva': '/product/the-strom-60l-rollerbag-black-out-eva',
  '/little-bastard-60l-desert-khaki-eva': '/the-strom-60l-rollerbag-desert-khaki-eva',
  '/product/little-bastard-60l-desert-khaki-eva': '/product/the-strom-60l-rollerbag-desert-khaki-eva',
  '/little-bastard-60l-deep-sea-blue-eva': '/the-strom-60l-rollerbag-deep-sea-blue-eva',
  '/product/little-bastard-60l-deep-sea-blue-eva': '/product/the-strom-60l-rollerbag-deep-sea-blue-eva',
  '/little-bastard-60l-scarlet-red-eva-aw1920': '/the-strom-60l-rollerbag-scarlet-red-eva-aw1920',
  '/product/little-bastard-60l-scarlet-red-eva-aw1920': '/product/the-strom-60l-rollerbag-scarlet-red-eva-aw1920',
  '/little-bastard-60l-purple': '/the-strom-60l-rollerbag-purple',
  '/product/little-bastard-60l-purple': '/product/the-strom-60l-rollerbag-purple',
  '/little-bastard-60l-whiteout': '/the-strom-60l-rollerbag-whiteout',
  '/product/little-bastard-60l-whiteout': '/product/the-strom-60l-rollerbag-whiteout',
  '/product/spare-douchebag-handle': '/product/the-djarv-snowroller-handle',
  '/spare-douchebag-handle': '/the-djarv-snowroller-handle',
  '/spare-douchebag-handle-black-out': '/the-djarv-snowroller-handle-black-out',
  '/product/spare-douchebag-handle-black-out': '/product/the-djarv-snowroller-handle-black-out',
  '/spare-douchebag-handle-desert-khaki': '/the-djarv-snowroller-handle-desert-khaki',
  '/product/spare-douchebag-handle-desert-khaki': '/product/the-djarv-snowroller-handle-desert-khaki',
  '/spare-douchebag-handle-cloud-grey': '/the-djarv-snowroller-handle-cloud-grey',
  '/product/spare-douchebag-handle-cloud-grey': '/product/the-djarv-snowroller-handle-cloud-grey',
  '/spare-douchebag-handle-scarlet-red': '/the-djarv-snowroller-handle-scarlet-red',
  '/product/spare-douchebag-handle-scarlet-red': '/product/the-djarv-snowroller-handle-scarlet-red',
  '/spare-douchebag-handle-deep-sea-blue': '/the-djarv-snowroller-handle-deep-sea-blue',
  '/product/spare-douchebag-handle-deep-sea-blue': '/product/the-djarv-snowroller-handle-deep-sea-blue',
  '/product/the-hugger-30l-1': '/product/the-strom-30l-backpack-1',
  '/the-hugger-30l-1': '/the-strom-30l-backpack-1',
  '/the-hugger-30l-1-scarlet-red': '/the-strom-30l-backpack-1-scarlet-red',
  '/product/the-hugger-30l-1-scarlet-red': '/product/the-strom-30l-backpack-1-scarlet-red',
  '/the-hugger-30l-1-cloud-grey': '/the-strom-30l-backpack-1-cloud-grey',
  '/product/the-hugger-30l-1-cloud-grey': '/product/the-strom-30l-backpack-1-cloud-grey',
  '/the-hugger-30l-1-gym-limited-edition': '/the-strom-30l-backpack-1-gym-limited-edition',
  '/product/the-hugger-30l-1-gym-limited-edition': '/product/the-strom-30l-backpack-1-gym-limited-edition',
  '/the-hugger-30l-1-leather-limited-edition': '/the-strom-30l-backpack-1-leather-limited-edition',
  '/product/the-hugger-30l-1-leather-limited-edition': '/product/the-strom-30l-backpack-1-leather-limited-edition',
  '/the-hugger-30l-1-u10': '/the-strom-30l-backpack-1-u10',
  '/product/the-hugger-30l-1-u10': '/product/the-strom-30l-backpack-1-u10',
  '/the-hugger-30l-1-purple': '/the-strom-30l-backpack-1-purple',
  '/product/the-hugger-30l-1-purple': '/product/the-strom-30l-backpack-1-purple',
  '/the-hugger-30l-1-doyoutravel-gypsea-lust': '/the-strom-30l-backpack-1-doyoutravel-gypsea-lust',
  '/product/the-hugger-30l-1-doyoutravel-gypsea-lust': '/product/the-strom-30l-backpack-1-doyoutravel-gypsea-lust',
  '/the-hugger-30l-1-steel-grey': '/the-strom-30l-backpack-1-steel-grey',
  '/product/the-hugger-30l-1-steel-grey': '/product/the-strom-30l-backpack-1-steel-grey',
  '/the-hugger-30l-1-pncintl': '/the-strom-30l-backpack-1-pncintl',
  '/product/the-hugger-30l-1-pncintl': '/product/the-strom-30l-backpack-1-pncintl',
  '/the-hugger-30l-1-pure-white': '/the-strom-30l-backpack-1-pure-white',
  '/product/the-hugger-30l-1-pure-white': '/product/the-strom-30l-backpack-1-pure-white',
  '/product/the-base-15l-1': '/product/the-naer-15l-backpack-1',
  '/the-base-15l-1': '/the-naer-15l-backpack-1',
  '/the-base-15l-1-scarlet-red': '/the-naer-15l-backpack-1-scarlet-red',
  '/product/the-base-15l-1-scarlet-red': '/product/the-naer-15l-backpack-1-scarlet-red',
  '/the-base-15l-1-cloud-grey': '/the-naer-15l-backpack-1-cloud-grey',
  '/product/the-base-15l-1-cloud-grey': '/product/the-naer-15l-backpack-1-cloud-grey',
  '/the-base-15l-1-blackred': '/the-naer-15l-backpack-1-blackred',
  '/product/the-base-15l-1-blackred': '/product/the-naer-15l-backpack-1-blackred',
  '/the-base-15l-1-leather-limited-edition': '/the-naer-15l-backpack-1-leather-limited-edition',
  '/product/the-base-15l-1-leather-limited-edition': '/product/the-naer-15l-backpack-1-leather-limited-edition',
  '/the-base-15l-1-pitch-black': '/the-naer-15l-backpack-1-pitch-black',
  '/product/the-base-15l-1-pitch-black': '/product/the-naer-15l-backpack-1-pitch-black',
  '/the-base-15l-1-pine-green': '/the-naer-15l-backpack-1-pine-green',
  '/product/the-base-15l-1-pine-green': '/product/the-naer-15l-backpack-1-pine-green',
  '/the-base-15l-1-pure-white': '/the-naer-15l-backpack-1-pure-white',
  '/product/the-base-15l-1-pure-white': '/product/the-naer-15l-backpack-1-pure-white',
  '/the-base-15l-1-jokke-pro': '/the-naer-15l-backpack-1-jokke-pro',
  '/product/the-base-15l-1-jokke-pro': '/product/the-naer-15l-backpack-1-jokke-pro',
  '/the-base-15l-1-pncintl': '/the-naer-15l-backpack-1-pncintl',
  '/product/the-base-15l-1-pncintl': '/product/the-naer-15l-backpack-1-pncintl',
  '/product/the-carryall-40l-1': '/product/the-naer-40l-duffel-1',
  '/the-carryall-40l-1': '/the-naer-40l-duffel-1',
  '/the-carryall-40l-1-pine-green': '/the-naer-40l-duffel-1-pine-green',
  '/product/the-carryall-40l-1-pine-green': '/product/the-naer-40l-duffel-1-pine-green',
  '/the-carryall-40l-1-cloud-grey': '/the-naer-40l-duffel-1-cloud-grey',
  '/product/the-carryall-40l-1-cloud-grey': '/product/the-naer-40l-duffel-1-cloud-grey',
  '/the-carryall-40l-1-desert-khaki': '/the-naer-40l-duffel-1-desert-khaki',
  '/product/the-carryall-40l-1-desert-khaki': '/product/the-naer-40l-duffel-1-desert-khaki',
  '/the-carryall-40l-1-deep-sea-blue': '/the-naer-40l-duffel-1-deep-sea-blue',
  '/product/the-carryall-40l-1-deep-sea-blue': '/product/the-naer-40l-duffel-1-deep-sea-blue',
  '/the-carryall-40l-1-doyoutravel-gypsea-lust': '/the-naer-40l-duffel-1-doyoutravel-gypsea-lust',
  '/product/the-carryall-40l-1-doyoutravel-gypsea-lust': '/product/the-naer-40l-duffel-1-doyoutravel-gypsea-lust',
  '/product/the-carryall-65l-1': '/product/the-naer-65l-duffel-1',
  '/the-carryall-65l-1': '/the-naer-65l-duffel-1',
  '/the-carryall-65l-1-scarlet-red': '/the-naer-65l-duffel-1-scarlet-red',
  '/product/the-carryall-65l-1-scarlet-red': '/product/the-naer-65l-duffel-1-scarlet-red',
  '/the-carryall-65l-1-pine-green': '/the-naer-65l-duffel-1-pine-green',
  '/product/the-carryall-65l-1-pine-green': '/product/the-naer-65l-duffel-1-pine-green',
  '/the-carryall-65l-1-jay-alvarrez-edition': '/the-naer-65l-duffel-1-jay-alvarrez-edition',
  '/product/the-carryall-65l-1-jay-alvarrez-edition': '/product/the-naer-65l-duffel-1-jay-alvarrez-edition',
  '/the-carryall-65l-1-pure-white': '/the-naer-65l-duffel-1-pure-white',
  '/product/the-carryall-65l-1-pure-white': '/product/the-naer-65l-duffel-1-pure-white',
  '/the-carryall-65l-1-cloud-grey': '/the-naer-65l-duffel-1-cloud-grey',
  '/product/the-carryall-65l-1-cloud-grey': '/product/the-naer-65l-duffel-1-cloud-grey',
  '/the-carryall-65l-1-desert-khaki': '/the-naer-65l-duffel-1-desert-khaki',
  '/product/the-carryall-65l-1-desert-khaki': '/product/the-naer-65l-duffel-1-desert-khaki',
  '/the-carryall-65l-1-deep-sea-blue': '/the-naer-65l-duffel-1-deep-sea-blue',
  '/product/the-carryall-65l-1-deep-sea-blue': '/product/the-naer-65l-duffel-1-deep-sea-blue',
  '/the-carryall-65l-1-black-out': '/the-naer-65l-duffel-1-black-out',
  '/product/the-carryall-65l-1-black-out': '/product/the-naer-65l-duffel-1-black-out',
  '/product/the-avenue-1': '/product/the-aera-16l-backpack-1',
  '/the-avenue-1': '/the-aera-16l-backpack-1',
  '/the-avenue-1-cloud-grey': '/the-aera-16l-backpack-1-cloud-grey',
  '/product/the-avenue-1-cloud-grey': '/product/the-aera-16l-backpack-1-cloud-grey',
  '/the-avenue-1-janni-edition': '/the-aera-16l-backpack-1-janni-edition',
  '/product/the-avenue-1-janni-edition': '/product/the-aera-16l-backpack-1-janni-edition',
  '/product/the-slim-jim-1': '/product/the-latt-light-snowroller-1',
  '/the-slim-jim-1': '/the-latt-light-snowroller-1',
  '/the-slim-jim-1-black-out': '/the-latt-light-snowroller-1-black-out',
  '/product/the-slim-jim-1-black-out': '/product/the-latt-light-snowroller-1-black-out',
  '/the-slim-jim-1-pine-green': '/the-latt-light-snowroller-1-pine-green',
  '/product/the-slim-jim-1-pine-green': '/product/the-latt-light-snowroller-1-pine-green',
  '/the-slim-jim-1-pure-white': '/the-latt-light-snowroller-1-pure-white',
  '/product/the-slim-jim-1-pure-white': '/product/the-latt-light-snowroller-1-pure-white',
  '/the-slim-jim-1-scarlet-red-aw1920': '/the-latt-light-snowroller-1-scarlet-red-aw1920',
  '/product/the-slim-jim-1-scarlet-red-aw1920': '/product/the-latt-light-snowroller-1-scarlet-red-aw1920',
  '/the-slim-jim-1-pitch-black': '/the-latt-light-snowroller-1-pitch-black',
  '/product/the-slim-jim-1-pitch-black': '/product/the-latt-light-snowroller-1-pitch-black',
  '/the-slim-jim-1-leather-limited-edition': '/the-latt-light-snowroller-1-leather-limited-edition',
  '/product/the-slim-jim-1-leather-limited-edition': '/product/the-latt-light-snowroller-1-leather-limited-edition',
  '/the-slim-jim-1-crimson-red': '/the-latt-light-snowroller-1-crimson-red',
  '/product/the-slim-jim-1-crimson-red': '/product/the-latt-light-snowroller-1-crimson-red',
  '/the-slim-jim-1-black-camo': '/the-latt-light-snowroller-1-black-camo',
  '/product/the-slim-jim-1-black-camo': '/product/the-latt-light-snowroller-1-black-camo',
  '/product/little-bastard-60l-1': '/product/the-strom-60l-rollerbag-1',
  '/little-bastard-60l-1': '/the-strom-60l-rollerbag-1',
  '/little-bastard-60l-1-black-out': '/the-strom-60l-rollerbag-1-black-out',
  '/product/little-bastard-60l-1-black-out': '/product/the-strom-60l-rollerbag-1-black-out',
  '/little-bastard-60l-1-blackred': '/the-strom-60l-rollerbag-1-blackred',
  '/product/little-bastard-60l-1-blackred': '/product/the-strom-60l-rollerbag-1-blackred',
  '/little-bastard-60l-1-pine-green': '/the-strom-60l-rollerbag-1-pine-green',
  '/product/little-bastard-60l-1-pine-green': '/product/the-strom-60l-rollerbag-1-pine-green',
  '/little-bastard-60l-1-pure-white': '/the-strom-60l-rollerbag-1-pure-white',
  '/product/little-bastard-60l-1-pure-white': '/product/the-strom-60l-rollerbag-1-pure-white',
  '/little-bastard-60l-1-cloud-grey': '/the-strom-60l-rollerbag-1-cloud-grey',
  '/product/little-bastard-60l-1-cloud-grey': '/product/the-strom-60l-rollerbag-1-cloud-grey',
  '/little-bastard-60l-1-leather-limited-edition': '/the-strom-60l-rollerbag-1-leather-limited-edition',
  '/product/little-bastard-60l-1-leather-limited-edition': '/product/the-strom-60l-rollerbag-1-leather-limited-edition',
  '/little-bastard-60l-1-crimson-red': '/the-strom-60l-rollerbag-1-crimson-red',
  '/product/little-bastard-60l-1-crimson-red': '/product/the-strom-60l-rollerbag-1-crimson-red',
  '/little-bastard-60l-1-u10': '/the-strom-60l-rollerbag-1-u10',
  '/product/little-bastard-60l-1-u10': '/product/the-strom-60l-rollerbag-1-u10',
  '/little-bastard-60l-1-blue': '/the-strom-60l-rollerbag-1-blue',
  '/product/little-bastard-60l-1-blue': '/product/the-strom-60l-rollerbag-1-blue',
  '/product/the-douchebag-1': '/product/the-djarv-snowroller-1',
  '/the-douchebag-1': '/the-djarv-snowroller-1',
  '/the-douchebag-1-pine-green': '/the-djarv-snowroller-1-pine-green',
  '/product/the-douchebag-1-pine-green': '/product/the-djarv-snowroller-1-pine-green',
  '/the-douchebag-1-desert-khaki': '/the-djarv-snowroller-1-desert-khaki',
  '/product/the-douchebag-1-desert-khaki': '/product/the-djarv-snowroller-1-desert-khaki',
  '/the-douchebag-1-deep-sea-blue': '/the-djarv-snowroller-1-deep-sea-blue',
  '/product/the-douchebag-1-deep-sea-blue': '/product/the-djarv-snowroller-1-deep-sea-blue',
  '/the-douchebag-1-black-out': '/the-djarv-snowroller-1-black-out',
  '/product/the-douchebag-1-black-out': '/product/the-djarv-snowroller-1-black-out',
  '/the-douchebag-1-scarlet-red-aw1920': '/the-djarv-snowroller-1-scarlet-red-aw1920',
  '/product/the-douchebag-1-scarlet-red-aw1920': '/product/the-djarv-snowroller-1-scarlet-red-aw1920',
  '/the-douchebag-1-pitch-black': '/the-djarv-snowroller-1-pitch-black',
  '/product/the-douchebag-1-pitch-black': '/product/the-djarv-snowroller-1-pitch-black',
  '/product/big-bastard-90l-1': '/product/the-strom-90l-rollerbag-1',
  '/big-bastard-90l-1': '/the-strom-90l-rollerbag-1',
  '/big-bastard-90l-1-scarlet-red': '/the-strom-90l-rollerbag-1-scarlet-red',
  '/product/big-bastard-90l-1-scarlet-red': '/product/the-strom-90l-rollerbag-1-scarlet-red',
  '/big-bastard-90l-1-janni-edition': '/the-strom-90l-rollerbag-1-janni-edition',
  '/product/big-bastard-90l-1-janni-edition': '/product/the-strom-90l-rollerbag-1-janni-edition',
  '/big-bastard-90l-1-blackred': '/the-strom-90l-rollerbag-1-blackred',
  '/product/big-bastard-90l-1-blackred': '/product/the-strom-90l-rollerbag-1-blackred',
  '/big-bastard-90l-1-pure-white': '/the-strom-90l-rollerbag-1-pure-white',
  '/product/big-bastard-90l-1-pure-white': '/product/the-strom-90l-rollerbag-1-pure-white',
  '/big-bastard-90l-1-cloud-grey': '/the-strom-90l-rollerbag-1-cloud-grey',
  '/product/big-bastard-90l-1-cloud-grey': '/product/the-strom-90l-rollerbag-1-cloud-grey',
  '/big-bastard-90l-1-doyoutravel-gypsea-lust': '/the-strom-90l-rollerbag-1-doyoutravel-gypsea-lust',
  '/product/big-bastard-90l-1-doyoutravel-gypsea-lust': '/product/the-strom-90l-rollerbag-1-doyoutravel-gypsea-lust',
  '/big-bastard-90l-1-crimson-red': '/the-strom-90l-rollerbag-1-crimson-red',
  '/product/big-bastard-90l-1-crimson-red': '/product/the-strom-90l-rollerbag-1-crimson-red',
  '/big-bastard-90l-1-u10': '/the-strom-90l-rollerbag-1-u10',
  '/product/big-bastard-90l-1-u10': '/product/the-strom-90l-rollerbag-1-u10',
  '/big-bastard-90l-1-white': '/the-strom-90l-rollerbag-1-white',
  '/product/big-bastard-90l-1-white': '/product/the-strom-90l-rollerbag-1-white',
  '/big-bastard-90l-1-blue': '/the-strom-90l-rollerbag-1-blue',
  '/product/big-bastard-90l-1-blue': '/product/the-strom-90l-rollerbag-1-blue',
  '/big-bastard-90l-1-steel-grey': '/the-strom-90l-rollerbag-1-steel-grey',
  '/product/big-bastard-90l-1-steel-grey': '/product/the-strom-90l-rollerbag-1-steel-grey',
  '/product/the-scholar-1': '/product/the-varldsvan-17l-backpack-1',
  '/the-scholar-1': '/the-varldsvan-17l-backpack-1',
  '/the-scholar-1-scarlet-red': '/the-varldsvan-17l-backpack-1-scarlet-red',
  '/product/the-scholar-1-scarlet-red': '/product/the-varldsvan-17l-backpack-1-scarlet-red',
  '/the-scholar-1-jay-alvarrez-edition': '/the-varldsvan-17l-backpack-1-jay-alvarrez-edition',
  '/product/the-scholar-1-jay-alvarrez-edition': '/product/the-varldsvan-17l-backpack-1-jay-alvarrez-edition',
  '/the-scholar-1-black-leather': '/the-varldsvan-17l-backpack-1-black-leather',
  '/product/the-scholar-1-black-leather': '/product/the-varldsvan-17l-backpack-1-black-leather',
  '/the-scholar-1-doyoutravel-gypsea-lust': '/the-varldsvan-17l-backpack-1-doyoutravel-gypsea-lust',
  '/product/the-scholar-1-doyoutravel-gypsea-lust': '/product/the-varldsvan-17l-backpack-1-doyoutravel-gypsea-lust',
  '/the-scholar-1-u10': '/the-varldsvan-17l-backpack-1-u10',
  '/product/the-scholar-1-u10': '/product/the-varldsvan-17l-backpack-1-u10',
  '/the-scholar-1-black-out': '/the-varldsvan-17l-backpack-1-black-out',
  '/product/the-scholar-1-black-out': '/product/the-varldsvan-17l-backpack-1-black-out',
  '/product/the-douchebag-black-camo': '/product/the-djarv-snowroller-2',
  '/the-douchebag-black-camo': '/the-djarv-snowroller-2',
  '/product/the-slim-jim-black-camo': '/product/the-latt-light-snowroller-2',
  '/the-slim-jim-black-camo': '/the-latt-light-snowroller-2',
  '/product/the-base-15l-black-camo': '/product/the-naer-15l-backpack-2',
  '/the-base-15l-black-camo': '/the-naer-15l-backpack-2',
  '/the-base-15l-black-camo-black-camo': '/the-naer-15l-backpack-2-black-camo',
  '/product/the-base-15l-black-camo-black-camo': '/product/the-naer-15l-backpack-2-black-camo',
  '/product/the-hugger-30l-black-camo': '/product/the-strom-30l-backpack-2',
  '/the-hugger-30l-black-camo': '/the-strom-30l-backpack-2',
  '/the-hugger-30l-black-camo-black-camo': '/the-strom-30l-backpack-2-black-camo',
  '/product/the-hugger-30l-black-camo-black-camo': '/product/the-strom-30l-backpack-2-black-camo',
  '/product/the-big-bastard-90l-black-camo': '/product/the-strom-90l-rollerbag-2',
  '/the-big-bastard-90l-black-camo': '/the-strom-90l-rollerbag-2',
  '/product/the-little-bastard-60l-black-camo': '/product/the-strom-60l-rollerbag-2',
  '/the-little-bastard-60l-black-camo': '/the-strom-60l-rollerbag-2',
  '/the-little-bastard-60l-black-camo-black-camo': '/the-strom-60l-rollerbag-2-black-camo',
  '/product/the-little-bastard-60l-black-camo-black-camo': '/product/the-strom-60l-rollerbag-2-black-camo',
  '/product/the-carryall-40l-black-camo': '/product/the-naer-40l-duffel-2',
  '/the-carryall-40l-black-camo': '/the-naer-40l-duffel-2',
  '/the-carryall-40l-black-camo-black-camo': '/the-naer-40l-duffel-2-black-camo',
  '/product/the-carryall-40l-black-camo-black-camo': '/product/the-naer-40l-duffel-2-black-camo',
  '/product/the-carryall-65l-black-camo': '/product/the-naer-65l-duffel-2',
  '/the-carryall-65l-black-camo': '/the-naer-65l-duffel-2',
  '/product/the-scholar-black-camo': '/product/the-varldsvan-17l-backpack-2',
  '/the-scholar-black-camo': '/the-varldsvan-17l-backpack-2',
  '/product/the-big-bastard-90l-janni-edition': '/product/the-strom-90l-rollerbag-3',
  '/the-big-bastard-90l-janni-edition': '/the-strom-90l-rollerbag-3',
  '/product/the-carryall-40l-janni-edition': '/product/the-naer-40l-duffel-3',
  '/the-carryall-40l-janni-edition': '/the-naer-40l-duffel-3',
  '/the-carryall-40l-janni-edition-janni-edition': '/the-naer-40l-duffel-3-janni-edition',
  '/product/the-carryall-40l-janni-edition-janni-edition': '/product/the-naer-40l-duffel-3-janni-edition',
  '/product/the-vain-washbag-janni-edition': '/product/the-varldsvan-washbag-2',
  '/the-vain-washbag-janni-edition': '/the-varldsvan-washbag-2',
  '/the-vain-washbag-janni-edition-janni-edition': '/the-varldsvan-washbag-2-janni-edition',
  '/product/the-vain-washbag-janni-edition-janni-edition': '/product/the-varldsvan-washbag-2-janni-edition',
  '/product/the-avenue-janni-edition': '/product/the-aera-16l-backpack-2',
  '/the-avenue-janni-edition': '/the-aera-16l-backpack-2',
  '/product/the-voyage-passport-cover-janni-edition': '/product/the-varldsvan-passport-cover-2',
  '/the-voyage-passport-cover-janni-edition': '/the-varldsvan-passport-cover-2',
  '/the-voyage-passport-cover-janni-edition-janni-edition': '/the-varldsvan-passport-cover-2-janni-edition',
  '/product/the-voyage-passport-cover-janni-edition-janni-edition': '/product/the-varldsvan-passport-cover-2-janni-edition',
  '/product/big-bastard-90l-benni-edition': '/product/the-strom-90l-rollerbag-4',
  '/big-bastard-90l-benni-edition': '/the-strom-90l-rollerbag-4',
  '/big-bastard-90l-benni-edition-benni-limited-edition': '/the-strom-90l-rollerbag-4-benni-limited-edition',
  '/product/big-bastard-90l-benni-edition-benni-limited-edition': '/product/the-strom-90l-rollerbag-4-benni-limited-edition',
  '/product/the-hugger-30l-friends-family': '/product/the-strom-30l-backpack-3',
  '/the-hugger-30l-friends-family': '/the-strom-30l-backpack-3',
  '/product/the-big-bastard-friends-family': '/product/the-strom-90l-rollerbag-5',
  '/the-big-bastard-friends-family': '/the-strom-90l-rollerbag-5',
  '/product/the-little-bastard-60l-friends-family': '/product/the-strom-60l-rollerbag-3',
  '/the-little-bastard-60l-friends-family': '/the-strom-60l-rollerbag-3',
  '/product/the-scholar-friends-family': '/product/the-varldsvan-17l-backpack-3',
  '/the-scholar-friends-family': '/the-varldsvan-17l-backpack-3',
  '/product/the-base-15l-friends-family': '/product/the-naer-15l-backpack-3',
  '/the-base-15l-friends-family': '/the-naer-15l-backpack-3',
  '/product/the-voyage-passport-cover-1': '/product/the-varldsvan-passport-cover-1',
  '/the-voyage-passport-cover-1': '/the-varldsvan-passport-cover-1',
  '/the-voyage-passport-cover-1-jay-alvarrez-edition': '/the-varldsvan-passport-cover-1-jay-alvarrez-edition',
  '/product/the-voyage-passport-cover-1-jay-alvarrez-edition': '/product/the-varldsvan-passport-cover-1-jay-alvarrez-edition',
  '/the-voyage-passport-cover-1-black-out': '/the-varldsvan-passport-cover-1-black-out',
  '/product/the-voyage-passport-cover-1-black-out': '/product/the-varldsvan-passport-cover-1-black-out',
  '/the-voyage-passport-cover-1-doyoutravel-gypsea-lust': '/the-varldsvan-passport-cover-1-doyoutravel-gypsea-lust',
  '/product/the-voyage-passport-cover-1-doyoutravel-gypsea-lust': '/product/the-varldsvan-passport-cover-1-doyoutravel-gypsea-lust',
  '/product/the-vain-wash-bag-1': '/product/the-varldsvan-washbag-1',
  '/the-vain-wash-bag-1': '/the-varldsvan-washbag-1',
  '/the-vain-wash-bag-1-black-out': '/the-varldsvan-washbag-1-black-out',
  '/product/the-vain-wash-bag-1-black-out': '/product/the-varldsvan-washbag-1-black-out',
  '/the-vain-wash-bag-1-blackred': '/the-varldsvan-washbag-1-blackred',
  '/product/the-vain-wash-bag-1-blackred': '/product/the-varldsvan-washbag-1-blackred',
  '/the-vain-wash-bag-1-doyoutravel-gypsea-lust': '/the-varldsvan-washbag-1-doyoutravel-gypsea-lust',
  '/product/the-vain-wash-bag-1-doyoutravel-gypsea-lust': '/product/the-varldsvan-washbag-1-doyoutravel-gypsea-lust',
  '/product/the-hugger-20l': '/product/the-strom-20l-backpack',
  '/the-hugger-20l': '/the-strom-20l-backpack',
  '/the-hugger-20l-black-out': '/the-strom-20l-backpack-black-out',
  '/product/the-hugger-20l-black-out': '/product/the-strom-20l-backpack-black-out',
  '/the-hugger-20l-deep-sea-blue': '/the-strom-20l-backpack-deep-sea-blue',
  '/product/the-hugger-20l-deep-sea-blue': '/product/the-strom-20l-backpack-deep-sea-blue',
  '/the-hugger-20l-desert-khaki': '/the-strom-20l-backpack-desert-khaki',
  '/product/the-hugger-20l-desert-khaki': '/product/the-strom-20l-backpack-desert-khaki',
  '/the-hugger-20l-scarlet-red': '/the-strom-20l-backpack-scarlet-red',
  '/product/the-hugger-20l-scarlet-red': '/product/the-strom-20l-backpack-scarlet-red',
  '/the-hugger-20l-purple': '/the-strom-20l-backpack-purple',
  '/product/the-hugger-20l-purple': '/product/the-strom-20l-backpack-purple',
  '/the-hugger-20l-whiteout': '/the-strom-20l-backpack-whiteout',
  '/product/the-hugger-20l-whiteout': '/product/the-strom-20l-backpack-whiteout',
  '/the-hugger-20l-camo-30': '/the-strom-20l-backpack-camo-30',
  '/product/the-hugger-20l-camo-30': '/product/the-strom-20l-backpack-camo-30',
  '/the-hugger-20l-sage-green': '/the-strom-20l-backpack-sage-green',
  '/product/the-hugger-20l-sage-green': '/product/the-strom-20l-backpack-sage-green',
  '/product/the-hugger-50l': '/product/the-strom-50l-backpack',
  '/the-hugger-50l': '/the-strom-50l-backpack',
  '/the-hugger-50l-black-out-eva': '/the-strom-50l-backpack-black-out-eva',
  '/product/the-hugger-50l-black-out-eva': '/product/the-strom-50l-backpack-black-out-eva',
  '/product/the-scholar-jay-alvarrez-edition': '/product/the-varldsvan-17l-backpack-4',
  '/the-scholar-jay-alvarrez-edition': '/the-varldsvan-17l-backpack-4',
  '/the-scholar-jay-alvarrez-edition-jay-alvarrez-edition': '/the-varldsvan-17l-backpack-4-jay-alvarrez-edition',
  '/product/the-scholar-jay-alvarrez-edition-jay-alvarrez-edition': '/product/the-varldsvan-17l-backpack-4-jay-alvarrez-edition',
  '/product/the-carryall-65l-jay-alvarrez-edition': '/product/the-naer-65l-duffel-3',
  '/the-carryall-65l-jay-alvarrez-edition': '/the-naer-65l-duffel-3',
  '/product/the-voyage-passport-cover-jay-alvarrez-edition': '/product/the-varldsvan-passport-cover-3',
  '/the-voyage-passport-cover-jay-alvarrez-edition': '/the-varldsvan-passport-cover-3',
  '/product/the-expeditionist-luggage-tag-jay-alvarrez-edition': '/product/the-varldsvan-luggage-tag-2',
  '/the-expeditionist-luggage-tag-jay-alvarrez-edition': '/the-varldsvan-luggage-tag-2',
  '/product/the-hugger-30l-jay-alvarrez-edition': '/product/the-strom-30l-backpack-4',
  '/the-hugger-30l-jay-alvarrez-edition': '/the-strom-30l-backpack-4',
  '/the-hugger-30l-jay-alvarrez-edition-jay-alvarrez-edition': '/the-strom-30l-backpack-4-jay-alvarrez-edition',
  '/product/the-hugger-30l-jay-alvarrez-edition-jay-alvarrez-edition': '/product/the-strom-30l-backpack-4-jay-alvarrez-edition',
  '/product/the-little-bastard-60l-jay-alvarrez-edition': '/product/the-strom-60l-rollerbag-4',
  '/the-little-bastard-60l-jay-alvarrez-edition': '/the-strom-60l-rollerbag-4',
  '/the-little-bastard-60l-jay-alvarrez-edition-jay-alvarrez-edition': '/the-strom-60l-rollerbag-4-jay-alvarrez-edition',
  '/product/the-little-bastard-60l-jay-alvarrez-edition-jay-alvarrez-edition': '/product/the-strom-60l-rollerbag-4-jay-alvarrez-edition',
  '/product/the-big-bastard-90l-jay-alvarrez-edition': '/product/the-strom-90l-rollerbag-6',
  '/the-big-bastard-90l-jay-alvarrez-edition': '/the-strom-90l-rollerbag-6',
  '/product/the-shelter-board-bag': '/product/the-djarv-single-surfboard-bag',
  '/the-shelter-board-bag': '/the-djarv-single-surfboard-bag',
  '/the-shelter-board-bag-black-out': '/the-djarv-single-surfboard-bag-black-out',
  '/product/the-shelter-board-bag-black-out': '/product/the-djarv-single-surfboard-bag-black-out',
  '/product/the-bunker-board-bag': '/product/the-djarv-3-4-surfboard-coffin',
  '/the-bunker-board-bag': '/the-djarv-3-4-surfboard-coffin',
  '/the-bunker-board-bag-black-out': '/the-djarv-3-4-surfboard-coffin-black-out',
  '/product/the-bunker-board-bag-black-out': '/product/the-djarv-3-4-surfboard-coffin-black-out',
  '/product/the-sidekick-25l': '/product/the-aera-25l-tote',
  '/the-sidekick-25l': '/the-aera-25l-tote',
  '/the-sidekick-25l-black-out': '/the-aera-25l-tote-black-out',
  '/product/the-sidekick-25l-black-out': '/product/the-aera-25l-tote-black-out',
  '/the-sidekick-25l-whiteout': '/the-aera-25l-tote-whiteout',
  '/product/the-sidekick-25l-whiteout': '/product/the-aera-25l-tote-whiteout',
  '/the-sidekick-25l-sage-green': '/the-aera-25l-tote-sage-green',
  '/product/the-sidekick-25l-sage-green': '/product/the-aera-25l-tote-sage-green',
  '/the-sidekick-25l-desert-khaki': '/the-aera-25l-tote-desert-khaki',
  '/product/the-sidekick-25l-desert-khaki': '/product/the-aera-25l-tote-desert-khaki',
  '/product/the-sidekick-50l': '/product/the-aera-50l-tote',
  '/the-sidekick-50l': '/the-aera-50l-tote',
  '/the-sidekick-50l-black-out': '/the-aera-50l-tote-black-out',
  '/product/the-sidekick-50l-black-out': '/product/the-aera-50l-tote-black-out',
  '/the-sidekick-50l-whiteout': '/the-aera-50l-tote-whiteout',
  '/product/the-sidekick-50l-whiteout': '/product/the-aera-50l-tote-whiteout',
  '/the-sidekick-50l-sage-green': '/the-aera-50l-tote-sage-green',
  '/product/the-sidekick-50l-sage-green': '/product/the-aera-50l-tote-sage-green',
  '/the-sidekick-50l-desert-khaki': '/the-aera-50l-tote-desert-khaki',
  '/product/the-sidekick-50l-desert-khaki': '/product/the-aera-50l-tote-desert-khaki',
  '/product/the-getaway': '/product/the-aera-40l-weeekend-bag',
  '/the-getaway': '/the-aera-40l-weeekend-bag',
  '/the-getaway-black-out': '/the-aera-40l-weeekend-bag-black-out',
  '/product/the-getaway-black-out': '/product/the-aera-40l-weeekend-bag-black-out',
  '/product/the-hook': '/product/the-tillagg-keychain',
  '/the-hook': '/the-tillagg-keychain',
  '/the-hook-sage-green': '/the-tillagg-keychain-sage-green',
  '/product/the-hook-sage-green': '/product/the-tillagg-keychain-sage-green',
  '/the-hook-black-out': '/the-tillagg-keychain-black-out',
  '/product/the-hook-black-out': '/product/the-tillagg-keychain-black-out',
  '/the-hook-midnight-sun': '/the-tillagg-keychain-midnight-sun',
  '/product/the-hook-midnight-sun': '/product/the-tillagg-keychain-midnight-sun',
  '/the-hook-deep-sea-blue': '/the-tillagg-keychain-deep-sea-blue',
  '/product/the-hook-deep-sea-blue': '/product/the-tillagg-keychain-deep-sea-blue',
  '/the-hook-desert-khaki': '/the-tillagg-keychain-desert-khaki',
  '/product/the-hook-desert-khaki': '/product/the-tillagg-keychain-desert-khaki',
  '/product/the-vault': '/product/the-tillagg-crossbody-bag',
  '/the-vault': '/the-tillagg-crossbody-bag',
  '/the-vault-sage-green': '/the-tillagg-crossbody-bag-sage-green',
  '/product/the-vault-sage-green': '/product/the-tillagg-crossbody-bag-sage-green',
  '/the-vault-black-out': '/the-tillagg-crossbody-bag-black-out',
  '/product/the-vault-black-out': '/product/the-tillagg-crossbody-bag-black-out',
  '/the-vault-whiteout': '/the-tillagg-crossbody-bag-whiteout',
  '/product/the-vault-whiteout': '/product/the-tillagg-crossbody-bag-whiteout',
  '/product/the-micro': '/product/the-tillagg-portable-pocket',
  '/the-micro': '/the-tillagg-portable-pocket',
  '/the-micro-sage-green': '/the-tillagg-portable-pocket-sage-green',
  '/product/the-micro-sage-green': '/product/the-tillagg-portable-pocket-sage-green',
  '/the-micro-black-out': '/the-tillagg-portable-pocket-black-out',
  '/product/the-micro-black-out': '/product/the-tillagg-portable-pocket-black-out',
  '/the-micro-whiteout': '/the-tillagg-portable-pocket-whiteout',
  '/product/the-micro-whiteout': '/product/the-tillagg-portable-pocket-whiteout',
  '/product/the-element': '/product/the-somlos-32l-rolltop-backpack',
  '/the-element': '/the-somlos-32l-rolltop-backpack',
  '/the-element-black-out': '/the-somlos-32l-rolltop-backpack-black-out',
  '/product/the-element-black-out': '/product/the-somlos-32l-rolltop-backpack-black-out',
  '/the-element-camo-30': '/the-somlos-32l-rolltop-backpack-camo-30',
  '/product/the-element-camo-30': '/product/the-somlos-32l-rolltop-backpack-camo-30',
  '/product/the-avenue-benni-edition': '/product/the-aera-16l-backpack-3',
  '/the-avenue-benni-edition': '/the-aera-16l-backpack-3',
  '/the-avenue-benni-edition-bennified-20': '/the-aera-16l-backpack-3-bennified-20',
  '/product/the-avenue-benni-edition-bennified-20': '/product/the-aera-16l-backpack-3-bennified-20',
  '/product/the-hugger-30l-benni-edition': '/product/the-strom-30l-backpack-5',
  '/the-hugger-30l-benni-edition': '/the-strom-30l-backpack-5',
  '/the-hugger-30l-benni-edition-bennified-20': '/the-strom-30l-backpack-5-bennified-20',
  '/product/the-hugger-30l-benni-edition-bennified-20': '/product/the-strom-30l-backpack-5-bennified-20',
  '/product/the-sidekick-25l-benni-edition': '/product/the-aera-25l-tote-2',
  '/the-sidekick-25l-benni-edition': '/the-aera-25l-tote-2',
  '/the-sidekick-25l-benni-edition-bennified-20': '/the-aera-25l-tote-2-bennified-20',
  '/product/the-sidekick-25l-benni-edition-bennified-20': '/product/the-aera-25l-tote-2-bennified-20',
  '/product/the-expeditionist-luggage-tag-1': '/product/the-varldsvan-luggage-tag-1',
  '/the-expeditionist-luggage-tag-1': '/the-varldsvan-luggage-tag-1',
  '/the-expeditionist-luggage-tag-1-jay-alvarrez-edition': '/the-varldsvan-luggage-tag-1-jay-alvarrez-edition',
  '/product/the-expeditionist-luggage-tag-1-jay-alvarrez-edition': '/product/the-varldsvan-luggage-tag-1-jay-alvarrez-edition',
  '/the-expeditionist-luggage-tag-1-blackred': '/the-varldsvan-luggage-tag-1-blackred',
  '/product/the-expeditionist-luggage-tag-1-blackred': '/product/the-varldsvan-luggage-tag-1-blackred',
  '/the-expeditionist-luggage-tag-1-black-out-1718': '/the-varldsvan-luggage-tag-1-black-out-1718',
  '/product/the-expeditionist-luggage-tag-1-black-out-1718': '/product/the-varldsvan-luggage-tag-1-black-out-1718',
  '/the-expeditionist-luggage-tag-1-janni-edition': '/the-varldsvan-luggage-tag-1-janni-edition',
  '/product/the-expeditionist-luggage-tag-1-janni-edition': '/product/the-varldsvan-luggage-tag-1-janni-edition',
  '/product/the-big-bastard-90l': '/product/the-strom-90l-rollerbag-7',
  '/the-big-bastard-90l': '/the-strom-90l-rollerbag-7',
  '/product/the-hugger-30l-url': '/product/the-strom-30l-backpack-test',
  '/the-hugger-30l-url': '/the-strom-30l-backpack-test',
  '/the-hugger-30l-url-deep-sea-blue-eva': '/the-strom-30l-backpack-test-deep-sea-blue-eva',
  '/product/the-hugger-30l-url-deep-sea-blue-eva': '/product/the-strom-30l-backpack-test-deep-sea-blue-eva',
  '/product/the-hugger-20l-1': '/product/the-strom-20l-backpack-1',
  '/the-hugger-20l-1': '/the-strom-20l-backpack-1',
  '/product/the-getaway-weekend-bag': '/product/the-aera-40l-weeekend-bag-2',
  '/the-getaway-weekend-bag': '/the-aera-40l-weeekend-bag-2',
  '/the-getaway-weekend-bag-bennified-20': '/the-aera-40l-weeekend-bag-2-bennified-20',
  '/product/the-getaway-weekend-bag-bennified-20': '/product/the-aera-40l-weeekend-bag-2-bennified-20',
  '/product/the-proper-2-0-16': '/product/the-varldsvan-16-laptop-sleeve',
  '/the-proper-2-0-16': '/the-varldsvan-16-laptop-sleeve',
  '/the-proper-2-0-16-black-out': '/the-varldsvan-16-laptop-sleeve-black-out',
  '/product/the-proper-2-0-16-black-out': '/product/the-varldsvan-16-laptop-sleeve-black-out',
  '/the-proper-2-0-16-whiteout': '/the-varldsvan-16-laptop-sleeve-whiteout',
  '/product/the-proper-2-0-16-whiteout': '/product/the-varldsvan-16-laptop-sleeve-whiteout',
  '/the-proper-2-0-16-sage-green': '/the-varldsvan-16-laptop-sleeve-sage-green',
  '/product/the-proper-2-0-16-sage-green': '/product/the-varldsvan-16-laptop-sleeve-sage-green',
  '/product/the-proper-2-0-13': '/product/the-varldsvan-13-laptop-sleeve',
  '/the-proper-2-0-13': '/the-varldsvan-13-laptop-sleeve',
  '/the-proper-2-0-13-black-out': '/the-varldsvan-13-laptop-sleeve-black-out',
  '/product/the-proper-2-0-13-black-out': '/product/the-varldsvan-13-laptop-sleeve-black-out',
  '/the-proper-2-0-13-whiteout': '/the-varldsvan-13-laptop-sleeve-whiteout',
  '/product/the-proper-2-0-13-whiteout': '/product/the-varldsvan-13-laptop-sleeve-whiteout',
  '/the-proper-2-0-13-sage-green': '/the-varldsvan-13-laptop-sleeve-sage-green',
  '/product/the-proper-2-0-13-sage-green': '/product/the-varldsvan-13-laptop-sleeve-sage-green',
  '/product/the-explorer-2-0': '/product/the-fjall-20l-backpack',
  '/the-explorer-2-0': '/the-fjall-20l-backpack',
  '/product/the-hugger-30l-white-camo': '/product/the-strom-30l-backpack-6',
  '/the-hugger-30l-white-camo': '/the-strom-30l-backpack-6',
  '/the-hugger-30l-white-camo-camo-20': '/the-strom-30l-backpack-6-camo-20',
  '/product/the-hugger-30l-white-camo-camo-20': '/product/the-strom-30l-backpack-6-camo-20',
  '/product/the-douchebag-white-camo': '/product/the-djarv-snowroller-3',
  '/the-douchebag-white-camo': '/the-djarv-snowroller-3',
  '/the-douchebag-white-camo-camo-20': '/the-djarv-snowroller-3-camo-20',
  '/product/the-douchebag-white-camo-camo-20': '/product/the-djarv-snowroller-3-camo-20',
  '/product/the-base-backpack': '/product/the-naer-15l-backpack-4',
  '/the-base-backpack': '/the-naer-15l-backpack-4',
  '/the-base-backpack-camo-20': '/the-naer-15l-backpack-4-camo-20',
  '/product/the-base-backpack-camo-20': '/product/the-naer-15l-backpack-4-camo-20',
  '/product/the-big-bastard-90l-roller-bag': '/product/the-strom-90l-rollerbag-8',
  '/the-big-bastard-90l-roller-bag': '/the-strom-90l-rollerbag-8',
  '/the-big-bastard-90l-roller-bag-camo-20': '/the-strom-90l-rollerbag-8-camo-20',
  '/product/the-big-bastard-90l-roller-bag-camo-20': '/product/the-strom-90l-rollerbag-8-camo-20',
  '/product/the-duplex-50l-split-duffel': '/product/the-hytta-50l',
  '/the-duplex-50l-split-duffel': '/the-hytta-50l',
  '/the-duplex-50l-split-duffel-black-out': '/the-hytta-50l-black-out',
  '/product/the-duplex-50l-split-duffel-black-out': '/product/the-hytta-50l-black-out',
  '/product/the-duplex-70l-split-duffel': '/product/the-hytta-70l',
  '/the-duplex-70l-split-duffel': '/the-hytta-70l',
  '/the-duplex-70l-split-duffel-black-out': '/the-hytta-70l-black-out',
  '/product/the-duplex-70l-split-duffel-black-out': '/product/the-hytta-70l-black-out',
  '/product/the-duplex-90l-split-duffel': '/product/the-hytta-90l',
  '/the-duplex-90l-split-duffel': '/the-hytta-90l',
  '/the-duplex-90l-split-duffel-black-out': '/the-hytta-90l-black-out',
  '/product/the-duplex-90l-split-duffel-black-out': '/product/the-hytta-90l-black-out',
  '/product/the-hugger-20l-2': '/product/the-strom-20l-backpack-2',
  '/the-hugger-20l-2': '/the-strom-20l-backpack-2',
  '/the-hugger-20l-2-alcantara': '/the-strom-20l-backpack-2-alcantara',
  '/product/the-hugger-20l-2-alcantara': '/product/the-strom-20l-backpack-2-alcantara',
  '/product/the-sidekick-25l-tote': '/product/the-aera-25l-tote-1',
  '/the-sidekick-25l-tote': '/the-aera-25l-tote-1',
  '/product/the-sidekick-50l-tote': '/product/the-aera-50l-tote-1',
  '/the-sidekick-50l-tote': '/the-aera-50l-tote-1',
  '/product/the-vault-cross-body-bag': '/product/the-tillagg-crossbody-bag-1',
  '/the-vault-cross-body-bag': '/the-tillagg-crossbody-bag-1',
  '/the-vault-cross-body-bag-alcantara': '/the-tillagg-crossbody-bag-1-alcantara',
  '/product/the-vault-cross-body-bag-alcantara': '/product/the-tillagg-crossbody-bag-1-alcantara',
  '/product/the-micro-portable-pocket': '/product/the-tillagg-portable-pocket-1',
  '/the-micro-portable-pocket': '/the-tillagg-portable-pocket-1',
  '/the-micro-portable-pocket-alcantara': '/the-tillagg-portable-pocket-1-alcantara',
  '/product/the-micro-portable-pocket-alcantara': '/product/the-tillagg-portable-pocket-1-alcantara',
  '/product/the-avenue-backpack': '/product/the-aera-16l-backpack-4',
  '/the-avenue-backpack': '/the-aera-16l-backpack-4',
  '/the-avenue-backpack-alcantara': '/the-aera-16l-backpack-4-alcantara',
  '/product/the-avenue-backpack-alcantara': '/product/the-aera-16l-backpack-4-alcantara',
  '/product/the-slim-jim-light-snow-roller': '/product/the-latt-light-snowroller-3',
  '/the-slim-jim-light-snow-roller': '/the-latt-light-snowroller-3',
  '/product/the-base-backpack-1': '/product/the-naer-15l-backpack-5',
  '/the-base-backpack-1': '/the-naer-15l-backpack-5',
  '/product/the-little-bastard-60l-roller-bag': '/product/the-strom-60l-rollerbag-5',
  '/the-little-bastard-60l-roller-bag': '/the-strom-60l-rollerbag-5',
  '/product/the-savage-bike-bag-1': '/product/the-djarv-bike-bag-1',
  '/the-savage-bike-bag-1': '/the-djarv-bike-bag-1',
  '/the-savage-bike-bag-1-black-out': '/the-djarv-bike-bag-1-black-out',
  '/product/the-savage-bike-bag-1-black-out': '/product/the-djarv-bike-bag-1-black-out',
  '/product/the-hugger-50l-1': '/product/the-strom-50l-backpack-1',
  '/the-hugger-50l-1': '/the-strom-50l-backpack-1',
  '/the-hugger-50l-1-deep-sea-blue': '/the-strom-50l-backpack-1-deep-sea-blue',
  '/product/the-hugger-50l-1-deep-sea-blue': '/product/the-strom-50l-backpack-1-deep-sea-blue',
  '/product/the-element-rolltop': '/product/the-somlos-32l-rolltop-backpack-1',
  '/the-element-rolltop': '/the-somlos-32l-rolltop-backpack-1',
}

export default redirects