import styled from 'styled-components';
import Colors from 'assets/styles/Colors';
import Fonts from 'assets/styles/Fonts';
import Base from 'assets/styles/Base';

export default styled.div`
    ${Fonts};
    ${Base};

    * {
        box-sizing: border-box;
    }

    background: #fff;
    color: ${Colors.black};
    font-family: 'Lyon Text';
    font-size: 16px;
    line-height: 25px;
    font-weight: normal;
`
