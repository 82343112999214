import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom';


class CartButton extends Component {
    
    /**
     * Render
     * @return {JSX}
     */
    render() {
        let totalQuantity = 0;

        if (this.props.data !== null) {
            if (typeof this.props.data.selection !== 'undefined') {
                totalQuantity = this.props.data.selection.totals.totalQuantity;
            }
        }

        return (
            <a href="#" onClick={this.props.onClick} className={this.props.className}>
                <img height="25" width="25" src={this.props.icon} alt="Cart" />
                {totalQuantity > 0 && <span>{totalQuantity}</span>}
            </a>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.cart,
        ...ownProps
    };
};

export default connect(mapStateToProps)(CartButton);