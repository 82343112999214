import React from 'react';
import { SelectEntry, Globals } from 'kbcms-react';
import styled, { css } from 'styled-components';
import Colors from 'assets/styles/Colors';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import BaseModule from 'modules/DBBaseModule';
import OptionsWrapper from 'components/OptionsWrapper';
import { default as media } from 'assets/styles/Breakpoints';

class Stories extends BaseModule {
    
    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Stories" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props)
        this.state = {
            fields: {
                ...{}, ...{
                    stories: {
                        entryType: 'stories',
                        filter: [],
                        data: []
                    }
                }, ...props.fields
            }
        }
    }

    componentDidMount() {
        this.prepareData().then(state => {
         this.setState(state);
        });
    }

    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'fullWidth',
                label: 'Full width',
                type: 'switch'
            },
        ]);
    }

    /**
    * Renderer
    */
    render() {
        const { options, editMode } = this.props;
        const { fields } = this.state;
        const dataLength = fields.stories.data.length;
        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper fullWidth={options.fullWidth} length={dataLength}>
                    { editMode && (
                        <SelectEntry module={this} field={'stories'} multiple={true}>
                            <SelectProductsButton>Select Stories</SelectProductsButton>
                        </SelectEntry>
                    )}
                    { fields.stories.data.map((story, key) => {
                        if (dataLength === 1) {
                            return (
                                <StyledImage src={story.image.srcset} key={key}>
                                    <StyledContentArea length={dataLength}>
                                        <p className="tag">{Globals.get('mod_st_heading')}</p>
                                        <h2>{story.name}</h2>
                                        <Link to={"/" + story.route[0].url}>
                                            <Button>{Globals.get('mod_st_readmore')}</Button>
                                        </Link>
                                    </StyledContentArea>
                                </StyledImage>
                            )
                        } else {
                            return (
                                <StyledStoryItem key={key} fullWidth={options.fullWidth && dataLength === 2} length={dataLength}> 
                                    {story.image !== null && (
                                        <Link to={"/" + story.route[0].url}><img srcSet={story.image.srcset} alt={story.name} /></Link>
                                    )}
                                    <StyledContentArea fullWidth={options.fullWidth && dataLength === 2}>
                                        <p className="tag">{Globals.get('mod_st_heading')}</p>
                                        <Link to={"/" + story.route[0].url}>
                                            <h2>{story.name}</h2>
                                        </Link>
                                        <p>{story.excerpt}</p>
                                        <Link to={"/" + story.route[0].url}>
                                            <Button black>{Globals.get('mod_st_readmore')}</Button>
                                        </Link>
                                    </StyledContentArea>
                                </StyledStoryItem>
                            )
                        }

                    })}
                </Wrapper>  
            </OptionsWrapper>
        );
    }
}

export default Stories


const SelectProductsButton = styled.button`
    position: absolute;
    z-index: 9;
    top: 10px;
    left: 50%;
    background: white;
    border: 1px solid #000;
    border-radius: 4px;
    padding: .5em;
    transform: translateX(-50%);
`

const StyledImage = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: auto;
    width: 100%;
    background-image: url(${p => p.src});
    background-size: cover;
    background-color: ${Colors.gray};

    h2, p {
        color: white;
    }

    ${media.greaterThan('medium')`
        min-height: 600px;
    `}

    ${media.lessThan('medium')`
        min-height: 400px;
    `}

    ${media.lessThan('small')`
        min-height: 285px;
    `}
`

const Wrapper = styled.section`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px 0;
    margin: 0 auto;

    ${p => ( !p.fullWidth && p.length !== 1 ) ? 'max-width: 1180px' : 'max-width: 1660px' };

    ${p => p.length > 2 && css`
        flex-wrap: wrap;
    `}

    ${media.lessThan('huge')`
        width: ${p => p.fullWidth ? '100%' : 'calc(100% - 180px)'};
        ${StyledImage} {
            width: 100%;
        }
    `}

    ${media.lessThan('large')`
        width: ${p => p.fullWidth ? '100%' : 'calc(100% - 160px)'};
        ${StyledImage} {
            width: 100%;
        }
    `}

    ${media.lessThan('medium')`
        width: ${p => p.fullWidth ? '100%' : 'calc(100% - 100px)'};
        ${StyledImage} {
            width: 100%;
        }
    `}

    ${media.lessThan('small')`
        width: ${p => p.fullWidth ? '100%' : 'calc(100% - 30px)'};
        ${StyledImage} {
            width: 100%;
        }
    `}

    ${media.lessThan('mobile')`
        flex-direction: column;
    `}
`

const StyledStoryItem = styled.div`
    padding-bottom: 30px;
    
    img {
        width: 100%;
        margin-bottom: 20px;
    }

    ${p => !p.fullWidth  && css`
        margin: 0 8px;
    `}

    ${p => p.length > 2 && css`
        width: calc(33% - 16px);
    `}

    ${media.lessThan('small')`
        width: calc(50% - 38px);
        margin-bottom: 30px;
    `}

    ${media.lessThan('mobile')`
        width: 100%;
        margin-left: 0;
        margin-right: 0; 
    `}
`

const StyledContentArea = styled.div`
    text-align: center;
    padding: 0 16px;

    h2 {
        color: ${Colors.black};
        font-size: 24px;
        line-height: 36px;
    }

    a { 
        text-decoration: none;
    }

    p {
        margin-bottom: 2em;
        
        &.tag {
            font-size: 14px !important;
            line-height: 20px !important;
            font-family: 'Circular Pro' !important;
            text-transform: uppercase;
            margin: 0 !important;
        }
    }

    ${p => p.length === 1 && css`
        padding-right: 30px;

        h2 {
            font-size: 60px;
        }
    `}

    ${media.lessThan('small')`
        margin: 0 auto;

        h2 {
            line-height: 26px;

            ${p => p.length === 1 && css`
                font-size: 40px;
                line-height: 36px;
            `}
        }
    `}
`