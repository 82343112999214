import React from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';
import IconButton from 'cms/components/Form/IconButton';
import { IoMdWarning } from 'react-icons/io';

const ReactTooltip = loadable(() => import('react-tooltip'), {
    fallback: (() => <div/>)(),
});

class ValidationError extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { id, error } = this.props;


        return (
            <ValidationErrorContainer>
                <div data-tip data-for={ 'validation-error-' + id }>
                    <IconButton size={'30px'} warning>
                        <IoMdWarning/>
                    </IconButton>
                </div>
                <ReactTooltip id={ 'validation-error-' + id } effect={ 'solid' } place={ 'right' }>
                    { error.map((er, k) => (<Tooltip key={ k }>{ er }</Tooltip>)) }
                </ReactTooltip>
            </ValidationErrorContainer>
        )
    }
}

export default ValidationError;

const ValidationErrorContainer = styled.div`
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
`

const Tooltip = styled.div`
  text-decoration: none !important;
  font-size: 1em;
  width: 150px;
  margin-bottom: .5em;
  
  &:last-child {
    margin-bottom: 0;
  }
  
`