import styled, {css} from 'styled-components';
import Colors from 'assets/styles/Colors';

const InputField = styled.input`
    width: 100%;
    border: 0;
    background: none;
    border-bottom: 1px solid #E8E4DE;
    padding: 10px 0;
    font-family: 'Circular Pro Book';
    font-size: 16px;
    outline: none;
    color: ${Colors.black};
    margin-bottom: 20px;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;

    &::placeholder {
        color: #D1D1D1;
    }

    ${p => (p.markRed) && css`
        color: red;
        &::placeholder {
            color: red;
        }
    `}
`

export default InputField;
