import React from 'react';
import { Editor, Linker, Media} from 'kbcms-react';
import BaseModule from 'modules/DBBaseModule';
import OptionsWrapper from 'components/OptionsWrapper';
import styled, {css} from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';
import fontOptions from 'assets/styles/FontOptions';
import produce from 'immer';

const defaultItem = {
    image: {},
    text: 'Enter text...',
    muted: false,
    forceNativeVideoAspectRatio: false,
    imageCaption: 'Enter a caption',
    linkUrl: ''
};

class CategoryImages extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Category images" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                ...{}, ...{
                    items: [{
                        ...defaultItem
                    }]
                },
            ...props.fields,
            }
        }
    }

    static options() {
        return super.options().concat([
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    /*
     * Toggles mute on the video on an category-item
     */
    onToggleMute = (index) => {
        const nextState = produce(this.state, draftState => {
            draftState.fields.items[index].muted = !draftState.fields.items[index].muted;
        })

        this.setState(nextState)
    }

    /*
     * Toggles native Aspect-Ratio for video on a category-item
     */
    onToggleNativeAspectRatio = (index) => {
        const nextState = produce(this.state, draftState => {
            draftState.fields.items[index].forceNativeVideoAspectRatio = !draftState.fields.items[index].forceNativeVideoAspectRatio;
        })

        this.setState(nextState)
    }

     getFileType = (file = {}) => {
        if (file.mime) {
            if (file.mime.includes('image')) {
                return 'image'
            } else if(file.mime.includes('video')) {
                return 'video'
            }
        }
        return 'none'
    }

    /**
    * Renderer
    */
    render() {
        let { options, editMode } = this.props;
        let { fields } = this.state;
        let width = () => (100 / fields.items.length + '%');

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper editMode={editMode}>
                    <ItemWrapper>
                        { fields.items.map((item,index) => {
                            const fileType = this.getFileType(item.image);
                            return (
                                <StyledItem key={index} itemWidth={width} editMode={editMode}>
                                    <StyledLinker module={this} field={'items.' + index + '.linkUrl'}>
                                        <StyledMedia
                                            module={this}
                                            field={'items.' + index + '.image'}
                                            types={['images', 'videos']}
                                            overlay={options.overlay}
                                            aspectRatio={18/11}
                                            editPosition={fileType === 'image' || fileType === 'none' ? 'sw' : 'e'}
                                            videoOptions={{
                                                onToggleMute: () => this.onToggleMute(index),
                                                onToggleNativeAspectRatio: () => this.onToggleNativeAspectRatio(index),
                                                forceNativeVideoAspectRatio: item.forceNativeVideoAspectRatio,
                                                muted: item.muted,
                                                autoplay: item.muted
                                            }}
                                            fill>
                                            <StyledContentArea>
                                                <Editor
                                                    module={this}
                                                    disabled={!editMode}
                                                    field={'items.' + index + '.text'}
                                                    data={item.text}
                                                    fonts={fontOptions}
                                                    defaultFont='Lyon Display'
                                                />
                                            </StyledContentArea>
                                        </StyledMedia>
                                        { editMode &&
                                            <StyledButton onClick={ () => this.removeItemFromArrayField('items', index) }>Delete</StyledButton>
                                        }
                                    </StyledLinker>
                                </StyledItem>
                            )
                        })}
                    </ItemWrapper>
                    { editMode && fields.items.length < 6 &&
                        <StyledButton onClick={ () => this.addItemToArrayField('items', defaultItem) }>Add item</StyledButton>
                    }
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default CategoryImages


const StyledButton = styled.div`
    margin: 8px;
    display: inline-block;
    background: #fff;
    padding: .5em;
    border: 1px solid #000;
    cursor: pointer;
`

const Wrapper = styled.div`
    width: calc(100% - 260px);
    max-width: 1180px;
    text-align: center;
    margin: 0 auto;
    ${p => p.editMode ? 'padding-top: 100px' : null}

    ${media.lessThan('huge')`
        width: calc(100% - 180px);
    `}

    ${media.lessThan('large')`
        max-width: 1200px;
        width: calc(100% - 160px);
    `}

    ${media.lessThan('medium')`
        max-width: 992px;
        width: calc(100% - 100px);
    `}

    ${media.lessThan('small')`
        max-width: 768px;
        width: calc(100% - 30px);
    `}

    ${media.lessThan('mobile')`
        width: 100%;
    `}
`

const ItemWrapper = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.lessThan('small')`
        flex-wrap: wrap;
    `}

    ${media.lessThan('mobile')`
        width: 100%;
    `}
`

const StyledItem = styled.div`
    width: ${p => p.itemWidth};
    margin: 0 8px;

    ${p => p.editMode && css`
        max-width: 400px;
    `}

    a {
        text-decoration: none;
    }

    ${media.lessThan('small')`
        width: calc(33% - 32px);
        margin-bottom: 30px;
    `}

    ${media.lessThan('mobile')`
        width: 50%;
        margin: 0;
    `}
`

const StyledLinker = styled(Linker)`
    width: 100%;
`

const StyledMedia = styled(Media)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const StyledContentArea = styled.div`
    text-align: center;
    z-index: 1;
    width: 80%;

    h1, h2, h3, div, p {
        color: #fff;
    }
`
