import store from '../store'
import ApiManager from "../ApiManager";
import debounce from 'lodash/debounce';

const Api = new ApiManager();

const defaultState = {
    loaded: false,
    settings: {},
};

const LOAD_SETTINGS = 'LOAD_SETTINGS';
const LOADED_SETTINGS = 'LOADED_SETTINGS';
const SET_SETTING = 'SET_SETTING';

let debouncedInstant = debounce((key, value) => {
    Api.saveSetting(key, value).then(r => {});
}, 250)

let debouncedResponse = debounce((key, value) => {
    Api.saveSetting(key, value).then((result) => {
        store.dispatch({
            type: SET_SETTING,
            key: result.data.key,
            value: result.data.value,
        });
    })
}, 250)

export const SettingsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_SETTINGS:
            return {
                ...{},
                ...state,
            };
        case LOADED_SETTINGS:
            return {
                ...{},
                ...state,
                ...{
                    loaded: action.loaded,
                    settings: action.settings,
                }
            };
        case SET_SETTING:
            return {
                ...{},
                ...state,
                ...{
                    settings: {
                        ...{}, ...state.settings,
                        ...{
                            [action.key]: (typeof action.value === 'string' || typeof action.value === 'boolean') ? action.value : {
                                ...{}, ...action.value,
                            },
                        }
                    }
                }
            };
        default:
            return state;
    }
};

const SettingsActions = {
    setSettings: (settings) => {
        store.dispatch({
            type: LOADED_SETTINGS,
            loaded: true,
            settings: settings,
        });
    },
    loadSettings: () => {
        store.dispatch({
            type: LOAD_SETTINGS,
        });

        Api.getSettings().then((result) => {
            store.dispatch({
                type: LOADED_SETTINGS,
                loaded: true,
                settings: result.data,
            });
        });
    },
    storeSetting: (key, value) => {
        if(value && value.value !== undefined && !value.entryType && !value.modelType) {
            debouncedInstant(key, value);
            store.dispatch({
                type: SET_SETTING,
                key: key,
                value: value.value,
            });
        } else {
            debouncedResponse(key, value);
        }
    },
    get: (key) => {
        let settings = store.getState().settings;

        return settings.settings[key];
    },
};

export default SettingsActions;