import isServer from '../isServer'

const loadGrowsurf = () => {
  (function (g, r, s, f) {
    g.grsfSettings = {
      campaignId: 'ctkdrf',
      version: '2.0.0',
    }
    s = r.getElementsByTagName('head')[0]
    f = r.createElement('script')
    f.defer = 1
    f.src = 'https://app.growsurf.com/growsurf.js' + '?v=' +
      g.grsfSettings.version
    f.setAttribute('grsf-campaign', g.grsfSettings.campaignId)
    !g.grsfInit ? s.appendChild(f) : ''
  })(window, document)
}

const growsurfLoader = () => {
  if(isServer) return

  setTimeout(() => {
    loadGrowsurf()
  }, 5000)
}

export default growsurfLoader