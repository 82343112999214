import React from 'react'
import { Globals } from 'kbcms-react'
import LimitedStar from '../images/limited-star.svg'
import SpecialStar from '../images/special-star.svg'
import ProductLabel from './ProductLabel'

function ProductLabels({ variation, onlyOne = false }) {
    if(!variation) return null

    let labels = [];

    if(variation.sizes[0].stock === 0) labels.push('oos')
    if(variation.is_preorder === 1) labels.push('preorder')
    if(variation.prices[0].on_sale === 1 && variation.is_preorder !== 1 && variation.is_bundle !== 1) labels.push('sale')
    if(variation.prices[0].new_product === 1) labels.push('new')
    if(variation.limited === 1) labels.push('limited')
    if(variation.special === 1) labels.push('special')

    if(labels.length === 0) return null

    if(onlyOne) return renderLabel(labels[0])

    return (
        <React.Fragment>{ labels.map(l => renderLabel(l)) }</React.Fragment>
    )
}

function renderLabel(type) {
    switch (type) {
        case 'oos':
            return <ProductLabel key={'oos'} center>{ Globals.get('mod_pb_oos') }</ProductLabel>
        case 'preorder':
            return <ProductLabel key={'preorder'} type={ 'preorder' }><img src={ SpecialStar } alt="Star Icon"/>{ Globals.get('mod_pb_preorder', 'PRE-ORDER') }</ProductLabel>
        case 'sale':
            return <ProductLabel key={'sale'} type={ 'sale' }>{ Globals.get('mod_pb_sale') }</ProductLabel>
        case 'new':
            return <ProductLabel key={'new'} type={ 'new' }>{ Globals.get('mod_pb_new') }</ProductLabel>
        case 'limited':
            return <ProductLabel key={'limited'} type={ 'limited' }><img src={ LimitedStar } alt="Star Icon"/>{ Globals.get('mod_pb_limited') }</ProductLabel>
        case 'special':
            return <ProductLabel key={'special'} type={ 'special' }><img src={ SpecialStar } alt="Star Icon"/>{ Globals.get('mod_pb_special') }</ProductLabel>
        default:
            return null
    }
}

export default ProductLabels
