import { Link } from 'react-router-dom'
import React, { Component } from 'react';
import Colors from 'assets/styles/Colors';
import styled, { css } from 'styled-components';
import ProductLabels from '../../components/ProductLabels'

export default class HighlightedProductBox extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activeVariation: props.product.variations[0]
        }
    };

    /**
     * Changes the color in the view, also sets the URI for the product link to the selected variation.
     */
    selectVariationForItem = variationKey => {
        this.setState({ activeVariation: this.props.product.variations[variationKey] });
    };

    render() {
        let { activeVariation } = this.state;
        let { product } = this.props;

        return (
            <Wrapper>
                <ProductLabels variation={ activeVariation }/>

                <ProductInfo>
                    <ProductTitle>{product.name}</ProductTitle>
                    <ProductType>{activeVariation.excerpt}</ProductType>
                    <ProductPrice>
                        {activeVariation.prices[0].on_sale === 1 && (
                            <React.Fragment>
                                <PreviousPrice>{activeVariation.prices[0].price_before_discount}</PreviousPrice>
                                {activeVariation.prices[0].price}
                            </React.Fragment>
                        )}
                        {activeVariation.prices[0].on_sale === 0 && activeVariation.prices[0].price}  
                    </ProductPrice>
                </ProductInfo>
                <Variations>
                    {product.variations.map((currentVariation, key) => {
                        return (
                            <Variation
                                key={currentVariation.id} 
                                swatch={currentVariation.swatch}
                                active={currentVariation.id === activeVariation.id}
                                onClick={() => this.selectVariationForItem(key)}
                            />
                            )
                        })
                    }
                </Variations>
                
                <StyledLink to={'/' + activeVariation.slug} outofstock={activeVariation.sizes[0].stock === 0}>
                    { activeVariation.sizes[0].stock === 0 ? 'Out of stock' : 'Shop now' }
                </StyledLink>
                
                <ReadMore to={'/' + activeVariation.slug}>Read More</ReadMore>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .product-label {
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 0;
    
        img {
            height: 10px;
            margin-right: 5px;
        }
    }
`

const ProductInfo = styled.div`
    text-align: center;
    padding: 32px 16px 0;
`

const PreviousPrice = styled.span`
    text-decoration: line-through;
    color: #ddd;
    margin-right: 7px;
    font-variant-numeric: lining-nums;
`

const ProductTitle = styled.h4`
`

const ProductType = styled.div`
    font-size: 11px;
    line-height: 15px;
    font-family: 'Circular Pro';
    text-transform: uppercase;
`

const ProductPrice = styled.div`
    font-size: 18px;
    line-height: 20px;
    font-family: 'Lyon Text Bold';
    margin-top: .3em;
    height: 21px;
    font-variant-numeric: lining-nums;
`

const Variations = styled.div`
    padding: 0 0 1em;
    margin-top: .8em;
`

const Variation = styled.div`
    position: relative;
    display: inline-block;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    margin: 0 3px;
    cursor: pointer;

    ${props => props.active ? `    
        &:before {
            position: absolute;
            top: 0px;
            left: 4px;
            content: "✓";
            font-family: "Circular Pro";
            font-size: 9px;
            line-height: 17px;
        }
    `: ''}

    ${p => p.swatch && p.swatch.hasOwnProperty('color_check') && p.swatch.color_check !== '' ? `
        &:before {
            color: ${p.swatch.color_check};
        }
    `: `
        &:before {
            color: #fff;
        }
    `}
    
    
    ${p => p.swatch !== null && p.swatch.color_hex === '' ? `
        background: url(${p.swatch.color_img.url});
        background-size: cover;
        background-position: center center;
    ` : ''}

    ${p => p.swatch !== null && p.swatch.color_hex !== '' ? `
        background: ${p.swatch.color_hex};
    ` : ''}

    ${p => p.swatch === null ? `
        background: black;
    ` : ''}
`

const ReadMore = styled(Link)`
    padding: 1em 0;
    text-decoration: none;
    color: ${Colors.blue};
    font-family: 'Circular Pro';
    font-size: 14px;
    line-height: 17px;
`

const StyledLink = styled(Link)`
    display: inline-block;
    padding: 12px 30px;
    position: relative;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Circular Pro Book';
    text-transform: uppercase;
    height: auto;
    text-decoration: none;
    color: #020202;
    border: 1px solid #020202;
    cursor: pointer;

    ${p => p.outofstock && css`
        color: ${Colors.darkGray};
        border: 1px solid ${Colors.darkGray};
        pointer-events: none;
    `};
`


/* const OutOfStock = styled.div`
    opacity: .4;
    text-align: center;
    margin: 0;
` */
