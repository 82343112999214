import React from 'react'
import { BaseModule } from 'kbcms-react';

class DBBaseModule extends BaseModule {

    /**
     * Global options for Douchebags modules.
     */
    static options() {
        const baseOptions = [
            {
                name: 'margin',
                label: 'Margins',
                type: 'dropdown',
                options: [
                    { name: '0', value: 0 },
                    { name: '30', value: 30 },
                    { name: '70', value: 70 },
                    { name: '140', value: 140 },
                ]
            },
            {
                name: 'hideMobile',
                label: 'Hide on mobile',
                type: 'switch'
            },
            {
                name: 'hideDesktop',
                label: 'Hide on desktop',
                type: 'switch'
            },
        ];

        const accordionOptions = [
            {
                name: 'accordionEnabled',
                label: 'Enable accordion functionality',
                type: 'switch',
            },
            {
                name: 'accordionTitle',
                label: 'Accordion title',
                type: 'input',
            },
            {
                name: 'accordionCount',
                label: 'Additional modules to include in the same accordion',
                type: 'dropdown',
                options: [
                    { name: '0', value: 0 },
                    { name: '1', value: 1 },
                    { name: '2', value: 2 },
                    { name: '3', value: 3 },
                    { name: '4', value: 4 },
                    { name: '5', value: 5 },
                    { name: '6', value: 6 },
                    { name: '7', value: 7 },
                    { name: '8', value: 8 },
                    { name: '9', value: 9 },
                ]
            },
        ]

        if(!this.accordionable || this.accordionable() === true) {
            return accordionOptions.concat(baseOptions)
        }

        return baseOptions
    }
}

export default DBBaseModule;