import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import FileManagerActions from 'reducers/files';
import CropperActions from 'reducers/cropper';
import styled, { css } from 'styled-components';
import BaseModule from 'cms/components/BaseModule';
import VideoMedia from './Video'
import ImageMedia from './Image'
import EditModeButtons from './EditModeButtons';
import { openFileManager, FILE_TYPES, getFileType, getMediaDimensions } from './helpers';

class NewMedia extends Component {

    handleOpenFileManager = (e) => {
        const { fileManagerOpened } = this.props;
        fileManagerOpened && fileManagerOpened();
        openFileManager(e, { ...this.props }, this);
    }

    renderButtons = (fileType) => {
        const { editMode } = this.props.module.props;
        const { className, videoOptions = {}, ...restProps } = this.props;

        return editMode && (
            <StyledEditModeButtons
                fileType={fileType}
                onOpenFileManager={this.handleOpenFileManager}
                {...videoOptions}
                {...restProps}
            />
        )
    }

    render() {
        const { module, field, absolutedStyles, overlay = 0, className, fill, aspectRatio, videoOptions = {}, preload = false, ...restProps } = this.props;
        const file = { ...this.defaultFile, ...module.getField(field) };
        const { editMode } = module.props;
        const fileType = getFileType(file);

        return (
            <MediaWrapper
                className={!absolutedStyles && className}
                fileType={fileType}
                editMode={editMode}
                forceNativeVideoAspectRatio={videoOptions.forceNativeVideoAspectRatio}>
                {fileType === 'image' && (
                    <ImageMedia
                        fill={fill}
                        aspectRatio={aspectRatio}
                        overlay={overlay}
                        className={className}
                        file={file}
                        preload={preload}
                        {...module.props}>
                        {this.props.children}
                        {this.renderButtons(fileType)}
                    </ImageMedia>
                )}
                {fileType === 'video' && (
                    <VideoMedia
                        className={className}
                        aspectRatio={aspectRatio}
                        module={module}
                        fill={fill}
                        field={field}
                        {...videoOptions}
                        {...module.props}
                        file={file}>
                        {this.props.children}
                        {this.renderButtons(fileType)}
                    </VideoMedia>
                )}
                {fileType === 'none' && (
                    <ChildrenWrapper
                        editMode={editMode}
                        aspectRatio={aspectRatio}
                        className={className}>
                        {this.props.children}
                        {this.renderButtons(fileType)}
                    </ChildrenWrapper>
                )}
            </MediaWrapper>
        )
    }
}

NewMedia.propTypes = {
    module: PropTypes.instanceOf(BaseModule).isRequired,
    forceNativeVideoAspectRatio: PropTypes.bool,
    field: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    videoOptions: PropTypes.object,
}


export const MediaWrapper = styled.div`
    ${p => p.editMode && 'background-color: #d2d2d2;'}

    ${p => p.fileType === 'video' && p.forceNativeVideoAspectRatio && css`
        position: relative;
    `}

    ${p => p.fileType === 'none' && css`
      padding-top: 0;
        &:before {
          display: block;
          content: ' ';
        }
    `}
`

const ChildrenWrapper = styled.div`
      position: relative;
      background-size: cover;
      opacity: 1;
      transition: opacity .3s ease;

      ${p => p.editMode && css`
        background-color: #d2d2d2;
      `}

      &.lazyloaded {
        opacity: 1;
      }

      ${ props => props.aspectRatio && css`
        &:before {
          display: block;
          content: ' ';
          padding-bottom: ${ props => 1 / props.aspectRatio * 100 }%;
        }
      `}
`

const StyledEditModeButtons = styled(EditModeButtons)`
    ${MediaWrapper}:hover & {
        opacity: 1;
    }
`



export default NewMedia
