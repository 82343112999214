import React, { Component, memo } from 'react'
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import imgix from '../../../../helpers/imgix'

const ImageMedia = memo(({ file, className, fill, preload = false, ...restProps }) => {

    const { srcSet, sizes } = imgix(file)

    return (
      <>
        {preload && <Helmet>
          <link
            rel="preload"
            as="image"
            imagesrcset={ srcSet }
            imagesizes={ sizes }
          />
        </Helmet>}

        {fill && <StyledFillImage
          {...restProps}
          key={ file ? file.id : '' }
          className={ 'lazyload ' + className }
          data-bgset={ srcSet }
          data-sizes="auto"
          focusX={ file.focus_x }
          focusY={ file.focus_y }
        />}

        {!fill && <StyledImage
          {...restProps}
          key={ file ? file.id : '' }
          className={ 'lazyload ' + className }
          data-bgset={ srcSet }
          data-sizes="auto"
          focusX={ file.focus_x }
          focusY={ file.focus_y }
        />}

      </>
    )
})

const StyledImage = styled.div`
    height: 100%;
    width: 100%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;

    ${p => p.editMode && css`
    background-color: #d2d2d2;
    `}

    &.lazyloaded {
    opacity: 1;
    }

    &:after {
        z-index: -1;
        content: ' ';
        position:absolute;
        width:100%; height:100%;
        top:0; left:0;
        background-color:rgba(0,0,0,1);
    }

`

const StyledFillImage = styled.div`
  position: relative;
  background-size: cover;
  opacity: 0;
  transition: opacity .3s ease;

   &:after {
       content: ' ';
       position:absolute;
       width:100%; height:100%;
       top:0; left:0;
       background-color:rgba(0,0,0,${p => p.overlay});
    }

  ${p => p.editMode && css`
    background-color: #d2d2d2;
  `}

  &.lazyloaded {
    opacity: 1;
  }

   ${ props => props.focusX !== null && css`
    background-position: ${ props => props.focusX }% ${ props => props.focusY }%;
  `}

  ${ props => props.focusX === null && css`
    background-position: 50% 50%;
  `}

  ${ props => props.aspectRatio && css`
    &:before {
      display: block;
      content: ' ';
      padding-bottom: ${ props => 1 / props.aspectRatio * 100 }%;
    }
  `}
`

export default ImageMedia
