/*
 * This file instantiates on Bugsnag client for the entire app
 * Components and modules that want to send handled errors can import the exported client to send handled errors
 *
 * Components can get acces to the React <ErrorBoundary/> by calling
 * const ErrorBoundary = bugsnagClient.getPlugin('react');
 */

import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import React from 'react'

const bugsnagClient = bugsnag.start({
  apiKey: '0a952f05694746d4872277c61279fd93',
  plugins: [new bugsnagReact(React)],
  notifyReleaseStages: ['production'],
  appVersion: process.env.RAZZLE_APP_VERSION,
})

export default bugsnagClient
