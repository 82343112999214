import { createMiddleware } from 'redux-beacon'

import GoogleAnalytics from './targets/GoogleAnalytics'
import FacebookPixel from './targets/FacebookPixel'
import FacebookConversionAPI from './targets/FacebookConversionAPI'
import Ometria from './targets/Ometria'
import Webgains from './targets/Webgains'

import isServer from '../../isServer'

// Match the event definition to a Redux action:
const eventsMap = {
    'ANALYTICS_404': () => {
        return {
            type: 'analytics404',
            page: isServer ? '' : window.location.pathname,
            title: '404 - Page not found',
            location: isServer ? '' : window.location.href,
            referrer: isServer ? '' : document.referrer,
        }
    },
    'ANALYTICS_PAGE_VIEW': (action) => {
        return {
            type: 'pageView',
            page: action.page.page,
            title: action.page.title,
            location: action.page.location,
        };
    },
    'ANALYTICS_PRODUCT_VIEW': (action) => {
        try {
            return {
                type: 'productView',
                id: action.variation.sku,
                name: action.product.name,
                category: action.product.categories && action.product.categories.length > 0 ? action.product.categories[0].name : '',
                variant: action.variation.sku,
                price: action.variation.prices[0].price,
            };
        }
        catch (e) {
            return {}
        }
    },
    'ANALYTICS_ADD_TO_CART': (action) => {
        return {
            type: 'addToCart',
            id: action.variation.sku,
            name: action.product.name,
            category: action.product.categories && action.product.categories.length > 0 ? action.product.categories[0].name : '',
            variant: action.variation.sku,
            price: action.variation.prices[0].price,
        }
    },
    'SET_CART_DATA': (action) => {
        return {
            type: 'setCartData',
            data: action.data,
        }
    },
    'ANALYTICS_INITIATE_CHECKOUT': (action) => {
        return {
            type: 'initiateCheckout', 
        }
    },
    'ANALYTICS_REMOVE_FROM_CART': (action) => {
        if(typeof action.product !== 'undefined' && action.product.product !== 'undefined') {
            return {
                type: 'removeFromCart',
                id: action.product.product.sku,
                name: action.product.product.name,
                category: action.product.product.categoryUri,
                variant: action.product.product.sku,
                price: action.product.product.price,
            }
        }
        if(typeof action.product[0] === 'undefined' && typeof action.product.sku !== 'undefined') {
            return {
                type: 'removeFromCart',
                id: action.product.sku,
                name: action.product.name,
                category: action.product.categoryUri,
                variant: action.product.sku,
                price: action.product.price,
            }
        }
        return {
            type: 'removeFromCart', 
            id: action.product[0].sku,
            name: action.product[0].name,
            category: action.product[0].categoryUri,
            variant: action.product[0].sku, 
            price: action.product[0].price,
        }
    },
    'ANALYTICS_COMPLETE_CHECKOUT': (action) => {
        const items = [];

        action.order.items.forEach(item => {
            items.push({
                id: item.sku,
                name: item.product.name,
                category: item.product.categoryName && item.product.categoryName.length > 0 ? item.product.categoryName[0] : '',
                variant: item.product.variantName,
                price: item.totalPriceAsNumber,
                priceWithoutTax: item.priceEachWithoutTaxAsNumber,
                discountWithoutTax: (item.priceEachReductionAsNumber - (item.priceEachReductionAsNumber * item.taxPercent / 100)).toFixed(2), 
                quantity: item.quantity
            })
        });

        return {
            type: 'completeCheckout',
            id: action.order.order,
            revenue: action.order.totals.grandTotalPriceAsNumber,
            tax: action.order.totals.grandTotalPriceTaxAsNumber,
            shipping: action.order.totals.shippingPriceAsNumber,
            coupon: action.order.discounts.vouchers.length > 0 ? action.order.discounts.vouchers[0].voucher : null,
            items: items,
            currency: action.order.currency, 
            email: action.order.address.email,
            isReturning: action.isReturning,
            orderRaw: action.order,
            billingDetails: action.billingDetails,
        }
    },
    'ANALYTICS_PAYMENT_FAILURE': (adyenMessage) => {
        return {
            type: 'paymentFailure',
            adyenMessage: adyenMessage
        }
    },
    'ANALYTICS_BACK_TO_STORE': () => {
        return {
            type: 'backToStore',
        }
    },
    'ANALYTICS_CUSTOM_EVENT': (action) => {
        return {
            type: 'customEvent',
            category: action.category,
            action: action.action,
            label: action.label,
            value: action.value,
        };
    },
    'ANALYTICS_IDENTIFY': (action) => {
        return {
            type: 'identify',
            email: action.email,
        }
    }
};

const ga = GoogleAnalytics();
const gaMiddleware = createMiddleware(eventsMap, ga);
export let googleAnalyticsMiddleware = gaMiddleware;

const fbp = FacebookPixel();
const fbpMiddleware = createMiddleware(eventsMap, fbp);
export let facebookPixelMiddleware = fbpMiddleware;

const fbca = FacebookConversionAPI()
const fbcaMiddleware = createMiddleware(eventsMap, fbca)
export let facebookConversionAPIMiddleware = fbcaMiddleware;

const ometria = Ometria();
const omeMiddleware = createMiddleware(eventsMap, ometria);
export let ometriaMiddleware = omeMiddleware;

const webgains = Webgains();
const webgMiddleware = createMiddleware(eventsMap, webgains);
export let webgainsMiddleware = webgMiddleware;