import React from 'react'
import styled from 'styled-components'
import Colors from '../assets/styles/Colors'

class ButtonNormalized extends React.Component {
  constructor (props) {
    super(props)

    this.buttonRef = React.createRef()
  }

  focus() {
    this.buttonRef.current.focus()
  }

  render() {
    const { children, className, ...rest } = this.props
    return <StyledButton className={ className } { ...rest } ref={ this.buttonRef }>{ children }</StyledButton>
  }
}

export default ButtonNormalized

const StyledButton = styled.button`
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  appearance: auto;
  background: none;
  border: none;
  text-align: left;
  padding: 0;
  font-weight: inherit;
  color: ${ Colors.black };
  cursor: pointer;

  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
`