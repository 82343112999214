import store from '../store'
import SessionActions from './session';

const defaultState = {
    address1: '',
    city: '',
    country: null,
    dial_code: '+47-no',
    email: '',
    email_confirm: '',
    firstName: '',
    lastName: '',
    newsletter: false,
    phoneNumber: '',
    password: '',
    new_password: '',
    state: '',
    zipCode: '',
};

const CLEAR_SEPARATE_BILLING_DETAILS = 'CLEAR_SEPARATE_BILLING_DETAILS';
const SET_ALL_SEPARATE_BILLING_INFO = 'SET_ALL_SEPARATE_BILLING_INFO';
const SET_DEFAULT_COUNTRY = 'SET_DEFAULT_COUNTRY';
const SET_SEPARATE_BILLING_INFO = 'SET_SEPARATE_BILLING_INFO';

export const SeparateBillingDetailsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_SEPARATE_BILLING_INFO:
            return {
                ...state,
                [action.key]: action.value
            }
        case SET_ALL_SEPARATE_BILLING_INFO:
            return action.separateBillingDetails
        case CLEAR_SEPARATE_BILLING_DETAILS:
            return defaultState
        case SET_DEFAULT_COUNTRY:
            return {
                ...state,
                country: action.country
            }
        default:
            return state;
    }
};

const SeparateBillingDetailsActions = {
    setSeparateBillingDetails: ({ key, value }) => {
        store.dispatch({
            type: SET_SEPARATE_BILLING_INFO,
            key,
            value
        })
    },
    setDefaultCountry: () => {
        store.dispatch({
            type: SET_DEFAULT_COUNTRY,
            country: SessionActions.getCurrentSession().country.toUpperCase(),
        })
    },
    clearSeparateBillingDetails: () => {
        store.dispatch({ type: CLEAR_SEPARATE_BILLING_DETAILS })
    },
    setAllSeparateBillingDetails: (separateBillingDetails) => {
        store.dispatch({ type: SET_ALL_SEPARATE_BILLING_INFO, separateBillingDetails })
    }
};

export default SeparateBillingDetailsActions;
