import React from 'react'
import styled from 'styled-components'
import Colors from '../assets/styles/Colors'

const StyledLabel = styled.span`
    background-color: #70716f;
    color: #fff;
    font-size: 9px;
    line-height: 15px;
    font-weight: 500;
    font-family: 'Circular Pro';
    padding: 1px 8px;

    ${p => p.type === 'sale' ? `
        background-color: red;
    ` : ''}
    
    
    ${p => p.type === 'limited' ? `
        background-color: #70716f;
        color: #ffbf00;
    ` : ''}

    ${p => p.type === 'pre-order' || p.type === 'preorder' ? `
        background-color: ${Colors.blue};
        color: ${Colors.white};
    ` : ''}
    
    ${p => p.center === true ? `
        text-align: center;
    ` : ''}
`

function Label({ className, children ,...rest }) {

    className = className + ' product-label'

    return <StyledLabel className={ className } { ...rest }>{ children }</StyledLabel>
}

export default Label;
