import React from 'react'
import BaseModule from 'modules/DBBaseModule';
import styled from 'styled-components';
import OptionsWrapper from 'components/OptionsWrapper';
import { InView } from 'react-intersection-observer';

class HTMLEmbed extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "HTML Embed" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        this.state = {
            inViewInitialized: false,
            fields: {
                ...{
                    html: 'Default'
                }, ...props.fields
            }
        }
        this.externalScriptContainerRef = React.createRef();
    }

    initializeEmbed() {
        if(process.env.RAZZLE_HTML_EMBED_ENABLED === 'false') return

        this.prepareData().then(state => {
            this.setState(state, () => {
                let range = document.createRange();
                range.setStart(this.externalScriptContainerRef.current, 0);

                this.externalScriptContainerRef.current.appendChild(
                  range.createContextualFragment(this.state.fields.html)
                );

                // Refresh widgets if yotpo snippets are pasted into embed
                setTimeout(function() {
                    window.yotpoLoader();
                }, 500);
            });
        });
    }

    /**
     * onChange event for textarea
     * @return {Void}
     */
    onChange = event => {
        this.setState({
            fields: { html: event.target.value } }
        )
    }

    onInView = (inView, entry) => {
        const { inViewInitialized } = this.state

        if(inView && !inViewInitialized) {
            this.setState({
                inViewInitialized: true,
            })
            this.initializeEmbed()
        }
    }

    /**
    * Renderer
    */
    render() {
        let { options, editMode } = this.props;
        let { html } = this.state.fields;

        if(process.env.RAZZLE_HTML_EMBED_ENABLED === 'false') return null

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                {editMode && (
                    <Wrapper>
                        <p>Paste your embed code in this box. This is at your own risk!</p>
                        <StyledTextArea value={html} onChange={this.onChange} ref={this.externalScriptContainerRef}></StyledTextArea>
                    </Wrapper>
                )}
                {editMode === false && (
                  <InView as="div" onChange={this.onInView}>
                      <div className="html-embed-container" style={{ width: '100%' }}  ref={this.externalScriptContainerRef} />
                  </InView>
                )}
            </OptionsWrapper>
        );
    }
}

export default HTMLEmbed;

const Wrapper = styled.div`
    padding: 30px;
    text-align: center;
`;

const StyledTextArea = styled.textarea`
    width: 50%;
    min-height: 200px;
`;
