import React from 'react'
import { Globals } from 'kbcms-react'
import styled, { css } from 'styled-components'
import Colors from '../../assets/styles/Colors'

class ProductStockNotifier extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  componentDidMount () {
    const self = this;

    this.timeout = setTimeout(() => {
      this.setState({ visible: true, }, () => {
        self.timeout = setTimeout(() => {
          self.setState({ visible: false, })
        }, 10000);
      });
    }, 2000);
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  render () {
    const { allReserved } = this.props;
    const { visible } = this.state;

    const color = !allReserved ? 'blue' : 'red';
    const text = !allReserved ? Globals.get('mod_pi_protected_info') : Globals.get('mod_pi_protected_all_reserved');

    return <Wrapper>
      <Notifier color={color} visible={visible}>
        <span>{text}</span>
      </Notifier>
    </Wrapper>
  }
}

const Notifier = styled.div`
  position: relative;
  top: -100px;
  width: 100%;
  text-align: center;
  background-color: ${Colors.red};
  color: #fff;
  font-size: 12px;
  font-family: 'Circular Pro', sans-serif;
  transition: top 1s ease;
  
  ${props => props.color === 'blue' ? ` background-color: ${Colors.blue}; `: ''}
  
  ${props => props.visible ? `top: 0;` : `top: -100px;`}
  
  span {
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 30;
`

export default ProductStockNotifier
