import React from 'react';
import store from '../store'

const defaultState = {
    loaded: false,
    markets: {},
};

const LOADED_MARKETS = 'LOADED_MARKETS';

export const MarketsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOADED_MARKETS:
            return {
                ...{},
                ...state,
                ...{
                    loaded: true,
                    markets: action.markets ? action.markets: state.markets,
                }
            };
        default:
            return state;
    }
};

const MarketsActions = {
    setMarkets: (markets) => {
        store.dispatch({
            type: LOADED_MARKETS,
            loaded: true,
            markets: markets,
        });
    },
    getMarkets: () => {
        let { markets } = store.getState();

        return markets;
    },
};

export default MarketsActions;