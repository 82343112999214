import React from 'react';
import loadable from '@loadable/component'
import styled, { css } from 'styled-components';
import BaseModule from 'modules/DBBaseModule';
import { Editor, Globals, NewMedia } from 'kbcms-react';
import produce from 'immer';

const LaunchTimer = loadable(() => import('kbcms-react'), {
    fallback: (() => <div/>)(),
});

class FullpageMedia extends BaseModule {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            fields: {
                background: null,
                mobileBackground: null,
                mutedBackground: false,
                forceNativeVideoAspectRatioBackground: false,
                mutedMobileBackground: false,
                forceNativeVideoAspectRatioMobileBackground: false,
                countDownDate: new Date(),
                ...props.fields
            }
        }

        this.reloadTimeout = null;
    }

    /**
     * Module display name
     */
    static getName() {
        return "Fullpage Media"
    }

    /**
     * It will be rendered, but not available for pickin'
     */
    static hidden() {
        return false
    }

    static options() {
        return super.options().concat([
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
            {
                name: 'launchTimer',
                label: 'Launch timer',
                type: 'switch'
            },
            {
                name: 'enableRefresh',
                label: 'Enable refresh interval',
                type: 'switch'
            },
            {
                name: 'enableRefreshText',
                label: 'Enable refresh interval description text',
                type: 'switch'
            }
        ]);
    }

    /**
     * If it should be deletable within the pagebuilder.
     */
    static deletable() {
        return true
    }

    componentDidMount() {
        let { editMode, options } = this.props;

        // Somewhat random to prevent every client from refreshing at the
        // exact same time after maintenance mode is enabled.
        if (!editMode && options.enableRefresh) {
            let randomTime = 60 + (Math.random() * 15)
            this.reloadTimeout = setTimeout(this.refresh, randomTime * 1000);
        }
        this.calculateIsMobile();
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    componentWillUnmount() {
        const { options } = this.props;
        if (options.enableRefresh) {
            clearTimeout(this.reloadTimeout);
        }
        window.removeEventListener('resize', this.resizeHandler);
    }

    resizeHandler() {
        this.calculateIsMobile();
    }

    calculateIsMobile() {
        this.setState({
            isMobile: window.innerWidth <= 992,
        });
    }

    refresh() {
        window.location.reload();
    }

    renderRefresh() {
        let refreshText = ``
        const globalText = Globals.get('mod_fullpage_media_refresh');

        if (globalText && globalText.length > 0) {
            refreshText = globalText;
        }

        return <StyledRefresh>{ refreshText }</StyledRefresh>
    }

    /*
     * Toggles mute on the video on an category-item
     */
    onToggleMute = (background) => {
        const nextState = produce(this.state, draft => {
            draft.fields[background] = !draft.fields[background];
        })

        this.setState(nextState)
    }

    /*
     * Toggles native Aspect-Ratio for video on a category-item
     */
    onToggleNativeAspectRatio = (background) => {
        const nextState = produce(this.state, draft => {
            draft.fields[background] = !draft.fields[background];
        })

        this.setState(nextState)
    }


    render() {
        let { isMobile } = this.state;
        let { editMode, options } = this.props;
        let { text, countDownDate } = this.state.fields;

        if (options.hideMobile && isMobile) {
           return null;
        }


        return (
            <StyledContainer>
                { isMobile ?
                    <StyledMobileMedia
                        fill
                        module={ this }
                        overlay={options.overlay}
                        field={ 'mobileBackground' }
                        videoOptions={{
                            onToggleMute: () => this.onToggleMute('mutedMobileBackground'),
                            onToggleNativeAspectRatio: () => this.onToggleNativeAspectRatio('forceNativeVideoAspectRatioMobileBackground'),
                            forceNativeVideoAspectRatio: this.state.fields.forceNativeVideoAspectRatioMobileBackground,
                            muted: this.state.fields.mutedMobileBackground,
                            autoplay: this.state.fields.mutedMobileBackground
                        }}
                        types={['images', 'videos']}
                        aspectRatio={ 9 / 16 }
                        editPosition={ 'nw' }
                        preload={true}
                    >
                        <Content>
                            <StyledEditor
                                field={ 'text' }
                                data={ text }
                                disabled={ !editMode }
                                disableLeftAlignButton
                                module={ this }
                                isMobile={ true }
                            />
                            {options.launchTimer && (
                                <LaunchTimer
                                    field={'countDownDate'}
                                    module={this}
                                    editMode={editMode}
                                    countDownDate={countDownDate}
                                    onDateChange={(newDate) => this.setState({
                                        ...this.state,
                                        fields: {
                                            ...this.state.fields,
                                            countDownDate: newDate
                                        }
                                    })}
                                />
                            )}
                        </Content>
                        {options.enableRefresh && options.enableRefreshText && (
                            <RefreshText><p>This page will automatically refresh every minute.</p></RefreshText>
                        )}
                        { options.enableRefresh && this.renderRefresh() }
                    </StyledMobileMedia> :
                    <StyledMedia
                        fill
                        module={ this }
                        types={['images', 'videos']}
                        field={ 'background' }
                        overlay={options.overlay}
                        videoOptions={{
                            onToggleMute: () => this.onToggleMute('mutedBackground'),
                            onToggleNativeAspectRatio: () => this.onToggleNativeAspectRatio('forceNativeVideoAspectRatioBackground'),
                            forceNativeVideoAspectRatio: this.state.fields.forceNativeVideoAspectRatioBackground,
                            muted: this.state.fields.mutedBackground,
                            autoplay: this.state.fields.mutedBackground
                        }}
                        aspectRatio={ 16 / 10 }
                        editPosition={ 'nw' }
                        preload={true}
                    >
                        <Content>
                            <StyledEditor
                                field={ 'text' }
                                data={ text }
                                disabled={ !editMode }
                                disableLeftAlignButton
                                module={ this }
                            />
                            {options.launchTimer && (
                                <StyledLaunchTimer
                                    field={'countDownDate'}
                                    module={this}
                                    editMode={editMode}
                                    countDownDate={countDownDate}
                                    onDateChange={(newDate) => this.setState({
                                        ...this.state,
                                        fields: {
                                            ...this.state.fields,
                                            countDownDate: newDate
                                        }
                                    })}
                                />
                            )}
                        </Content>
                        {options.enableRefresh && options.enableRefreshText && (
                            <RefreshText><p>This page will automatically refresh every minute.</p></RefreshText>
                        )}
                        { options.enableRefresh && this.renderRefresh() }
                        { editMode && <StyledMobileMediaEditor
                            fill
                            types={['images', 'videos']}
                            module={ this }
                            overlay={options.overlay}
                            videoOptions={{
                                onToggleMute: () => this.onToggleMute('mutedMobileBackground'),
                                onToggleNativeAspectRatio: () => this.onToggleNativeAspectRatio('forceNativeVideoAspectRatioMobileBackground'),
                                forceNativeVideoAspectRatio: this.state.fields.forceNativeVideoAspectRatioMobileBackground,
                                muted: this.state.fields.mutedMobileBackground,
                                autoplay: this.state.fields.mutedMobileBackground
                            }}
                            field={ 'mobileBackground' }
                            aspectRatio={ 9 / 16 }
                            editPosition={ 'nw' }/> }
                    </StyledMedia> }

            </StyledContainer>
        );
    }
}

export default FullpageMedia

const RefreshText = styled.div`
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    p {
       text-transform: uppercase;
       font-size: 16px;
       font-family: "Circular Pro";
       line-height: 20px;

       @media screen and (max-width: 992px) {
           font-size: 9px;
           line-height: 11px;
       }
    }

`


const StyledContainer = styled.div`
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;

    h1, h2, .h3-div, .h4-div, p, span[data-slate-content], div[data-key] {
        color: #fff;
        text-align: center;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    pointer-events: auto;
    width: 90%;

    ${props => props.isMobile === true && css`
        top: 40%;
    `}

    .countdown {
      color: #fff !important;
    }
`

const StyledLaunchTimer = styled(LaunchTimer)`
  color: #fff;
`

const StyledEditor = styled(Editor)`
    flex: 1;
    margin-bottom: 33px;
    max-width: 980px;
      display: flex;
      flex-direction: column;
      align-items: center;
    > div {
      text-align: center;
    }

`

const StyledRefresh = styled.div`
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
    text-align: center;
`

const StyledMedia = styled(NewMedia)`
    height: 100%;
    width: 100%;
`

const StyledMobileMedia = styled(NewMedia)`
    height: 100%;
    width: 100%;
`

const StyledMobileMediaEditor = styled(NewMedia)`
    z-index: 1;
    position: absolute;
    bottom: 25px;
    width: 150px;
    left: 25px;
`
