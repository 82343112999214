import React, { useEffect } from 'react'

class Escape extends React.Component {
  constructor (props) {
    super(props)
  }

  escapeListener = (e) => {
    const { onEscape } = this.props

    if (e.keyCode === 27 && typeof onEscape === 'function') {
      onEscape(e)
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.escapeListener)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.escapeListener)
  }

  render () {
    const { children } = this.props

    return (
      <React.Fragment>
        { children }
      </React.Fragment>
    )
  }
}

export default Escape
