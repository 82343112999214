import store from '../store'

const defaultState = {
};

const ANALYTICS_404 = 'ANALYTICS_404';
const ANALYTICS_PAGE_VIEW = 'ANALYTICS_PAGE_VIEW';
const ANALYTICS_PRODUCT_VIEW = 'ANALYTICS_PRODUCT_VIEW';
const ANALYTICS_ADD_TO_CART = 'ANALYTICS_ADD_TO_CART';
const ANALYTICS_REMOVE_FROM_CART = 'ANALYTICS_REMOVE_FROM_CART'; 
const ANALYTICS_INITIATE_CHECKOUT = 'ANALYTICS_INITIATE_CHECKOUT';
const ANALYTICS_COMPLETE_CHECKOUT = 'ANALYTICS_COMPLETE_CHECKOUT';
const ANALYTICS_PAYMENT_FAILURE = 'ANALYTICS_PAYMENT_FAILURE'; 
const ANALYTICS_BACK_TO_STORE = 'ANALYTICS_BACK_TO_STORE';
const ANALYTICS_CUSTOM_EVENT = 'ANALYTICS_CUSTOM_EVENT';
const ANALYTICS_IDENTIFY = 'ANALYTICS_IDENTIFY';


export const AnalyticsReducer = (state = defaultState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const AnalyticsActions = {
    pageView: (page) => {
        store.dispatch({
            type: ANALYTICS_PAGE_VIEW,
            page: page,
        });
    },
    addToCart: (product, variation, item) => {
        store.dispatch({
            type: ANALYTICS_ADD_TO_CART,
            product: product,
            variation: variation,
            item: item,
        });
    },
    removeFromCart: (lineId) => {
        store.dispatch({
            type: ANALYTICS_REMOVE_FROM_CART, 
            product: lineId,
        })
    },
    productView: (product, variation, item) => {
        store.dispatch({
            type: ANALYTICS_PRODUCT_VIEW,
            product: product,
            variation: variation,
            item: item,
        });
    },
    initiateCheckout: () => {
        store.dispatch({
            type: ANALYTICS_INITIATE_CHECKOUT,
        }); 
    },
    completeCheckout: (data) => {
        store.dispatch({
            type: ANALYTICS_COMPLETE_CHECKOUT,
            order: data.order,
            billingDetails: data.billingDetails,
            isReturning: data.isReturning,
        });
    },
    paymentFailure: adyenMessage => {
        store.dispatch({
            type: ANALYTICS_PAYMENT_FAILURE, 
            adyenMessage: adyenMessage
        });
    },
    backToStore: () => {
        store.dispatch({
            type: ANALYTICS_BACK_TO_STORE, 
        }); 
    },
    customEvent: (category, action, label, value) => {
        store.dispatch({
            type: ANALYTICS_CUSTOM_EVENT,
            category: typeof category !== 'undefined' ? category : null,
            action: typeof action !== 'undefined' ? action : null,
            label: typeof label !== 'undefined' ? label : null,
            value: typeof value !== 'undefined' ? value : null,
        });
    },
    identify: (email) => {
      store.dispatch({
          type: ANALYTICS_IDENTIFY,
          email: email,
      })
    },
    set404: () => {
        store.dispatch({
            type: ANALYTICS_404,
        });
    },
};

export default AnalyticsActions;