import React from 'react';
import styled, { css } from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';

/**
 * Seperator Plugin
 */
export default class H4 extends React.Component {

    /**
     * Component config
     * @return {Object}
     */
    static config() {
        return {
            // Button name. This we can change to Icon name or something.
            name: 'H4',

            // Is this component void? Void means no editable contents within.
            isVoid: false,

            // Should this plugin insert new block, or replace current.
            insert: false,
        }
    }


    /**
     * Serialize this class to HTML.
     * @return {String}
     */
    static serialize(data, children) {
        return <H4div className={"h4-div"}>{children}</H4div>
    }


    /**
     * Renderer. {this}
     * @return {JSX}
     */
    render() {
        return (
            <H4div {...this.props.attributes} className={"h4-div"}>{this.props.children}</H4div>
        )
    }
}

const H4div = styled.div`
    font-size: 22px!important;
    line-height: 35px!important;
    font-family: 'Lyon Display'!important;

    ${media.lessThan('medium')`
        font-size: 16px !important;
        line-height: 20px !important;
    `}
`

