import React from 'react';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import CloseIcon from 'images/db-close.svg';
import { EditorToHTML, Globals, ImageMedia } from 'kbcms-react';
import ButtonNormalized from '../ButtonNormalized'
import Escape from '../Escape'
import PopupModal from '../PopupModal'

export default class Modal extends PopupModal {
    constructor (props) {
        super(props)

        this.modal = React.createRef()
        this.closeButtonRef = React.createRef()
    }

    componentDidMount () {
        if(this.closeButtonRef && this.closeButtonRef.current) {
            this.closeButtonRef.current.focus()
        }
    }

    render() {
        const image = Globals.get('carbon_offset_modal_image_id')
        const content = Globals.get('carbon_offset_modal_content')

        if (content && image) {
            return (
                <ModalContainer onClick={ this.props.onCloseModal } ref={ this.modal }>
                    <div onClick={e => e.stopPropagation()} className="modal_content">
                        <div className="left">
                            {image && <StyledImageMedia file={ image.value }/>}
                        </div>
                        <div className="right">
                            <CloseButton aria-label="Close" onClick={ this.props.onCloseModal } icon={ this.props.icon ? this.props.icon : CloseIcon } ref={ this.closeButtonRef }/>
                            <ContentWrapper>
                                <EditorToHTML data={content} ></EditorToHTML>
                            </ContentWrapper>
                        </div>
                    </div>
                </ModalContainer>
            )
        }
        return null
    }
}


const ModalContainer = styled.div`
  background: rgba(0,0,0,0.4);
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal_content {
    display: flex;
    flex-direction: row;
    max-width: 95%;
    background-color: white;
    
    ${media.greaterThan('medium')`
          max-width: 900px;
          .left {
            min-height: 450px
          }
      `}

    ${media.lessThan('medium')`
          width: 85%;
      `}

    p {
      font-family: 'Circular Pro Book', sans-serif;
    }

    .left {
      position: relative;
      width: 50%;
      flex: 1;
      img {
        max-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
      ${media.lessThan('medium')`
            display: none;
            flex: 1.1;
        `}
    }

    .right {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .close {
      position: relative;
      background: blue;
      top: 10px;
      height: 25px;
      width: 25px;
      right: 10px;
    }
  }
`

const StyledImageMedia = styled(ImageMedia)`
  background-position: 50% 50%;
`

const ContentWrapper = styled.div`
  padding: 2.5em 1.5em;
`

const CloseButton = styled(ButtonNormalized)`
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-block;
    background: url(${p => p.icon ? p.icon : ''}) no-repeat center center;
    width: 25px;
    height: 25px;
    cursor: pointer;
`
