import loadable from '@loadable/component'
import React from 'react'
import isServer from '../isServer'

const Icon  = loadable(() => import('./FlagIcon/Client'), {
  fallback: (() => <div/>)(),
})

const FlagIcon = ({ code, ...rest }) => {
  if(isServer) return <div> </div>

  return <Icon code={code}/>
}

export default FlagIcon