import React from 'react';
import DouchebagTemplateStyles from 'components/DouchebagTemplateStyles';
import { BaseTemplate, ModuleZone } from 'kbcms-react'
import MenulessLayout from 'components/Layouts/MenulessLayout';
import styled from 'styled-components';
import MainTemplate from 'templates/MainTemplate';

class MenulessTemplate extends BaseTemplate {

    constructor(props) {
        super(props);
    }

    /**
     * Human readable name. Will be displayed in the CMS
     */
    static getName() { return "Menuless layout" }


    /**
     * Override the master layout with a custom layout.
     */
    static overrideMasterLayout() { return MenulessLayout }

    /**
     * Renderer
    */
    render() {
        const { entry, ssr, frontend, zones, editMode } = this.props

        return (
            <DouchebagTemplateStyles>
                <StyledWrapper>
                    <ModuleZone
                      entry={entry}
                      ssr={true}
                      frontend={ frontend }
                      zones={zones}
                      ref={this.registerZone}
                      editMode={editMode}
                    />
                </StyledWrapper>              
            </DouchebagTemplateStyles>
        )
    }
}

export default MenulessTemplate

const StyledWrapper = styled.section`
    background: #fff;
    position: relative;
`