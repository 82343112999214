import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EntrySelectorActions from 'cms/reducers/entry_selector';
import BaseModule from 'cms/components/BaseModule';

class SelectEntry extends React.Component {
    constructor(props) {
        super(props);
    }


    openEntrySelector() {
        let { module, field, multiple, callback } = this.props;
        let { entryType, filter, variations } = module.state.fields[field];

        let selectedEntries = [];
        let selectedVariations = [];

        // If the provided module already has filtered entries we want to display the EntrySelector with already selected entries
        if (typeof filter !== 'undefined' && filter !== null) {
            selectedEntries = filter;
            // If the provided module already has variations we want to display the EntrySelector with already selected variations
            if (typeof variations !== 'undefined' && variations !== null) {
                selectedVariations = variations;
            }
        }

        // Null out the values from a '*' selector, as that is supposed to fetch everything.
        if (selectedEntries.length === 1 && selectedEntries[0] === '*') {
            selectedEntries = [];
            selectedVariations = [];
        }

        // Prep callback and options for selector
        let modalCallback = (filter) => {


            // Set the state for this field...
            module.setState({
                fields: {
                    ...{}, ...module.state.fields, ...{
                        [field]: {
                            ...{}, ...module.state.fields[field], ...{
                                filter: filter,
                            }
                        }
                    }
                }
            }, () => {
                // After prepare data on new field data we need to refresh module state with the data.
                module.prepareData(true).then((result) => {

                    Object.keys(result.fields).map((key) => {
                        let field = result.fields[key];

                        if (field && field.entryType !== 'undefined') {
                            module.setState({
                                fields: {
                                    ...module.state.fields, ...{
                                        [key]: field,
                                    }
                                }
                            }, () => {
                                if (typeof callback === 'function') {
                                    callback()
                                }
                            });
                        }
                    });
                });
            });
        };

        // The options for the EntrySelector modal.
        let options = {
            entryType: entryType,
            multiple: typeof multiple !== 'undefined' ? multiple : false,
            selectedEntries: selectedEntries,
            selectedVariations: selectedVariations,
        };

        // Open selector
        EntrySelectorActions.openModal(modalCallback, options)
    }

    render() {
        let { children } = this.props;

        return (
            <StyledSelectEntry onClick={ () => this.openEntrySelector() }>
                { typeof children !== 'undefined' && children }
                { typeof children === 'undefined' && (
                    <span>Select Entry</span>
                ) }
            </StyledSelectEntry>
        )
    }
}

SelectEntry.propTypes = {
    module: PropTypes.instanceOf(BaseModule).isRequired,
    field: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    callback: PropTypes.func,
};

export default SelectEntry;

const StyledSelectEntry = styled.div`
  cursor: pointer;
`