import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BaseModule from 'cms/components/BaseModule';
import FileManagerActions from 'cms/reducers/files';

class SelectFiles extends React.Component {
    constructor(props) {
        super(props);
    }


    openFileManager() {
        let { module, field, multiple, fileTypes } = this.props;

        let selected = module.getField(field);

        if(selected === undefined) {
            selected = [];
        } else if(!Array.isArray(selected)) {
            selected = [selected];
        }

        let options = {
            multiple: multiple !== undefined ? multiple : false,
            fileTypes: fileTypes !== undefined ? fileTypes : [],
            selected: selected,
        };

        FileManagerActions.openModal(options, (files) => this.handleFiles(files));
    }

    handleFiles(files) {
        let { module, field } = this.props;

        module.setState({
            fields: {
                ...module.state.fields, ...{
                    [field]: files,
                }
            }
        });
    }

    render() {
        let { children } = this.props;

        return (
            <StyledSelectFiles onClick={ () => this.openFileManager() }>
                { typeof children !== 'undefined' && children }
                { typeof children === 'undefined' && (
                    <span>Select Files</span>
                ) }
            </StyledSelectFiles>
        )
    }
}

SelectFiles.propTypes = {
    module: PropTypes.instanceOf(BaseModule).isRequired,
    field: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    fileTypes: PropTypes.array,
};

export default SelectFiles;

const StyledSelectFiles = styled.div`
  cursor: pointer;
`