import React from 'react';
import { withRouter } from 'react-router-dom';
import entryToZones from '../helpers/entryToZones'

class FrontendPageRenderer extends React.Component {

    constructor(props) {
        super(props);

        this.zones = [];
        this.currentRouteId = props.route.id;

        this.loadedTemplates = null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.route.id !== this.props.route.id) {
            this._clearForNewRoute(this.props.route.id);
        }
    }

    _clearForNewRoute = routeId => {
        this.currentRouteId = routeId;
        this.zones = [];
    }

    loadTemplates() {
        let loadedTemplates = [];
        let req = require.context("../../templates/", false, /.*\.js$/);

        req.keys().forEach(function(key){
            let currentComp = req(key).default

            loadedTemplates[key.substring(2).split('.')[0]] = {
                name: currentComp.getName(),
                component: currentComp
            }
        });

        return loadedTemplates
    }

    /**
     * Registers a zone with the template loader
     */
    registerModuleZone = (zoneRef) => {
        let { entry } = this.props;

        if (this.props.route.id !== this.currentRouteId) {
            this._clearForNewRoute(this.props.route.id);
        }

        // fix for not registering a zone when exiting the component
        if (zoneRef == null) return;

        let content = entry.content.json;
        let selectedTemplate = entry.content.template;
        
        let id = parseInt(Object.keys(this.zones).length);

        let currentZone = {
            id: id,
            component: zoneRef,
            modules: {}
        }

        let modules = {};

        if (typeof content[selectedTemplate] !== 'undefined') {
            modules = content[selectedTemplate].zones[this.zones.length].modules;
        }

        currentZone.component.loadModulesFromSaveDataAndAttachDataForFrontEnd(modules);
        currentZone.component.setCurrentTemplate(selectedTemplate);

        this.zones.push(currentZone);
    }

    /**
     * Renderer
    */
    render() {
        const { ssr, entry } = this.props

        if(this.loadedTemplates === null) this.loadedTemplates = this.loadTemplates();

        const zones = entryToZones(entry)

        if (
          Object.keys(this.loadedTemplates).length > 0 &&
          typeof this.loadedTemplates[entry.content.template] !== 'undefined'
        ) {
            const Template = this.loadedTemplates[entry.content.template].component;

            return <Template
              entry={entry}
              ssr={ssr}
              frontend={true}
              zones={zones}
              onRegisterZone={this.registerModuleZone}
              editMode={false}
            />
        }

        return null
    }
}

export default withRouter(FrontendPageRenderer);
