import React from 'react';
import { NewMedia } from 'kbcms-react';
import BaseModule from 'modules/DBBaseModule';
import styled from 'styled-components';
import OptionsWrapper from 'components/OptionsWrapper';
import produce from 'immer';

class FullWidthImage extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Full Width Media" }

    /**
     * Constructor
     */
    constructor(props) {
        super(props)

        this.state = {
            fields: {
                image: {},
                muted: false,
                forceNativeVideoAspectRatio: false,
                ...props.fields
            }
        }
    }

    static options() {
        return super.options().concat([
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    /*
     * Toggles mute on the video on an category-item
     */
    onToggleMute = () => {
        const nextState = produce(this.state, draft => {
            draft.fields.muted = !draft.fields.muted;
        })

        this.setState(nextState)
    }

    /*
     * Toggles native Aspect-Ratio for video on a category-item
     */
    onToggleNativeAspectRatio = () => {
        const nextState = produce(this.state, draft => {
            draft.fields.forceNativeVideoAspectRatio = !draft.fields.forceNativeVideoAspectRatio;
        })

        this.setState(nextState)
    }

    /**
     * Renderer
     */
    render() {
        let { options, editMode } = this.props;
        const { fields } = this.state;
        const { forceNativeVideoAspectRatio, muted } = fields;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper>
                    <StyledMedia
                        editMode={editMode}
                        module={this}
                        overlay={options.overlay}
                        editPosition={'w'}
                        field={'image'}
                        videoOptions={{
                            onToggleMute: () => this.onToggleMute(),
                            onToggleNativeAspectRatio: () => this.onToggleNativeAspectRatio(),
                            forceNativeVideoAspectRatio,
                            muted,
                            autoplay: muted
                        }}
                        types={['images', 'videos']}
                        aspectRatio={16/9}
                        fill
                    />
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default FullWidthImage

const Wrapper = styled.section`
    position: relative;
    margin: 0 auto;
`

const StyledMedia = styled(NewMedia)`
  width: 100%;
`
