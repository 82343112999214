export default {
    black: '#000000',
    grey: '#767676',
    lightGrey: '#BCBCBC',
    dividers: '#E5E5E5',
    greyFills: '#F8F8F8',
    white: '#FFFFFF', 
    green: '#0059af', 
    yellow: '#F0DC2A', 
    red: '#EA475B', 
}
