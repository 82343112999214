import store from '../store'

const defaultState = {
    open: false,
    modal: null,

    confirmationModal: false,
    confirmationModalText: null,
    confirmationModalCallback: null,

    dateSelectorModal: false,
    dateSelectorModalDate: new Date(),
};

const OPEN_MODAL = 'OPEN_MODAL';
const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';
const OPEN_DATESELECTOR_MODAL = 'OPEN_DATESELECTOR_MODAL';
const CLOSE_DATESELECTOR_MODAL = 'CLOSE_DATESELECTOR_MODAL';
const CONFIRM_DATESELECTOR_MODAL = 'CONFIRM_DATESELECTOR_MODAL';


export const ModalReducer = (state = defaultState, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...{},
                ...state
            };
        case OPEN_CONFIRMATION_MODAL:
            return {
                ...{}, ...state,
                ...{
                    confirmationModal: true,
                    confirmationModalText: action.text,
                    confirmationModalCallback: action.callback,
                }
            };
        case OPEN_DATESELECTOR_MODAL:
            return {
                ...{}, ...state,
                ...{
                    dateSelectorModal: true,
                }
            };
        case CONFIRM_DATESELECTOR_MODAL:
            return {
                ...state,
                dateSelectorModalDate: action.date,
                dateSelectorModal: false,
            }
        case CLOSE_CONFIRMATION_MODAL:
            return {
                ...{}, ...state,
                ...{
                    confirmationModal: false,
                }
            };
        case CLOSE_DATESELECTOR_MODAL:
            return {
                ...{}, ...state,
                ...{
                    dateSelectorModal: false,
                }
            };
        default:
            return state;
    }
};

export default {
    openModal: (open, modal) => {
        store.dispatch({
            type: OPEN_MODAL,
            open: open,
            modal: modal,
        });
    },
    openConfirmationModal: (text, callback) => {
        store.dispatch({
            type: OPEN_CONFIRMATION_MODAL,
            text: text,
            callback: callback
        });
    },
    openDateSelectorModal: (text, callback) => {
        store.dispatch({
            type: OPEN_DATESELECTOR_MODAL,
            text: text,
            callback: callback
        });
    },
    cancelDateSelectorModal: () => {
        store.dispatch({
            type: CLOSE_DATESELECTOR_MODAL,
        });
    },
    confirmDateSelectorModal: (date) => {
        store.dispatch({
            type: CONFIRM_DATESELECTOR_MODAL,
            date
        })
    },
    cancelConfirmationModal: () => {
        store.dispatch({
            type: CLOSE_CONFIRMATION_MODAL,
        });
    },
    confirmConfirmationModal: () => {
        let state = store.getState();

        if(typeof state.modal.confirmationModalCallback === 'function') {
            state.modal.confirmationModalCallback();
        }

        store.dispatch({
            type: CLOSE_CONFIRMATION_MODAL,
        });
    }
}
