import React from 'react';
import DouchebagTemplateStyles from 'components/DouchebagTemplateStyles';
import { BaseTemplate, ModuleZone } from 'kbcms-react';

class ProductTemplate extends BaseTemplate {

    /**
     * Human readable name. Will be displayed in the CMS
     */
    static getName() { return "Single product template" }


    /**
     * Renderer
    */
    render() {
      const { entry, ssr, frontend, zones, editMode } = this.props

        return (
            <DouchebagTemplateStyles>
                <ModuleZone
                    ref={this.registerZone}
                    entry={entry}
                    ssr={ssr}
                    frontend={ frontend }
                    zones={zones}
                    prependModules={['ProductInfo']}
                    editMode={editMode}
                />
            </DouchebagTemplateStyles>
        )
    }
}

export default ProductTemplate