import React from 'react';
import { SelectEntry, Media } from 'kbcms-react';
import BaseModule from 'modules/DBBaseModule';
import ContentEditable from 'react-contenteditable';
import styled, { css } from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';
import Colors from 'assets/styles/Colors';
import HighlightedProductBox from './HighLightedProductBox';
import OptionsWrapper from 'components/OptionsWrapper';

class HighlightedProduct extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return 'Highlighted Product'}

    /**
    * Constructor
    */
    constructor(props) {
        super(props)
        this.onChangeField = this.onChangeField.bind(this)
        this.state = {
            fields: {
                ...{
                    product: {
                        entryType: 'products',
                        filter: [],
                        data: []
                    },
                    image: {},
                    caption: 'Add a caption',
                    flipped: false,
                }, ...props.fields
            }
        }
    }

    componentDidMount() {
        this.prepareData().then(state => {
            this.setState(state);
        });
    }


    static options() {
        return super.options().concat([
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    /**
    * When content editable field is changed
    * @return {Void}
    */
    onChangeField = (name, value) => {
        this.setState({ fields: Object.assign(this.state.fields, { [name]: value }) })
    }

    /**
    * Set position for Product box
    * @return {Void}
    */
    toggleFlip = () => {
        this.setState({ fields: {...this.state.fields, flipped: !this.state.fields.flipped } })
    }

    /**
    * Renderer
    */
    render() {
        let { options, editMode } = this.props;
        let { fields } = this.state;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper>
                    { editMode && (
                    <Flipper onClick={this.toggleFlip}>Flip direction</Flipper>)}
                    <StyledMedia
                        module={this}
                        field={'image'}
                        types={['images']}
                        overlay={options.overlay}
                        aspectRatio={3/1}
                        fill
                        flipped={fields.flipped}>

                        {editMode && (
                            <SelectEntry module={this} field={'product'}>
                                <SelectProductsButton>Select Product</SelectProductsButton>
                            </SelectEntry>
                        )}

                        <ProductBox>
                            {fields.product.data.length > 0 && (
                                <HighlightedProductBox product={fields.product.data[0]}/>
                            )}
                        </ProductBox>

                        <StyledContentEditable
                            name="text"
                            disabled={!editMode}
                            html={fields.caption}
                            onChange={e => this.setField('caption', e.target.value)}
                        />
                    </StyledMedia>
                    <MobileProductBox>
                        {fields.product.data.length > 0 && (
                            <HighlightedProductBox product={fields.product.data[0]}/>
                        )}
                    </MobileProductBox>
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default HighlightedProduct

const SelectProductsButton = styled.div`
    display: none;
    position: absolute;
    bottom: 42px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 6px;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
`

const Flipper = styled.div`
    display: none;
    position: absolute;
    z-index: 10;
    background: white;
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 2px;
    padding: .5em;
    top: 42px;
    left: 50%;
    transform: translate(-50%);
`

const ProductBox = styled.div`
    position: relative;
    width: 230px;
    min-height: 292px;
    margin: 0 5%;
    z-index: 1;
    background: #fff;
    border: 1px solid ${Colors.black};
`

const MobileProductBox = styled(ProductBox)`
`

const Wrapper = styled.section`

    ${MobileProductBox} {
        display: none;
        margin: 0;
    }

    &:hover {
        ${Flipper} {
            display: block;
        }
    }

    ${media.greaterThan('huge')`
        margin: 0 auto;
        max-width: 1920px;
    `}

    ${media.lessThan('small')`
        ${MobileProductBox} {
            display: block;
            width: 100%;
            border: none;
        }
    `}
`

const StyledMedia = styled(Media)`
    display: flex;
    align-items: center;
    min-height: 292px;

    ${props => props.flipped && css`
        justify-content: flex-end;
    `}

    &:hover {
        ${SelectProductsButton} {
            display: inline-block;
        }
    }

    ${media.lessThan('small')`
        ${ProductBox} {
            display: none;
        }
    `}
`

const StyledContentEditable = styled(ContentEditable)`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 22px;
    min-width: 200px;
    display: inline-block;
    font-size: 11px;
    line-height: 15px;
    font-weight: normal;
    font-family: 'Circular Pro Book';
    outline: 0;
    color: #5b5b5b;
    transform: rotate(-90deg) translateX(105%);
    transform-origin: bottom right;
`
