import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import logger from 'redux-logger';
import { facebookPixelMiddleware, googleAnalyticsMiddleware, ometriaMiddleware, facebookConversionAPIMiddleware, webgainsMiddleware } from 'analytics/middleware';
import rootReducer from 'reducers'
import isServer from '../isServer'

const persistConfig = {
    key: 'root-1c5cd731',
    blacklist: [
        // Blacklisting to force redux to get filled per session.
        'router',
        'cropper',
        'entry_selector',
        'link_selector',
        'file_manager',
        'modal',
        'settings',
        'globals',
        'markets',
    ],
    storage,
};

const isChrome = isServer ? false : /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const persistedReducer = persistReducer(persistConfig, rootReducer);

let middlewares = [
    googleAnalyticsMiddleware,
    facebookPixelMiddleware,
    ometriaMiddleware,
    facebookConversionAPIMiddleware,
    webgainsMiddleware,
];

let store;

if (process.env.NODE_ENV !== 'production' && isChrome && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
        persistedReducer,
        composeEnhancers(
            applyMiddleware(...middlewares),
        )
    );
} else {
    // Use redux logger for other browsers without the redux devtools-extention
    if (process.env.NODE_ENV !== 'production' && !isServer) {
        middlewares.push(logger);
    }
    store = createStore(
        persistedReducer,
        applyMiddleware(...middlewares),
    );
}

export default store;

export let persistor = persistStore(store);
