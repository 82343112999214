import { combineReducers } from 'redux';

import { AuthReducer } from 'reducers/auth';
import { FileManagerReducer } from 'reducers/files';
import { SessionReducer } from 'reducers/session';
import { CropperReducer } from 'reducers/cropper';
import { CartReducer } from 'reducers/cart';
import { LinkSelectorReducer } from 'reducers/link_selector';
import { EntrySelectorReducer } from 'reducers/entry_selector';
import { VariationSelectorReducer } from 'reducers/variation_selector';
import { RouterReducer } from 'reducers/router';
import { ModalReducer } from 'reducers/modal';
import { SettingsReducer } from 'reducers/settings';
import { GlobalsReducer } from 'reducers/globals';
import { MarketsReducer } from 'reducers/markets';
import { CountriesReducer } from 'reducers/countries';
import { PricelistReducer } from 'reducers/price_lists';
import { BillingDetailsReducer } from 'reducers/billing_details';
import { SeparateBillingDetailsReducer } from 'reducers/separate_billing_details';
import { SystemReducer } from 'reducers/system';
import { LanguagesReducer } from "reducers/languages";
import { ReservationReducer } from 'reducers/reservation';
import { PlantProductsReducer } from 'reducers/plant_products';
import { CarbonOffsetProductsReducer } from 'reducers/carbon_offset_products';
import { PhoneCaseReducer } from './phone_cases';

export default combineReducers({
    // Frontend
    cart: CartReducer,
    session: SessionReducer,
    router: RouterReducer,
    settings: SettingsReducer,
    markets: MarketsReducer,
    countries: CountriesReducer,
    billingDetails: BillingDetailsReducer,
    separateBillingDetails: SeparateBillingDetailsReducer,
    globals: GlobalsReducer,
    languages: LanguagesReducer,
    pricelists: PricelistReducer,
    reservation: ReservationReducer,
    plantProducts: PlantProductsReducer,
    carbonOffsetProducts: CarbonOffsetProductsReducer,
    phoneCases: PhoneCaseReducer,

    // System
    auth: AuthReducer,
    file_manager: FileManagerReducer,
    cropper: CropperReducer,
    link_selector: LinkSelectorReducer,
    entry_selector: EntrySelectorReducer,
    variation_selector: VariationSelectorReducer,
    modal: ModalReducer,
    system: SystemReducer
});
