import React from "react";

import styled, { css } from 'styled-components';
import Colors from 'assets/Colors'; 

class Switch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.inputRef = React.createRef();
    }

    toggleInput() {
        this.inputRef.current.click();
    }

    render() {
        let { name, label, value, onChange, warning } = this.props;
        let { inputRef } = this;

        if (value === 1) value = true;
        if (value === '1') value = true;
        if (value === 0) value = false;
        if (value === null) value = false;
        if (value === '') value = false;

        if (label === undefined) return (
          <SwitchWrapper value={ value } onClick={ () => this.toggleInput() } warning={ warning }>
            <StyledInput ref={ inputRef } type={ 'checkbox' } name={ name } value={ value } checked={ value } onChange={ onChange }/>
        </SwitchWrapper>
        )

        return (
            <SwitchContainer value={ value }>
                <SwitchWrapper value={ value } onClick={ () => this.toggleInput() } warning={ warning }>
                    <StyledInput ref={ inputRef } type={ 'checkbox' } name={ name } value={ value } checked={ value } onChange={ onChange }/>
                    <StyledLabel value={ value } htmlFor={ name }>{ label }</StyledLabel>
                </SwitchWrapper>
            </SwitchContainer>
        )
    }
}

export default Switch;

const SwitchContainer = styled.div`
  min-width: 180px;
  width: 100%; 
  height: 44px;
  border: 1px solid ${Colors.dividers};
  &:hover{
    border: 1px solid ${Colors.lightGrey};
  }

  display: flex; 
  align-items: center; 
  margin-bottom: 20px; 

  div{
    margin-left: 15px; 
  }
`

const SwitchWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  margin: 1em 0;
  backface-visibility: hidden;
  height: 24px;
  width: 100%;
  
  &:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    border-radius: 23px;
    background-color: ${Colors.lightGrey};
    width: 44px;
    height: 100%;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    ${ props => props.value === true && css`
      background-color: ${Colors.black};
      ${ props => props.warning && css`
        background-color: #E10050;
      ` }
    ` }
  }
  
  &:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 50%;
    left: 12px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: #fff;
    width: 20px;
    height: 20px;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    
    ${ props => props.value === true && css`
      background-color: ${Colors.white};
      left: 32px;
      ${ props => props.warning && css`
        background-color: #E10050;
      ` }
    ` }
  }
`

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  margin: 0;
  opacity: 0;
`

const StyledLabel = styled.label`
  cursor: pointer;
  color: ${Colors.lightGrey};
  position: absolute;
  top: 50%;
  left: calc(56px);
  transform: translateY(-50%);
  width: calc(100% - 56px);

  ${p => p.value && css`
    color: ${Colors.black};
  `}
`