import React from 'react';
import { Media, Linker } from 'kbcms-react';
import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import Colors from '../../assets/styles/Colors';
import BaseModule from 'modules/DBBaseModule';
import OptionsWrapper from 'components/OptionsWrapper';
import { default as media } from 'assets/styles/Breakpoints';

class TwoStories extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Two Images" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props)

        this.onChangeField = this.onChangeField.bind(this)

        this.state = {
            fields: {
                ...{}, ...{
                    image1: null,
                    image2: null,
                    preText1: 'Product name',
                    text1: '— Credentials',
                    preText2: 'Product name',
                    text2: '— Credentials',
                    imageLink1: null,
                    imageLink2: null,
                },
                ...props.fields,
            }
        }
    }

    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    /**
    * When content editable field is changed
    * @return {Void}
    */
    onChangeField = (name, value) => {
        this.setState({ fields: Object.assign(this.state.fields, { [name]: value }) })
    }

    /**
    * Renderer
    */
    render() {
        const { options, editMode } = this.props;
        const { fields } = this.state;
        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper>
                    <Slot>
                        <StyledLinker module={this} field={'imageLink1'}>
                            <Media
                                module={this}
                                overlay={options.overlay}
                                fill
                                aspectRatio={9/10}
                                field={'image1'}/>
                        </StyledLinker>
                        <StyledContentArea>
                            <Caption
                                name="preText1"
                                tagName="p"
                                disabled={!editMode}
                                html={fields.preText1}
                                onChange={(event) => this.onChangeField(event.nativeEvent.target.getAttribute('name'), event.target.value)}
                            />
                            <Caption
                                name="text1"
                                tagName="p"
                                disabled={!editMode}
                                html={fields.text1}
                                onChange={(event) => this.onChangeField(event.nativeEvent.target.getAttribute('name'), event.target.value)}
                            />
                        </StyledContentArea>
                    </Slot>

                    <Slot>
                        <StyledLinker module={this} field={'imageLink2'}>
                            <Media
                                module={this}
                                fill
                                overlay={options.overlay}
                                aspectRatio={9/10}
                                field={'image2'} />
                        </StyledLinker>
                        <StyledContentArea>
                            <Caption
                                name="preText2"
                                tagName="p"
                                disabled={!editMode}
                                html={fields.preText2}
                                onChange={(event) => this.onChangeField(event.nativeEvent.target.getAttribute('name'), event.target.value)}
                            />
                            <Caption
                                name="text2"
                                tagName="p"
                                disabled={!editMode}
                                html={fields.text2}
                                onChange={(event) => this.onChangeField(event.nativeEvent.target.getAttribute('name'), event.target.value)}
                            />
                        </StyledContentArea>
                    </Slot>
                </Wrapper>
            </OptionsWrapper>
        );
    }
}

export default TwoStories

const Wrapper = styled.section`
    display: flex;

    ${media.lessThan('mobile')`
        flex-direction: column;
    `}

`

const Slot = styled.div`
    width: 50%;

    :last-child {
        text-align: right;
    }

    ${media.lessThan('mobile')`
        width: calc(100% - 30px);
        margin: 0 auto;

        :last-child {
            text-align: left;
        }
    `}
`

const StyledLinker = styled(Linker)`
    width: 100%;
`

const StyledContentArea = styled.div`
    p {
        font-family: Circular Pro;
        font-size: 11px;
        display: inline;
        margin: 0 0 0 8px;
        color: ${Colors.blue};
    }
    p + p {
        color: ${Colors.darkGray};
        margin: 0 8px 0 4px;
    }
`

const Caption = styled(ContentEditable)`
    outline: none;

    &:focus {
        outline: 1px dashed rgba(0, 89, 175, 0.4);
    }
`
