import React, { Component } from 'react'
import { connect } from 'react-redux';

/**
 * HoC that passes the countries data as a prop.
 * @param {*} PassedComponent
 */
const withCarbonOffsetProducts = PassedComponent => {
  const Wrapper = props => {
    return <PassedComponent carbonOffsetProducts={ props.carbonOffsetProducts } {...props} />
  }

  return connect(mapStateToProps)(Wrapper);
}

const mapStateToProps = (state, ownProps) => {
  return {
    carbonOffsetProducts: state.carbonOffsetProducts.products,
    ...ownProps
  };
};

export default withCarbonOffsetProducts;