import React from 'react';
import styled from 'styled-components';
import { Cart, SessionActions, Reservation } from 'kbcms-react';
import Dropdown from 'components/Dropdown';
import FlagIcon from 'components/FlagIcon';
import isServer from '../isServer'

class CountryDropdown extends React.Component {

    /**
     * State
     */
    state = {
        selectedCountry: SessionActions.getCurrentSession().country,
        countries: []
    }

    /**
     * Component did mount
     * @return {Void}
     */
    componentDidMount() {
        Cart.getCountries().then(result => {
            this.setState({
                countries: result.countries,
            });
        });
    }

    /**
     * On select country
     * @param {String} countryCode
     * @return {Void}
     */
    onSelectCountry = async countryCode => {
        if(Reservation.getReservations().length !== 0) await Reservation.clearReservations(false);

        this.setState({ selectedCountry: countryCode }, () => {
            SessionActions.setCountry(countryCode);
            Cart.setCountry(countryCode).then(result => {
                setTimeout(() => {
                    window.location.reload();
                }, 100)
            });
        });
    }


    /**
     * Returns items for dropdown
     * @return {Array}
     */
    getItems = () => {
        const items = this.state.countries.map(country => {
            return {
                key: country.country,
                display: (
                    <CountryItem>
                        <Flag code={country.country.toLowerCase()} />
                        <Name>{country.name + ', '}</Name>
                        <Currency>{country.currency}</Currency>
                    </CountryItem>
                )
            }
        });

        return items;
    }

    /**
     * Renderer
     * @return {JSX}
     */
    render() {
        let { countries, selectedCountry } = this.state;

        if (countries.length <= 0) return <div />

        return (
            <Dropdown
                onSelect={this.onSelectCountry}
                selectedItem={selectedCountry.toUpperCase()}
                items={this.getItems()}
            />
        )
    }

}

export default CountryDropdown;

const Flag = isServer ? null : styled(FlagIcon)`
    width: 25px;
    min-width: 25px;
    margin-right: 5px;
`

const Name = styled.div`
    position: relative;
    hyphens: auto;
`
const Currency = styled.div`
    min-width: 40px;
    margin-left: 5px;
    position: relative;
    color: #8889;
    display: inline-flex;
    flex-direction: row;
`

const CountryItem = styled.div`
    display: flex;
    padding: 4px 6px;
    align-items: center;
    font-family: 'Circular Pro Book';
`
