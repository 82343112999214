import QueryString from 'query-string'
import isServer from '../../isServer'
import AnalyticsActions from '../reducers/analytics'
import SettingsActions from '../reducers/settings';

const Analytics = ({
    debug: process.env.NODE_ENV === 'development',
    options: {},

    init: (options) => {
        if(isServer) return

        options = {
            ...{
                gaEnabled: SettingsActions.get('analytics_ga_enabled') === '1',
                gaTrackingCode: SettingsActions.get('analytics_ga_code'),
                fbpEnabled: SettingsActions.get('analytics_fbp_enabled') === '1',
                fbpCode: SettingsActions.get('analytics_fbp_code'),
                ometriaEnabled: SettingsActions.get('analytics_ometria_enabled') === '1',
                ometriaCode: SettingsActions.get('analytics_ometria_code'),
            },
            ...options,
        };

        Analytics.options = options;

        if(options.gaEnabled) {
            Analytics.loadGoogleAnalytics(options);
        }

        if(options.fbpEnabled) {
            Analytics.loadFacebookPixel(options);
        }

        if(options.ometriaEnabled) {
            Analytics.loadOmetria(options);
        }

        Analytics.loadWebgains()

        Analytics.checkOmetriaSignup()

        return this;
    },

    checkOmetriaSignup: () => {
        const queryString = QueryString.parse(window.location.search)

        if(queryString.ometria_form_ok && queryString.ometria_form_ok == 'Y' && queryString.ometria_form) {
            if(queryString.ometria_form === 'footer') {
                AnalyticsActions.customEvent('DbBlack', 'Signup', 'Footer')
            } else if(queryString.ometria_form === 'signup-module' && (queryString.listId && queryString.listId == '1668')) {
                AnalyticsActions.customEvent('DbBlack', 'Signup', 'Signup-page')
            }
        }
    },

    getOptions: () => {
        return Analytics.options;
    },

    loadGoogleAnalytics: (options) => {
        let gaAddress = 'https://www.google-analytics.com/analytics.js';

        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(window, document, 'script', gaAddress, 'ga');

        ga('create', options.gaTrackingCode, 'auto');
        ga('require', 'ec');
    },

    loadFacebookPixel: async(options) => {
        let fbAddress = 'https://connect.facebook.net/en_US/fbevents.js';

        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        }(window, document, 'script', fbAddress))

        fbq('init', options.fbpCode);
    },

    loadWebgains: async() => {
        (function (w, e, b, g, a, i, n, s) {
            w['ITCLKOBJ'] = a
            w[a] = w[a] || function () {(w[a].q = w[a].q || []).push(arguments)}, w[a].l = 1 * new Date()
            i = e.createElement(b), n = e.getElementsByTagName(b)[0]
            i.async = 1
            i.src = g
            n.parentNode.insertBefore(i, n)
        })(window, document, 'script', 'https://analytics.webgains.io/clk.min.js', 'ITCLKQ')
    },

    loadOmetria: async(options) => {
        const script = document.createElement("script")

        script.src = `https://cdn.ometria.com/tags/${ options.ometriaCode }.js`

        document.body.appendChild(script)
    },


});

export default Analytics;