import React from 'react';
import ApiManager from '../ApiManager'
import store from '../store'

const defaultState = {
  loaded: false,
  loadedDate: Date.now(),
  products: [],
};

const Api = new ApiManager();

const START_LOADING_PHONE_CASES = 'START_LOADING_PHONE_CASES';
const LOADED_PHONE_CASES = 'LOADED_PHONE_CASES';

export const PhoneCaseReducer = (state = defaultState, action) => {
  switch (action.type) {
    case START_LOADING_PHONE_CASES:
      return {
        ...state,
        loaded: false,
      }
    case LOADED_PHONE_CASES:
      return {
        loaded: true,
        loadedDate: Date.now(),
        products: action.products ? action.products : state.products
      };
    default:
      return state;
  }
};

const PhoneCasesActions = {
  loadPhoneCases: () => {
    store.dispatch({
      type: START_LOADING_PHONE_CASES,
    })
    Api.getIphoneCases().then(r => {
      store.dispatch({
        type: LOADED_PHONE_CASES,
        products: r.data,
      })
    })
  },
  all: () => {
    return store.getState().phoneCases.products;
  },
};

export default PhoneCasesActions;