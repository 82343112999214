import isServer from '../isServer'

const loadFlowbox = () => {
  (function(d, id) {
    if (d.getElementById(id)) {return;}
    var s = d.createElement('script'), fjs = d.scripts[d.scripts.length - 1]; s.id = id; s.defer = true;
    s.src = ' https://connect.getflowbox.com/flowbox.js';
    fjs.parentNode.insertBefore(s, fjs);
  })(document, 'flowbox-js-embed');
}

const flowboxLoader = () => {
  if(isServer) return

  window.flowbox = (event, data) => {
    let f = function () {
      f.q.push(arguments);
    }
    f.q = [[event, data ]];
    window.flowbox = f;
    loadFlowbox(event, data)
  }
}

export default flowboxLoader