import React from "react";

import styled, { css } from 'styled-components';

class IconButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, className, warning, invertWarning, size } = this.props;

        return (
            <StyledIconButton
                size={ size }
                onClick={ this.props.onClick }
                className={ className }
                warning={ warning }
                invertWarning={invertWarning}>
                { children }
            </StyledIconButton>
        )
    }
}

export default IconButton;


const StyledIconButton = styled.div`
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;

  ${ props => typeof props.size !== 'undefined' && css`
    width: ${ props.size };
    height: ${ props.size };
  ` }

  display: flex;
  justify-content: center;
  align-items: center;
  /*border-radius: 50%;
  padding: 0;
  background-color: #2699FB;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -2px rgba(0, 0, 0, 0.12);

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: #2690F1;
  }

  ${ props => props.invertWarning === true && css`
    box-shadow: none;
    background-color: transparent;
    svg {
      fill: #878a8c !important;
    }
    &:hover {
      background-color: transparent;
      svg{
        fill: #333 !important;
      }
    }
  `}

  ${ props => props.warning === true && css`
    background-color: #E10050;
    &:hover {
      background-color: #9D0038;
    }
  ` }*/

  svg {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    fill: #fff;
  }
`
