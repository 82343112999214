import React, { Component } from 'react'
import { connect } from 'react-redux';

/**
 * HoC that passes the countries data as a prop.
 * @param {*} PassedComponent
 */
const withCountries = PassedComponent => {
    const Wrapper = props => {
        return <PassedComponent countries={props.countries} session={props.session} {...props} />
    }

    return connect(mapStateToProps)(Wrapper);
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...{
            countries: state.countries,
            session: state.session,
        },
        ...ownProps
    };
};

export default withCountries;
