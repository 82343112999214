import React from 'react'
import { Editor, Globals } from 'kbcms-react';
import BaseModule from 'modules/DBBaseModule';
import styled from 'styled-components';
import RangeSlider from 'react-rangeslider';
import OptionsWrapper from 'components/OptionsWrapper';
import { default as media } from 'assets/styles/Breakpoints';

import fontOptions from 'assets/styles/FontOptions'; 

const images = [
    '/imgs/slimjim/capture0015-7-redigera.png',
    '/imgs/slimjim/capture0016-7-redigera.png',
    '/imgs/slimjim/capture0018-7-redigera.png',
    '/imgs/slimjim/capture0019-7-redigera.png',
    '/imgs/slimjim/capture0020-7-redigera.png',
    '/imgs/slimjim/capture0021-7-redigera.png',
    '/imgs/slimjim/capture0022-7-redigera.png',
    '/imgs/slimjim/capture0024-7-redigera.png',
    '/imgs/slimjim/capture0026-6-redigera.png',
    '/imgs/slimjim/capture0030-5-redigera.png',
];



class SlimJimModule extends BaseModule {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                ...{
                    text: '',
                }, ...props.fields
            },
            step: 0
        }
    }


    
    componentDidMount() {
        this.prepareData().then(state => {
            this.setState(state);
        });
    }


    /**
     * Module display name
     */
    static getName() { return "Slim Jim Length Adjustment" }


    /**
     * It will be rendered, but not available for pickin'
     */
    static hidden() { return false }

    onSliderChange = value => {
        this.setState({ step: value });
    }


    render() {
        const { options, editMode } = this.props;
        const { text } = this.state.fields;
        const { step } = this.state;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <ModuleWrapper>
                    <InnerWrapper>
                        <Editor 
                            field={'text'} 
                            data={text} 
                            disabled={!editMode} 
                            module={this} 
                            fonts={fontOptions}
                            defaultFont='Lyon Display'
                        />
                    </InnerWrapper>
                    

                    <SliderWrapper>
                        <Images>
                            {images.map((image, key) => {
                                return <StyledImage key={key} image={image} visible={key === step} />
                            })}
                            <StyledImage image={images[step]}></StyledImage>
                        </Images>
                        <RangeWrapper>
                            <div>{Globals.get('mod_ds_extended')}</div>
                            <StyledSlider
                                min={0}
                                max={9}
                                tooltip={false}
                                value={step} 
                                orientation="horizontal" 
                                onChange={this.onSliderChange} 
                            />
                            <div>{Globals.get('mod_ds_compressed')}</div>
                        </RangeWrapper>
                    </SliderWrapper>
                </ModuleWrapper>
            </OptionsWrapper>
        );
    }
}

export default SlimJimModule;

const Images = styled.div``

const InnerWrapper = styled.div`
    width: calc(100% - 260px);
    max-width: 780px;
    margin: 0 auto;
    text-align: center;

    ${media.lessThan('medium')`
        width: 100%;
        padding: 0 20px;
    `}
`

const RangeWrapper = styled.div`
    display: flex;
    align-items: center;

    div {
        font-size: 14px;
        line-height: 17px;
        font-weight: normal;
        font-family: 'Circular Pro Book';
        text-transform: uppercase;
    }
`

const StyledSlider = styled(RangeSlider)`
    margin: 0 20px;
    height: 1px;
    flex: 1;
    position: relative;
    background: #979797;

    .rangeslider__handle {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: #444;
        position: relative;
        transform: translate(-7px, -7px);
    }

    &:before {
        position: absolute;
        left: 0;
        top: -5px;
        height: 11px;
        width: 1px;
        background: #979797;
        content: ' ';
    }

    &:after {
        position: absolute;
        right: 0;
        top: -5px;
        height: 11px;
        width: 1px;
        background: #979797;
        content: ' ';
    }

`

const StyledImage = styled.div`
    /* NB! Try to reproduce issue on iOS where image disappears when sliding progress bar */
    background: url(${p => p.image}) no-repeat center center;
    background-size: contain;
    width: 100%;
    display: ${p => p.visible ? 'block' : 'none'};

    &:before {
        display: block;
        content: ' ';
        width: 100%;
        padding-top: 20.45455%;
    }
`

const SliderWrapper = styled.div`
    width: calc(100% - 260px);
    max-width: 780px;
    margin: 0 auto;

    ${media.lessThan('medium')`
        width: 100%;
        padding: 0 20px;
    `}
`

const ModuleWrapper = styled.section`
    text-align: center;
    background: #f3f3f3;
    padding: 75px 0 50px;
`
