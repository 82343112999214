import React from 'react'
import ImageLink from './ImageLink'

function loadCustomComponents () {
  let loadedComponents = []
  let req = require.context('./components/', false, /.*\.js$/)

  req.keys().forEach(function(key){
    loadedComponents[key.substring(2).split('.')[0]] = req(key).default;
  });

  return loadedComponents;
}

const customComponents = loadCustomComponents();

const renderText = (text) => {
  return text.split('\n').map((item, i) => {
    return (
      <React.Fragment key={i}>
        {i !== 0 && <br/>}
        {item}
      </React.Fragment>
    )
  })
}

const Leaf = ({ leaf, ...rest }) => {
  if(leaf.marks.length === 0) return <>{renderText(leaf.text)}</>

  return leaf.marks.reduce((accumulator, leaf, index) => {
    switch (leaf.type) {
      case 'font':
        return <span style={{fontFamily: leaf.data.font}}>{accumulator}</span>
      case 'italic':
        return <i>{accumulator}</i>
      case 'bold':
        return <b>{accumulator}</b>
      case 'underlined':
        return <u>{accumulator}</u>
      case 'align_left':
        return <span style={{textAlign: 'left', display: 'inline-block'}}>{accumulator}</span>
      default:
        console.log('Unhandled leaf type', leaf.type);
        return accumulator
    }
  }, <>{renderText(leaf.text)}</>)
}

const Text = ({ node, ...rest}) => {
  return (
    <>
      {node.leaves.map((l, i) => {
          return <Leaf key={ i } leaf={ l }/>
      })}
    </>
  )
}

const Inline = ({ node, ...rest }) => {
  switch (node.type) {
    case 'link':
      const href = node.data.href
      const type = node.data.type
      const value = node.data.value

      if (type === 'internal') {
        return <a href={href} data-react-link><Nodes nodes={node.nodes}/></a>
      } else if(type === 'image'){
        return <ImageLink id={'#' + value} ><Nodes nodes={node.nodes}/></ImageLink>
      } else if (type === 'anchor') {
        return <a href={href}><Nodes nodes={node.nodes}/></a>
      } else{
        return <a href={href} target="_blank" rel="nofollow"><Nodes nodes={node.nodes}/></a>
      }
    default:
      console.log('Un-handled Inline Type', node.type, node);
      return null;
  }
}

const Block = ({ node, ...rest}) => {

  if (customComponents.hasOwnProperty(node.type)) {
    return customComponents[node.type].serialize(node.data, <Nodes nodes={node.nodes}/>, true);
  }

  switch (node.type) {
    case 'paragraph':
      return (
        <p>
          {node.nodes.map((n,i) => {
            return <RenderNode key={i} node={n}/>
          })}
        </p>
      )
    case 'heading-one':
      return <h1><Nodes nodes={node.nodes}/></h1>
    case 'heading-two':
      return <h2><Nodes nodes={node.nodes}/></h2>
    case 'image':
      const url = node.data.url
      return <img src={url} alt=""/>
    default:
      console.log(`Un-handled block`, node);
      return null
  }
}

const RenderNode = ({ node, ...rest }) => {
  switch (node.object) {
    case 'block':
      return <Block node={node}/>
    case 'text':
      return <Text node={node}/>
    case 'inline':
      return <Inline node={node}/>
    default:
      console.log(`Un-handled object`, node);
      return null
  }
}

const Nodes = ({ nodes, ...rest }) => {
  return (
    <>
      {nodes.map((n, i) => {
        return <RenderNode key={i} node={n}/>
      })}
    </>
  )
}


const FrontendRenderer = ({field = '', data = null, className = '', disabled = true, module, ...rest}) => {
  if(data !== null && data !== 'Enter title...' && data !== 'Enter text...') {
    const json = typeof data === 'string' ? JSON.parse(data) : data

    if(typeof json !== 'undefined' && typeof json.document !== 'undefined' && typeof json.document.nodes !== 'undefined') {
      return <div className={"wysiwig-content " + className}><Nodes nodes={json.document.nodes}/></div>
    }
  }

  return <div className="wysiwig-content"></div>
}

export default FrontendRenderer