const getModelName = (variation) => {
  return variation.name.
    replace(' Whiteout', '').
    replace(' Blackout', '')
}

const productToModels = (product) => {
  let models = {}

  if (product.slug.lastIndexOf('the-varldsvan-iphone', 0) === 0) {
    if (product.variations.length === 0) {
      return null
    }

    const variations = product.variations.sort((a, b) => a.sort > b.sort).
      forEach(v => {
        v.modelName = getModelName(v)

        if(typeof models[v.modelName] === 'undefined') models[v.modelName] = []
        models[v.modelName].push(v)
        models[v.modelName] = models[v.modelName].sort((a,b) => a.name > b.name ? 1 : -1)
      })

    return {
      models: models,
      firstModel: Object.keys(models)[0]
    }
  }
  return null
}

export default productToModels