import React from 'react';
import BaseModule from 'modules/DBBaseModule';
import { Editor, Media, Linker } from '../../cms';
import styled, { css } from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';
import OptionsWrapper from 'components/OptionsWrapper';

import LeftSwipe from 'images/swiper-left.svg';
import RightSwipe from 'images/swiper-right.svg';
import loadable from '@loadable/component';
import 'react-id-swiper/src/styles/css/swiper.css';

const Swiper = loadable(() => import('react-id-swiper'), {
    fallback: (() => <div/>)(),
});

const imageSlides = {
    image: {},
}


class GenderPreview extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Gender preview" }


    /**
     * Constructor
     */
    constructor(props) {
        super(props)

        this.state = {
            activeSlide: 0,
            fields: {
                ...{}, ...{
                    title: 'Edit this title',
                    text: 'Edit this text',
                    image: {},
                    slides: [{
                        ...imageSlides
                    }]
                },
                ...props.fields,
            }
        }

        this.swiper = React.createRef();
    }


    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'flipped',
                label: 'Flip content direction',
                type: 'switch',
            },
            {
                name: 'size',
                label: 'Module size',
                type: 'dropdown',
                options: [
                    { name: 'Small', value: '2' },
                    { name: 'Medium', value: '1.5' },
                    { name: 'Large', value: '1' },
                ]
            },
            {
                name: 'lockAspect',
                label: 'Lock media at 72:35 aspect ratio',
                type: 'switch'
            },
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    componentDidMount() {
        let editor = document.getElementById("editor");
        let self = this;

        editor.addEventListener('mouseover', function(e) {
            if(e.target.tagName === 'A') {
                self.handleHover(e);
            }
        });
        editor.addEventListener('click', function(e) {
            if(e.target.tagName === 'A') {
                return null;
            }
        });
    }

    componentWillUnmount() {
        let editor = document.getElementById("editor");
        editor.removeEventListener('mouseover', this.handleHover);
    }

    handleHover = (e) => {
        let link = e.target.id;

        this.state.fields.slides.map((slide, key) => {
            if(Object.keys(slide.image).length > 0 && slide.image.id.toString() === link.split('#')[1]){
                if(this.swiper.swiper && this.swiper.props.activeSlideKey !== key) {
                    this.swiper.swiper.slideTo(key+1);
                }
            }
        });
    }

    /**
    * Adds imageSlides object to array, updates fields.slides[]
    * @return {Void, Object}
    */
   addSlide() {
        this.addItemToArrayField('slides', imageSlides)

        this.setState({
            activeSlide: this.state.fields.slides.length,
        });
    }

    /**
     * Renderer
     */
    render() {
        let { options, editMode } = this.props;
        let { fields, activeSlide } = this.state;

        const sliderParams = {
            loop: !editMode,
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
            navigation: {
              nextEl: '.swiper-button-next.custom-button-next',
              prevEl: '.swiper-button-prev.custom-button-prev'
            },
            containerClass: 'swiper-container',
            activeSlideKey: editMode ? this.state.activeSlide.toString() : '1',
            initialSlide: this.state.activeSlide || 0,
        };

        let images = { name: "Images", key: "images", entries : fields.slides };

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper flipped={options.flipped}>
                    <SwiperWrapper>
                        <Swiper {...sliderParams}
                            getSwiper={(swiper) => { this.swiper = swiper; }} key={ fields.slides.length + activeSlide } >
                            { fields.slides.map((slide, index) => {
                                return (
                                    <Media
                                        key={index}
                                        field={'slides.' + index + '.image'}
                                        types={['images']}
                                        overlay={options.overlay}
                                        module={this}
                                        aspectRatio={parseFloat(options.lockAspect ? 72/35 : options.size)}
                                        fill>
                                        {editMode &&
                                            <div>
                                                <StyledButton onClick={() => this.addSlide(index)} className="add">Add slide</StyledButton>
                                                <StyledButton onClick={() => this.removeItemFromArrayField('slides', index)} className="remove">Delete slide</StyledButton>
                                            </div>
                                        }
                                    </Media>
                                )
                            })
                            }
                        </Swiper>
                    </SwiperWrapper>
                    <StyledContentArea width={1/2} alignSelf="center" id="editor">
                        <Editor
                            field={'text'}
                            data={fields.text}
                            disabled={!editMode}
                            images={images}
                            module={this}
                        />
                    </StyledContentArea>
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default GenderPreview;

const Wrapper = styled.section`
    max-width: 1660px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    ${props => props.flipped === true && css`
        flex-direction: row-reverse;
    `}

    ${media.lessThan('small')`
        flex-direction: column-reverse;
    `}
`

const StyledContentArea = styled.div`
    padding: 100px 130px;
    margin: auto;
    text-align: center;
    width: 50%;

    ${media.lessThan('small')`
        width: 100%;
        padding: 15px;
    `}
`

const Button = (props) => {
    return (
        <div className={props.className} onClick={props.onClick}>
            {props.children}
        </div>
    )
}

const StyledButton = styled(Button)`
    position: absolute;
    bottom: 16px;
    display: inline-block;
    width: 80px;
    height: 80px;
    text-align: center;
    background: #2699fb;
    color: #fff;
    padding: 12px .5em;
    font-family: Lato;
    font-weight: bold;
    border-radius: 100%;
    cursor: pointer;
    z-index: 1;

    &.add {
        right: 15px;
    }

    &.remove {
        right: 100px;
    }
`

const SwiperWrapper = styled.div`

    width: 50%;

    .swiper-container{
        width: 100%; 
        height: 100%; 
    }

    ${media.lessThan('small')`
        width: 100%;
    `}

    & > ${StyledButton} {
        position: relative;
        transform: none;
        bottom: 0;
        left: 0;
        margin: 30px;
    }

    .custom-button-next,
    .custom-button-prev {
        width: 22px;
        z-index: 1;
        height: 46px;
        background-size: 22px 46px;
    }

    .custom-button-next {
        right: 0;
        z-index: 1;
        left: auto;
        background-image: url(${RightSwipe});
    }

    .custom-button-prev {
        left: 0;
        z-index: 1;
        right: auto;
        background-image: url(${LeftSwipe});
    }

    .swiper-pagination-bullet {
        width: 13px;
        height: 13px;
        background: #fff;
        ${media.lessThan('mobile')`
            width: 8px;
            height: 8px;
        `}
    }
`
