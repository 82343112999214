import React from 'react';
import store from '../store'

const defaultState = {
  loaded: false,
  products: [],
};

const LOADED_CARBON_OFFSET_PRODUCTS = 'LOADED_CARBON_OFFSET_PRODUCTS';

export const CarbonOffsetProductsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOADED_CARBON_OFFSET_PRODUCTS:
      return {
        loaded: true,
        products: action.products ? action.products : state.products
      };
    default:
      return state;
  }
};

const CarbonOffsetProductsActions = {
  setProducts: (products) => {
    store.dispatch({
      type: LOADED_CARBON_OFFSET_PRODUCTS,
      loaded: true,
      products,
    });
  },
  all: () => {
    return store.getState().carbonOffsetProducts.products;
  },
};

export default CarbonOffsetProductsActions;