import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { IoMdAdd, IoMdImage, IoMdVolumeOff, IoMdVolumeHigh } from 'react-icons/io';
import IconButton from 'cms/components/Form/IconButton';

const VideoButtons = ({ onOpenFileManager, onAddPointer, editPosition, entryType, mute, onToggleMute }) => {
    const editPositions = {
        nw: { t: '10', l: '10' },
        n: { t: '10', l: '50' },
        ne: { t: '10', l: '90' },
        w: { t: '50', l: '10' },
        c: { t: '50', l: '50' },
        e: { t: '50', l: '90' },
        sw: { t: '90', l: '10' },
        s: { t: '90', l: '50' },
        se: { t: '90', l: '90' },
    };

    if (typeof editPosition === 'undefined' || editPosition === null) editPosition = 'c';

    return (
        <StyledButtons
            className={ 'media-buttons' }
            top={ editPositions[editPosition].t }
            left={ editPositions[editPosition].l }
            editPosition={ editPosition }>
            <StyledIconButton onClick={onOpenFileManager}>
                <IoMdImage/>
            </StyledIconButton>
            <StyledIconButton onClick={onToggleMute}>
                {mute ? <IoMdVolumeHigh/> : <IoMdVolumeOff/>}
            </StyledIconButton>
            { entryType && (
                <StyledIconButton onClick={onAddPointer}>
                    <IoMdAdd/>
                </StyledIconButton>
            ) }
        </StyledButtons>
    )
}

// const VideoButtons.propTypes = {
//     onOpenFileManager: PropTypes.func.isRequired,
//     onAddPointer: PropTypes.func.isRequired,
//     editPosition: PropTypes.string,
//     entryType: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.array
//     ])
// }

const StyledButtons = styled.div`
    z-index: 5;
    position: absolute;
    top: ${ props => props.top }%;
    left: ${ props => props.left }%;
    opacity: 1;
    transform: translate(-50%, -50%);
`

const StyledIconButton = styled(IconButton)`
  margin-bottom: 6px;
`

export default VideoButtons
