import React from 'react';
import styled from 'styled-components';
import { LinkerActions } from 'kbcms-react';
import { MdInsertLink, MdInvertColors } from 'react-icons/md';
import Button from 'components/Button';


/**
 * Button w/ Link Plugin
 */
export default class LinkButton extends React.Component {

    /**
     * Component state
     */
    state = {
        url: this.props.node.data.get('url'),
        url_type: this.props.node.get('url_type'),
        black: this.props.node.data.get('black'),
    }


    /**
     * Component config
     * @return {Object}
     */
    static config() {
        return {
            // Button name. This we can change to Icon name or something.
            name: 'Button',

            // Is this component void? Void means no editable contents within.
            isVoid: false,

            // Should this plugin insert new block, or replace current. 
            insert: true,

            // Default data state.
            defaultState: {
                url: "",
                url_type: 'internal',
                black: true
            }
        }
    }


    /**
     * Serialize this class to HTML.
     * @return {String}
     */
    static serialize(data, children, frontend) {
        let urlType = 'internal', black, url

        if(frontend) {
            urlType = typeof data.url_type !== 'undefined' ? data.url_type : urlType
            black = typeof data.black !== 'undefined' ? data.black : false
            url = typeof data.url !== 'undefined' ? data.url : ''
        } else {
            urlType = data.get('url_type') ? data.get('url_type') : 'internal';
            black = data.get('black')
            url = data.get('url')
        }

        if (urlType === 'external') {
            return (
                <StyledLinkButton 
                    black={black}
                    href={url}
                    target={'_blank'}>
                    {children}
                </StyledLinkButton>
            )
        }
        if (urlType === 'anchor') {
            return (
                <StyledLinkButton 
                    black={black}
                    href={url.charAt(0) === '#' ? url : '#' + url}
                >
                    {children}
                </StyledLinkButton>
            )
        }
        return (
            <StyledLinkButton 
                black={black}
                href={'/' + url}
                data-react-link>
                {children}
            </StyledLinkButton>
        );
    }


    /**
     * Changes the link
     * @return {Void}
     */
    changeLink = () => {
        let self = this;
        let { editor, node } = this.props;

        LinkerActions.openModal(result => {
            self.setState({ url: result.url, url_type: result.type }, () => {
                editor.setNodeByKey(node.key, { data: this.state });
            });
        });
    }

    
    /**
     * Flips the colors
     * @return {Void}
     */
    flipColors = () => {
        let { editor, node } = this.props;

        this.setState({ black: !this.state.black }, () => {
            editor.setNodeByKey(node.key, { data: this.state});
        });
    }


    /**
     * Renders the edit link button
     * @return {JSX}
     */
    renderButtons() {
        return (
            <ButtonsWrapper>
                <SettingsButton onClick={this.changeLink}>
                    <MdInsertLink />
                </SettingsButton>
                <SettingsButton onClick={this.flipColors}>
                    <MdInvertColors />
                </SettingsButton>
            </ButtonsWrapper>
        );
    }


    /**
     * Component renderer
     * @return {JSX}
     */
    render() {
        let { url, black } = this.state;

        return (
            <LinkWrapper isFocused={this.props.isFocused}>
                <StyledLinkButton black={black} title={"Links to /" + url} {...this.props.attributes}>
                    {this.props.children}
                    {this.renderButtons()}
                </StyledLinkButton>
            </LinkWrapper>
        );
    }

}

const LinkWrapper = styled.section`
    position: relative;
    display: block;
    width: 100%;
    border: 1px solid transparent;

    ${p => p.isFocused ? `
        border: 1px dotted #d2d2d2;
    `:''}
`

const ButtonsWrapper = styled.div`
    position: absolute;
    top: -1px;
    left: 100%;
    width: 25px; 
    height: auto;
    opacity: 0;
    border: 1px solid #222;

    &> div ~ div {
        border-top: 1px solid #222;
    }
`

const SettingsButton = styled.div`
    width: 23px;
    height: 23px;
    background-color: #fff;
    cursor: pointer;
    transition: opacity .3s ease;
    position: relative;

    svg {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: #000;
    }
`

const StyledLinkButton = styled(Button)`
    margin-left: 0;
    margin: 0 0 15px;
    
    &:hover {
        ${ButtonsWrapper} {
            opacity: 1;
        }
    }
`
