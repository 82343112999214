import CircularProBook from 'assets/fonts/CircularPro-Book.woff';
import CircularProMedium from 'assets/fonts/CircularPro-Medium.woff';
import LyonDisplayBold from 'assets/fonts/LyonDisplay-Bold.woff';
import LyonTextBold from 'assets/fonts/LyonText-Bold.woff';
import LyonTextRegular from 'assets/fonts/LyonText-Regular.woff';
import LyonTextRegularItalic from 'assets/fonts/LyonText-RegularItalic.woff';
import { css } from 'styled-components';

const Fonts = css`
    @font-face {
        font-family: 'Circular Pro Book';
        src: url(${CircularProBook}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
    }
    @font-face {
        font-family: 'Circular Pro';
        src: url(${CircularProMedium}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: fallback;
    }
    @font-face {
        font-family: 'Lyon Display';
        src: url(${LyonDisplayBold}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
    }
    @font-face {
        font-family: 'Lyon Text Bold';
        src: url(${LyonTextBold}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
    }
    @font-face {
        font-family: 'Lyon Text';
        src: url(${LyonTextRegular}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
    }
    @font-face {
        font-family: 'Lyon Text';
        src: url(${LyonTextRegularItalic}) format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: fallback;
    }
`

export default Fonts;
