import React from 'react';
import isServer from 'isServer'

function loadTemplates (addProps) {
    let loadedTemplates = []

    var req = require.context("../templates/", false, /.*\.js$/);

    req.keys().forEach(function(key){
        let currentComp = req(key).default

        if (typeof addProps == 'undefined') addProps = {};

        let component = React.createElement(currentComp, addProps, null);

        loadedTemplates[key.substring(2).split('.')[0]] = {
            name: currentComp.getName(),
            layout: currentComp.hasOwnProperty('overrideMasterLayout') ? currentComp.overrideMasterLayout() : null,
            component: component
        }
    });

    return loadedTemplates;
}

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = isServer ? null : regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}


export { loadTemplates, getUrlParameter }
