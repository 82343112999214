import React from 'react';
import loadable from '@loadable/component';
import { NewMedia } from 'kbcms-react';
import produce from 'immer';
import BaseModule from 'modules/DBBaseModule';
import OptionsWrapper from 'components/OptionsWrapper';
import styled, { css } from 'styled-components';
import LeftSwipe from 'images/swiper-left.svg';
import RightSwipe from 'images/swiper-right.svg';
import { default as media } from 'assets/styles/Breakpoints';
import 'react-id-swiper/src/styles/css/swiper.css';

const Swiper = loadable(() => import('react-id-swiper'), {
    fallback: (() => <div/>)(),
});

const imageSlides = {
    image: {},
    muted: true,
    forceNativeVideoAspectRatio: true
}

class Slider extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return 'Image Slider' }

    /**
    * Constructor
    */
    constructor(props) {
        super(props)
        this.state = {
            activeSlide: 0,
            fields: {
                ...{}, ...{
                    slides: [{
                        ...imageSlides
                    }]
                },
                ...props.fields,
            }
        }
    }

    /**
    * Adds imageSlides object to array, updates fields.slides[]
    * @return {Void, Object}
    */
    addSlide() {
        this.addItemToArrayField('slides', imageSlides)

        this.setState({
            activeSlide: this.state.fields.slides.length,
        });
    }


    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    /*
     * Toggles mute on the video on an category-item
     */
    onToggleMute = (index) => {
        const nextState = produce(this.state, draft => {
            draft.activeSlide = index;
            draft.fields.slides[index].muted = !draft.fields.slides[index].muted;
        })

        this.setState(nextState)
    }

    /*
     * Toggles native Aspect-Ratio for video on a category-item
     */
    onToggleNativeAspectRatio = (index) => {
        const nextState = produce(this.state, draft => {
            draft.activeSlide = index;
            draft.fields.slides[index].forceNativeVideoAspectRatio = !draft.fields.slides[index].forceNativeVideoAspectRatio;
        })

        this.setState(nextState)
    }

    /**
    * Renderer
    */
    render() {
        let { options, editMode } = this.props;
        let { fields, activeSlide } = this.state;

        const sliderParams = {
            loop: !editMode,
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
            navigation: {
              nextEl: '.swiper-button-next.custom-button-next',
              prevEl: '.swiper-button-prev.custom-button-prev'
            },
            containerClass: 'swiper-container',
            activeSlideKey: editMode ? this.state.activeSlide.toString() : '1',
            initialSlide: this.state.activeSlide || 0
        };

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper>
                    <Swiper {...sliderParams} key={ fields.slides.length + activeSlide } >
                        { fields.slides.map((slide, index) => {
                            return (
                                <NewMedia
                                    key={index}
                                    overlay={options.overlay}
                                    field={'slides.' + index + '.image'}
                                    videoOptions={{
                                        onToggleMute: () => this.onToggleMute(index),
                                        onToggleNativeAspectRatio: () => this.onToggleNativeAspectRatio(index),
                                        forceNativeVideoAspectRatio: slide.forceNativeVideoAspectRatio,
                                        muted: slide.muted,
                                        autoplay: slide.muted
                                    }}
                                    module={this}
                                    types={['images', 'videos']}
                                    aspectRatio={72 / 35}
                                    fill>
                                    {editMode &&
                                        <div>
                                            <StyledButton onClick={() => this.addSlide(index)} className="add">Add slide</StyledButton>
                                            <StyledButton onClick={() => this.removeItemFromArrayField('slides', index)} className="remove">Delete slide</StyledButton>
                                        </div>
                                    }
                                </NewMedia>
                            )
                        })
                        }
                    </Swiper>
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default Slider

const Button = (props) => {
    return (
        <div className={props.className} onClick={props.onClick}>
            {props.children}
        </div>
    )
}

const StyledButton = styled(Button)`
    position: absolute;
    bottom: 16px;
    display: inline-block;
    width: 80px;
    height: 80px;
    text-align: center;
    background: #2699fb;
    z-index: 1;
    color: #fff;
    padding: 12px .5em;
    font-family: Lato;
    font-weight: bold;
    border-radius: 100%;
    cursor: pointer;
    
    &.add {
        right: 15px;
    }
    
    &.remove {
        right: 100px;
    }
`

const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 1660px;
    & > ${StyledButton} {
        position: relative;
        transform: none;
        bottom: 0;
        left: 0;
        margin: 30px;
    }

    .custom-button-next,
    .custom-button-prev {
        width: 22px;
        height: 46px;
        background-size: 22px 46px;
    }

    .custom-button-next {
        right: 0;
        left: auto;
        background-image: url(${RightSwipe});
    }

    .custom-button-prev {
        left: 0;
        right: auto;
        background-image: url(${LeftSwipe});
    }

    .swiper-pagination-bullet {
        width: 13px;
        height: 13px;
        background: #fff;
        ${media.lessThan('mobile')`
            width: 8px;
            height: 8px;
        `}
    }
`
