import axios from 'axios';
import Bugsnag from '@bugsnag/js'
import CartActions from 'reducers/cart';
import Store from './store';

class Cart  {

    constructor() {
        this.url = process.env.RAZZLE_CENTRA_ENDPOINT + '/api/checkout/';
        this.bypassToken = false;
    }

    getState() {
        return Store.getState().cart;
    }

    headers = () => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        let cartStore = Store.getState().cart;

        if (cartStore.token !== null && this.bypassToken === false) {
            headers['API-Token'] = cartStore.token;
        }

        return headers;
    }

    /**
     * generic GET request to the API
     * @param  {String} path
     * @return {Promise}
     */
    get(path) {
        return axios.get(this.url + path, {
            headers: this.headers()
        });
    }

    /**
     * generic POST request to the API
     * @param  {String} path
     * @param  {Object} data
     * @return {Promise}
     */
    post(path, data) {
        return axios.post(this.url + path, data, {
            headers: this.headers()
        });
    }

    /**
     * generic PUT request to the API
     * NOTE: PUR does not work with FormData() use POST instead and append '_method', 'PUT' to form data
     * @param  {String} path
     * @param  {Object} data
     * @return {Promise}
     */
    put(path, data) {
        return axios.put(this.url + path, data, {
            headers: this.headers()
        });
    }

    /**
     * generic DELETE request to the API
     * @param  {String} path
     * @return {Promise}
     */
    delete(path) {
        return axios.delete(this.url + path, {
            headers: this.headers()
        });
    }


    clearCart = () => {
        CartActions.clearCart();
    }

    /**
     * Get the current selection.
     * @returns {Promise<unknown>}
     */
    selection = () => {
        var self = this;

        return new Promise(function (resolve, reject) {
            self.get('selection/')
            .then(result => {

                // set cart data.
                CartActions.setToken(result.data.token);
                CartActions.setCartData(result.data);

                resolve(result.data);
            })
            .catch(error => {
                reject(error.response.data.errors);
            });
        });
    }


    /**
     * Add an item to cart.
     */
    add = (item, variation) => {
        var self = this;

        return new Promise(function (resolve, reject) {
            CartActions.addVariation(variation)

            self.post('items/' + item)
                .then(result => {

                    // set cart data.
                    CartActions.setToken(result.data.token);
                    CartActions.setCartData(result.data);

                    resolve(result.data);
                })
                .catch(error => {
                    reject(error.response.data.errors);
                });
        });
    }

    getLineId = lineId => {
        return Store.getState().cart.data.selection.items.find(i => {
            return i.line === lineId;
        })
    }

    removeLine = (lineId, variation) => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.delete('lines/' + lineId).then(result => {
                CartActions.removeVariation(variation);
                CartActions.setCartData(result.data);
                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }

    updateQuantity = (lineId, newQuantity) => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.put('lines/' + lineId + '/quantity/' + newQuantity).then(result => {
                CartActions.setCartData(result.data);

                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }


    /**
     * Attemps to add a voucher to the cart
     */
    addVoucher = (code) => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.post('vouchers', {voucher: code}).then(result => {
                CartActions.setCartData(result.data);
                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }

    /**
     * Removes a voucher from the cart
     */
    removeVoucher = (code) => {
        var self = this;
        return new Promise(function(resolve, reject) {
            self.delete(`vouchers/${code}`, {}).then(result => {
                CartActions.setCartData(result.data);
                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }


    loginUser = (email, password) => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.post('login/' + email, { password: password }).then(result => {
                CartActions.setCartData(result.data);
                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }

    setCountry = country => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.put('countries/' + country).then(result => {
                CartActions.setCartData(result.data);
                CartActions.setToken(result.data.token);
                resolve(result.data);
            }).catch(error => {

                // clear the cart if we get an error here.
                CartActions.clearCart();
                reject(error.response.data.errors);
            });
        });
    }

    setState = (country, state) => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.put('countries/' + country + '/states/' + state).then(result => {
                CartActions.setCartData(result.data);
                resolve(result.data);
            }).catch(error => {

                // clear the cart if we get an error here.
                CartActions.clearCart();
                reject(error.response.data.errors);
            });
        });
    }

    getCountries = () => {
        let self = this;

        return new Promise(function(resolve, reject) {
            self.get('countries').then(result => {
                resolve(result.data);
            }).catch(error => {
                Bugsnag.notify(error)

                if(error?.response?.data?.errors !== undefined) {
                    reject(error.response.data.errors);
                }
                reject(error)
            });
        });
    }


    registerUser = userFields => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.post('register', userFields).then(result => {
                CartActions.setCartData(result.data);
                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }


    selectPaymentMethod = method => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.put('payment-methods/' + method).then(result => {
                CartActions.setCartData(result.data);
                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }


    selectShippingMethod = method => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.put('shipping-methods/' + method).then(result => {
                CartActions.setCartData(result.data);
                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }

    paymentFields = fields => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.put('payment-fields', fields).then(result => {
                CartActions.setCartData(result.data);
                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }

    payment = fields => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.post('payment', fields).then(result => {
                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }


    paymentResult = fields => {
        var self = this;

        return new Promise(function(resolve, reject) {
            self.post('payment-result', { paymentMethodFields: fields }).then(result => {
                CartActions.setCartData(result.data);
                resolve(result.data);
            }).catch(error => {
                reject(error.response.data.errors);
            });
        });
    }
}

export default (new Cart());
