
/**
 * Compare two arrays
 * @param {Array, Array}
 * @return {Boolean}
 */
export const compareArrays = (array1, array2) => {
    var ret = [];
    let arr1 = [...array1];
    let arr2 = [...array2];

    arr1.sort();
    arr2.sort();

    for(var i = 0; i < arr1.length; i += 1) {
        if(arr2.indexOf(arr1[i]) > -1){
            ret.push(arr1[i]);
        }
    }

    return ret.length > 0 ? true : false;
};

/**
 * Check if array contains on of values in another array
 * @param {Array, Array}
 * @return {Boolean}
 */
export const hasOneOfValues = (productArray, selectedArray) => {
    let has = false;
    productArray.map(productValue => {
        if(selectedArray.includes(productValue)){
            has = true;
        }
    })
    return has;
}
