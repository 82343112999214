import React from 'react';
import { Editor, Linker, NewMedia } from 'kbcms-react';
import styled, { css } from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';
import BaseModule from 'modules/DBBaseModule';
import OptionsWrapper from 'components/OptionsWrapper';
import fontOptions from 'assets/styles/FontOptions';

class AskewedImageAndText extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Askewed image and text" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props)

        this.onChangeField = this.onChangeField.bind(this)

        this.state = {
            fields: {
                ...{}, ...{
                    title: 'Edit this title',
                    text: 'Edit this text',
                    muted: true,
                    forceNativeVideoAspectRatio: false,
                    imageCaption: 'Enter a caption',
                    image: {}
                },
            ...props.fields,
            }
        }
    }

    static options() {
        return super.options().concat([
            {
                name: 'flipped',
                label: 'Flip content direction',
                type: 'switch'
            },
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    /**
    * When content editable field is changed
    * @return {Void}
    */
    onChangeField = (name, value) => {
        this.setState({ fields: Object.assign(this.state.fields, { [name]: value }) })
    }

    /**
    * Renderer
    */
    render() {
        let { options, editMode } = this.props;
        let { fields } = this.state;
        const { muted, forceNativeVideoAspectRatio } = fields;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper flipped={options.flipped}>
                    <StyledMediaLinker module={this} field={'image_link_url'}>
                        <StyledMedia
                            fill
                            overlay={options.overlay}
                            field={'image'}
                            module={this}
                            types={['images', 'videos']}
                            videoOptions={{
                                onToggleMute: () => this.setState({
                                    ...this.state,
                                    fields: {
                                        ...fields,
                                        muted: !muted
                                    }
                                }),
                                onToggleNativeAspectRatio: () => this.setState({
                                    ...this.state,
                                    fields: {
                                        ...fields,
                                        forceNativeVideoAspectRatio: !forceNativeVideoAspectRatio
                                    }
                                }),
                                forceNativeVideoAspectRatio,
                                muted,
                                autoplay: muted
                            }}
                            aspectRatio={4/3}
                        />
                    </StyledMediaLinker>

                    <StyledContentArea width={'40%'} alignSelf="center">
                        <Editor
                            module={this}
                            disabled={!editMode}
                            field={'text'}
                            data={fields.text}
                            fonts={fontOptions}
                            defaultFont='Lyon Display'
                        />
                    </StyledContentArea>
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default AskewedImageAndText;

const Wrapper = styled.div`
    max-width: 1660px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    ${props => props.flipped === true && css`
        flex-direction: row-reverse;
    `}

    ${media.lessThan('small')`
        display: block;
        width: 100%;
    `}
`

const StyledContentArea = styled.div`
    padding: 30px;
    margin: auto;
    text-align: center;
    width: 40%;

    .wysiwig-admin-content {
        margin: 0;
    }

    ${media.lessThan('small')`
        padding: 15px;
        width: 100%;
    `}
`

const StyledMediaLinker = styled(Linker)`
    display: block;
    width: 60%;
    padding: 0;
    margin: 0;

    ${media.lessThan('small')`
        width: 100%;
    `}
`

const StyledMedia = styled(NewMedia)`
    width: 100%;
    height: 100%;
`
