import React from 'react';
import BaseModule from 'modules/DBBaseModule';
import styled, { css } from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';
import map from 'lodash/map';
import Colors from 'assets/styles/Colors';
import ProductBox from 'components/ProductBox/index';
import CloseIcon from 'images/close.svg';
import CheckIcon from 'images/check.svg';
import Down from 'images/arrow_down_grey.svg';
import Up from 'images/arrow_up_black.svg';
import { Globals } from 'kbcms-react';
import { hasOneOfValues, compareArrays } from './helpers';

class ArchiveProductsList extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Archive module" }

    /**
     * Constructor
     */
    constructor(props) {
        super(props)

        this.state = {
            fields: {
                ...{}, ...{
                    products: {
                        entryType: 'products',
                        filter: ['*'],
                        data: []
                    },
                    categories: {
                        entryType: 'categories',
                        filter: ['*'],
                        data: []
                    },
                    selectedVariations: {}
                },
                ...props.fields
            },
            filterCategory: '',
            selectedCategory: 0,

            sizes: [],
            uses: [],
            colors: [],
            features: [],
            selectedSizes: [],
            selectedUses: [],
            selectedColours: [],
            selectedFeatures: [],
            isMobile: window.innerWidth < 768,
            isMobilefilter: false,
            toggleButtonText: 'Open filter',
        }
    }

    /**
     * When changing a variation we save the selected variation
     * @return {Void}
     */
    onVariationChange = (productId, variationId) => {
        let newFieldsState = Object.assign({}, this.state.fields);
        newFieldsState.selectedVariations = Object.assign({}, this.state.fields.selectedVariations, { [productId]: variationId } );
        this.setState({ fields: newFieldsState });
    }

    /**
     * Lifecycle method
     * @return {Void}
     */
    componentDidMount() {
        var self = this;

        this.prepareData().then(state => {
            let activeVariations = {};

            // set active variation fields
            state.fields.products.data.forEach(item => {
                activeVariations[item.id] = {
                    variationKey: 0,
                    variationUri: item.slug
                }
            });

            state.activeVariations = activeVariations;

            // get data for filter categories from products
            let sizes = [];
            let uses = [];
            let colors = [];
            let colorsTest = [];
            let features = [];


            state.fields.products.data.forEach(product => {

                product.variations.forEach(variation => {
                    if (variation.outlet === 0) return

                    if(variation.product_size !== null && !sizes.includes(variation.product_size)){
                        sizes.push(variation.product_size);
                    }
                    if(variation.swatch !== null && !colorsTest.includes(variation.swatch.color_text)){
                        if(variation.swatch.color_hex === ""){
                            colorsTest.push(variation.swatch.color_text);
                            colors.push({
                                "color_img": variation.swatch.color_img.url,
                                "color_text": variation.swatch.color_text,
                                "isDark": variation.swatch.color_check && variation.swatch.color_check !== '#FFFFFF' ? true : false
                            });
                        } else {
                            colorsTest.push(variation.swatch.color_text);
                            colors.push({
                                "color_hex": variation.swatch.color_hex,
                                "color_text": variation.swatch.color_text,
                                "isDark": variation.swatch.color_check && variation.swatch.color_check !== '#FFFFFF' ? true : false
                            });
                        }
                    }
                    if (variation.tags !== null){
                        Object.values(variation.tags).forEach(tag => {
                            if(!features.includes(tag.name)){
                                features.push(tag.name);
                            }
                        });
                    }
                    if(variation.product_use !== null){
                        Object.values(variation.product_use).forEach(use => {
                            if(!uses.includes(use)){
                                uses.push(use);
                            }
                        })
                    }
                })
            })

            state.sizes = sizes;
            state.uses = uses;
            state.features = features;
            state.colors = colors;

            // check if the url belongs to one of the categories, if it is, we should filter automatically.
            if (self.props.editMode) {
                const urlMatches = self.props.router.match.params;

                if (urlMatches.entryType && urlMatches.entryType === 'categories') {
                    state.selectedCategory = parseInt(urlMatches.id);
                }
            } else {
                const pathName = self.props.router.location.pathname.substring(1);

                state.fields.categories.data.forEach(category => {
                    category.route.forEach(route => {

                        if (pathName === route.url) {
                            state.selectedCategory = category.id;
                        }
                    });
                });
            }

            this.setState(state);
        });
    }

    /**
     * Renders the products.
     * @return {JSX}
     */
    renderProducts = () => {
        const { selectedColours, selectedUses, selectedSizes, selectedFeatures } = this.state;

        if (this.state.fields.products.data.length === 0
            || this.state.activeVariations === null) return null;

        const { data } = this.state.fields.products;
        const { selectedCategory, fields } = this.state;

        const filteredProducts = data.filter(p => {
            p.variations = p.variations.filter(v => v.outlet === 1)
            if (p.variations.length > 0) {
                return true;
            }
            return false;
        })
        // FIlter out products not marked as outlet
        // sort products.
        // Then map over and return a ProductBox-component
        const products = filteredProducts.sort((a, b) => b.sort - a.sort).map((product, key) => {
            // filter out products that doesn't belong to the selected category
            if (selectedCategory !== 0 && map(product.categories, 'id').includes(selectedCategory) === false) return null;

            let productColors = [];
            let productUses = [];
            let productFeatures = [];
            let productSizes = [];

            product.variations.map(variation => {
                if( variation.swatch !== null) {
                    productColors.push(variation.swatch.color_text);
                }
                let uses = [];
                if( variation.product_use !== null) {
                    Object.values(variation.product_use).map(use => {
                        uses.push(use);
                    });
                    productUses = uses;
                }
                let features = [];
                if( variation.tags !== null) {
                    Object.values(variation.tags).map(tag => {
                        features.push(tag.name);
                    });
                    productFeatures = features;
                }
                if(variation.product_size !== null){
                    productSizes.push(variation.product_size);
                }
            })

            if(selectedSizes.length > 0 && compareArrays(selectedSizes, productSizes)=== false ) return null;
            if(selectedUses.length > 0 && hasOneOfValues(productUses, selectedUses) === false) return null;
            if(selectedColours.length > 0 && compareArrays(selectedColours, productColors) === false) return null;
            if(selectedFeatures.length > 0 && hasOneOfValues(productFeatures, selectedFeatures) === false) return null;

            let colorMatchVariationId = null;
            let colorMatchIndex = 0;

            if(selectedColours.length > 0) {
                product.variations.map((variation, key) => {
                    if(colorMatchIndex === 0 || colorMatchIndex !== selectedColours.length - 1){
                        for(let i = selectedColours.length; i >= 0; i--){
                            if (variation.swatch !== null && variation.swatch.color_text === selectedColours[i]){
                                colorMatchVariationId = variation.id;
                                colorMatchIndex = i;
                                break;
                            }
                        }
                    }
                    if(colorMatchIndex === selectedColours.length - 1) return true;
                });
            }

            return {
                ...product,
                selectedVariation: (colorMatchVariationId !== null && selectedColours.length) > 0 ? colorMatchVariationId : typeof product.variations[0] !== 'undefined' ? product.variations[0].id : 0
            };
        });

        const addDuplicateParent = () => {
            let duplicateParentProducts = [];
            products.filter(p => p !== null).forEach(p => {
                if (p.variations.length > 0) {
                    p.variations.forEach(v => {
                        if (selectedColours.length > 0 && selectedColours.findIndex(c => c === v.swatch_text) !== -1) {
                            duplicateParentProducts.push({
                                ...p,
                                variations: [{ ...v }]
                            })
                        }
                        if (selectedColours.length === 0) {
                            duplicateParentProducts.push({
                                ...p,
                                variations: [{ ...v }]
                            })
                        }
                    })
                }
            })
            return duplicateParentProducts
        }

        const archiveProducts = addDuplicateParent();

        return (
            <StyledProductsWrapper>
                {archiveProducts.map((p, key) => {
                    return (
                        <ProductBox
                            key={key}
                            product={p}
                            selectedVariation={p.selectedVariation}
                            onVariationChange={variationId => this.onVariationChange(p.id, variationId)}
                        />
                    )
                })}
            </StyledProductsWrapper>
        )
    }

    /**
     * Render the filter bar
     * @return {JSX}
     */
    renderfilter = () => {
        let filterCategories = [];
        let filterCategoryNames = ['Categories', 'Colour'];
        let { selectedCategory, selectedSizes, selectedUses, selectedColours, selectedFeatures } = this.state;

        if( selectedCategory !== 0 || selectedSizes.length > 0 || selectedUses.length > 0 || selectedColours.length > 0 || selectedFeatures.length > 0){
            filterCategories.push(
                <FilterButton key={123} onClick={this.clearAllFilters}>CLEAR FILTERS</FilterButton>
            )
        }

        {filterCategoryNames.map((filterCategory, key) => {
            let hasValueTest = null;
            (filterCategory === 'Categories') ?
            hasValueTest = selectedCategory :
            hasValueTest = this.state['selected'+filterCategory+'s'];

            let data = filterCategory.toLowerCase()+'s';
            if (data === 'colours') data = 'colors';

            if(filterCategory === 'Categories' || (typeof this.state[data] !== 'undefined' && this.state[data].length > 0)){
                return filterCategories.push(
                    <FilterCategory
                        key={key}
                        onClick={e => this.selectFilterCategory(e, filterCategory)}
                        active={this.state.filterCategory === filterCategory}
                        hasValue={(hasValueTest !== null && ((hasValueTest.length > 0) || (!Array.isArray(hasValueTest) && hasValueTest !== 0)))}
                    >
                        {filterCategory}
                        <ArrowDown src={Down}/>
                        <ArrowUp src={Up} />
                        <Close className="clearButton" src={CloseIcon} onClick={() => this.clearFilter(filterCategory)}/>
                    </FilterCategory>
                )
            }
        })}
        return filterCategories;
    }

    /**
     * Button handler for mobile filter
     * @return {Void}
     */
    toggleMobileFilter = () => {
        if(this.state.isMobilefilter === false){
            this.setState({
                isMobilefilter : true,
                toggleButtonText : 'Close filter'
            });
        } else {
            this.setState({
                isMobilefilter : false,
                toggleButtonText : 'Open filter'
            });
        }
    }

    /**
     * Render mobile filter
     * @return {Void}
     */
    renderMobilefilter = () => {
        let filterCategories = [];
        let filterCategoryNames = ['Categories', 'Colour'];
        let { colors, sizes, uses, features } = this.state;

        {filterCategoryNames.map((filterCategory, key) => {
            return filterCategories.push(
                <FilterCategory
                    key={key}
                >
                    {filterCategory}
                    {filterCategory === 'Categories' &&
                        <Categories>
                            {this.renderCategories()}
                        </Categories>
                    }
                    {filterCategory === 'Size' &&
                        <Categories>
                            {this.renderSelectedFilterCategory(sizes, filterCategory)}
                        </Categories>
                    }
                    {filterCategory === 'Use' &&
                        <Categories>
                            {this.renderSelectedFilterCategory(uses, filterCategory)}
                        </Categories>
                    }
                    {filterCategory === 'Colour' &&
                        <Categories>
                            {this.renderColors(colors)}
                        </Categories>
                    }
                    {filterCategory === 'Feature' &&
                        <Categories>
                            {this.renderSelectedFilterCategory(features, filterCategory)}
                        </Categories>
                    }
                </FilterCategory>
            )
        })}
        return filterCategories;
    }

    /**
     * Select filter category handler
     * @param {Event, String}
     * @return {Void}
     */
    selectFilterCategory = (e, filterCategory) => {
        if(!this.state.isMobile){
            if(!e.target.className.includes('clearButton')){
                if( this.state.filterCategory === filterCategory){
                    this.setState({ filterCategory: ''});
                } else{
                    this.setState({ filterCategory: filterCategory })
                }
            }
        }
    }

    /**
     * Clear filter category
     * @param {String}
     * @return {Void}
     */
    clearFilter = filterCategory => {
        if(filterCategory === 'Categories') {
            this.setState({ selectedCategory: 0});
            this.props.router.history.push('/db-archive');
        } else {
            this.setState({ ['selected'+filterCategory+'s']: [] })
        }
    }

    /**
     * Clear all filter categories
     * @param {String}
     * @return {Void}
     */
    clearAllFilters = () => {
        this.setState({
            filterCategory: '',
            selectedCategory: 0,
            selectedSizes: [],
            selectedUses: [],
            selectedColours: [],
            selectedFeatures: [],
        }, () => {
            if(window.location.href !== '/db-archive'){
                this.props.router.history.push('/db-archive');
            }
        })
    }

    /**
     * Renders the categories
     * @return {Array}
     */
    renderCategories = () => {
        let { data } = this.state.fields.categories;
        let { selectedCategory } = this.state;

        if (data.length === 0) return null;

        let sortedCategories = data.slice(0);

        sortedCategories.sort(function(a, b) {
            if (a.sort < b.sort) return -1;
            if (a.sort > b.sort) return 1;
            return 0;
        });

        let categories = [];

        categories.push(
            <Category
                key="all"
                active={selectedCategory === 0}
                onClick={() => this.props.router.history.push('/db-archive')}>
                <input type = 'checkbox' checked = { selectedCategory === 0 } readOnly></input>
                <span className="checkmark"></span>
                {Globals.get('mod_sm_all_products')}
            </Category>
        );

        sortedCategories.forEach((item, key) => {
            if (item.route.length > 0) {
                return categories.push(
                    <Category key={key}
                        active={item.id === selectedCategory}
                        onClick={() => this.selectCategory(item)}>
                            <input type = 'checkbox' checked = { selectedCategory === item.id } readOnly></input>
                            <span className="checkmark"></span>
                            {item.meta_fields.name}
                    </Category>
                );
            }
        });
        return categories;
    }

    /**
     * Sets active category
     * @return {Void}
     */
    selectCategory = categoryItem => {
        if(categoryItem.id !== this.state.selectedCategory){
            this.setState({ selectedCategory: categoryItem.id });
            // this.props.router.history.push('/' + categoryItem.route[0].url);
        } else {
            this.setState({ selectedCategory: 0 });
            this.props.router.history.push('/db-archive');
        }
    }

    /**
     * Renders the selected filter category
     * @param {Array}
     * @return {JSX}
     */
    renderSelectedFilterCategory = (itemNames, filterCategory) => {
        let { selectedCategory } = this.state;
        let items = [];
        if (itemNames.length === 0) return null;

        let sortedCategories = itemNames.slice(0);

        sortedCategories.sort(function(a, b) {
            if (a.sort < b.sort) return -1;
            if (a.sort > b.sort) return 1;
            return 0;
        });

        let activeTest;
        (filterCategory === 'Categories') ?
        activeTest = selectedCategory :
        activeTest = this.state['selected'+filterCategory+'s'];

        sortedCategories.map((item, key) => {
            return items.push(
                <Category
                    key={key}
                    onClick={() => this.onClickItem(item, filterCategory)}
                    active={typeof item === 'string' ? activeTest.includes(item) : activeTest.includes(item.id)}
                >
                    <input type = 'checkbox' checked = {typeof item === 'string' ? activeTest.includes(item) : activeTest.includes(item.id)} readOnly></input>
                    <span className="checkmark"></span>
                    {typeof item === 'string' ? item : item.name}
                </Category>
            )
        });
        return items;
    }

    /**
     * Renders color options
     * @param {Array}
     * @return {JSX}
     */
    renderColors = colors => {
        const { selectedColours } = this.state;
        return colors.map((item, key) => (
            <Color
                key={key}
                onClick={() => this.onClickItem(item.color_text, 'Colour')}
                active={selectedColours.includes(item.color_text)}
                background = { item.color_hex }
                backgroundUrl = { item.color_img }
                isDark = { item.isDark }
            >
                <input type = 'checkbox' checked = {selectedColours.includes(item.color_text)} readOnly></input>
                <span className="checkmark"></span>
                <span>{item.color_text}</span>
            </Color>
        ))
    }

    /**
     * Handles selected item in filter category
     * @param {String}
     * @return {Void}
     */
    onClickItem = (item, filterCategory) => {
        if(!(this.state['selected'+filterCategory+'s']).includes(item)){
            let joined = this.state['selected'+filterCategory+'s'].concat(item);
            this.setState({
                filterCategory: '',
                ['selected'+filterCategory+'s']: joined
            });
        } else {
            this.setState({
                ['selected'+filterCategory+'s']: this.state['selected'+filterCategory+'s'].filter(function(i) { return i !== item }),
                filterCategory: ''
            });
        }
    }

    /**
     * Renderer
     * @return {JSX}
     */
    render() {
        let { isMobile, isMobilefilter, toggleButtonText, filterCategory, sizes, uses, colors, features } = this.state;

        // const RenderProducts = this.renderProducts();

        // let numberOfProducts;
        // if (RenderProducts !== null) {
        //     let renderedProducts = [];
        //     RenderProducts.props.children.forEach(rp => {
        //         if(rp !== null) renderedProducts.push(rp);
        //     })
        //     numberOfProducts = renderedProducts.length;
        // } else {
        //     numberOfProducts = 0;
        // }

        return (
            <Wrapper>
                {!isMobile &&
                    <FilterOuterWrapper>
                        <FilterWrapper>
                            <FilterCategories>
                                {this.renderfilter()}
                            </FilterCategories>
                        </FilterWrapper>

                        { filterCategory === 'Categories' &&
                            <Categories>
                                {this.renderCategories()}
                            </Categories>
                        }
                        { filterCategory === 'Size' &&
                            <Categories>
                                {this.renderSelectedFilterCategory(sizes, filterCategory)}
                            </Categories>
                        }
                        { filterCategory === 'Use' &&
                            <Categories>
                                {this.renderSelectedFilterCategory(uses, filterCategory)}
                            </Categories>
                        }
                        { filterCategory === 'Colour' &&
                            <Categories>
                                {this.renderColors(colors)}
                            </Categories>
                        }
                        { filterCategory === 'Feature' &&
                            <Categories>
                                {this.renderSelectedFilterCategory(features, filterCategory)}
                            </Categories>
                        }
                    </FilterOuterWrapper>
                }
                {isMobile &&
                    <MobileFilterWrapper>
                        <NumberOfProductsAndButton>
                        {/*<NumberOfProducts> {numberOfProducts} results</NumberOfProducts>*/}
                        <ToggleFilterButton onClick={this.toggleMobileFilter} rotateImg={!isMobilefilter}>
                            <img alt="Close" src={ CloseIcon } />
                            <span> {toggleButtonText }</span>
                        </ToggleFilterButton>
                        </NumberOfProductsAndButton>
                        { isMobilefilter &&
                            <React.Fragment>
                                <FilterCategories>
                                    {this.renderMobilefilter()}
                                </FilterCategories>
                                <ToggleFilterButton onClick={this.toggleMobileFilter} rotateImg={!isMobilefilter} center>
                                    <img alt="Close" src={ CloseIcon } />
                                    <span> {toggleButtonText }</span>
                                </ToggleFilterButton>
                            </React.Fragment>
                        }
                    </MobileFilterWrapper>
                }
                <InnerWrapper>
                    {this.renderProducts()}
                </InnerWrapper>
            </Wrapper>
        )
    }
}

export default ArchiveProductsList;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const StyledProductsWrapper = styled.div`
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    text-align: center;

    ${media.lessThan('medium')`
        margin: 0;
    `}
`

const InnerWrapper = styled.div`
    width: calc(100% - 260px);
    max-width: 1180px;
    margin: 0 auto;

    ${media.lessThan('huge')`
        width: calc(100% - 180px);
    `}

    ${media.lessThan('large')`
        width: calc(100% - 160px);
    `}

    ${media.lessThan('medium')`
        width: calc(100% - 100px);
    `}

    ${media.lessThan('small')`
        width: calc(100% - 30px);
    `}
`

const Categories = styled.ul`
    margin: 20px 0;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-wrap: wrap;
    padding-bottom: 20px;
    border-bottom:  1px solid #d1d1d1;
    ${media.lessThan('small')`
        justify-content: flex-start;
        margin: 0;
        border-bottom: none;
        border-top: 1px solid #ddd;
        padding-top: 10px;
    `}
`

const Category = styled.li`
    padding: 0;
    display: inline-block;
    margin: 0 25px 0;
    font-family: 'Circular Pro';
    font-size: 14px;
    white-space: nowrap;
    color: ${Colors.black};
    cursor: pointer;
    position: relative;

    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: -25px;
        height: 20px;
        width: 20px;
        background-color: none;
        border-radius: 50%;
        border: 1px solid;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: url(${CheckIcon}) center center no-repeat;
    }

    ${media.lessThan('medium')`
        width: calc(50% - 25px);
        margin: 5px 0 5px 25px;
    `}
`
const ArrowDown = styled.img`
    opacity: 1;
    ${media.lessThan('medium')`
        display: none;
    `}
`
const ArrowUp = styled.img`
    opacity: 0;
    ${media.lessThan('medium')`
        display: none;
    `}
`
const Close = styled.img`
    display: none;
`

const FilterOuterWrapper = styled.div`
    margin-bottom: 20px;
    width: calc(100% - 260px);
    max-width: 1180px;
    margin: 0 auto;
    position: sticky;
    top: 89px;
    z-index: 1;
    background: white;
    opacity: 0.96;

    ${media.lessThan('medium')`
        width: 100%;
    `}
`

const FilterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 42px;
    position: relative;

    ${media.lessThan('medium')`
        flex-direction: row;
        justify-content: flex-start;
    `}
`

const FilterButton = styled.button`
    border: 1px solid ${Colors.gray};
    padding: 10px;
    margin-right: 20px !important;
    background: none;
    font-family: 'Circular Pro Book' !important;
    font-size: 14px !important;

    :hover{
        cursor: pointer;
    }
`

const FilterCategories = styled.ul`
    list-style: none;
    padding: 0;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    ${media.lessThan('medium')`
        border-bottom: 1px solid #ddd;
        margin: 0 25px 0;
    `}
`

const FilterCategory = styled.li`
    padding: 0;
    display: inline-block;
    font-family: 'Circular Pro';
    font-size: 14px;
    white-space: nowrap;
    align-self: center;
    margin: 0 25px 0;

    ${media.lessThan('medium')`
        width: 100%;
        margin: 0;
    `}

    img{
        position: absolute;
        padding: 10px 0 10px 5px;
    }

    :hover{
        cursor: pointer;
    }

    ${p => p.active && css`
        color: black;
        ${ArrowUp}{
            opacity: 1;
        }
        ${ArrowDown}{
            opacity: 0;
        }
        ${Close}{
            display: none;
        }
    `}

    ${p => p.hasValue && !p.active && css`
        color: ${Colors.blue};
        ${ArrowUp}{
            opacity: 0;
        }
        ${ArrowDown}{
            opacity: 0;
        }
        ${Close}{
            display: inline-block;
            opacity: 1;
            ${media.lessThan('medium')`
                display: none;
            `}
        }
    `}
`

const Color = styled.li`
    padding: 0;
    display: inline-block;
    margin: 0 25px 0;
    font-family: 'Circular Pro';
    font-size: 12px;
    white-space: nowrap;
    color: ${Colors.black};
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    .checkmark {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 1px solid ${Colors.gray};
        ${p => p.background && css`
            background-color: ${p.background};
        `}
        ${p => p.backgroundUrl && css`
            background: url(${p.backgroundUrl}) center center;
            background-size: cover;
        `}
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: url(${CheckIcon}) center center no-repeat;
        ${p => p.isDark === true && css`
            filter: brightness(0);
        `}
        ${p => p.isDark === false && css`
            filter: brightness(0) invert(1);
        `}
    }

    ${media.lessThan('medium')`
        width: calc(100% / 3);
        margin: 5px 0;
    `}
`

const MobileFilterWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`

const NumberOfProductsAndButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 25px;
`

const NumberOfProducts = styled.div`
    color: ${Colors.darkGray};
    font-family: 'Circular Pro Book';
`

const ToggleFilterButton = styled.div`
    color: ${Colors.blue};
    border: 1px solid;
    text-transform: uppercase;
    font-family: 'Circular Pro Book';
    font-size: 14px;
    padding: 10px;
    width: 140px;
    align-self: flex-end;
    text-align: center;
    margin: 10px 0;

    :hover{
        cursor: pointer;
    }

    ${p => p.center && css`
        align-self: center;
        margin: 20px 0 40px 0;
    `}

    img{
        margin-right: 5px;
        ${p => p.rotateImg && css`
            transform: rotate(45deg);
        `}
    }
`
