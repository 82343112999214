import React from 'react';
import { createGlobalStyle } from 'styled-components';
import Globals from 'assets/styles/Globals';
import CarbonOffsetPopup from '../CarbonOffsetPopup'
import DbBlackPopup from '../DbBlackPopup'
import SkipToLandmarks, {MAIN_CONTENT_ID} from "../SkipToLandmarks";

const GlobalStyles = createGlobalStyle`
    ${Globals};

    body {
        padding-top: 0 !important;
    }
`;

class Layout extends React.Component {

    /**
     * Renderer
     */
    render () {

      return (
        <React.Fragment>
          <SkipToLandmarks />
          <DbBlackPopup/>
          <CarbonOffsetPopup/>

          <GlobalStyles/>
          <main id={MAIN_CONTENT_ID}>
            { this.props.children }
          </main>
        </React.Fragment>
      )
    }
}

export default Layout;

