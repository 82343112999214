import { ApiInstance, Globals } from 'kbcms-react'
import React from 'react'
import BaseModule from 'modules/DBBaseModule'
import styled from 'styled-components'
import OptionsWrapper from 'components/OptionsWrapper'
import Button from '../../components/Button'
import Input from '../../components/Input'

class OrderTracking extends BaseModule {

    static getName () { return 'Order Tracking' }

    constructor (props) {
        super(props)

        this.state = {
            orderId: '',
            authToken: null,
            fields: {},
        }
    }

    /**
     * Custom module options
     * @return {Array}
     */
    static options () {
        return super.options().concat([

        ])
    }

    componentDidMount () {
        const script = document.createElement('script')
        script.async = true
        script.src = process.env.RAZZLE_INGRID_SCRIPT_URL
        document.body.appendChild(script);
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    submit(e) {
        e.preventDefault()
        const { orderId } = this.state

        ApiInstance.getIngridToken(orderId).then(res => {
            this.setState({
                ingridAuthToken: res.data.token,
            }, () => {
                const node = document.querySelectorAll('#ingrid_widget')[0];
                node.innerHTML = ``

                this.loadWidget()
            })
        })
    }

    loadWidget() {
        const { orderId, ingridAuthToken } = this.state
        const siteId = process.env.RAZZLE_INGRID_SITE_ID

        window.IngridTrackingWidget.config({
            elementId: "ingrid_widget",
            externalId: orderId,
            siteId: siteId,
            locale: 'en-GB',
            authToken: ingridAuthToken,
        });
    }

    render () {
        const { options, editMode } = this.props
        const { ...rest } = options
        const { orderId } = this.state

        return (
            <OptionsWrapper options={ options } editMode={ editMode }>
                <Wrapper>
                    <Form onSubmit={ (e) => this.submit(e) }>
                        <Input name="orderId" value={ orderId } onChange={ e => this.handleInputChange(e) } label={ Globals.get('mod_order_tracking_input_label', 'Order ID') }/>
                        <Button blue onClick={ (e) => this.submit(e) }>{ Globals.get('mod_order_tracking_submit', 'Submit') }</Button>
                    </Form>
                </Wrapper>
                <div id={"ingrid_widget"}/>
            </OptionsWrapper>
        )
    }
}

export default OrderTracking

const Wrapper = styled.section`
  max-width: 500px;
  padding: 24px;
  font-family: 'Circular Pro Book', sans-serif;
  margin: 0 auto;
  display: flex;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
`