import React from 'react';
import store from '../store'

const defaultState = {
    loaded: false,
    redirectPromptDisplayed: false,
    countries: {},
};

const LOADED_COUNTRIES = 'LOADED_COUNTRIES';
const SET_REDIRECT_PROMPT_DISPLAYED = 'SET_REDIRECT_PROMPT_DISPLAYED';

export const CountriesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOADED_COUNTRIES:
            return {
                ...{},
                ...state,
                ...{
                    loaded: true,
                    countries: action.countries ? action.countries: state.countries,
                }
            };
        case SET_REDIRECT_PROMPT_DISPLAYED:
            return {
                ...{},
                ...state,
                ...{
                    redirectPromptDisplayed: action.value,
                }
            }
        default:
            return state;
    }
};

const CountriesActions = {
    setCountries: (countries) => {
        store.dispatch({
            type: LOADED_COUNTRIES,
            loaded: true,
            countries: countries,
        });
    },
    setRedirectPromptDisplayed: (value) => {
        store.dispatch({
            type: SET_REDIRECT_PROMPT_DISPLAYED,
            value: value,
        })
    }
};

export default CountriesActions;