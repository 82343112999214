import React from 'react';
import styled, { css } from 'styled-components';
import CloseIcon from 'images/db-close.svg';
import { default as media } from 'assets/styles/Breakpoints';
import ButtonNormalized from '../ButtonNormalized'
import Escape from '../Escape'

export default class Drawer extends React.Component {

    onClose = (e) => {
        e.preventDefault()

        const { onClose } = this.props

        if(typeof onClose === 'function') {
            onClose()
        }
    }

    render () {
        const { visible, header, noLayout, children, footer } = this.props

        return (
            <Escape onEscape={ this.onClose }>
                <DrawerElement role="dialog" visible={ visible }>
                    <FadedBackground onClick={ this.onClose }/>
                    { (noLayout === undefined || noLayout === false) && (
                        <DrawerWrapper>
                            <DrawerHeader>
                                { header }
                                <StyledCloseButton href="#" onClick={ this.onClose }>
                                    <CloseButton src={ CloseIcon } alt="Close"/>
                                </StyledCloseButton>
                            </DrawerHeader>
                            <DrawerContent>{ children }</DrawerContent>
                            <DrawerFooter>{ footer }</DrawerFooter>
                        </DrawerWrapper>
                    ) }

                    { noLayout !== undefined && noLayout === true && (
                        <React.Fragment>
                            { children }
                            <StyledCloseButton href="#" onClick={ this.onClose }>
                                <CloseButton src={ CloseIcon } drawerMode alt="Close"/>
                            </StyledCloseButton>
                        </React.Fragment>
                    ) }
                </DrawerElement>
            </Escape>
        )
    }
}

const DrawerElement = styled.aside`
    display: ${p => p.visible ? 'flex' : 'none'};
    max-height: 100%;
    position: fixed;
    top: 0; 
    right: 0;
    bottom: 0;
    width: 380px;
    height: 100%;
    min-width: 260px;
    max-width: 380px;
    margin: 0;
    z-index: 100;
    border: 0;
    background: #fff;
    box-shadow: 0 3px 3px rgb(0,0,0,.15);
    text-align: left;

    ${media.lessThan('medium')`
        width: 100%;
        max-width: unset; 
    `}
`

const FadedBackground = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #000;
    opacity: .25;
`

const DrawerWrapper = styled.div`
    z-index: 101;
    display: flex;
    flex: 1;
    flex-direction: column;
    background: #fff;
    padding: 20px;
    max-height: 100%;
`

const DrawerHeader = styled.div`
    padding: 0 0 20px;
    margin: 0;
    border-bottom: 1px solid #e3e0d6;
    position: relative;
    
    h3 {
        font-family: 'Circular Pro', sans-serif;
        font-size: 16px;
        display: inline-block;
    }
`

const StyledCloseButton = styled(ButtonNormalized)`
    &:focus {
        img {
            outline: auto;
        }
    }
`

const CloseButton = styled.img`
    float: right;
    position: relative;
    top: 13px;
    cursor: pointer;

    ${p => p.drawerMode && css`
        position: absolute;
        top: 23px;
        right: 20px;
        z-index: 999;
    `}
`

const DrawerContent = styled.div`
    padding: 0;
    margin: 0;
    height: auto;
    flex: 1;
    display: flex;
    overflow-y: auto;
    text-align: left;

    .wysiwig-content {
        margin-top: 25px;
    }
`

const DrawerFooter = styled.div`
    text-align: left;
    font-size: 13px;
    padding-top: 15px;
    margin-top: 20px;
    border-top: 1px solid #eae8e0;
`

