import React from "react";

import styled, { css } from 'styled-components';
import Colors from 'assets/Colors'; 

class Button extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { children, onClick, warning, className, white, disabled, active } = this.props;

      return <StyledButton 
        className={className}
        onClick={onClick}
        warning={warning}
        white={white}
        disabled={disabled}
        active={active}>
        {children}
      </StyledButton>;
    }
}

export default Button;

const StyledButton = styled.button`
  display: inline-flex;
  cursor: pointer;
  margin: 1em .5em;
  padding: .50em .8em;
  min-width: 4em;
  height: 50px; 

  font-size: 16px; 
  line-height: 22px; 
  
  outline: none;
  -webkit-appearance: none;
  text-align: center;
  
  background-color: ${Colors.black};
  color: ${Colors.white};
  font-weight: 500;
  /*border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -2px rgba(0, 0, 0, 0.12);*/
  
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  justify-content: center; 

  &:hover {
    opacity: 0.75; 
  }
  
  ${p => p.active === true && css`
    opacity: 0.75; 
  `}

  ${p => p.white === true && css`
    background-color: ${Colors.white}; 
    color: ${Colors.black}; 
    border: 1px solid ${Colors.black};
    ${p => p.disabled !== true && css`
      &:hover{
        opacity: 1; 
        background-color: ${Colors.black};
        color: ${Colors.white}; 
      }
    `}
    ${p => p.active === true && css`
      opacity: 1; 
      background-color: ${Colors.black};
      color: ${Colors.white}; 
    `}
  `}

  ${p => p.disabled === true && css`
    background-color: ${Colors.dividers}; 
    color: ${Colors.grey}; 
    border: none; 
  `}
  
  ${ props => props.warning === true && css`
    background-color: #E10050;
    &:hover {
     background-color: #9D0038;
    }
  ` }
`