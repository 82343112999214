import React from 'react';
import { Editor, NewMedia } from 'kbcms-react';
import loadable from '@loadable/component'
import styled, { css } from 'styled-components';
import BaseModule from 'modules/DBBaseModule';
import isServer from '../../isServer'

const LaunchTimer = loadable(() => import('kbcms-react'), {
    fallback: (() => <div/>)(),
});

class TakeOverModule extends BaseModule {

    constructor(props) {
        super(props);

        this.state = {
            isPlaying: false,
            fields: {
                ...{
                    media: null,
                    mobile: null,
                    muted: true,
                    forceNativeVideoAspectRatio: true,
                    text: 'Default title',
                    countDownDate: new Date()

                }, ...props.fields
            },
            isMobile: isServer ? true : window.innerWidth <= 992,
        }
    }


    componentDidMount() {
        this.prepareData().then(state => {
            this.setState({
                ...state,
                ...{
                    isMobile: isServer ? true : window.innerWidth <= 992,
                }
            });
        });
        window.addEventListener('resize', this.checkIsMobileOnResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkIsMobileOnResize)
    }

    /**
     * Module display name
     */
    static getName() { return "Takeover Module" }


    /**
     * It will be rendered, but not available for pickin'
     */
    static hidden() { return true }

    static options() {
        return super.options().concat([
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
            {
                name: 'launchTimer',
                label: 'Launch timer',
                type: 'switch'
            }
        ]);
    }


    /**
     * If it should be deletable within the pagebuilder.
     */
    static deletable() { return true }

    checkIsMobileOnResize = () => {
        const { isMobile } = this.state;
        if (window.innerWidth <= 992) {
            !isMobile && this.setState({ isMobile: true })
        } else {
            isMobile && this.setState({ isMobile: false })
        }
    }


    handleShouldStartPlaying = () => {
        if (!this.state.isPlaying) {
            this.setState({ isPlaying: true })
        }
    }

    render() {
        let { editMode, options } = this.props;
        let { isMobile } = this.state;
        let { text, countDownDate } = this.state.fields;

        return (
            <React.Fragment>
                    <TakeOverModuleWrapper>
                        <StyledPrimary
                            module={this}
                            field={'media'}
                            editPosition={'nw'}
                            aspectRatio={16/9}
                            play={this.handleShouldStartPlaying}
                            playing={this.state.isPlaying}
                            overlay={options.overlay}
                            fill
                            videoOptions={{
                                onToggleMute: () => this.setState({
                                    ...this.state,
                                    fields: {
                                        ...this.state.fields,
                                        muted: !this.state.fields.muted
                                    }
                                }),
                                onToggleNativeAspectRatio: () => this.setState({
                                    ...this.state,
                                    fields: {
                                        ...this.state.fields,
                                        forceNativeVideoAspectRatio: !this.state.fields.forceNativeVideoAspectRatio
                                    }
                                }),
                                forceNativeVideoAspectRatio: this.state.fields.forceNativeVideoAspectRatio,
                                muted: this.state.fields.muted,
                                autoplay: this.state.fields.muted
                            }}
                            types={['images', 'videos']}>
                            <Content>
                                <StyledEditor
                                    field={'text'}
                                    data={text}
                                    disabled={!editMode}
                                    module={this}
                                />
                                {options.launchTimer && (
                                    <StyledLaunchTimer
                                        field={'countDownDate'}
                                        module={this}
                                        editMode={editMode}
                                        countDownDate={countDownDate}
                                        onDateChange={(newDate) => this.setState({
                                            ...this.state,
                                            fields: {
                                                ...this.state.fields,
                                                countDownDate: newDate
                                            }
                                        })}
                                    />
                                )}
                            </Content>
                        </StyledPrimary>
                        {editMode && (
                            <StyledMobile
                                module={this}
                                absolutedStyles
                                backgroundVideo={true}
                                field={'mobile'}
                                editPosition={'nw'}
                                fill
                                aspectRatio={9/19}
                                types={['images']}
                                editMode={editMode}
                                overlay={options.overlay}
                            />
                        )}
                        {!editMode && isMobile && (
                            <StyledMedia
                                module={this}
                                field={'mobile'}
                                fill
                                editPosition={'nw'}
                                aspectRatio={9/19}
                                types={['images']}
                                overlay={options.overlay}
                                editMode={editMode}>
                                <Content>
                                    <StyledEditor
                                        field={'text'}
                                        data={text}
                                        disabled={!editMode}
                                        module={this}
                                    />
                                    {options.launchTimer && (
                                        <StyledLaunchTimer
                                            className={'dumb-test'}
                                            field={'countDownDate'}
                                            module={this}
                                            editMode={editMode}
                                            countDownDate={countDownDate}
                                            onDateChange={(newDate) => this.setState({
                                                ...this.state,
                                                fields: {
                                                    ...this.state.fields,
                                                    countDownDate: newDate
                                                }
                                            })}
                                        />
                                    )}
                                </Content>
                            </StyledMedia>
                        )}
                    </TakeOverModuleWrapper>
            </React.Fragment>
        );
    }
}

export default TakeOverModule;


const TakeOverModuleWrapper = styled.section`
    width: 100%;
    height: calc(100vh - 89px);
    height: calc(calc(var(--vh, 1vh) * 100) - 89px);
    position: relative;
    z-index: 0;
    overflow: hidden;

    h1, h2, .h3-div, .h4-div, p {
        color: #fff;
    }
`

const StyledMedia = styled(NewMedia)`
    height: 100%;
    width: 100%;
`

const Parallax = styled.div`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    height: calc(100vh - 90px);
    height: calc(calc(var(--vh, 1vh) * 100) - 90px);
`;

const Content = styled.div`
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    max-width: 980px;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);

    ${props => props.isMobile === true && css`
        top: 40%;
    `}
`

const StyledLaunchTimer = styled(LaunchTimer)`
    color: #fff;
`

const StyledEditor = styled(Editor)`
    margin-bottom: 33px;
    text-align: center;
    flex: 1;
    > div {
      text-align: center;
    }

`

const StyledPrimary = styled(NewMedia)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 992px) {
      display: none;
    }
`

const StyledMobile = styled(NewMedia)`
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    ${ props => !props.editMode && css`
      display: none;
      @media screen and (max-width: 992px) {
        display: block;
      }
    `}

    ${ props => props.editMode && css`
      top: 70%;
      left: 10%;
      height: 30%;
      width: 20%;
    `}
`
