import React from 'react';
import ApiManager from 'ApiManager';

const api = new ApiManager();

export default new (class EntriesManager {

    all = () => {
        return new Promise(function(resolve, reject) {
            api.getEntries().then(result => {
                resolve(result.data);
            }).catch(error => {
                reject();
            });
        })
    }

    find = entryType => {
        return new Promise(function(resolve, reject) {
            api.getEntries(entryType).then(result => {
                resolve(result.data);
            }).catch(error => {
                reject();
            })
        });
    }
})
