import store from 'cms/store';
import ApiManager from 'ApiManager';
import produce from 'immer'

const Api = new ApiManager();

const defaultState = {
    currentEntryId: null,
    modalOpen: false,
    variations: [],
    isLoading: false
};

const TOGGLE_SELECTED_VARIATION = 'TOGGLE_SELECTED_VARIATION';
const OPEN_VARIATION_SELECTOR_MODAL = 'OPEN_VARIATION_SELECTOR_MODAL';
const VARIATIONS_FETCHED = 'VARIATIONS_FETCHED';
const CLOSE_VARIATION_SELECTOR_MODAL = 'CLOSE_VARIATION_SELECTOR_MODAL';
const SET_ACTIVE_ENTRY_TYPE = 'SET_ACTIVE_ENTRY_TYPE';
const SELECT_ENTRY = 'SELECT_ENTRY';

export const VariationSelectorReducer = (state = defaultState, action) => {
    switch (action.type) {
        case TOGGLE_SELECTED_VARIATION:
            return produce(state, draftState => {
                const { variation } = action;
                const variationIndex = draftState.variations.findIndex(a => a.id === variation.id)
                if (draftState.variations[variationIndex].selected) {
                    draftState.variations[variationIndex].selected = false;
                } else {
                    draftState.variations[variationIndex].selected = true;
                }
                return draftState;
            })
        case VARIATIONS_FETCHED:
            return {
                ...state,
                isLoading: false,
                variations: action.variations
            }
        case OPEN_VARIATION_SELECTOR_MODAL:
            return {
                ...state,
                currentEntryId: action.parentId,
                modalOpen: true,
                isLoading: true
            };
        case CLOSE_VARIATION_SELECTOR_MODAL:
            return defaultState;
        case SELECT_ENTRY:
        default:
            return state;
    }
};

const VariationSelectorActions = {
    openModal: (selectedVariations, parentId) => {

        store.dispatch({ type: OPEN_VARIATION_SELECTOR_MODAL, parentId });

        Api.getOrphanEntries().then((response) => {
            const orphans = response.data;

            store.dispatch({
                type: VARIATIONS_FETCHED,
                variations: [...selectedVariations.map(x => ({ ...x, selected: true })), ...orphans]
            })
        });

    },
    closeModal: () => {
        store.dispatch({
            type: CLOSE_VARIATION_SELECTOR_MODAL,
        });
    },
    setActiveEntryType: (activeEntryType) => {
        store.dispatch({
            type: SET_ACTIVE_ENTRY_TYPE,
            activeEntryType: activeEntryType,
        });
    },
    selectEntry: (key, children) => {
        let { options, callback } = store.getState().entry_selector;

        if (options.multiple) {
            store.dispatch({
                type: SELECT_ENTRY,
                key: key,
                children: children,
            });
        } else {
            callback([{ id: key, children: children ? [children] : [] }]);

            store.dispatch({
                type: 'CLOSE_ENTRY_SELECTOR_MODAL',
            });
        }
    },
    applyEntries: () => {
        let { callback, selectedEntries } = store.getState().entry_selector;

        callback(selectedEntries);

        store.dispatch({
            type: 'CLOSE_ENTRY_SELECTOR_MODAL',
        });
    }
};

export default VariationSelectorActions;
