import React from 'react';
import styled, { css } from 'styled-components';
import Colors from 'assets/styles/Colors';
import Down from 'images/arrow_down_grey.svg';

class Dropdown extends React.Component {

    state = {
        listOpen: false,
        dropUp: false,
    }

    /**
     * Component did mount
     * @return {Void}
     */
    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }

    /**
     * Component will unmount
     * @return {Void}
     */
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    /**
     * Toggles dropdown visibility
     * @param {Object} event
     */
    toggleList = event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }));

        const windowHeight = window.innerHeight;
        const clickHeight = this.node.getBoundingClientRect().bottom;

        this.setState({
            dropUp: windowHeight - clickHeight < 150,
        });

    }

    /**
     * On select dropdown item
     * @param {Object} event
     * @param {String} key
     * @return {Void}
     */
    onSelect = (event, key) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ listOpen: false }, () => {
            if (key !== this.props.selectedItem) {
                this.props.onSelect(key); 
            }
        });
    }

     /**
     * Handles clicks outside component
     * @param {Object} event 
     * @return {Void}
     */
    handleClick = event => {
        if (this.node && !this.node.contains(event.target)) {
            this.setState({ listOpen : false });
        }
    }


    /**
     * Returns the selected item
     * @return {Object}
     */
    getSelectedItem = () => {
        const { items, selectedItem } = this.props

        let item = items.find(item => {
            if (selectedItem === item.key) {
                return true
            }
            return false
        });

        if(typeof item === 'undefined' || typeof item.display === 'undefined') {
            return null
        }

        return item.display;
    }


    /**
     * Returns dropdown items
     * @return {Mixed} null|JSX n
     */
    getItems = () => {
        let { items } = this.props;

        return items.map(item => {
            return (
                <DropdownItem key={item.key} onClick={e => this.onSelect(e, item.key)}>{item.display}</DropdownItem>
            )
        });
    }


    /**
     * Renderer
     * @return {JSX}
     */
    render() {
        let { listOpen, dropUp } = this.state;
        let { items } = this.props;
        
        if (items === undefined || items.length === 0) return <div />;

        const item = this.getSelectedItem()

        return (
            <StyledDropdown onClick={this.toggleList} ref={node => this.node = node}>
                <SelectedItemWrapper>
                    <SelectedItem>
                        {item !== null ? item : <ItemPlaceholder/>}
                    </SelectedItem>
                    <Arrow />
                </SelectedItemWrapper>
                {listOpen && <DropdownItems open={listOpen} dropUp={dropUp} >{this.getItems()}</DropdownItems>}
            </StyledDropdown>
        )
    }
}

export default Dropdown;

const SelectedItemWrapper = styled.div`
    display: flex;
`
const SelectedItem = styled.div`
    flex: 1;
`

const Arrow = styled.div`
    width: 30px;
    background: url(${Down}) center center no-repeat;
    background-size: 10px; 
`

const ItemPlaceholder = styled.div`
    display: flex;
    padding: 15px 6px;
`

const DropdownItems = styled.ul`
    overflow-y: overlay;
    background-color: #f8f8f8;
    max-height: 150px; 
    list-style: none;
    padding: 0;
    position: absolute;
    top: calc(100% + 1px);
    width: calc(100% + 2px);
    left: -1px;
    margin: 0;
    z-index: 80;

    ${p => p.open === false && css`
        display: none;
    `}

    ${p => p.dropUp && css`
        top: initial;
        bottom: 100%
        border-bottom: 1px solid ${Colors.darkGray};
    `}
`

const DropdownItem = styled.li`
    padding-right: 30px;

    &:hover{
        text-decoration: underline; 
    }
`

const StyledDropdown = styled.div`
    background-color: none;
    border-bottom: 1px solid ${Colors.darkGray};
    width: 100%;
    cursor: pointer; 
    position: relative;
    * {
        box-sizing: border-box;
    }
`