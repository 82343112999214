import React from 'react'
import loadable from '@loadable/component'
import styled from 'styled-components'
import BaseModule from 'modules/DBBaseModule'
import OptionsWrapper from 'components/OptionsWrapper'
import { Editor } from 'kbcms-react'
import Colors from '../../assets/styles/Colors'
import { default as media } from 'assets/styles/Breakpoints';

const LaunchTimer = loadable(() => import('../../cms/components/LaunchTimer'), {
  fallback: (() => <div/>)(),
});

class Countdown extends BaseModule {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            fields: {
                countDownDate: new Date(),
                ...props.fields
            }
        }
    }
    
    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Countdown" }

  static options() {
    return super.options().concat([
      {
        name: 'enablePreText',
        label: 'Enable text above timer',
        type: 'switch'
      },
      {
        name: 'enablePostText',
        label: 'Enabled text below timer',
        type: 'switch'
      }
    ]);
  }

    /**
    * Renderer
    */
    render() {
        let { options, editMode } = this.props
        let { preText, countDownDate, postText } = this.state.fields

        return (
          <OptionsWrapper options={ options } editMode={ editMode }>
            <CountdownWrapper>
                {options.enablePreText &&
                  <StyledEditor
                    field={ 'preText' }
                    data={ preText }
                    disabled={ !editMode }
                    disableLeftAlignButton
                    module={ this }
                  />}
                <StyledLaunchTimer
                  field={ 'countDownDate' }
                  module={ this }
                  editMode={ editMode }
                  countDownDate={ countDownDate }
                  onDateChange={ (newDate) => this.setState({
                      ...this.state,
                      fields: {
                          ...this.state.fields,
                          countDownDate: newDate,
                      },
                  }) }
                />
              {options.enablePostText &&
                <StyledEditor
                  field={ 'postText' }
                  data={ postText }
                  disabled={ !editMode }
                  disableLeftAlignButton
                  module={ this }
                />}
            </CountdownWrapper>
          </OptionsWrapper>
        )
    }
}

export default Countdown

const CountdownWrapper = styled.div`
  text-align: center;
  padding: 0 20px;

  ${media.lessThan('small')`
    padding: 0 15px;
  `}
`

const StyledEditor = styled(Editor)`
    max-width: 980px;
    margin: 0 auto;
`

const StyledLaunchTimer = styled(LaunchTimer)`
    position: relative;
    padding: 0 30px;
    width: calc(100% - 260px);
    margin: 0 auto;
    
    .countdown {
        color: ${Colors.black};
    }
`
