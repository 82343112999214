import React from 'react'
import BaseModule from 'modules/DBBaseModule';
import styled from 'styled-components';
import FacebookIcon from 'images/db-facebook.svg';
import TwitterIcon from 'images/db-twitter.svg';
import PinterestIcon from 'images/db-pinterest.svg';
import env from '../../helpers/env'

class SocialModule extends BaseModule {

    constructor(props) {
        super(props);

        // If you use the key "entryType" within a field it will try to auto populate with data.
        this.state = {
            fields: {
                ...{            
                }, ...props.fields
            }
        }
    }


    
    componentDidMount() {
        // prepare data will AJAX fetch data from API if it isnt already added by the server.
        this.prepareData().then(state => {

            // do something with state here.

            this.setState(state);
        });
    }


    /**
     * Module display name
     */
    static getName() { return "Social media share module" }



    render() {
        let currentPageURL = env.RAZZLE_PUBLIC_URL + this.props.router.location.pathname.substring(1);

        return (
            <Wrapper>
                <Label>SHARE</Label>
                <Item rel="nofollow" target="_blank" href={"https://www.facebook.com/sharer.php?u=" + currentPageURL}><img src={FacebookIcon} alt="Facebook" /></Item>
                <Item rel="nofollow" target="_blank" href={"https://twitter.com/intent/tweet?url=" + currentPageURL}><img src={TwitterIcon} alt="Twitter" /></Item>
                <Item rel="nofollow" target="_blank" href={"http://pinterest.com/pin/create/link/?url="+ currentPageURL}><img src={PinterestIcon} alt="Pinterest" /></Item>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`    
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
`

const Label = styled.span`
    font-family: 'Circular Pro Book';
    color: #d2d2d2;
    font-size: 14px;
    padding: 0 .5em;
    line-height: 1em;  
`

const Item = styled.a`  
    padding: 0 .5em;
    line-height: 1em;
`

export default SocialModule;
