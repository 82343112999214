import React from 'react';
import styled from 'styled-components';
import Colors from 'assets/styles/Colors';
import BaseModule from 'modules/DBBaseModule';
import OptionsWrapper from 'components/OptionsWrapper';
import { default as media } from 'assets/styles/Breakpoints';

class Divider extends BaseModule {
    state = {fields: {}}
    
    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Divider" }

    /**
    * Renderer
    */
    render() {
        let { options, editMode } = this.props;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <StyledDivider />
            </OptionsWrapper>
        )
    }
}

export default Divider


const StyledDivider = styled.div`
    position: relative;
    padding: 30px 0;
    height: 1px;
    width: calc(100% - 260px);
    margin: 0 auto;

    &::after {
        content: '';
        display: block;
        border-bottom: 1px solid ${Colors.lightGray};
        margin: 0 auto;
    }

    ${media.lessThan('huge')`
        width: calc(100% - 180px);
    `}
    ${media.lessThan('large')`
        max-width: 1200px;
        width: calc(100% - 160px);
    `}
    ${media.lessThan('medium')`
        max-width: 992px;
        width: calc(100% - 100px);
    `}
    ${media.lessThan('small')`
        max-width: 768px;
        width: calc(100% - 30px);  
    `}
`
