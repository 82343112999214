import React from 'react';
import PropTypes from 'prop-types';
import { IoMdAdd } from 'react-icons/io'
import loadable from '@loadable/component'
import styled, { css } from 'styled-components';
import Colors from 'assets/Colors';

import FileManagerActions from 'reducers/files';
import CropperActions from 'reducers/cropper';

import BaseModule from 'cms/components/BaseModule';
import IconButton from 'cms/components/Form/IconButton';
import Icon from 'cms/components/Icon';
import imgix from '../../../helpers/imgix'

const ReactPlayer = loadable(() => import('react-player'), {
    fallback: (() => <div/>)(),
});

const Pointers = loadable(() => import('cms/components/Media/Pointers'), {
    fallback: (() => <div/>)(),
});

class Media extends React.Component {
    constructor(props) {
        super(props);

        this.defaultFile = {
            original: '',
            focus_x: 0,
            focus_y: 0,
        };

        this.state = {
            ...props,
            videoWidth: null,
            videoHeight: null,
        };

        this.pointersRef = React.createRef();
        this.videoContainer = React.createRef();
        this.videoPlayer = React.createRef();

        let defaultVideoOptions = {
            controls: true,
            muted: false,
            autoplay: true
        };

        this.options = Object.assign({}, defaultVideoOptions, props.videoOptions ? props.videoOptions : {});

        this.resizeVideo = this.resizeVideo.bind(this);
    }

    componentDidMount() {
        this.resizeVideo();
        window.addEventListener('resize', this.resizeVideo())
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeVideo())
    }

    openFileManager(e) {
        e.stopPropagation();
        e.preventDefault();

        if(this.props.fileManagerOpened){
            this.props.fileManagerOpened();
        }

        let { aspectRatio, field, module, types } = this.props;

        FileManagerActions.openModal({
           fileTypes: types !=='' && types !== undefined ? types : ['images', 'videos'],
        }, (file) => {
           if (aspectRatio && file.mime.includes('image')) {
                // Crop here
                CropperActions.openModal(aspectRatio, file, (file) => {
                    module.setField(field, {
                        ...{},
                        ...file
                    }, () => {
                        this.forceUpdate();
                    });
                });
            } else {
                module.setField(field, {
                    ...{},
                    ...file
                }, () => {
                    this.forceUpdate();
                });
            }
        });
    }

    addPointer(e) {
        e.stopPropagation();
        e.preventDefault();

        this.pointersRef.current.addPointer();
    }

    renderButtons() {
        let { editPosition, entryType } = this.props;

        const editPositions = {
            nw: { t: '2', l: '7' },
            n: { t: '10', l: '50' },
            ne: { t: '10', l: '90' },
            w: { t: '50', l: '10' },
            c: { t: '50', l: '50' },
            e: { t: '50', l: '90' },
            sw: { t: '90', l: '10' },
            s: { t: '90', l: '50' },
            se: { t: '90', l: '90' },
        };

        if (typeof editPosition === 'undefined' || editPosition === null) editPosition = 'nw';

        return (
            <StyledButtons
                className={ 'media-buttons' }
                top={ editPositions[editPosition].t }
                left={ editPositions[editPosition].l }
                editPosition={ editPosition }>
                <StyledIconButton onClick={ (e) => this.openFileManager(e) }>
                    <IconWrapper>
                        <Icon name="img" width={30} height={30} stroke={Colors.grey} />
                    </IconWrapper>
                </StyledIconButton>
                { entryType && (
                    <StyledIconButton onClick={ (e) => this.addPointer(e) }>
                        <IoMdAdd/>
                    </StyledIconButton>
                ) }
            </StyledButtons>
        )
    }

    resizeVideo(videoWidth, videoHeight) {
        let { field, module, fill } = this.props;
        let file = { ...{}, ...this.defaultFile, ...module.getField(field) };
        let self = this;

        if (!file || !file.mime || (file.mime.includes('url/video') === false && file.mime.includes('mp4') === false)) {
            return;
        }

        let fileData = JSON.parse(file.data),
            containerWidth = this.videoContainer.current ? this.videoContainer.current.clientWidth : 0,
            containerHeight = this.videoContainer.current ? this.videoContainer.current.clientHeight : 0,
            nativeVideoWidth = null,
            nativeVideoHeight = null;

        // if we have no file data, we have to wait for the video to load its meta data, then we run again.
        if (fileData === null && !videoWidth && !videoHeight) {
            return this.videoPlayer.current.getInternalPlayer().addEventListener("loadedmetadata", function(e) {
                self.resizeVideo(this.videoWidth, this.videoHeight);
            }, false);
        } else if (fileData) {
            nativeVideoWidth = fileData.width;
            nativeVideoHeight = fileData.height;
        }

        // if we have passed in native dimensions, overwrite.
        if (videoWidth && videoHeight) {
            nativeVideoWidth = videoWidth;
            nativeVideoHeight = videoHeight;
        }

        // if the container has no aspect, set original video values.
        if (containerHeight === 0) {
            return this.setState({
                videoWidth: nativeVideoWidth,
                videoHeight: nativeVideoHeight,
            });
        }

        // background-cover-esque
        if (fill) {
            const widthScaleFactor = containerWidth / nativeVideoWidth;
            const heightScaleFactor = containerHeight / nativeVideoHeight;

            if (widthScaleFactor > heightScaleFactor) {
                return this.setState({
                    videoWidth: containerWidth,
                    videoHeight: nativeVideoHeight * widthScaleFactor + 10
                });
            } else {
                return this.setState({
                    videoWidth: nativeVideoWidth * heightScaleFactor + 10,
                    videoHeight: containerHeight
                });
            }
        }

        return this.setState({
            videoWidth: '100%',
            videoHeight: '100%'
        });
    }

    /**
     * Get thumbnail url
     * @return {String} url
     */
    getThumbnailUrl = (file) => {

        if (file.mime.includes('video')) {
            let vimeoData = JSON.parse(file.data);
            if (vimeoData !== undefined && vimeoData !== null) {
                let url = vimeoData.thumbnail_url;
                url = url.replace("640", "1920");
                url = url.replace("_295x166", "");
                return url;
            }
        }
        return null;
    }

    /**
     * Check if the videoplayer should render or not and call parent-component play function
     */
    handleShouldStartPlaying = ({ playedSeconds }) => {
        if (playedSeconds > 0) {
            this.props.play && !this.props.playing && this.props.play()
        }
    }

    /**
     * Check if the videoplayer should render or not
     * @return {Bool} shouldRender
     */
    shouldRenderVideoPlayer = () => {
        const { fromTakeover, isMobile } = this.props;
        if (fromTakeover && isMobile) {
           return false
        }
        return true
    }

    /**
     * Check if the thumbnail should render or not
     * @return {Bool} shouldRender
     */
    shouldRenderThumbnail = () => {
        const { fromTakeover, isMobile } = this.props;
        if (fromTakeover && !isMobile) {
           return true
        }
        return false
     }

    render() {
        let { field, module, overlay = 0, fill, aspectRatio, className, entryType, PointerComponent, fromTakeover, isMobile } = this.props;
        let { videoWidth, videoHeight } = this.state;
        let { editMode } = module.props;
        let file = { ...{}, ...this.defaultFile, ...module.getField(field) };
        let pointers = module.getField(field + '_entryPointers');
        const { srcSet } = imgix(file)

        if (file.mime !== undefined) {
            if (file.mime.includes('video')) {
                return (
                    <VideoContainer
                        className={className}
                        ref={this.videoContainer}
                        style={{display: 'block'}}
                        aspectRatio={aspectRatio}>
                            {(this.getThumbnailUrl(file) !== null && this.shouldRenderThumbnail() && !this.props.playing) &&
                                <Thumbnail image={this.getThumbnailUrl(file)} />
                            }
                            {this.shouldRenderVideoPlayer() && (
                                <VideoPlayer
                                    fill={fill ? 'true' : 'false'}
                                    fromtakeover={fromTakeover}
                                    aspectratio={aspectRatio}
                                    onProgress={this.handleShouldStartPlaying}
                                    ref={this.videoPlayer}
                                    width={videoWidth + 'px'}
                                    height={videoHeight + 'px'}
                                    className={"player"}
                                    url={file.mime === 'url/video' ? file.filename : file.original}
                                    controls={this.options.controls}
                                    playing={this.props.playing ? this.props.playing : this.options.autoplay}
                                    loop
                                    playsinline
                                    muted={this.options.muted}
                                    config={{
                                        vimeo: {
                                            playerOptions: {
                                                api: true,
                                                byline: false,
                                                title: false,
                                                loop: true,
                                                muted: this.options.muted,
                                                autoplay: this.options.autoplay,
                                                background: !this.options.controls
                                            },
                                            preloading: true,
                                        }
                                }}/>
                            )}
                            <VideoChildContent>
                                { this.props.children }
                            </VideoChildContent>
                        { editMode && this.renderButtons() }
                    </VideoContainer>
                );
            }
        }
        if (fill) {
            return (
                <StyledFillImage
                    ref={ this.mediaRef }
                    key={ file ? file.id : '' }
                    editMode={editMode}
                    className={`${className} lazyload`}
                    data-bgset={ srcSet } data-sizes="auto"
                    focusX={ file.focus_x }
                    focusY={ file.focus_y }
                    overlay={overlay}
                    aspectRatio={ aspectRatio }
                >

                    { editMode && this.renderButtons() }

                    { this.props.children }

                    { (entryType && (typeof pointers !== 'undefined' || editMode))
                        && <Pointers
                            ref={ this.pointersRef }
                            pointers={ pointers }
                            media={ this }
                            file={ file }
                            module={ module }
                            PointerComponent={ PointerComponent }/>
                    }
                </StyledFillImage>
            )
        }

        return (
            <StyledImgContainer key={ file ? file.id : '' } className={ className } editMode={editMode}>
                <StyledImg overlay={overlay} data-sizes={'auto'} data-srcset={srcSet} className={'lazyload'}/>
                { editMode && this.renderButtons() }
            </StyledImgContainer>
        )
    }
}

Media.propTypes = {
    module: PropTypes.instanceOf(BaseModule).isRequired,
    field: PropTypes.string.isRequired,
    fill: PropTypes.bool,
    aspectRatio: PropTypes.number,
    entryType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    types: PropTypes.array,
    noChildrenStyle: PropTypes.bool,
};

export default Media;

const StyledFillImage = styled.div`
  position: relative;
  background-size: cover;
  opacity: 0;
  transition: opacity .3s ease;
   &:after {
       content: ' ';
       position:absolute;
       pointer-events: none;
       width:100%; height:100%;
       top:0; left:0;
       background-color:rgba(0,0,0,${p => p.overlay});
    }

  ${p => p.editMode && css`
    opacity: 1;
    background-color: #d2d2d2;
  `}

  &.lazyloaded {
    opacity: 1;
  }

   ${ props => props.focusX !== null && css`
    background-position: ${ props => props.focusX }% ${ props => props.focusY }%;
  `}

  ${ props => props.focusX === null && css`
    background-position: 50% 50%;
  `}

  ${ props => props.aspectRatio && css`
    &:before {
      display: block;
      content: ' ';
      padding-bottom: ${ props => 1 / props.aspectRatio * 100 }%;
    }
  `}
`

const VideoPlayer = styled(ReactPlayer)`
    ${p => p.fromtakeover && 'pointer-events: none;'}
    ${p => (p.aspectratio || p.fill === 'true') && css`
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
    `}
`;

const VideoContainer = styled.div`
    position: relative;
    overflow: hidden;

    ${p => p.aspectRatio && css`
        &:before {
            display: block;
            content: ' ';
            padding-bottom: ${p => 1 / p.aspectRatio * 100 }%;
        }
    `}
`;


const VideoChildContent = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    z-index: 10;

    &:not(.no-style) {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        z-index: 2;
    }
`;

const StyledImgContainer = styled.div`
  position: relative;

  ${p => p.editMode && css`
    background-color: #d2d2d2;
  `}
`

const StyledImg = styled.img`
  margin: 0 auto;
   &:after {
       content: ' ';
       pointer-events: none;
       position:absolute;
       width:100%; height:100%;
       top:0; left:0;
       background-color:rgba(0,0,0,${p => p.overlay});
    }

`

const StyledButtons = styled.div`
  position: absolute;
  z-index: 11;
  top: ${ props => props.top }%;
  left: ${ props => props.left }%;
  opacity: 0;
  transform: translate(-50%, -50%);
  margin-top: 15px;

  ${ StyledFillImage }:hover & {
    opacity: 1;
  }

  ${ StyledImgContainer }:hover & {
    opacity: 1;
  }

  ${ VideoContainer }:hover & {
    opacity: 1;
  }
`

const StyledIconButton = styled(IconButton)`
  /*margin-bottom: 6px;*/
`

const IconWrapper = styled.div`
    background-color: ${Colors.white};
    border: 1px solid ${Colors.dividers};
    width: 30px;
    height: 30px;
    margin-bottom: 6px;
`

const Thumbnail = styled.div`
    position: absolute;
    z-index: 2;
    background: black;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    ${p => p.image && css`
        background-image: url(${p.image});
        background-size: cover;
    `}
`
