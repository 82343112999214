import React, { Component } from 'react'
import { connect } from 'react-redux';

/**
 * HoC that passes the countries data as a prop.
 * @param {*} PassedComponent
 */
const withPlantProducts = PassedComponent => {
  const Wrapper = props => {
    return <PassedComponent plantProducts={ props.plantProducts } {...props} />
  }

  return connect(mapStateToProps)(Wrapper);
}

const mapStateToProps = (state, ownProps) => {
  return {
    plantProducts: state.plantProducts.products,
    ...ownProps
  };
};

export default withPlantProducts;