import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Globals, AnalyticsActions, Settings } from 'kbcms-react';
import { default as media } from 'assets/styles/Breakpoints';
import CountryDropdown from 'components/CountryDropdown';
import LanguageDropdown from 'components/LanguageDropdown';
import Colors from 'assets/styles/Colors';
import InputField from 'components/InputField';
import Button from 'components/Button';
import debounce from 'lodash/debounce';
import Checkbox from 'components/Checkbox';
import axios from 'axios'
import { FOOTER_ID } from 'components/SkipToLandmarks'
import isServer from '../isServer'

const navigationList = [
    [
        {
            name: "Home",
            url: "/"
        },
        {
            name: "Shop",
            url: "/shop"
        },
        {
            name: "Stories",
            url: "/stories"
        },
        {
            name: "About",
            url: "/about"
        },
        {
            name: "Contact",
            url: "/contact-us"
        },
    ],
    [
        {
            name: "Need help?",
            url: "https://support.douchebags.com",
            type: 'external'
        },
        {
            name: "Retailers",
            url: "/retailers"
        },
        {
            name: "Warranty",
            url: "/warranty"
        },
        {
            name: "Career Opportunities",
            url: "/careers"
        },
    ]
];

export default class Footer extends PureComponent {

    state = {
        email: '',
        isEmailValid: true,
        success: false,
        message: '',
        termsAndConditionsAccepted: false,
        subscriptionStarted: false
    }

    subscribe = (e) => {
        const { email, termsAndConditionsAccepted } = this.state
        e.preventDefault()

        if(!termsAndConditionsAccepted) {
            this.displayError()
        } else if(!this.validateEmail(email)){
            this.displayEmailError()
        } else {
            AnalyticsActions.identify(email)
            document.getElementById('footerSignupForm').submit()
            this.setState({ isEmailValid: true })
        }
    }

    runMentionMeEmailSignupScript(email) {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://tag.mention-me.com/api/v2/incentivised-action/mm3cc06962/5fa1296608a1a?email=' + encodeURIComponent(email);
        document.body.appendChild(script);
    }

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    deboucedValidate = debounce((email) => {
        if (!this.validateEmail(email)) {
            this.displayError()
        } else {
            this.setState({ isEmailValid: true, message: 'Hit enter to submit' })
        }
    }, 1000);

    displayEmailError = () => {
        this.setState({ isEmailValid: false, message: 'Seems like the email is invalid... Check your email and try again!' })
    }

    displayError = () => {
        this.setState({ message: 'Oops.. Please check terms and conditions in order to sign up' })
    }

    /**
     * Get email from input field
     * @return {Void}
     */
    onChangeEmail = event => {
        this.setState({ email: event.target.value });
    }

    render() {
        const { isEmailValid, email, message, success, termsAndConditionsAccepted, subscriptionStarted } = this.state;

        const ometriaToken = Settings.get('analytics_ometria_code')

        return (
            <StyledFooter id={FOOTER_ID}>
                <FooterUpper>
                    <CopyrightAndCountry>
                        <About>
                            <AboutTitle>{Globals.get('about_title')}</AboutTitle>
                            <AboutInfo>{Globals.get('about_info')}</AboutInfo>
                        </About>
                        <NewsletterWrapper>
                            {!success && (
                                <StyledForm id="footerSignupForm" action="https://api.ometria.com/forms/signup" method="POST">
                                    <DropdownLabel>{Globals.get('footer_signup_text', 'Sign up to Db Black')}</DropdownLabel>
                                    <input type="hidden" name="__form_id" value="07503d547d079df2a014fcb4154ad77e"/>
                                    <input type="hidden" name="email" value=""/>
                                    <input type="hidden" name="__email" value=""/>
                                    <input type="hidden" name="@account" value={ ometriaToken }/>
                                    <input type="hidden" name="@return_url" value={`${ isServer ? '' : window.location.href }?ometria_form=footer`}/>

                                    <input type="hidden" name="@subscription_status" value="SUBSCRIBED"/>
                                    <input type="hidden" name="@add_to_lists[]" value="1668"/>
                                    <input type="hidden" name="properties.signup_source" value="db-black footer"/>
                                    <InputWrapper>
                                        <StyledNewsletterInputField
                                            name='ue'
                                            type='email'
                                            isValid={isEmailValid}
                                            value={email}
                                            onChange={this.onChangeEmail}
                                            onFocus={() => this.setState({ subscriptionStarted: true })}
                                            onBlur={() => this.setState({ subscriptionStarted: false })}
                                            placeholder="Enter E-mail"
                                        />
                                        {(subscriptionStarted || email !== '') &&
                                            <SubscribeButtton onClick={ this.subscribe }>{'SUBSCRIBE'}</SubscribeButtton>
                                        }
                                    </InputWrapper>
                                    {(subscriptionStarted || email !== '') &&
                                        <TermsAndConditions>
                                            <Checkbox
                                                onChange={() => this.setState({ termsAndConditionsAccepted: !termsAndConditionsAccepted })}
                                                enabled={termsAndConditionsAccepted}
                                            />
                                            <div>
                                                <span>{Globals.get('footer_terms_and_conditions_text', 'I have read and accept the')}</span>
                                                <Link to={'/terms-and-conditions'}>
                                                    {Globals.get('footer_terms_and_conditions_link', 'terms and conditions')}
                                                </Link>
                                            </div>
                                        </TermsAndConditions>
                                    }
                                </StyledForm>
                            )}
                            {message !== '' &&
                                <MessageWrapper>
                                    {message}
                                </MessageWrapper>
                            }
                        </NewsletterWrapper>
                        <CountryAndLanguageSelectors>
                            <ChooseCountry>
                                <DropdownLabel>Country</DropdownLabel>
                                {isServer ? null : <CountryDropdown />}
                            </ChooseCountry>

                            <ChooseLanguage>
                                <DropdownLabel>Language</DropdownLabel>
                                {isServer ? null : <LanguageDropdown />}
                            </ChooseLanguage>
                        </CountryAndLanguageSelectors>
                    </CopyrightAndCountry>
                    <Navigation>
                        <NavigationList>
                            {Globals.get('footerMenuLeft').map((item, key) => {
                                if (item.link.value.type && item.link.value.type === 'external') {
                                    return <NavigationItem key={key}><a href={item.link.value.url} rel={'nofollow'}>{item.label.value}</a></NavigationItem>
                                } else {
                                    return <NavigationItem key={key}><Link to={`/${item.link.value.url}`}>{item.label.value}</Link></NavigationItem>
                                }
                            })}
                        </NavigationList>
                        <NavigationList>
                            {Globals.get('footerMenuRight').map((item, key) => {
                                if (item.link.value.type && item.link.value.type === 'external') {
                                    return <NavigationItem key={key}><a href={item.link.value.url} rel={'nofollow'}>{item.label.value}</a></NavigationItem>
                                } else {
                                    return <NavigationItem key={key}><Link to={`/${item.link.value.url}`}>{item.label.value}</Link></NavigationItem>
                                }
                            })}
                        </NavigationList>
                    </Navigation>
                </FooterUpper>
                { (!isServer && window.innerWidth > 992) &&
                <FooterLower>
                    <Copyrights>{Globals.get('footer_copyright_text', '©2021, Db Equipment. All rights reserved.')}</Copyrights>
                    <BottomLinks>
                        {Globals.get('footerMenuCenter').map((item, key) => {
                            if (item.link.value.type && item.link.value.type === 'external') {
                                return <a key={key} href={item.link.value.url} rel={'nofollow'}>{item.label.value}</a>
                            } else {
                                return (
                                    <React.Fragment key={key}>
                                        <Link key={ key } to={ `/${ item.link.value.url }` }>
                                            { item.label.value }
                                        </Link>
                                        { item.link.value.url === 'accessibility' && <a href="https://www.essentialaccessibility.com/dbjourney?utm_source=dbjourneyhomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=dbjourney" rel="nofollow" target="_blank"><img width="52" height="28" className="ea-link-image" src="/imgs/essential-accessibility.png"/></a>}
                                    </React.Fragment>
                                )
                            }
                        })}
                    </BottomLinks>
                    <PaymentIcons>
                        <img width="35" height="22" alt="Visa" data-src="/imgs/payment/visa.png" className="lazyload"/>
                        <img width="35" height="22" alt="PayPal" data-src="/imgs/payment/paypal.png" className="lazyload"/>
                        <img width="35" height="22" alt="MasterCard" data-src="/imgs/payment/mastercard.svg" className="lazyload"/>
                        <img width="35" height="22" alt="American Express" data-src="/imgs/payment/ae.png" className="lazyload"/>
                        <img width="35" height="22" alt="Sofort" data-src="/imgs/payment/sofort.png" className="lazyload"/>
                        <img width="35" height="22" alt="Maestro" data-src="/imgs/payment/maestro.png" className="lazyload"/>
                        <img width="35" height="22" alt="iDeal" data-src="/imgs/payment/ideal.png" className="lazyload"/>
                    </PaymentIcons>
                </FooterLower>
                }
                { (!isServer && window.innerWidth < 992) &&
                <FooterLower>
                    <BottomLinks>
                        {Globals.get('footerMenuCenter').map((item, key) => {
                            if (item.link.value.type && item.link.value.type === 'external') {
                                return <a key={key} href={item.link.value.url} rel={'nofollow'}>{item.label.value}</a>
                            } else {
                                return (
                                    <React.Fragment key={key}>
                                        <Link key={ key } to={ `/${ item.link.value.url }` }>
                                            { item.label.value }
                                        </Link>
                                        { item.link.value.url === 'accessibility' && <a href="https://www.essentialaccessibility.com/dbjourney?utm_source=dbjourneyhomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=dbjourney" rel="nofollow" target="_blank"><img width="52" height="28" className="ea-link-image" src="/imgs/essential-accessibility.png"/></a>}
                                    </React.Fragment>
                                )
                            }
                        })}
                    </BottomLinks>
                    <PaymentIcons>
                        <img width="35" height="22" alt="Visa" data-src="/imgs/payment/visa.png" className="lazyload"/>
                        <img width="35" height="22" alt="PayPal" data-src="/imgs/payment/paypal.png" className="lazyload"/>
                        <img width="35" height="22" alt="MasterCard" data-src="/imgs/payment/mastercard.svg" className="lazyload"/>
                        <img width="35" height="22" alt="American Express" data-src="/imgs/payment/ae.png" className="lazyload"/>
                        <img width="35" height="22" alt="Sofort" data-src="/imgs/payment/sofort.png" className="lazyload"/>
                        <img width="35" height="22" alt="Maestro" data-src="/imgs/payment/maestro.png" className="lazyload"/>
                        <img width="35" height="22" alt="iDeal" data-src="/imgs/payment/ideal.png" className="lazyload"/>
                    </PaymentIcons>
                    <Copyrights>{Globals.get('footer_copyright_text', '©2021, Db Equipment. All rights reserved.')}</Copyrights>
                </FooterLower>
                }
            </StyledFooter>
        );
    }
}

const CountryAndLanguageSelectors = styled.div`
    display: flex;
    margin-right: 30px;
    margin-top: 20px;

    ${media.lessThan('large')`
        display: block;
    `}

    ${media.lessThan('medium')`
        padding: 20px 5px;
        margin: 0;
    `}
`

const ChooseCountry = styled.div`
    flex: 1;
    max-width: 260px;
    margin-right: 15px;

    ${media.lessThan('medium')`
        margin-right: 0;
        margin-bottom: 10px;
        max-width: 100%;
    `}
`
const ChooseLanguage = styled.div`
    flex: 1;
    max-width: 260px;
    ${media.lessThan('medium')`
        max-width: 100%;
    `}
`

const DropdownLabel = styled.div`
    font-family: 'Lyon Text Bold';
    font-size: 14px;
    color: #666;
`

const CopyrightAndCountry = styled.div`
    width: 100%;
    display: flex;
    flex: 2;
    flex-direction: column;

    ${media.lessThan('medium')`
        display: block;
    `}
`

const Copyrights = styled.div`
    font-size: 12px;
    font-family: 'Circular Pro';
    color: #666;
    flex: 1;

    ${media.lessThan('medium')`
        padding: 0 15px;
        text-align: center;
    `}
`

const Navigation = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
`

const NavigationList = styled.ul`
    flex: 1;
    list-style: none;
    margin: 0;
    padding: 0;
`

const NavigationItem = styled.li`
    margin-bottom: 1rem;

    ${media.lessThan('medium')`
        margin: 10px 0;
        padding: 0 5px;
    `}

    a {
        font-family: "Circular Pro";
        color: #666;
        font-size: 14px;
        font-weight: 300;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        ${media.lessThan('medium')`
            text-align: center;
            border: 1px solid #ddd;
            display: inline-block;
            width: 100%;
            padding: 10px;
        `}
    }
`

const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    padding: 60px 130px 40px 130px;
    background: #f8f8f8;

    ${media.lessThan('medium')`
        padding: 30px 15px;
        display: flex;
        flex-direction: column;
    `}
  
    .ea-link-image {
      vertical-align: middle;
      height: 28px;
    }
`
const MessageWrapper = styled.div`
    flex: 1;
    margin: -10px 30px 20px 0;
    font-family: 'Circular Pro Book';
    white-space: pre-line;
    font-size: 14px;
    color: #666;

    ${media.lessThan('large')`
        display: block;
    `}

    ${media.lessThan('medium')`
        padding: 20px 5px;
        margin: 0;
    `}
`

const NewsletterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-top: 20px;

    ${media.lessThan('large')`
        display: block;
    `}

    ${media.lessThan('medium')`
        padding: 20px 5px;
        margin: 0;
    `}
`

const StyledForm = styled.form`
    font-family: 'Circular Pro Book';
`

const StyledNewsletterInputField = styled(InputField)`
    ${p => !p.isValid && `
        color: red;
    `}
    max-width: 535px;
    margin-top: 5px;
    ${media.lessThan('medium')`
        max-width: 100%;
    `}
`

const InputWrapper = styled.div`
    max-width: 535px;
    position: relative; 
`

const SubscribeButtton = styled.div`
    position: absolute; 
    top: 13px;
    font-size: 14px;
    right: 0;
    cursor: pointer; 
`

const StyledInputField = styled(InputField)`
    ${p => !p.isValid && `
        color: red;
    `}
    margin-top: 5px;
    max-width: 260px;
`

const NewsletterInputWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
`

const StyledButton = styled(Button)`
    border: 1px solid #d1d1d1;
    color: #d1d1d1;
    font-family: 'Circular Pro Book';
    font-size: 14px;
    margin-left: 14px;
    text-align: center;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;

    ${media.lessThan('small')`
        padding: 4px 18px;
        font-size: 12px;
    `}
`

const About = styled.div`
    max-width: 650px;
    color: #666;
`
const AboutTitle = styled.div`
    font-family: 'Lyon Text Bold';
    font-weight: normal;
    font-size: 23px;
`
const AboutInfo = styled.div`
    font-family: 'Circular Pro Book';
    padding: 20px 30px 20px 0;
`


const BottomLinks = styled.div`
    flex: 2;
    text-align: center;
    a{
        font-family: "Circular Pro";
        margin: 0 10px;
        color: #666;
        font-size: 12px;
        font-weight: 300;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
`
const PaymentIcons = styled.div`
    flex: 1;
    text-align: right;

    img{
        width: 35px;
        height: auto;
        margin: 0 5px;
    }

    ${media.lessThan('medium')`
        text-align: center;
        padding-top: 20px;
    `}
`
const FooterUpper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    padding-bottom: 60px;

    ${media.lessThan('medium')`
        padding: 30px 0;
        display: flex;
        flex-direction: column;
    `}
`

const FooterLower = styled.div`
    border-top: 1px solid ${Colors.darkGray};
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;

    ${media.lessThan('medium')`
        padding: 30px 0;
        display: flex;
        flex-direction: column;
    `}
`
const TermsAndConditions = styled.div`
    display: flex; 
    align-items: center; 
    margin-bottom: 20px;
    font-family: 'Circular Pro Book';
    white-space: pre-line;
    font-size: 14px;
    color: #666;

    li{
        width: 20px;
        height: 20px;
        margin: 0 -20px 0 25px;
    }

    > div span{
        margin-right: 4px;
    }

    a{
        color: inherit;
        &:hover{
            text-decoration: none;
        }
    }
`
