import store from '../store'
import produce from 'immer';
import SessionActions from './session';
import ApiManager from "../ApiManager";

const Api = new ApiManager();

const defaultState = {
    token: null,
    data: null,
    variations: [],
    warrantyMap: {},
    customerBillingDetails: {
        address1: '',
        city: '',
        country: null,
        dial_code: '+47-no',
        email: '',
        firstName: '',
        lastName: '',
        newsletter: false,
        phoneNumber: '',
        password: '',
        new_password: '',
        state: '',
        zipCode: '',
    }, 
    separateBillingDetails: {
        address1: '',
        city: '',
        country: null,
        dial_code: '+47-no',
        email: '',
        firstName: '',
        lastName: '',
        newsletter: false,
        phoneNumber: '',
        password: '',
        new_password: '',
        state: '',
        zipCode: '',
    },
};

const SET_CART_TOKEN = 'SET_CART_TOKEN';
const SET_CART_DATA = 'SET_CART_DATA';
const CLEAR_CART = 'CLEAR_CART';
const ADD_VARIATION = 'ADD_VARIATION';
const REMOVE_VARIATION = 'REMOVE_VARIATION';
const SET_VARIATION = 'SET_VARIATION';
const SET_WARRANTY_MAP = 'SET_WARRANTY_MAP';
const ADD_WARRANTY_COMBINATION = 'ADD_WARRANTY_COMBINATION';
const REMOVE_WARRANTY_ITEM = 'REMOVE_WARRANTY_ITEM';
const REMOVE_PRODUCT_WARRANTIES = 'REMOVE_PRODUCT_WARRANTIES';
const UPDATE_WARRANTY_QUANTITY = 'UPDATE_WARRANTY_QUANTITY';

export const CartReducer = (state = defaultState, action) => {
    let warrantyMap

    switch (action.type) {
        case SET_CART_TOKEN:
            return {
                ...{},
                ...state,
                ...{token: action.token}
            };
        case SET_CART_DATA:
            return produce(state, draft => {
                draft.token = action.token ? action.token : state.token
                draft.data = action.data
            });
        case ADD_VARIATION:

            return {
                ...{},
                ...state,
                ...{
                    variations: [...[], ...state.variations.filter(function(v) { return v.sku !== action.variations.sku }), action.variations],
                }
            }
        case REMOVE_VARIATION: 
            return {
                ...{},
                ...state,
                ...{
                    variations: state.variations.filter(variation => variation.id !== action.variations.id)
                }
            }
        case SET_VARIATION: 
            return {
                ...{},
                ...state,
                ...{
                    variations: action.variations
                }
            }
        case SET_WARRANTY_MAP:
            return {
                ...{},
                ...state,
                ...{
                    warrantyMap: {
                        ...{},
                        ...state.warrantyMap,
                        ...action.warrantyMap,
                    },
                }
            }
        case ADD_WARRANTY_COMBINATION:
            warrantyMap = JSON.parse(JSON.stringify(state.warrantyMap))

            if(!warrantyMap[action.warrantyVariation.sku]) {
                warrantyMap[action.warrantyVariation.sku] = {}
            }

            if(!warrantyMap[action.warrantyVariation.sku][action.productVariation.sku]) {
                warrantyMap[action.warrantyVariation.sku][action.productVariation.sku] = 1
            } else {
                warrantyMap[action.warrantyVariation.sku][action.productVariation.sku]++
            }

            return {
                ...{},
                ...state,
                ...{
                    warrantyMap: {
                        ...{},
                        ...state.warrantyMap,
                        ...warrantyMap,
                    },
                }
            }
        case UPDATE_WARRANTY_QUANTITY:
            warrantyMap = JSON.parse(JSON.stringify(state.warrantyMap))

            if (
              typeof warrantyMap[action.warrantySku] !== 'undefined'
              && typeof warrantyMap[action.warrantySku][action.productSku] !== 'undefined'
            ) {
                warrantyMap[action.warrantySku][action.productSku] = action.newQuantity
            }

            return {
                ...{},
                ...state,
                ...{
                    warrantyMap: {
                        ...{},
                        ...state.warrantyMap,
                        ...warrantyMap,
                    },
                }
            }
        case REMOVE_WARRANTY_ITEM:
            warrantyMap = JSON.parse(JSON.stringify(state.warrantyMap))

            if(typeof warrantyMap[action.sku] !== 'undefined') {
                delete warrantyMap[action.sku]
            }

            return {
                ...{},
                ...state,
                ...{
                    warrantyMap: {
                        ...{},
                        ...state.warrantyMap,
                        ...warrantyMap,
                    },
                }
            }
        case REMOVE_PRODUCT_WARRANTIES:
            warrantyMap = JSON.parse(JSON.stringify(state.warrantyMap))

            Object.keys(warrantyMap).forEach(warrantySku => {
                if(typeof warrantyMap[warrantySku][action.variation.sku] !== 'undefined') {
                    delete warrantyMap[warrantySku][action.variation.sku]
                }
            })

            return {
                ...{},
                ...state,
                ...{
                    warrantyMap: {
                        ...{},
                        ...state.warrantyMap,
                        ...warrantyMap,
                    },
                }
            }
        case CLEAR_CART:
            return defaultState;
        default:
            return state;
    }
};

const CartActions = {
    setToken: (token) => {
        store.dispatch({
            type: SET_CART_TOKEN,
            token: token,
        });
    },
    setCartData: (data) => {
        store.dispatch({
            type: SET_CART_DATA,
            data: data,
            token: data.token ? data.token : null,
        })
    },

    addVariation: (variation) => {
        store.dispatch({
            type: ADD_VARIATION,
            variations: variation,
        });
    },

    removeVariation: (variation) => {
        store.dispatch({
            type: REMOVE_VARIATION,
            variations: variation,
        });
    },

    setVariations: (variations) => {
        store.dispatch({
            type: SET_VARIATION,
            variations: variations,
        });
    },

    getVariationBySku: (sku) => {
        const variations = store.getState().cart.variations

        return variations.find(v => v.sku === sku)
    },

    getVariationByParentSku: (sku) => {
        const variations = store.getState().cart.variations

        return variations.find(v => v.parent_sku === sku)
    },

    getSelectionItemBySku: (sku) => {
        try {
            const items = store.getState().cart.data.selection.items

            const item = items.find(v => v.sku === sku)

            return item ? item : null
        } catch(e) {
            return null
        }
    },

    getCurrentSelection: () => {
        try {
            return store.getState().cart.data.selection.items
        } catch(e) {
            return null
        }
    },

    addWarranty: (warrantyVariation, productVariation) => {
        store.dispatch({
            type: ADD_WARRANTY_COMBINATION,
            warrantyVariation: warrantyVariation,
            productVariation: productVariation,
        });
    },

    updateWarrantyQuantity: (warrantySku, productSku, newQuantity) => {
        store.dispatch({
            type: UPDATE_WARRANTY_QUANTITY,
            warrantySku,
            productSku,
            newQuantity,
        });
    },

    getWarrantiesForProduct: (variation) => {
        const warrantyMap = store.getState().cart.warrantyMap
        let warranties = {}

        Object.keys(warrantyMap).forEach(key => {
            if(typeof warrantyMap[key][variation.sku] !== 'undefined') {
                warranties[key] = warrantyMap[key][variation.sku]
            }
        })

        return warranties
    },

    removeWarrantiesForProduct: (variation) => {
        store.dispatch({
            type: REMOVE_PRODUCT_WARRANTIES,
            variation: variation,
        });
    },

    removeWarranty: async (sku) => {
        store.dispatch({
            type: REMOVE_WARRANTY_ITEM,
            sku: sku,
        });
    },

    addWarrantyMap: (orderId, data) => {
        return Api.addWarrantyMap(orderId, data).then((result) => {
            return result;
        });
    },

    clearCart: () => {
        store.dispatch({
            type: CLEAR_CART
        });
    },
}

export default CartActions;
