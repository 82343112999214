import React, { Component } from 'react'
import { connect } from 'react-redux';

/**
 * HoC that passes the session as a prop.
 *
 * @param {*} PassedComponent
 */
const withSession = PassedComponent => {
    const Wrapper = props => {
        return <PassedComponent
            session={ props.session }
            {...props} 
        />
    }

    return connect(mapStateToProps)(Wrapper);
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...{
            session: state.session,
        },
        ...ownProps
    };
};

export default withSession;
