import styled from 'styled-components'

const ImageLink = styled.a`
    color: inherit;
    text-decoration: underline;
    &:hover{
        cursor: pointer
    };
`

export default ImageLink