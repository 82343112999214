import styled from 'styled-components';
import Colors from 'assets/styles/Colors';
import { default as media } from 'assets/styles/Breakpoints';

const Button = styled.a`
    display: inline-block;
    padding: 12px 30px;
    position: relative;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Circular Pro Book';
    text-transform: uppercase;
    height: auto;
    text-decoration: none;
    color: #FFF;
    border: 1px solid #FFF;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, .5);
    }

    ${p => p.black ? `
        color: #4a494a;
        border: 1px solid #4a494a;
        &:hover {
            background-color: #4a494a;
            color: white;
            border: 1px solid #FFF;
        }
    `: ''}
    
    ${p => p.hoverBlue ? `
        &:hover {
            background-color: ${Colors.blue};
            color: white;
            border: 1px solid ${Colors.blue};
        }
    ` : ``}

    ${p => p.blue ? `
        color: #fff;
        background-color: ${Colors.blue};
        border: 1px solid ${Colors.blue};
    ` : ''}

    ${media.lessThan('medium') `
        font-size: 12px;
        padding: 8px 20px;
    `}
`

export default Button;
