import store from 'cms/store';
import ApiManager from 'ApiManager';
import produce from 'immer'

const Api = new ApiManager();

const defaultOptions = {
    entryType: null,
    multiple: false,
}

const defaultState = {
    entries: null,
    categories: null,
    entryType: null,
    modalOpen: false,
    callback: null,
    options: defaultOptions,
    activeEntryType: 0,
    entryTypes: [],
    selectedEntries: []
};

const TOGGLE_SELECTED_VARIATION = 'TOGGLE_SELECTED_VARIATION';
const UPDATE_ENTRIES = 'UPDATE_ENTRIES';
const ENTRY_TYPE_LOADED = 'ENTRY_TYPE_LOADED';
const ENTRIES_LOADED = 'ENTRIES_LOADED';
const ENTRIES_EMPTIED = 'ENTRIES_EMPTIED';
const OPEN_ENTRY_SELECTOR_MODAL = 'OPEN_ENTRY_SELECTOR_MODAL';
const CLOSE_ENTRY_SELECTOR_MODAL = 'CLOSE_ENTRY_SELECTOR_MODAL';
const SET_ACTIVE_ENTRY_TYPE = 'SET_ACTIVE_ENTRY_TYPE';
const SELECT_ENTRY = 'SELECT_ENTRY';

export const EntrySelectorReducer = (state = defaultState, action) => {

    let selectedEntries;

    switch (action.type) {
        case TOGGLE_SELECTED_VARIATION:
            return produce(state, draftState => {
                const { parentId, variation } = action;
                const parentEntryIndex = draftState.entries.findIndex(a => a.id === parentId);
                if (draftState.entries[parentEntryIndex].variations.findIndex(x => x.id === variation.id) !== -1) {
                    draftState.entries[parentEntryIndex].variations = draftState.entries[parentEntryIndex].variations.filter(a => a.id !== variation.id)
                } else {
                    draftState.entries[parentEntryIndex].variations.push(variation);
                }
                return draftState;
            })
            return {
                ...state,
                entryTypes: [
                    ...state.entryTypes,
                ]
            }
        case ENTRY_TYPE_LOADED:
            return {
                ...state,
                entryType: action.entryType
            };
        case ENTRIES_LOADED:
            return {
                ...state,
                entries: action.entries,
                categories: action.categories
            };
        case ENTRIES_EMPTIED:
            return {
                ...state,
                entries: null,
                categories: null,
            }
        case UPDATE_ENTRIES:
            return {
                ...state,
                entries: action.newEntries
            }
        case OPEN_ENTRY_SELECTOR_MODAL:

            selectedEntries = typeof action.options.selectedEntries !== 'undefined'
                ? action.options.selectedEntries.slice(0) : [];

            return {
                ...{}, ...state,
                ...{
                    modalOpen: true,
                    callback: action.callback,
                    entryTypes: action.entryTypes,
                    options: action.options,
                    selectedEntries: selectedEntries,
                }
            };
        case CLOSE_ENTRY_SELECTOR_MODAL:
            return {
                ...{}, ...state,
                ...{
                    modalOpen: false,
                    callback: null,
                }
            };
        case SET_ACTIVE_ENTRY_TYPE:
            return {
                ...{}, ...state,
                ...{
                    activeEntryType: action.activeEntryType,
                    selectedEntries: [],
                }
            }
        case SELECT_ENTRY:
            selectedEntries = state.selectedEntries.slice(0);

            // de-select entry
            let selectedEntryExistingIndex = null;

            selectedEntries.forEach((entry, key) => {
                // check for existence
                if (entry.id && entry.id === action.key) {
                    selectedEntryExistingIndex = key;
                }

                // backwards compatibility
                if (entry === action.key) {
                    selectedEntries[key] = {
                        id: entry,
                        children: []
                    }

                    selectedEntryExistingIndex = key;
                }
            });

            if (selectedEntries[selectedEntryExistingIndex] !== undefined && action.children === null) {
                selectedEntries.splice(selectedEntryExistingIndex, 1);
            } else if (selectedEntries[selectedEntryExistingIndex] === undefined) {
                let arrayLength = selectedEntries.push({
                    id: action.key,
                    children: []
                });

                selectedEntryExistingIndex = arrayLength -1;
            }


            if (selectedEntries[selectedEntryExistingIndex] !== undefined) {
                if (selectedEntries[selectedEntryExistingIndex].children.includes(action.children)) {
                    // de-select children
                    let currentchildrenIndex = selectedEntries[selectedEntryExistingIndex].children.indexOf(action.children);
                    selectedEntries[selectedEntryExistingIndex].children.splice(currentchildrenIndex, 1);
                } else {
                    // select children
                    if (action.children !== null) {
                        selectedEntries[selectedEntryExistingIndex].children.push(action.children);
                    }
                }
            }

            return {
                ...{}, ...state,
                ...{
                    selectedEntries: [...[], ...selectedEntries]
                }
            }
        default:
            return state;
    }
};

const EntrySelectorActions = {
    openModal: (callback, options) => {

        if (typeof options === 'undefined') options = { ...{}, ...defaultOptions };

        Api.getEntrySelectorIndex(options.entryType).then((result) => {
            store.dispatch({
                type: OPEN_ENTRY_SELECTOR_MODAL,
                callback: callback,
                options: options,
                entryTypes: result.data,
            });
        });
    },
    updateEntries: (newEntries) => {
        store.dispatch({ type: UPDATE_ENTRIES, newEntries })
    },
    getEntryType: async (entryType) => {
        const result = await Api.getEntryType(entryType)
        store.dispatch({ type: ENTRY_TYPE_LOADED, entryType: result.data })
    },
    fetchEntries: async (entryType) => {
        store.dispatch({
            type: ENTRIES_EMPTIED,
        })

        const result = await Api.getEntries(entryType)
        let categoryNames = [];
        let categories = [{name: '', key: 0}];

        if(entryType === 'products'){
            result.data.map((entry, key) => {
                if(entry.categories.length > 0 && !categoryNames.includes(entry.categories[0].name)) {
                    categories.push({name: entry.categories[0].name, key: key});
                    categoryNames.push(entry.categories[0].name);
                }
                if(entry.variations){
                    entry.variations.sort(function(a, b) {
                        return b.sort - a.sort;
                    });
                }
            });
        }
        store.dispatch({
            type: ENTRIES_LOADED,
            categories,
            entries: result.data
        })
    },
    closeModal: () => {
        store.dispatch({
            type: CLOSE_ENTRY_SELECTOR_MODAL,
        });
    },
    setActiveEntryType: (activeEntryType) => {
        store.dispatch({
            type: SET_ACTIVE_ENTRY_TYPE,
            activeEntryType: activeEntryType,
        });
    },
    selectEntry: (key, children) => {
        let { options, callback } = store.getState().entry_selector;

        if (options.multiple) {
            store.dispatch({
                type: SELECT_ENTRY,
                key: key,
                children: children,
            });
        } else {
            callback([{ id: key, children: children ? [children] : [] }]);

            store.dispatch({
                type: CLOSE_ENTRY_SELECTOR_MODAL,
            });
        }
    },
    applyEntries: () => {
        let { callback, selectedEntries } = store.getState().entry_selector;

        callback(selectedEntries);

        store.dispatch({
            type: CLOSE_ENTRY_SELECTOR_MODAL,
        });
    },
    toggleSelectedVariation: (parentId, variation) => {
        store.dispatch({
            type: TOGGLE_SELECTED_VARIATION,
            parentId,
            variation
        })
    }
};

export default EntrySelectorActions;
