import React from 'react'
import BaseModule from 'modules/DBBaseModule';
import styled from 'styled-components';
import { Media } from 'kbcms-react';
import { default as media } from 'assets/styles/Breakpoints';
import Button from 'components/Button';
import OptionsWrapper from 'components/OptionsWrapper';

class Iframe3D extends BaseModule {

   constructor(props) {
        super(props);
        
        this.state = {
            fields: {
                ...{
                    
                }, ...props.fields
            },
            showIframe : false,
        }
    }

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
   static getName() { return "3D model" }

    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'url',
                label: 'URL',
                type: 'input',
            },
            {
                name: 'flipped',
                label: 'Button text black',
                type: 'switch',
            },
        ]);
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        var self = this; 
        let listener = window.addEventListener('blur', function() {
            if (document.activeElement === document.getElementById('iframe')) {
                setTimeout(function(){
                    self.setState({showIframe:true});
                },300);
            }
            window.removeEventListener('blur', listener);
        });
}

    /**
     * Renderer
     */
    render() {
        let { editMode, options } = this.props;
        let { showIframe } = this.state;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Container>
                    <Wrapper onClick={this.onClick} className={"wrapper"} >
                        <iframe title={"Iframe3D"} id="iframe" onClick={this.onClick} src={options.url} />
                        {!showIframe &&
                            <ClickDiv editMode={editMode}>
                                <Media module={this} field={'image'} fill aspectRatio={16 / 9} editPosition="nw" />
                                {!options.flipped && <StyledButton> Click to Load </StyledButton>}
                                {options.flipped && <StyledButton black> Click to Load </StyledButton>}
                            </ClickDiv>
                        }
                    </Wrapper>
                </Container>
            </OptionsWrapper>
        );
    }
}

export default Iframe3D;

const Container = styled.div`
    width: calc(100% - 260px);
    max-width: 1180px;
    margin: 0 auto;
    
    
    ${media.lessThan('huge')`
        width: calc(100% - 180px);
    `}

    ${media.lessThan('large')`
        width: calc(100% - 160px);
    `}

    ${media.lessThan('medium')`
        width: calc(100% - 100px);
    `}

    ${media.lessThan('small')`
        width: calc(100% - 30px);
    `}
`

const Wrapper = styled.div`
    font-family: 'Circular Pro Book';
    position: relative;
    padding-top: 56.25%;
    text-align: center;
    font-size: 18px;

    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; 
        height: 100%; 
        z-index: 1;
        border: none;
    }
   
`

const StyledButton = styled(Button)`
    position: absolute;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
`

const ClickDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgb(250, 250, 250);

    ${p => p.editMode ? `
        opacity: 0.9; 
    ` : `
        opacity: 0.9999; 
        pointer-events: none;
    `}
`
