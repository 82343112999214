import isServer from '../isServer'

const loadPolyfills = async () => {
  if (!Date.now) {
    Date.now = function now() {
      return new Date().getTime();
    };
  }

  if(isServer) return

  require('intersection-observer')
}

export default loadPolyfills