export default {
    black: '#4a494a',
    gray: '#f1f1f1',
    darkGray: '#dddddd',
    lightGray: '#e8e4de',
    red: '#EF0001',
    blue: '#0059af',
    green: '#20BC06',
    darkGreen: '#1F9E07',
    fadedBlue: 'rgba(0,89,175,.5)',
}
