import React from 'react'

class BaseTemplate extends React.Component {

    constructor(props) {
        super(props)

        this.registerZone = this.registerZone.bind(this)
    }

    registerZone(zone) {
        const { frontend } = this.props

        if(frontend) return

        this.props.onRegisterZone(zone)
    }
}

export default BaseTemplate
