import React from 'react'
import Modal from './Modal'

class CarbonOffsetPopup extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            display: false,
        }

        this.clickHandler = this.clickHandler.bind(this)
    }

    componentDidMount () {
        window.addEventListener('click', this.clickHandler)
    }

    componentWillUnmount () {
        window.removeEventListener('click', this.clickHandler)
    }

    clickHandler(e) {
        if (e.target instanceof HTMLAnchorElement && e.target.href.endsWith('#carbon-offset-modal')) {
            e.preventDefault()
            e.stopPropagation()
            this.setState({
                display: true,
            })
        }
    }

    render() {
        const { display } = this.state

        return <React.Fragment>
            { display && <Modal onCloseModal={ () => this.setState({ display: false }) }/> }
        </React.Fragment>
    }
}

export default CarbonOffsetPopup;