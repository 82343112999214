import React from 'react'
import { BaseTemplate, ModuleZone } from 'kbcms-react'

class BlankTemplate extends BaseTemplate {

    /**
     * Human readable name. Will be displayed in the CMS
     */
    static getName() { return "Blank template" }

    render() {
        const { entry, ssr, frontend, zones, editMode } = this.props

        return (
          <ModuleZone
            entry={ entry }
            ssr={ ssr }
            frontend={ frontend }
            zones={ zones }
            ref={ this.registerZone }
            editMode={editMode}
          />
        )
    }
}

export default BlankTemplate
