import React, { Component } from 'react'
import FileManagerActions from 'reducers/files';
import styled, { css } from 'styled-components';
import isServer from '../../../isServer'
import VideoButtons from './VideoButtons';
import Thumbnail from './Thumbnail';
import loadable from '@loadable/component'

const ReactPlayer = loadable(() => import('react-player'), {
    fallback: (() => <div/>)(),
});

class VideoMedia extends Component {

    /**
     * Constructor
     * @return {Void}
     */
    constructor(props) {
        super(props)

        this.state = {
            shouldRenderPlayer: true,
            windowSize: {
                width: isServer ? undefined : window.innerHeight,
                height: isServer ? undefined : window.innerWidth,
            }
        }

        this.onResize = this.onResize.bind(this)
    }


    openFileManager = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.props.fileManagerOpened && this.props.fileManagerOpened();

        const { aspectRatio, field, module, types } = this.props;

        FileManagerActions.openModal({ fileTypes: types !== '' && types !== undefined ? types : ['videos']}, (file) => {
                module.setField(field, { ...file }, () => {
                    this.forceUpdate();
                });
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const { muted } = this.props;
        const { muted: prevMuted } = prevProps;

        // Force reload on player to make new playback options reload
        if (muted !== prevMuted) {
            this.setState({ shouldRenderPlayer: false }, () => {
                this.setState({ shouldRenderPlayer: true })
            })
        }
    }

    componentDidMount () {
        window.addEventListener('resize', this.onResize)
        this.onResize()
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.onResize)
    }

    onResize() {
        this.setState({
            windowSize: {
                width: window.innerWidth,
                height: window.innerHeight,
            },
        })
    }

    getVideoDimensions = (file) => {
        if (file.data) {
            const { height, width } = JSON.parse(file.data);
            return { height, width };
        }
        return { height: 720, width: 1280 }
    }

    render() {
        const { field, editPosition, entryType, module, playing, onPause, muted } = this.props;
        const { shouldRenderPlayer, windowSize } = this.state;
        const file = { ...this.defaultFile, ...module.getField(field) };
        const { editMode } = module.props;
        const { height, width } = this.getVideoDimensions(file);
        const videoAspectRatio = width / height
        const windowAspectRatio = windowSize.width / (windowSize.height - 100)
        let maxWidth = '100%'

        if(windowAspectRatio > videoAspectRatio) {
            maxWidth = `${(windowSize.height - 100) * videoAspectRatio}px`
        }

        return (
          <AspectPadding maxWidth={maxWidth}>
            <PlayerWrapper
                editMode={editMode}
                videoHeight={height}
                videoWidth={width}>
                {shouldRenderPlayer && (
                    <VideoPlayer
                        url={file.mime === 'url/video' ? file.filename : file.original}
                        playing={playing}
                        onPause={onPause}
                        playsinline
                        loop
                        config={{
                            vimeo: {
                                playerOptions: {
                                    api: true,
                                    byline: false,
                                    title: false,
                                    loop: true,
                                    muted: !muted,
                                    autoplay: false,
                                    background: !muted,
                                    controls: muted,
                                },
                                preloading: true,
                            }
                        }}
                        width={'100%'}
                        height={'100%'}
                    />
                )}
                { editMode && (
                    <StyledVideoButtons
                        onOpenFileManager={this.openFileManager}
                        onAddPointer={this.addPointer}
                        editPosition={editPosition}
                        entryType={entryType}
                        mute={muted}
                        onToggleMute={this.props.onToggleMute}
                    />
                )}
            </PlayerWrapper>
          </AspectPadding>
        )
    }
}

const AspectPadding = styled.div`
    max-width: ${p => p.maxWidth};
    margin: 0 auto;
`

const PlayerWrapper = styled.div`
    ${p => p.editMode && 'background-color: #d2d2d2;'}
    position: relative;
    padding-top: ${p => 100 / (p.videoWidth / p.videoHeight)}%;
`

const VideoPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`

const StyledVideoButtons = styled(VideoButtons)`
      ${PlayerWrapper}:hover & {
        opacity: 1;
      }

      ${VideoPlayer}:hover & {
        opacity: 1;
      }
`


export default VideoMedia
