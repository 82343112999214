import React from 'react';
import styled, { css } from 'styled-components';
import _uniqueId from 'lodash/uniqueId';
import Colors from 'assets/styles/Colors';
import { default as media } from 'assets/styles/Breakpoints';
import CheckIcon from 'images/check-white.svg';

const CheckBox = ({ enabled, onChange, text, disabled }) => {
    const checkboxId = _uniqueId('checkbox-');

    const handleKeyPress = (e) => {
      // Only trigger the onChange if key was not:
      // TAB(9) => default option to move to next element
      // Shift(16) => Shift + Tab previous element
      if (e.keyCode === 9 || e.keyCode === 16) {
        //do nothing
        return;
      }
      onChange(e);
    }

    return (
        <InputWrapper
            onClick={onChange}
            onKeyDown={handleKeyPress}
            active={enabled}
            disabled={disabled}
            role="checkbox"
            tabIndex={0}
            aria-checked={enabled}
            aria-labelledby={checkboxId}
        >
            {/*Removed from screen readers replaced by input wrapper*/}
            <input type = 'checkbox' checked={enabled} readOnly aria-hidden={true} tabIndex={-1} />
            <span className="checkmark"/>
            <label className="text" id={checkboxId}>{text}</label>
        </InputWrapper>
    )
}

const InputWrapper = styled.li`
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 25px 0;
    color: ${Colors.black};
    cursor: pointer;
    position: relative;

    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: -2px;
        left: -25px;
        height: 20px;
        width: 20px;
        background: #ffffff;
        border-radius: 5px;
        border: 1px solid;
    }

    input:checked ~ .checkmark {
        border: none; 
    }

    &:hover{
        .checkmark {
            border: 1px solid ${Colors.blue};
        }
    } 

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
        background-color: ${Colors.blue};
    }

    .checkmark:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background: url(${CheckIcon}) center center no-repeat;
    }

    ${p => p.disabled && css`  
        color: ${Colors.darkGray};
        pointer-events: none;
        .checkmark {
            border: 1px solid ${Colors.darkGray};
        }
    `}

    ${media.lessThan('medium')`
        margin: 5px 0 5px 25px;
    `}
`

export default CheckBox
