import React from 'react';
import { Editor, Linker, NewMedia } from 'kbcms-react';
import BaseModule from 'modules/DBBaseModule';
import { Box } from 'grid-styled';
import styled, { css } from 'styled-components';
import OptionsWrapper from 'components/OptionsWrapper';
import { default as media } from 'assets/styles/Breakpoints';
import fontOptions from 'assets/styles/FontOptions';
import produce from 'immer';

class FrontPageCover extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Front Page Cover" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props)

        this.onChangeField = this.onChangeField.bind(this)

        this.state = {
            fields: {
                ...{}, ...{
                    imageArray: [
                        null,
                        null,
                        null,
                        null
                    ],
                    title1: 'Edit this title',
                    title2: 'Edit this title',
                    title3: 'Edit this title',
                    title4: 'Edit this title',
                    muted: [
                        false,
                        false,
                        false,
                        false,
                    ],
                    forceNativeVideoAspectRatios: [
                        false,
                        false,
                        false,
                        false
                    ]
                },
                ...props.fields,
            }
        }
    }

    /**
    * When content editable field is changed
    * @return {Void}
    */
    onChangeField = (name, value) => {
        this.setState({ fields: Object.assign(this.state.fields, { [name]: value }) })
    }

    static options() {
        return super.options().concat([
            {
                name: 'flipped',
                label: 'Flip content direction',
                type: 'switch'
            },
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    /*
     * Toggles mute on the video on an category-item
     */
    onToggleMute = (index) => {
        const nextState = produce(this.state, draft => {
            draft.fields.muted[index] = !draft.fields.muted[index];
        })

        this.setState(nextState)
    }

    /*
     * Toggles native Aspect-Ratio for video on a category-item
     */
    onToggleNativeAspectRatio = (index) => {
        const nextState = produce(this.state, draft => {
            draft.fields.forceNativeVideoAspectRatios[index] = !draft.fields.forceNativeVideoAspectRatios[index];
        })

        this.setState(nextState)
    }

    /**
    * Renderer
    */
    render() {
        let { options, editMode } = this.props;
        let { fields } = this.state;
        const { forceNativeVideoAspectRatios, muted } = fields;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper flipped={options.flipped}>
                    <Slot>
                        <StyledMediaLinker module={this} field={'image_link_url_1'}>
                            <StyledMedia
                                module={this}
                                overlay={options.overlay}
                                aspectRatio={31/34}
                                editPosition={"sw"}
                                types={['images', 'videos']}
                                videoOptions={{
                                    onToggleMute: () => this.onToggleMute(0),
                                    onToggleNativeAspectRatio: () => this.onToggleNativeAspectRatio(0),
                                    forceNativeVideoAspectRatio: forceNativeVideoAspectRatios[0],
                                    muted: muted[0],
                                    autoplay: muted[0]
                                }}
                                fill
                                field={'imageArray[0]'}>
                                <StyledContentArea>
                                    <Editor
                                        module={this}
                                        disabled={!editMode}
                                        field={'title1'}
                                        data={fields.title1}
                                        fonts={fontOptions}
                                        defaultFont='Lyon Display'
                                    />
                                </StyledContentArea>
                            </StyledMedia>
                        </StyledMediaLinker>

                        <StyledMediaLinker module={this} field={'image_link_url_2'}>
                            <StyledMedia
                                module={this}
                                aspectRatio={31/18}
                                editPosition={"sw"}
                                overlay={options.overlay}
                                types={['images', 'videos']}
                                fill
                                videoOptions={{
                                    onToggleMute: () => this.onToggleMute(1),
                                    onToggleNativeAspectRatio: () => this.onToggleNativeAspectRatio(1),
                                    forceNativeVideoAspectRatio: forceNativeVideoAspectRatios[1],
                                    muted: muted[1],
                                    autoplay: muted[1]
                                }}
                                field={'imageArray[1]'}>
                                <StyledContentArea>
                                <Editor
                                        module={this}
                                        disabled={!editMode}
                                        field={'title2'}
                                        data={fields.title2}
                                        fonts={fontOptions}
                                        defaultFont='Lyon Display'
                                    />
                                </StyledContentArea>
                            </StyledMedia>
                        </StyledMediaLinker>
                    </Slot>

                    <Slot>
                        <StyledMediaLinker module={this} field={'image_link_url_3'}>
                            <StyledMedia
                                module={this}
                                aspectRatio={31/18}
                                types={['images', 'videos']}
                                overlay={options.overlay}
                                editPosition={"sw"}
                                fill
                                videoOptions={{
                                    onToggleMute: () => this.onToggleMute(2),
                                    onToggleNativeAspectRatio: () => this.onToggleNativeAspectRatio(2),
                                    forceNativeVideoAspectRatio: forceNativeVideoAspectRatios[2],
                                    muted: muted[2],
                                    autoplay: muted[2]
                                }}
                                field={'imageArray[2]'}>
                                <StyledContentArea>
                                    <Editor
                                        module={this}
                                        disabled={!editMode}
                                        field={'title3'}
                                        data={fields.title3}
                                        fonts={fontOptions}
                                        defaultFont='Lyon Display'
                                    />
                                </StyledContentArea>
                            </StyledMedia>
                        </StyledMediaLinker>

                        <StyledMediaLinker module={this} field={'image_link_url_4'}>
                            <StyledMedia
                                module={this}
                                aspectRatio={31/34}
                                types={['images', 'videos']}
                                overlay={options.overlay}
                                editPosition={"sw"}
                                fill
                                videoOptions={{
                                    onToggleMute: () => this.onToggleMute(3),
                                    onToggleNativeAspectRatio: () => this.onToggleNativeAspectRatio(3),
                                    forceNativeVideoAspectRatio: forceNativeVideoAspectRatios[3],
                                    muted: muted[3],
                                    autoplay: muted[3]
                                }}
                                field={'imageArray[3]'}>
                                <StyledContentArea>
                                    <Editor
                                        module={this}
                                        disabled={!editMode}
                                        field={'title4'}
                                        data={fields.title4}
                                        fonts={fontOptions}
                                        defaultFont='Lyon Display'
                                    />
                                </StyledContentArea>
                            </StyledMedia>
                        </StyledMediaLinker>
                    </Slot>
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default FrontPageCover;

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: center;
    max-width: 1260px;

    ${props => props.flipped === true && css`
        flex-direction: row-reverse;
    `}

    ${media.lessThan('huge')`
        width: calc(100% - 180px);
    `}

    ${media.lessThan('large')`
        width: calc(100% - 160px);
    `}

    ${media.lessThan('medium')`
        width: calc(100% - 100px);
    `}

    ${media.lessThan('small')`
        width: calc(100% - 30px);
    `}

    ${media.lessThan('mobile')`
        flex-direction: column;
    `}
`

const Slot = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const StyledMediaLinker = styled(Linker)`
    width: 100%;
    padding: 8px;
    text-decoration: none;

    ${media.lessThan('medium')`
        padding: 0 8px 16px;
    `}
`

const StyledMedia = styled(NewMedia)`
    position: relative;
`

const StyledContentArea = styled(Box)`
    position: absolute;
    pointer-events: auto;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    text-align: center;

    h1, h2, div {
        color: white;
        text-decoration: none;
    }
`
