import React, { Component } from 'react'
import { connect } from 'react-redux';

/**
 * HoC that passes the reservation data as a prop.
 * @param {*} PassedComponent
 */
const withReservation = PassedComponent => {
    const Wrapper = props => {
        return <PassedComponent reservation={props.reservation} {...props} />
    }

    return connect(mapStateToProps)(Wrapper);
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...{
            reservation: state.reservation,
        },
        ...ownProps
    };
};

export default withReservation;
