
import React, { Component } from 'react'
import { connect } from 'react-redux';

/**
 * HoC that passes the cart as a prop.
 * @param {*} PassedComponent
 */
const withCart = PassedComponent => {
    const Wrapper = props => {
        return <PassedComponent 
            customerBillingDetails={props.customerBillingDetails} 
            separateBillingDetails={props.separateBillingDetails} 
            cart={props.data} 
            variations={props.variations}
            {...props} 
        />
    }

    return connect(mapStateToProps)(Wrapper);
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.cart,
        customerBillingDetails: {
            ...state.billingDetails,
        },
        separateBillingDetails: {
            ...state.separateBillingDetails,
        },
        ...ownProps
    };
};

export default withCart;
