import React from 'react'
import loadable from '@loadable/component'
import FrontendRenderer from '../../../editor/FrontendRenderer'

const Editable = loadable(() => import(/* webpackChunkName: "editable" */'./Editable'), {
    fallback: (() => <div/>)(),
});

class Editor extends React.Component {
    render() {
        const { props } = this

        if(props.disabled) {
            return <FrontendRenderer {...props}/>
        }

        return <Editable {...props}/>
    }
}

export default Editor