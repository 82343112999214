import React from "react";

const getViewBox = name => {
    switch (name) {
        case "add":
            return "0 0 30 30";
        case "arrow-down":
            return "0 0 24 24";
        case "arrow-right":
            return "0 0 24 24";
        case "arrow-up":
            return "0 0 24 24";
        case "arrow-left":
            return "0 0 24 24";
        case "checkmark":
            return "0 0 14 11";
        case "hamburger-menu":
            return "0 0 24 24";
        case "img":
            return "0 0 24 24";
        case "link":
            return "0 0 24 24"
        case "mark-bold":
            return "0 0 24 24";
        case "mark-italic":
            return "0 0 24 24";
        case "align_left":
            return "0 0 24 24";
        case "align_center":
            return "0 0 24 24 "
        case "mark-underline":
            return "0 0 24 24";
        case "search":
            return "0 0 24 24";
        case "spinner":
            return "0 0 24 24";
        case "trash":
            return "0 0 24 24";
        case "triangle-down":
            return "0 0 16 16";
        case "tirangle-right":
            return "0 0 16 16";
        case "x":
            return "0 0 24 24";
        case "+":
            return "0 0 24 24";
        case "-":
            return "0 0 24 24";
        case "expand":
            return "0 0 24 24";
    }
};

const getPath = (name, props) => {
    switch (name) {
        case "add":
            return (
                <React.Fragment>
                    <circle {...props} cx="15" cy="15" r="15"/>
                    <path {...props} d="M22 15L8 15" />
                    <path {...props} d="M15 22L15 8" />
                </React.Fragment>
            );
        case "arrow-down":
            return (
                <path {...props} d="M19 9L12 16L5 9" fill="none"/>
            );
        case "arrow-up":
            return (
                <path {...props} d="M19 15L12 8L5 15"fill="none"/>
            );
        case "arrow-right":
            return (
                <path {...props} d="M9 5L16 12L9 19" fill="none"/>
            );
        case "arrow-left":
            return (
                <path {...props} d="M15 5L8 12L15 19" fill="none"/>
            );
        case "checkmark":
            return (
                <path {...props} d="M13 1L5 9L1.00001 5" fill="none"/>
            );
        case "hamburger-menu":
            return (
                <React.Fragment>
                    <path {...props} d="M4 7H20" fill="none"/>
                    <path {...props} d="M4 12H20" fill="none"/>
                    <path {...props} d="M4 17H20" fill="none"/>
                </React.Fragment>
            );
        case "img":
            return (
                <React.Fragment>
                    <path {...props} d="M17 7H20V18H4V7H7H7.61803L7.89443 6.44721L8.61803 5H15.382L16.1056 6.44721L16.382 7H17Z" fill="none"/>
                    <circle {...props} cx="12" cy="12" r="1.5" strokeWidth="3" fill="none"/>
                </React.Fragment>
            )

        case "link":
            return (
                <React.Fragment>
                    <path {...props} d="M7.75737 13.4142L5.63605 11.2929C4.07395 9.7308 4.07395 7.19814 5.63605 5.63604V5.63604C7.19815 4.07395 9.73081 4.07395 11.2929 5.63604L13.4142 7.75736" fill="none"/>
                    <path {...props} d="M10.5858 16.2428L12.7071 18.3641C14.2692 19.9262 16.8019 19.9262 18.364 18.3641V18.3641C19.9261 16.802 19.9261 14.2693 18.364 12.7072L16.2426 10.5859" fill="none"/>
                    <path {...props} d="M8.46448 8.46436L15.5355 15.5354" fill="none"/>
                </React.Fragment>
            );
        case "mark-bold":
            return (
                <React.Fragment>
                    <path {...props} d="M9 12H13C14.933 12 16.5 13.567 16.5 15.5V15.5C16.5 17.433 14.933 19 13 19H8V5H11" fill="none"/>
                    <path {...props} d="M11 5H12.5C14.433 5 16 6.567 16 8.5V8.5C16 10.433 14.433 12 12.5 12H9" fill="none"/>
                </React.Fragment>
            );
        case "mark-italic":
            return (
                <React.Fragment>
                    <path {...props} d="M7 19H12.5" fill="none" />
                    <path {...props} d="M11.5 5H17" fill="none" />
                    <path {...props} d="M9.5 19L14.5 5" fill="none" />
                </React.Fragment>
            );
        case "mark-underline":
            return (
                <React.Fragment>
                    <path {...props} d="M6 19H18" fill="none" />
                    <path {...props} d="M7 4V11C7 13.7614 9.23858 16 12 16V16C14.7614 16 17 13.7614 17 11V4" fill="none" />
                </React.Fragment>
            );
        case "search":
            return (
                <React.Fragment>
                    <circle {...props} cx="10.5" cy="10.5" r="6.5" fill="none"/>
                    <path {...props} d="M15 15L19 19" fill="none"/>
                </React.Fragment>
            );
        case "spinner":
            return (
                <path {...props} d="M20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84286 16.1566 4 14.1217 4 12" fill="none"/>
            );
        case "trash":
            return (
                <React.Fragment>
                    <path {...props} d="M4 8H6.5H20" fill="none"/>
                    <path {...props} d="M9 4H9.9375H15" fill="none"/>
                    <path {...props} d="M6 11L7 20H17L18 11" fill="none"/>
                </React.Fragment>
            );
        case "align_left":
            return (
                <React.Fragment>
                    <path
                        d="M0 0h24v24H0z"
                        fill="none"/>
                        <path
                            {...props}
                            d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z"
                        />
                </React.Fragment>
            )
        case "align_center":
            return (
                <React.Fragment>
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path {...props} d="M7 15v2h10v-2H7zm-4 6h18v-2H3v2zm0-8h18v-2H3v2zm4-6v2h10V7H7zM3 3v2h18V3H3z"/>
                </React.Fragment>
            )
        case "triangle-down":
            return (
                <path {...props} d="M8 11L13 4L3 4L8 11Z" stroke="none"/>
            );
        case "triangle-right":
            return (
                <path {...props} d="M11 8L4 3V13L11 8Z" stroke="none"/>
            );
        case "x":
            return (
                <React.Fragment>
                    <path {...props} d="M18 6L6 18"/>
                    <path {...props} d="M18 18L6 6"/>
                </React.Fragment>
            );
        case "+":
            return (
                <React.Fragment>
                    <path {...props} d="M19 12L5 12" fill="none" />
                    <path {...props} d="M12 19L12 5" fill="none"/>
                </React.Fragment>
            );
        case "-":
            return (
                <React.Fragment>
                    <path {...props} d="M19 12L5 12" fill="none" />
                </React.Fragment>
            );
        case "expand":
            return (
                <React.Fragment>
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" >
                        <g {...props} id="expand-2" transform="translate(2.000000, 2.000000)" strokeWidth="2.72727273">
                            <g id="expand">
                                <polyline id="Path" points="20 13.6363636 20 20 13.6363636 20"></polyline>
                                <path d="M19.4204545,19.41875 L12.7272727,12.7272727" id="Path"></path>
                                <polyline id="Path" points="0 6.36363636 0 0 6.36363636 0"></polyline>
                                <path d="M0.579545455,0.58125 L7.27272727,7.27272727" id="Path"></path>
                                <polyline id="Path" points="13.6363636 0 20 0 20 6.36363636"></polyline>
                                <path d="M19.41875,0.579545455 L12.7272727,7.27272727" id="Path"></path>
                                <polyline id="Path" points="6.36363636 20 0 20 0 13.6363636"></polyline>
                                <path d="M0.58125,19.4204545 L7.27272727,12.7272727" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </React.Fragment>
            )
        default:
            return <path />;
    }
};

const Icon = ({
    name = "",
    style = {},
    fill = "#000",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%",
    stroke = "#000",
    strokeWidth = 2,
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || getViewBox(name)}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            {getPath(name, { fill, stroke, strokeWidth })}
        </svg>
    );

export default Icon;
