import React, { Component } from 'react'
import { connect } from "react-redux";

class CartQuantity extends Component {
    render() {
        let totalQuantity = 0;

        if (this.props.data !== null) {
            if (typeof this.props.data.selection !== 'undefined') {
                totalQuantity = this.props.data.selection.totals.totalQuantity;
            }
        }

        return <span>{totalQuantity}</span>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.cart,
        ...ownProps
    };
};

export default connect(mapStateToProps)(CartQuantity);