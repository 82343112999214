import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';

hydrate(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
  function() {
    let interval = setInterval(() => {
      if (window.google_optimize !== undefined) {
        window.dataLayer.push({ event: "optimize.activate" });
        clearInterval(interval);
      }
    }, 100);
  }
);

if (module.hot) {
  module.hot.accept();
}
