import FocusTrap from 'focus-trap-react'
import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import CloseIcon from 'images/db-close.svg';
import ButtonNormalized from './ButtonNormalized'

/**
 * Custom modal styles.
 */
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0, 0.2)',
        zIndex: '1000'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0px',
        padding: '0px',
        border: 'none'
    }
}

ReactModal.setAppElement('#root');

class Modal extends React.Component {

    state = {
        modalVisible: false
    }

    /**
     * Opens the modal
     * @return {Void}
     */
    openModal = () => {
        this.setState({ modalVisible: true });
    }

    /**
     * Closes the modal
     * @return {Void}
     */
    closeModal = () => {
        this.setState({ modalVisible: false });

        if(this.props.close) {
            this.props.close();
        }
    }

    isOpen = () => {
        return this.state.modalVisible;
    }

    /**
     * Renderer
     * @return {Void}
     */
    render() {
        const { modalVisible } = this.state
        const { hideCloseIcon, icon, style } = this.props

        const FocusTrapIfVisible = modalVisible ? FocusTrap : React.Fragment

        return (
            <ReactModal 
                isOpen={ modalVisible }
                onRequestClose={this.closeModal}
                style={style ? style : customStyles}
                {...this.props}>

                { (!hideCloseIcon) && <CloseButton onClick={ this.closeModal } icon={ icon ? icon : CloseIcon }/> }
                {this.props.children}
            </ReactModal>
        )
    }
}

export default Modal;

const CloseButton = styled(ButtonNormalized)`
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-block;
    background: url(${p => p.icon ? p.icon : ''}) no-repeat center center;
    width: 25px;
    height: 25px;
    cursor: pointer;
`
