import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { Globals } from 'kbcms-react';
import Colors from '../../assets/styles/Colors'
import ProductLabels from '../ProductLabels'
import Variation from './Variation'

export default class ProductBox extends PureComponent {

    /**
     * Constructor
     * @param {Object} props
     * @return {Void}
     */
    constructor(props) {
        super(props);

        let activeVariation = null;
        let variations = []

        this.props.product.variations.sort((a, b) => {
            return a.sort < b.sort
        }).forEach(variation => {
            variations.push(variation)
        });

        activeVariation = props.product.variations.find(v => {
            return v.sku === variations[0].sku
        })

        if (props.selectedVariation) {
            props.product.variations.forEach(variation => {
                if (variation.id === props.selectedVariation) {
                    activeVariation = variation;
                }
            });
        }

        this.state = {
            activeVariation: activeVariation,
            imagesPreloaded: false,
            variations: variations,
        }
    }

    componentDidMount() {
        if (window.yotpo_refreshed === undefined || window.yotpo_refreshed < Math.floor(Date.now() / 1000) - 1) {
            window.yotpo_refreshed = Date.now() / 1000;
            setTimeout(function() {
                window.yotpoLoader();
            }, 1000);
        }
    }

    componentDidUpdate(prevProps) {
        let { selectedVariation, product } = this.props;
        let { activeVariation } = this.state;

        if(selectedVariation !== prevProps.selectedVariation){
            let active;

            if( activeVariation.id !== selectedVariation){
                active = false;
                product.variations.forEach(variation => {
                    if (variation.id === selectedVariation) {
                        this.setState({ activeVariation : variation });
                        active = variation;
                    }
                });
            }

            if(active === false && activeVariation !== product.variations[0]){
                this.setState({ activeVariation: product.variations[0] });
            }
        }
    }

    /**
     * Changes the color in the view, also sets the URI for the product link to
     * the selected variation.
     * @return {Void}
     */
    selectVariationForItem = variationId => {
        let currentVariation;

        this.props.product.variations.forEach(variation => {
            if (variationId === variation.id) {
                currentVariation = variation;
            }
        })

        this.setState({ activeVariation: currentVariation }, () => {
            if (typeof this.props.onVariationChange === 'function') {
                this.props.onVariationChange(variationId);
            }
        });
    }

    /**
     * Preloads first image variations
     * @return {Void}
     */
    preloadImages = () => {
        this.props.product.variations.forEach(item => {
            const img = new Image();
            img.src = item.images.standard[0].url;
        });
    }


    /**
     * When mouse enters
     * @return {Void}
     */
    onMouseEnter = event => {
        if (this.state.imagesPreloaded === true) return null;

        this.setState({ imagesPreloaded: true }, () => {
            this.preloadImages();
        });
    }



    /**
     * Render
     * @return {JSX}
     */
    render() {
        let { activeVariation, variations } = this.state;
        let { product } = this.props;

        const removeAW1920 = (activeVariation) => {
            const { sku, slug } = activeVariation;
            /*
             * TODO
             * This is a workaround to the issue with some yotpo star ratings
             * This issue should probably be resolved by adding the correct sku's
             * to yotpos product catalog. The check's below represents the affected products
             */
            if (slug.includes('the-slim-jim') ||
                slug.includes('the-proper-laptop') ||
                slug.includes('the-vain') ||
                slug.includes('the-expeditionist')
            ) {
                const lastSix = sku.slice(sku.length - 6);
                if (lastSix === 'AW1920') {
                   return sku.slice(0, sku.length - 6)
                }
            }

            return sku;
        }

        if(typeof activeVariation === 'undefined' || activeVariation === null) return <div />;

        if(activeVariation.slug.includes('the-varldsvan-iphone')) {
            variations = [variations[0], variations[1]]
        }

        return (
            <StyledProductWrapper onMouseEnter={this.onMouseEnter} className={this.props.className}>
                {this.props.children}
                <ImageWrapper>
                    <Link to={'/' + activeVariation.slug}>
                        
                        { activeVariation.hover_image === 0 &&
                        <React.Fragment>
                            <DefaultImage data-src={ activeVariation.images.standard[0].url } alt={ product.name } className="lazyload"/>
                            <HoverImage fill="true" data-src={ activeVariation.images.standard[activeVariation.images.standard.length - 1].url } alt={ product.name } className="lazyload"/>
                        </React.Fragment> }

                        { activeVariation.hover_image === 1 &&
                        <React.Fragment>
                            <DefaultImage fill="true" data-src={ activeVariation.images.standard[activeVariation.images.standard.length - 1].url } alt={ product.name } className="lazyload"/>
                            <HoverImage data-src={ activeVariation.images.standard[0].url } alt={ product.name } className="lazyload"/>
                        </React.Fragment> }

                    </Link>
                    <ProductLabelWrapper>
                        <ProductLabels variation={ activeVariation }/>
                    </ProductLabelWrapper>
                    <ReadMore>
                        <Button>{Globals.get('mod_pb_read_more')}</Button>
                    </ReadMore>
                </ImageWrapper>
                <ProductInfo>
                    <ProductTitle>{product.name}</ProductTitle>
                    <YotpoStars className="yotpo bottomLine"
                        data-product-id={removeAW1920(activeVariation)}
                    />
                    { (product.slogan && product.slogan.length > 0) ?
                      <ProductType>{ product.slogan }</ProductType> :
                      <ProductType>{ activeVariation.meta_fields.excerpt }</ProductType>
                    }
                    <ProductPrice>
                        {activeVariation.prices[0].on_sale === 1 && (
                            <React.Fragment>
                                <PreviousPrice>{activeVariation.prices[0].price_before_discount}</PreviousPrice>
                                {activeVariation.prices[0].price}
                            </React.Fragment>
                        )}
                        {activeVariation.prices[0].on_sale === 0 && activeVariation.prices[0].price}
                    </ProductPrice>
                    <VariationsWrapper>
                       { variations.length > 0 &&
                            <VariationGroup>
                                <Variations>
                                    {variations.map((currentVariation, key) => {
                                        if (currentVariation.product_size) {
                                            return (
                                                <SizeVariation
                                                    key={currentVariation.id}
                                                    productSize={currentVariation.product_size}
                                                    active={currentVariation.id === activeVariation.id}
                                                    onClick={() => this.selectVariationForItem(currentVariation.id)}
                                                />
                                            )
                                        }
                                        return (
                                            <Variation
                                                variation={currentVariation}
                                                key={currentVariation.id}
                                                swatch={currentVariation.swatch}
                                                active={currentVariation.id === activeVariation.id}
                                                onClick={() => this.selectVariationForItem(currentVariation.id)}
                                                className="lazyload"
                                            />
                                        )
                                    })}
                                </Variations>
                            </VariationGroup>
                        }
                    </VariationsWrapper>
                </ProductInfo>
            </StyledProductWrapper>
        );
    }
}

const YotpoStars = styled.div`
    text-align: center;
    margin: 5px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .text-m {
        display: none;
    }
`

const StyledProductWrapper = styled.div`
    margin: 0 10px 40px;
    width: calc(25% - 20px);
    text-align: center;

    ${media.lessThan('medium')`
        width: calc(33% - 20px);
        margin: 0 10px 15px;
    `}

    ${media.lessThan('mobile')`
        width: calc(50% - 20px);
    `}
`

const ProductInfo = styled.div`
    padding: 1em 30px 0;

    ${media.lessThan('medium')`
        padding: 1em 15px 0;
    `}
`

const PreviousPrice = styled.span`
    text-decoration: line-through;
    color: ${Colors.darkGray};
    margin-right: 7px;
    font-variant-numeric: lining-nums;

    ${media.lessThan('medium')`
        display: block;
    `}
`

const ProductLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    left: 0;
    
    .product-label {
        margin-bottom: 10px;
        img {
            height: 10px;
            margin-right: 3px;
        }
    }
`

const ProductTitle = styled.h4`
`

const ProductType = styled.div`
    font-size: 11px;
    line-height: 15px;
    font-family: 'Circular Pro';
    text-transform: uppercase;
`

const ProductPrice = styled.div`
    font-size: 18px;
    line-height: 20px;
    font-family: 'Lyon Text Bold';
    margin-top: .3em;
    height: 21px;
    font-variant-numeric: lining-nums;

    ${media.lessThan('medium')`
        height: auto;
    `}
`


const VariationsWrapper = styled.div`
    border-top: 1px solid #e8e4de;
    padding: 1em 0;
    margin-top: .8em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${media.lessThan('medium')`
        align-items: center;
    `}
`

const VariationGroup = styled.div`
    display: flex;
    padding: 5px 0;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;

    ${media.lessThan('medium')`
        flex-direction: column;
    `}

    ${p => p.shouldCenter && css`
        align-self: center;
    `}
`

const Variations = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const SizeVariation = styled.div`
    position: relative;
    display: inline-block;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    margin: 1px 3px;
    cursor: pointer;

    &:before {
        font-family: "Circular Pro", sans-serif;
        color: ${p => p.active ? 'black' : 'gray'};
        position: absolute;
        top: 0;
        left: 4px;
        content: "${p => p.productSize}";
        font-size: 14px;
        line-height: 17px;
    }
`

const ReadMore = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 66%;
    transform: translate(-50%, -50%);
    padding: 15px;
    opacity: 0;
    transition: opacity .3s ease;
    background: none;
    pointer-events: none;

    ${media.lessThan('medium')`
        display: none;
    `}
`

const DefaultImage = styled.img`
    opacity: 0;
    
    &.lazyloaded {
        opacity: 1;
    }
    
    ${p => p.fill === "true" && css`
      width: auto !important;
      height: 100% !important;
    `}
`

const HoverImage = styled.img`
    opacity: 0;
  
    ${p => p.fill === "true" && css`
      width: auto !important;
      height: 100% !important;
    `}
`

const ImageWrapper = styled.div`
    background: #f1f1f1;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 134%;
    }

    &> a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        text-decoration: none;

        &> img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &:hover {
        ${ReadMore} {
            opacity: 1;
        }
        ${HoverImage} {
            opacity: 1;
        }
        ${DefaultImage} {
            opacity: 0;
        }
    }
`

const VariationGroupTitle = styled.div`
    font-size: 11px;
    line-height: 15px;
    font-family: 'Circular Pro';
    text-transform: uppercase;
    width: 60px;
    text-align: left;
`
