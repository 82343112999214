import Button from './Button';
import IconButton from './IconButton';
import Input from './Input';
import Switch from './Switch';

const Form = {
    Button,
    IconButton,
    Input,
    Switch,
}

export default Form;