import store from '../store'
import ApiManager from "../ApiManager";

const Api = new ApiManager();

const STATUSES = {
    LOGGED_OUT: 'LOGGED_OUT',
    AWAITING_VERIFICATION_CODE: 'AWAITING_VERIFICATION_CODE',
    LOGGED_IN: 'LOGGED_IN',
};

const defaultState = {
    loggedIn: false,
    email: null,
    user: null,
    jwt: null,
    status: STATUSES.LOGGED_OUT,
    maintenanceCode: null,
};

const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
const LOGIN_USER = 'LOGIN_USER';
const LOG_OUT = 'LOG_OUT';
const SET_MAINTENANCE_CODE = 'SET_MAINTENANCE_CODE';

export const AuthReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOG_OUT:
            return {...{},...state,
                ...{
                    loggedIn: false,
                    email: null,
                    user: null,
                    jwt: null,
                    status: STATUSES.LOGGED_OUT,
                }
            };
        case AUTHENTICATE_USER:
            return {...{},...state,
                ...{
                    email: action.email,
                    status: STATUSES.AWAITING_VERIFICATION_CODE,
                }
            };
        case LOGIN_USER:
            return {...{},...state,
                ...{
                    loggedIn: true,
                    user: action.user,
                    jwt: action.jwt,
                    status: STATUSES.LOGGED_IN,
                }
            };
        case SET_MAINTENANCE_CODE:
            return {...{}, ...state,
                ...{
                    maintenanceCode: action.code
                }
            };
        default:
            return state;
    }
};

const AuthActions = {
    logOut: () => {
        store.dispatch({
            type: LOG_OUT,
        });
    },
    authenticateUser: (email, password) => {
        return Api.authenticateUser(email, password).then((result) => {
            store.dispatch({
                type: AUTHENTICATE_USER,
                email: email,
            });
        }).catch((error) => {
            return false; 
        }); 
    },
    verifyAuthenticationCode: (email, code) => {
        return Api.verifyAuthenticationCode(email, code).then((result) => {
            store.dispatch({
                type: LOGIN_USER,
                user: result.data.user,
                jwt: result.data.jwt,
            });
        }).catch((error) => {
            return false; 
        })
    },
    storeUserChanges: (userValues) => {
        return Api.updateUser(userValues).then((result) => {
            store.dispatch({
                type: LOGIN_USER,
                user: result.data
            });
        }).catch(error => {
            return false; 
        }); 
    },
    get: () => {
        return store.getState().auth.user;
    },
    setMaintenanceCode: (code) => {
        store.dispatch({
            type: SET_MAINTENANCE_CODE,
            code: code,
        });
    }
};

export default AuthActions;