import React from 'react'
import loadable from '@loadable/component'
import styled from 'styled-components';
import shortid from 'shortid'
import ModuleLoader from './ModuleLoader'
import scrollToId from '../../../helpers/scrollToId'

const AvailableModules = loadable(() => import('./AvailableModules'), {
    fallback: (() => <div/>)(),
});

const OptionsModal = loadable(() => import('cms/components/Modal/OptionsModal'), {
    fallback: (() => <div/>)(),
});

/**
 * A module zone is where we have the ability to add modules in a template.
 * @extends React
 */
class ModuleZone extends React.Component {

    /**
     * constructor
     */
    constructor(props) {
        super(props)

        this.state = {
            availableModules: this.loadAllAvailableModulesFromProject(),
            modules: {},
            editMode: typeof props.editMode !== 'undefined' ? props.editMode : true,
            modalOptionsForModuleId: null,
            moduleLimit: props.moduleLimit ? props.moduleLimit : null,
            currentTemplate: null,
            addNewModule: false,
            currentModuleId: null,
            atBottom: false,
        }
    }


    /**
     * Sets the current template
     * @return {Void}
     */
    setCurrentTemplate = template => {
        const { frontend } = this.props

        if(frontend) return

        this.setState({ currentTemplate: template });
    }


    /**
     * Loads all available modules from the project's ./src/modules/ folder
     * and reads out the names for the module picker.
     */
    loadAllAvailableModulesFromProject = () => {
        let loadedModules = []

        var req = require.context("modules/", true, /.*index\.js$/);

        req.keys().forEach(function(key){
            let currentComp = req(key).default;

            let moduleName = key.substring(2).split('.')[0].split('/')[0];

            loadedModules[moduleName] = {
                name: currentComp.getName(),
                hidden: currentComp.hasOwnProperty('hidden') ? currentComp.hidden() : false,
                deletable: currentComp.hasOwnProperty('deletable') ? currentComp.deletable() : true,
                component: currentComp,
                image : (typeof currentComp.getImage === 'function') ? currentComp.getImage() : null
            }

        });

        return loadedModules;
    }


    /**
     * Gets all modules and the data saved in this.state.fields within each
     * module.
     */
    getModulesWithData() {
        let { modules } = this.state

        let modulesWithData = Object.assign({}, modules);

        Object.keys(modules).map(key => {
            let currentModule = modules[key]

            return modulesWithData[key] = Object.assign({}, currentModule, {
                fields: currentModule.component.getFields(),
                options: currentModule.options
            })
        });

        return modulesWithData
    }


    /**
     * Checks if the modules we want to prepend already have been added, if so
     * we dont.
     * @return {Void}
     */
    prependModules = () => {
        let { prependModules } = this.props;
        let { availableModules, modules } = this.state;

        if (typeof prependModules !== 'undefined') {
            // we dont want to prepend when it already exists
            const existingModuleNames = [];

            Object.keys(modules).forEach(key => {
                existingModuleNames.push(modules[key].name);
            });

            prependModules.forEach(moduleKey => {
                if (existingModuleNames.includes(moduleKey) === false) {
                    let newModule = {
                        'description': availableModules[moduleKey].name,
                        'name': moduleKey
                    }

                    this.onModuleSelected(newModule);
                }
            });
        }
    }


    /**
     * Handles loading of modules from save data
     */
    loadModulesFromSaveDataAndAttachData = (modules, callback) => {
        const { availableModules } = this.state;
        const loadedModules = {}

        Object.keys(modules).map(key => {
            let currentModule = modules[key];

            if (availableModules.hasOwnProperty(currentModule.name) === false) {
                return loadedModules[key] = currentModule;
            }

            // make sure config is up to date
            const defaultOptions = availableModules[currentModule.name].component.options(this.props.entry);
            const defaultOptionNames = [];

            // add options that exists in configuration but not saved on the module.
            // TODO: Refactor this to avoid code dupes
            defaultOptions.forEach(o => {
                defaultOptionNames.push(o.name);

                if (currentModule.options.hasOwnProperty(o.name) === false) {
                    let defaultValue;

                    if (o.defaultValue) {
                        defaultValue = o.defaultValue;
                    } else if (o.options !== undefined) {
                        defaultValue = o.options.slice(0).shift().value;
                    } else {
                        if (o.type === 'switch') {
                            defaultValue = false;
                        } else {
                            defaultValue = '';
                        }
                    }

                    currentModule.options[o.name] = defaultValue;
                }
            });

            // delete options that no longer are defined in configuration
            Object.keys(currentModule.options).forEach(key => {
                if (defaultOptionNames.includes(key) === false) {
                    delete currentModule.options[key];
                }
            });

            return loadedModules[key] = currentModule;
        });

        return this.setState(prevState => {
            return {
                modules: Object.assign({}, prevState.modules, loadedModules)
            }
        }, () => {
            if (typeof callback === 'function') callback();
        });
    }

    /**
     * Loads modules from save data, and sets disables edit mode.
     */
    loadModulesFromSaveDataAndAttachDataForFrontEnd = modules => {
        this.setState({ editMode: false }, () => {
            this.loadModulesFromSaveDataAndAttachData(modules);
        });
    }


    /**
     * Deletes a module
     */
    onModuleDelete = (id) => {
        console.log('[Module deleted]', id)

        let currentModules = {...this.state.modules};

        Object.keys(currentModules).map((key, i) => {
            if(currentModules[key].sort > currentModules[id].sort){
                currentModules[key].sort = currentModules[key].sort-1;
            }
        });

        delete currentModules[id];
        this.setState({modules: currentModules});
    }

    /**
     * Moves a module up
     */
    onModuleMoveUp = (id) => {
        console.log(id);
        this._changeSortPosition(id, -1, () => {
            scrollToId(id)
        });
    }

    /**
     * Moves a module down
     */
    onModuleMoveDown = (id) => {
        console.log(id);
        this._changeSortPosition(id, +1, () => {
            scrollToId(id)
        });
    }

    /**
     * Internally changes the sort position on the modules,
     */
    _changeSortPosition(id, positionChange, callback) {
        let currentSortValue = this.state.modules[id].sort;
        let newSortValue = currentSortValue + positionChange;

        var currentOccupier = null;

        // change the module with the new sort value to the currents module sort value
        Object.keys(this.state.modules).map(key => {
            return (this.state.modules[key].sort === newSortValue) ? currentOccupier = key : '';
        });

        let tempModules = this.state.modules;

        // set the new sort value on the module we want positioned
        tempModules[id].sort = newSortValue;

        // set the current modules position on the occupant of the sort we are after.
        tempModules[currentOccupier].sort = currentSortValue;

        this.setState({ modules: tempModules }, () => {
            if (typeof callback === 'function') callback();
        }); // boom, nailed it
    }


    /**
     * When we select a new module to add, push it to the module array
     */
    onModuleSelected = module => {
        let { modules, currentModuleId, atBottom } = this.state;
        let currentModule = modules[currentModuleId];

        // fetch default module options
        const defaultOptions = this.state.availableModules[module.name].component.options(this.props.entry);

        // assign default values
        let defaultModuleOptions = {};

        defaultOptions.forEach(o => {
            if (o.defaultValue) {
                defaultModuleOptions[o.name] = o.defaultValue;
            } else if (o.options !== undefined) {
                defaultModuleOptions[o.name] = o.options.slice(0).shift().value;
            } else {
                if (o.type === 'switch') {
                    defaultModuleOptions[o.name] = false;
                } else {
                    defaultModuleOptions[o.name] = '';
                }
            }
        });
        let index = 0;
        Object.keys(modules).map((key, i) => {
            if(modules[key].sort >= modules[currentModuleId].sort){
                if(key === currentModuleId) {
                    if(atBottom) index = modules[currentModuleId].sort + 1;
                    if(!atBottom) index = modules[currentModuleId].sort

                }
                if(modules[key].sort < Object.keys(modules).length ){
                    if(!atBottom) modules[key].sort = modules[key].sort + 1;
                }
            }
        })

        let sortValue = 0;

        Object.keys(this.state.modules).forEach(key => {
            if (this.state.modules[key].sort >= sortValue) {
                sortValue = this.state.modules[key].sort + 1;
            }
        });

        let newModule = Object.assign({
            id: shortid.generate(),
            sort: (atBottom && index !== 0) ? Object.keys(modules).length : index,
            //sort: sortValue,
            options: defaultModuleOptions,
            fields: {}
        }, module);

        this.setState((prevState) => {
            return {modules: {...prevState.modules, [newModule.id]: newModule}}
        });
    }

    /**
     * When a module is loaded/rendered, it will automatically register its ref
     * in the state on the correct module ID.
     */
    onModuleLoaded = (ref) => {
        const { editMode } = this.state
        if (!editMode) return
        if (ref == null) return

        ref.prepareData().then((result) => {
            Object.keys(result.fields).map((key) => {
                let field = result.fields[key];
                if(field && field.entryType !== undefined) {
                    ref.setState({
                        fields: {
                            ...ref.state.fields, ...{
                                [key]: field,
                            }
                        }
                    });
                }
            });

            // If the module have a afterPrepareData() method we will call it when we're done preparing
            if (typeof ref.afterPrepareData === 'function') {
                ref.afterPrepareData();
            }
        });

        this.setState((prevState) => {
            let modules = Object.assign({}, prevState.modules);
            modules[ref.getKey()].component = ref;

            return {modules: modules}
        });
    }

    /**
     * Fetches the options from the module and displays them in a modal
     * after pressing the options button.
     */
    onModuleOptions = (moduleId) => {
        let { modules, availableModules } = this.state;

        this.setState({
            modalOptionsForModuleId: moduleId,
            modalOptionsOptions: availableModules[modules[moduleId].name].component.options(this.props.entry)
        });
    }

    /**
     * Updates the module with the new options.
     */
    updateModuleAfterOptionChange = newOptionsData => {
        let { modules, modalOptionsForModuleId } = this.state;

        let updatedModules = Object.assign({}, modules);
        updatedModules[modalOptionsForModuleId] = Object.assign({}, modules[modalOptionsForModuleId], {options: newOptionsData});

        this.setState({ modules: updatedModules });
    }


    /**
     * Closes the options modal.
     */
    closeOptionsModal = () => {
        this.setState({ modalOptionsForModuleId: null,  modalOptionsOptions: null });
    }

    onAddNewModule = (current, atBottom) => {
        this.setState({ addNewModule : true, currentModuleId : current, atBottom: atBottom });
    }

    closeSelectModal = () => {
        this.setState({ addNewModule : false });
    }


    /**
     * Renderer
     */
    render() {
        const { entry, ssr, frontend, index = 0, zones = [] } = this.props
        let {
            availableModules,
            currentTemplate,
            editMode,
            modules,
            modalOptionsForModuleId,
            modalOptionsOptions,
            moduleLimit,
            addNewModule
        } = this.state;

        if (Object.keys(availableModules).length <= 0) return <StyledModuleZone />;

        if(frontend && zones.length !== 0 && Object.keys(entry).length > 0) {
            currentTemplate = Object.keys(entry.content.json)[0]
            modules = zones[index]
        }

        return (
            <StyledModuleZone className={this.props.className}>
                <ModuleLoader
                    currentTemplate={currentTemplate}
                    editMode={editMode}
                    modules={modules}
                    entry={entry}
                    ssr={ssr}
                    moduleLimit={moduleLimit}
                    availableModules={availableModules}
                    onModuleOptions={this.onModuleOptions}
                    onModuleLoaded={this.onModuleLoaded}
                    onModuleDelete={this.onModuleDelete}
                    onModuleMoveUp={this.onModuleMoveUp}
                    onModuleMoveDown={this.onModuleMoveDown}
                    onAddNewModule={(current, atBottom) => this.onAddNewModule(current, atBottom)}
                />

                { editMode && (moduleLimit === null || (moduleLimit > modules.length)) && (
                    <AvailableModules
                        modules={ availableModules }
                        addNewModule={addNewModule}
                        onModuleSelected={this.onModuleSelected}
                        closeSelectModal={this.closeSelectModal}
                        currentTemplate={ currentTemplate }
                    />
                )}

                { (editMode && modalOptionsForModuleId) && <OptionsModal
                    title="Module options"
                    onClose={this.closeOptionsModal}
                    onChange={this.updateModuleAfterOptionChange}
                    visible={modalOptionsForModuleId}
                    module={modules[modalOptionsForModuleId]}
                    options={modalOptionsOptions}
                />}
            </StyledModuleZone>
        )
    }
}

export default ModuleZone;

const StyledModuleZone = styled.div`
    position: relative;
`
