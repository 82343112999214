const Q = 65
const urlPrefix = process.env.RAZZLE_IMAGE_PREFIX
const sizes = [
  360, 480, 720, 960, 1024, 1280, 1440, 2560,
]

const imgix = (file) => {
  let srcSet = []

  if(typeof file === 'undefined') return {
    srcSet: '',
    sizes: sizes,
  }

  for (const sizeKey in sizes) {
    const size = sizes[sizeKey]
    if(size > file.width) {
      srcSet.push(`${urlPrefix}/${file.id}/${encodeURI(file.filename)}?auto=format&w=${file.width}&q=${Q} ${file.width}w`)
      break;
    } else {
      srcSet.push(`${urlPrefix}/${file.id}/${encodeURI(file.filename)}?auto=format&w=${size}&q=${Q} ${size}w`)
    }
  }

  return {
    srcSet: srcSet.join(','),
    sizes: sizes,
  }
}

export default imgix