import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

class OptionsWrapper extends React.Component {
    constructor(props) {
        super(props)
    }

    renderContent() {
        const { children, options, ...rest } = this.props

        return <OptionsSection options={options} { ...rest }>{ children }</OptionsSection>
    }

    render() {
        const { children, options, ...rest } = this.props

        return this.renderContent()
    }
}


const OptionsSection = styled.section`
    margin-top: ${p => 
        p.options.margin ? 
            (p.options.marginTop ? 
                p.options.marginTop : 
                p.options.margin) : '0'}px;

    margin-bottom: ${p => 
        p.options.margin ? 
            (p.options.marginBottom ? 
                p.options.marginBottom : 
                p.options.margin) : '0'}px;

    ${media.lessThan('medium')`
        ${p => p.options.hideMobile && p.editMode === false ? `
            display: none;
        ` : ''}
    `}

    ${media.greaterThan('medium')`
        ${p => p.options.hideDesktop && p.editMode === false ? `
            display: none;
        ` : ''}
    `}
`

export default OptionsWrapper;