import store from '../store'
import ApiManager from '../ApiManager';

const defaultState = {
    routes: {},
    routeData: {},
};

const ROUTES_SET_ROUTES = 'ROUTES_SET_ROUTES';
const ROUTES_SET_ROUTE_DATA = 'ROUTES_SET_ROUTE_DATA';

const api = new ApiManager();

export const RouterReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ROUTES_SET_ROUTES:
            return {
                ...{}, ...state,
                routes: action.routes,
            };
        case ROUTES_SET_ROUTE_DATA:

            return {
                ...{}, ...state,
                ...{
                    routeData: {
                        ...{}, ...state.routeData,
                        ...{
                            [action.route.id]: action.routeData,
                        }
                    }
                }
            };
        default:
            return state;
    }
};

const RouterActions = {
    setRoutes: (routes) => {
        store.dispatch({
            type: ROUTES_SET_ROUTES,
            routes: routes,
        });
    },
    getRouteEntry: (route) => {
        let { routeData } = store.getState().router;
        let timestamp = Math.floor(Date.now() / 1000);

        return new Promise((resolve, reject) => {
            if(typeof routeData[route.id] !== 'undefined' && timestamp < routeData[route.id].ttl) {
                return resolve(routeData[route.id]);
            }
            api.getRoute(route.id).then(result => {
                result.data.ttl = timestamp + 2;
                store.dispatch({
                    type: ROUTES_SET_ROUTE_DATA,
                    route: route,
                    routeData: result.data,
                });
                resolve(result.data);
            });
        });

    }
}

export default RouterActions;