import Colors from './Colors';
import { default as media } from 'assets/styles/Breakpoints';
import { css } from 'styled-components';

export default css`
    * {
        box-sizing: border-box;
    }

    h1 {
        font-family: 'Lyon Display';
        font-weight: bold;
        font-size: 60px;
        line-height: 60px;
        color: ${Colors.black};
        margin: 0 0 15px;

        ${media.lessThan('medium')`
            font-size: 26px;
            line-height: 30px;
            font-weight: normal;
        `}
    }
        
    .rebuild-text {
    // Just trying to rebuild CSS
    font-color: #000;
    }

    h2 {
        font-family: 'Lyon Display';
        font-weight: bold;
        font-size: 46px;
        line-height: 46px;
        color: ${Colors.black};
        margin: 0 0 15px;

        ${media.lessThan('medium')`
            font-size: 22px;
            line-height: 30px;
            font-weight: normal;
        `}
    }

    h3 {
        font-family: 'Lyon Display';
        font-weight: normal;
        font-size: 30px;
        line-height: 35px;
        color: ${Colors.black};
        margin: 0 0 15px;

        ${media.lessThan('medium')`
            font-size: 20px;
            line-height: 24px;
        `}
    }

    h4 {
        font-family: 'Lyon Text Bold';
        font-weight: normal;
        font-size: 23px;
        line-height: 30px;
        color: ${Colors.black};
        margin: 0 0 15px;

        ${media.lessThan('medium')`
            font-size: 16px;
            line-height: 20px;
        `}
    }

    h5 {
        font-family: 'Lyon Text Bold';
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: ${Colors.black};
        margin: 0 0 15px;
    }

    p {
        font-size: 16px;
        line-height: 25px;
        font-weight: normal;
        font-family: 'Lyon text';
        margin: 0;

        ${media.lessThan('medium')`
            font-size: 14px;
            line-height: 25px;
            font-weight: normal;
        `}
    }

    strong {
        font-size: inherit;
        line-height: inherit;
    }

    em {
        font-size: inherit;
        line-height: inherit;
        font-weight: normal;
        font-style: italic;
    }

    .wysiwig-admin-content {
        &> div[data-key] {
            font-size: 16px;
            line-height: 25px;
            font-weight: normal;
            font-family: 'Lyon text';
            margin: 0 0 15px;
        }

        h1, h2 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .wysiwig-content {
        p {
            margin: 0 0 15px;
            display: inline-block;
            width: 100%;
        }

        h1, h2 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    ${media.lessThan('medium')`
        #fc_frame {
            bottom: 65px !important;
        }
        #hubspot-messages-iframe-container.widget-align-left {
            .shadow-container + iframe {
                bottom: 65px !important;
            }
            .shadow-container.active + iframe {
                bottom: 0px !important;
            }
        }
    `}
`
