import React from 'react'
import BaseModule from 'modules/DBBaseModule';
import styled from 'styled-components';
import OptionsWrapper from 'components/OptionsWrapper';
import env from '../../helpers/env'

class YotpoModule extends BaseModule {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                ...{
                    //
                }, ...props.fields
            }
        }
    }

    /**
     * Module display name
     */
    static getName() { return "Yotpo Reviews" }

    componentDidMount() {
        window.yotpoLoader()
    }


    render() {
        let { editMode, entry, options } = this.props;

        if (editMode) {
            return (
                <YotpoSystemDefault><h2>Yotpo Reviews</h2></YotpoSystemDefault>
            )
        }

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                {entry.variations_mini && entry.variations_mini[0] !== undefined && (
                    <Wrapper className="yotpo yotpo-main-widget"
                        data-product-id={entry.variations_mini[0].sku}
                        data-name={entry.name}
                        data-url={env.RAZZLE_PUBLIC_URL + this.props.router.location.pathname.substring(1)}>
                    </Wrapper>
                )}
            </OptionsWrapper>
            
        );
    }
}

export default YotpoModule;

const YotpoSystemDefault = styled.div`
    height: 200px;
    background: yellow;
    color: black;
    display: flex;
    align-items: center;
    text-align: center;

    h2 {
        text-align: center;
        flex: 1;
    }
`

const Wrapper = styled.div`
    width: calc(100% - 260px);
    max-width: 1180px;
    margin: 0 auto;
`