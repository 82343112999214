import React from 'react';
import store from '../store'

const defaultState = {
    loaded: false,
    pricelists: {},
};

const LOADED_PRICELISTS = 'LOADED_PRICELISTS';

export const PricelistReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOADED_PRICELISTS:
            return {
                ...state,
                loaded: true,
                ...{
                    loaded: true,
                    pricelists: action.pricelists ? action.pricelists: state.pricelists,
                }            
            };
        default:
            return state;
    }
};

const PricelistActions = {
    setPricelists: (pricelists) => {
        store.dispatch({
            type: LOADED_PRICELISTS,
            loaded: true,
            pricelists: pricelists,
        });
    },
    all: () => {
        return store.getState().pricelists.pricelists;
    },
};

export default PricelistActions;
