import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './system.css';
import loadable from '@loadable/component';

import { Provider } from 'react-redux';
import store, { persistor } from 'cms/store'
import DynamicPage from 'cms/components/DynamicPage';
import trimSlash from '../helpers/trimSlash'
import ApiManager from './ApiManager';

import SettingsActions from 'reducers/settings';

import { getUrlParameter, loadTemplates } from 'cms/helpers';
import GlobalsActions from 'reducers/globals';
import MarketsActions from 'reducers/markets';
import LanguagesActions from 'reducers/languages';
import PricelistActions from 'reducers/price_lists';
import SessionActions from 'reducers/session';
import AuthActions from 'reducers/auth';
import Cart from 'Cart'
import { PersistGate } from "redux-persist/integration/react";
import analytics from 'analytics';
import Page404 from 'pages/404Page';
import SystemActions from 'reducers/system';
import CartActions from './reducers/cart'
import CountriesActions from './reducers/countries'
import PlantProductsActions from './reducers/plant_products'
import CarbonOffsetProductsActions from './reducers/carbon_offset_products'
import isServer from '../isServer'

const System = loadable(() => import('System'), {
    fallback: (() => <div/>)(),
});

class Routes extends React.Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        const { serverData } = props

        this.loadedTemplates = loadTemplates();

        if(typeof serverData !== 'undefined' && serverData !== null) {
            this.setBootstrap(serverData)
            this.state = {
                bootstrapped: true,
                systemPath: props.systemPath || 'cms',
                currentRoute: serverData.route,
                currentPath: trimSlash(serverData.requestedUrl),
                current404: false,
                ssr: true,
            };
        } else if(serverData !== null &&  typeof window.__DB_BOOTSTRAP__ !== 'undefined' && window.__DB_BOOTSTRAP__ !== null) {
            this.setBootstrap(window.__DB_BOOTSTRAP__);

            SessionActions.setSession(
              window.__DB_BOOTSTRAP__.session.market,
              window.__DB_BOOTSTRAP__.session.pricelist,
              window.__DB_BOOTSTRAP__.session.country
            )

            this.state = {
                bootstrapped: true,
                systemPath: props.systemPath || 'cms',
                currentRoute: window.__DB_BOOTSTRAP__.route,
                currentPath: trimSlash(window.__DB_BOOTSTRAP__.requestedUrl),
                current404: false,
                ssr: false,
            }
        } else {
            this.state = {
                bootstrapped: false,
                systemPath: props.systemPath || 'cms',
                currentRoute: null,
                currentPath: null,
                current404: false,
                ssr: false,
            };
        }

        this.router = React.createRef();

        this.clickHandler = this.clickHandler.bind(this);

        this.api = new ApiManager();

        this.analytics = null;

        this.prevUri = null;
    }

    /**
     * Component did mount.
     */
    componentDidMount() {
        document.addEventListener('click', this.clickHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.clickHandler);
    }

    registerMaintenanceCode() {
        let maintenanceCode = getUrlParameter('maintenanceCode');

        if(maintenanceCode.length > 0) {
            AuthActions.setMaintenanceCode(maintenanceCode);
        }
    }

    // If the customer wants a way to set the language of a site manually
    // without having to change site language
    registerLanguageBypass = () => {
        const langQuery = getUrlParameter('secretlySetLang');
        if (langQuery.length > 0) {
           SessionActions.setLang(langQuery)
        }
    }

    onPersistGateLift = () => {
        this.registerMaintenanceCode();
        this.registerLanguageBypass();

        // As long as we think there is something in the current selection in redux
        // we send a request to Centra to get the selection fresh from them.
        if(CartActions.getCurrentSelection() !== null && CartActions.getCurrentSelection().length !== 0) {
            Cart.selection()
        }

        if (SessionActions.getCurrentSession().marketId === null) {
            this.api.getSession().then(result => {
                this.loadBootstrap();
            });
        } else {
            this.loadBootstrap();
        }
    }

    loadBootstrap() {
        const { bootstrapped } = this.state

        if(bootstrapped) return

        this.api.getBootstrap(window.location.pathname).then(result => {
            // Initialize all bootstrapped data
            this.setBootstrap(result.data)

            let path = window.location.pathname.replace(/^\/+/g, '');

            this.setState({
                bootstrapped: true,
                currentRoute: result.data.route,
                currentPath: path,
                current404: result.data.route === null,
            });
        });
    }

    setBootstrap(data) {
        // Set bootstrap data in redux
        SettingsActions.setSettings(data.settings);
        MarketsActions.setMarkets(data.markets);
        PricelistActions.setPricelists(data.pricelists);
        LanguagesActions.setLanguages(data.languages);
        SystemActions.setEntryTypes(data.entryTypes);
        CountriesActions.setCountries(data.countries);
        PlantProductsActions.setProducts(data.plantProducts);
        CarbonOffsetProductsActions.setProducts(data.carbonOffsetProducts)

        data.globals.categories = data.categories;

        if(data.extra) {
            Object.keys(data.extra).map((k) => {
                data.globals[k] = data.extra[k];
            })
        }
        GlobalsActions.setGlobals(data.globals);

        // Initialize analytics module.
        this.analytics = analytics.init();
    }

    loadNewRoute(uri) {
        var self = this;

        this.api.getRoute(null, uri).then(result => {
            if (self.state.currentRoute === null || result.data.rewrite !== self.state.currentRoute.rewrite) {
                this.setState({
                    currentPath: uri,
                    currentRoute: result.data,
                    current404: result.data === null,
                }, () => {
                    window.scrollTo(0, 0);
                })
            }
        }).catch((error) => {
            this.setState({
                currentPath: uri,
                currentRoute: null,
                current404: true,
            })
        });
    }


    clickHandler(e) {
        let target = e.target;
        let isReactLink = target.attributes['data-react-link'];
        let href;

        if(isReactLink) href = target.attributes.href.value;

        if(e.target && e.target.parentNode && e.target.parentNode.attributes) {
            if(e.target.parentNode.attributes['data-react-link']) {
                isReactLink = true;
                if(isReactLink) href = e.target.parentNode.attributes.href.value;
            }
        }

        if (isReactLink) {
            let router = this.router.current;
            e.preventDefault();
            e.stopPropagation();
            router.history.push(href)
        }
    }


    /**
     * Renders the system
     */
    renderSystem = () => {
        let { systemPath } = this.props;

        return <System systemPath={ systemPath } persistor={ persistor }/>;
    };


    /**
     * Renders a page based on slug provided
     */
    renderPageBySlug = (props) => {
        const { systemPath, serverData } = this.props;
        const { bootstrapped, currentRoute, currentPath } = this.state;

        // The uri without leading slash as provided by react-router
        let uri = props.location.pathname.substring(1);

        let renderCheckout = false;

        // If we're not bootstrapped yet we don't render anything
        // If we're in the `systemPath` we don't render anything
        if(!bootstrapped ||
            (
                props.location.pathname.indexOf(systemPath) !== -1 &&
                props.location.pathname !== '/systems' &&
                props.location.pathname !== '/systems/'
            )
        ) return null;

        if (
            uri === this.props.checkoutUri
            || uri === this.props.checkoutUri + '/summary'
            || uri === this.props.checkoutUri + '/failed'
            || currentPath.includes(this.props.checkoutUri)
            || uri !== currentPath && this.prevUri && this.prevUri.includes(this.props.checkoutUri)
        ) {
            renderCheckout = true;
        }

        // On product variation URL changes we actually don't need to reload
        // any data from the server.
        const newVariationHistory = !!(this.router && this.router.current &&
          this.router.current.history && this.router.current.history.location &&
          this.router.current.history.location.state &&
          this.router.current.history.location.state.newVariation === true);

        // If our uri (route) doesn't match the loaded route, we need to reload.
        if(uri !== currentPath && !uri.includes(this.props.checkoutUri) && !newVariationHistory) {
            this.loadNewRoute(uri);
        }

        this.prevUri = uri;
        if(renderCheckout) return this.renderCheckout();
        if(currentRoute) return this.renderDynamicPage();
        return this.render404();
    }

    renderDynamicPage() {
        const { masterLayout } = this.props;
        const { currentRoute, currentPath } = this.state;

        // Change the layout if the model has a override
        let template = currentRoute.model.content.template;

        let Layout = this.loadedTemplates[template].layout !== null
            ? this.loadedTemplates[template].layout
            : masterLayout;

        return (<Layout><DynamicPage key={ currentRoute.id } currentRoute={ currentRoute } currentPath={currentPath} ssr={this.state.ssr}/></Layout>)
    }

    renderCheckout() {
        const { checkoutPage } = this.props;

        return React.createElement(checkoutPage, null, null);
    }

    render404(uri) {
        let { masterLayout } = this.props;

        return React.createElement(masterLayout, null, <Page404 uri={ uri }/>);
    }

    ssr() {
        const { serverData } = this.props;

        if(isServer && serverData !== null) {
            return this.renderPageBySlug({
                location: { pathname: serverData.requestedUrl }
            })
        }

        return null
    }

    /**
     * React renderer
     */
    render() {
        const { systemPath, serverData } = this.props;

        return (
            <Provider store={ store }>
                <PersistGate loading={ this.ssr() } persistor={ persistor } onBeforeLift={ this.onPersistGateLift }>
                    <Router ref={ this.router }>
                        <Switch>
                            <Route path={ systemPath } render={ this.renderSystem }/>
                            <Route path="/" component={ this.renderPageBySlug }/>
                        </Switch>
                    </Router>
                </PersistGate>
            </Provider>
        );
    }
}

export default Routes
