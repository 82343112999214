import React from 'react'
import { Globals } from 'kbcms-react';
import styled from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';
import BaseModule from 'modules/DBBaseModule';
import OptionsWrapper from 'components/OptionsWrapper';
import Payment from 'images/Payment.svg'; 
import Shipping from 'images/Shipping.svg'; 
import Warranty from 'images/Warranty.svg'; 

class OrderInfo extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Order Info" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        // If you use the key "entryType" within a field it will try to auto populate with data.
        this.state = {
            fields: { ...props.fields }
        }
    }
    
    componentDidMount() {
        // prepare data will AJAX fetch data from API if it isnt already added by the server.
        this.prepareData().then(state => { this.setState(state); });
    }

    /**
    * Renderer
    */
    render() {
        let { options, editMode } = this.props;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <InfoWrapper>
                    <Info>
                        <Inner>
                            <ImgAndTitle>
                                <img alt="Payment" src={Payment} />
                                <h4>{ Globals.get('orderinfo_payment_title') }</h4>
                            </ImgAndTitle>
                            <p>{Globals.get('payment')}</p>
                        </Inner>
                    </Info>
                    <Info>
                        <Inner>
                            <ImgAndTitle>
                                <img alt="Shipping" src={Shipping} />
                                <h4>{ Globals.get('orderinfo_shipping_title') }</h4>
                            </ImgAndTitle>
                            <p>{Globals.get('shipping')}</p>
                        </Inner>
                    </Info>
                    <Info>
                        <Inner>
                            <ImgAndTitle>
                                <img alt="Warranty" src={Warranty} />
                                <h4>{ Globals.get('orderinfo_warranty_title') }</h4>
                            </ImgAndTitle>
                            <p>{Globals.get('warranty')}</p>
                        </Inner>
                    </Info>
                </InfoWrapper>
            </OptionsWrapper>
        );
    }
}

export default OrderInfo;   

const InfoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 260px);
    max-width: 1180px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: hidden;

    ${media.lessThan('huge')`
        width: calc(100% - 180px);
    `}

    ${media.lessThan('large')`
        width: calc(100% - 160px);
    `}

    ${media.lessThan('medium')`
        flex-direction: column;
        width: 100%;
        margin: 0;
        padding: 15px;
    `}
`

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    width: calc(33.333% - 20px);
    background-color: #f9f9f9;
    text-align: center;

    ${media.lessThan('medium')`
        width: auto;
    `}

    p {
        font-family: 'Circular Pro Book';
        font-size: 14px;
        line-height: 17px;
    }
`

const Inner = styled.div`
    padding: 2em;
`

const ImgAndTitle = styled.div`
    display: flex; 
    justify-content: center; 
    margin: 0 0 10px; 

    img{
        width: 18px; 
    }

    h4{
        font-family: 'Circular Pro';
        font-size: 18px; 
        margin: 0; 
        margin-left: 10px; 
    }
`