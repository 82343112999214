import React from 'react';
import store from '../store'
import ApiManager from "../ApiManager";

const Api = new ApiManager();

const defaultState = {
    loaded: false,
    config: {},
    globals: {},
};

const LOAD_GLOBALS = 'LOAD_GLOBALS';
const LOADED_GLOBALS = 'LOADED_GLOBALS';
const SET_GLOBAL = 'SET_GLOBAL';

export const GlobalsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_GLOBALS:
            return {
                ...{},
                ...state,
                ...{
                    loaded: false,
                }
            };
        case LOADED_GLOBALS:
            return {
                ...{},
                ...state,
                ...{
                    loaded: true,
                    globals: action.globals ? action.globals : state.globals,
                    config: action.config ? action.config : state.config,
                }
            };
        case SET_GLOBAL:
            return {
                ...{},
                ...state,
                ...{
                    globals: {
                        ...{}, ...state.globals,
                        ...{
                            [action.key]: typeof action.value === 'string' ? (action.key === 'matrix' ? JSON.parse(action.value) : action.value) : {
                                ...{}, ...action.value,
                            },
                        }
                    }
                }
            };
        default:
            return state;
    }
};

const GlobalsActions = {
    setGlobals: (globals) => {
        store.dispatch({
            type: LOADED_GLOBALS,
            loaded: true,
            globals: globals,
        });
    },
    loadGlobals: (market, lang, pricelist) => {
        store.dispatch({
            type: LOAD_GLOBALS,
        });

        Api.getGlobals(market, lang, pricelist).then((result) => {
            store.dispatch({
                type: LOADED_GLOBALS,
                globals: result.data.globals,
                config: result.data.config,
            });
        });
    },
    storeGlobal: (key, value, market, lang, pricelist) => {
        Api.saveGlobal(key, value, market, lang, pricelist).then((result) => {
            store.dispatch({
                type: SET_GLOBAL,
                key: result.data.key,
                value: result.data.value,
            });
        })
    },
    get: (key, defaultValue = '') => {
        let globals = store.getState().globals;

        let value = globals.globals[key];

        // check if json
        try {
            return JSON.parse(value);
        } catch (e) {
            return value !== undefined && value !== '' ? value : defaultValue;
        }
    },
};

export default GlobalsActions;
