import React from 'react';
import BaseModule from 'modules/DBBaseModule';
import { Linker, NewMedia } from 'kbcms-react';
import styled, { css } from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';
import OptionsWrapper from 'components/OptionsWrapper';
import CheckMark from 'images/checkmark.png';
import Button from 'components/Button';

class Signup extends BaseModule {

    static getName() { return "Signup" }

    constructor(props) {
        super(props)

        this.state = {
            fields: {
                ...{}, ...{
                    image: {}
                },
                ...props.fields,
            }
        }
    }


    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'flipped',
                label: 'Flip content direction',
                type: 'switch',
            },
            {
                name: 'size',
                label: 'Module size',
                type: 'dropdown',
                options: [
                    { name: 'Small', value: '2' },
                    { name: 'Medium', value: '1.5' },
                    { name: 'Large', value: '1' },
                ]
            },
        ]);
    }

     getFileType = (file = {}) => {
        if (file.mime) {
            if (file.mime.includes('image')) {
                return 'image'
            } else if(file.mime.includes('video')) {
                return 'video'
            }
        }
        return 'none'
    }

    renderStyledMedia = (editPos) => {
        let { options, editMode } = this.props;
        let { fields } = this.state;
        const { muted, forceNativeVideoAspectRatio } = fields;
        return (
            <StyledMedia
                module={this}
                overlay={options.overlay}
                field={'image'}
                editPosition={editPos}
                types={['images']}
                fill
                aspectRatio={options.lockAspect ? 16/9 : options.size}
            >
                <Button className="button">Join now</Button>
            </StyledMedia>
        )
    }

    /**
     * Renderer
     */
    render() {
        let { options, editMode } = this.props;
        let { fields } = this.state;
        const fileType = this.getFileType(fields.image);

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper flipped={options.flipped}>
                    {fileType === 'image' && (
                        <StyledMediaLinker module={this} field={'image_link_url'}>
                            {this.renderStyledMedia('ne')}
                        </StyledMediaLinker>
                    )}
                    {fileType === 'none' && (
                        <StyledMediaLinker module={this} field={'image_link_url'}>
                            {this.renderStyledMedia('ne')}
                        </StyledMediaLinker>
                    )}
                    <StyledContentArea width={1/2} alignSelf="center">
                        <Text>
                            <Title>Join Db Black</Title>
                            <div>Finally, the membership you've been waiting for.</div>
                            <ul>
                                <li>
                                    <img alt="" src={CheckMark} />
                                    <span>Member-only product drops</span>
                                </li>
                                <li>
                                    <img alt="" src={CheckMark} />
                                    <span>Product design input</span>
                                </li>
                            </ul>
                            <div>+ So much more!</div>
                        </Text>
                    </StyledContentArea>
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default Signup;

const Wrapper = styled.section`
    max-width: 1260px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;

    ${media.lessThan('1260px')`
        width: calc(100% - 180px);
    `} 

    ${media.lessThan('1200px')`
        width: calc(100% - 160px);
    `} 

    ${media.lessThan('992px')`
        width: calc(100% - 100px);
    `} 

    ${media.lessThan('small')`
        width: calc(100% - 30px); 
        display: block;
    `}
`

const StyledContentArea = styled.div`
    padding: 100px 130px;
    margin: auto;
    text-align: center;
    width: 50%;

    ${media.lessThan('1200px')`
        padding: 50px 75px;
    `} 

    ${media.lessThan('small')`
        width: 100%;
        padding: 15px;
    `}
`

const StyledMediaLinker = styled(Linker)`
    width: 50%;
    padding: 0;
    margin: 0;

    ${media.lessThan('small')`
        width: 100%;
    `}
`

const StyledMedia = styled(NewMedia)`
   width: 100%;
   height: 100%;
   position: relative; 

    .button{
       position: absolute; 
       top: 50%; 
       left: 50%; 
       transform: translate(-50%, -50%);
   }
`

const Title = styled.h3`
    font-size: 30px!important;
    line-height: 35px!important;
`

const Text = styled.div`
    font-family: 'Circular Pro';
    text-align: left; 
    
    ul{
        list-style: none; 
        padding: 0;
    }

    li{
        display: flex; 
        align-items: center; 
        padding: 3px 0;
    }

    img{
        width: 16px;
        margin-right: 10px;
    }

    ${media.lessThan('small')`
        text-align: center; 
        li{
            justify-content: center; 
        }
    `}
`