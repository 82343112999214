import React from 'react';
import styled from 'styled-components';
import Dropdown from 'components/Dropdown';
import FlagIcon from 'components/FlagIcon';
import { Languages, SessionActions } from 'kbcms-react'
import isServer from '../isServer'
import withSession from '../cms/components/WithSession'

class LanguageDropdown extends React.Component {

    /**
     * State
     */
    state = {
        languages: Languages.all()
    };

    /**
     * On selecting a language
     * @param {String} languageCode
     */
    onSelectLanguage = languageCode => {
        SessionActions.setLang(languageCode);
        setTimeout(function() {
            window.location.reload();
        }, 500);
    };


    /**
     * Returns all dropdown items
     * @return {Array}
     */
    getItems = () => {
        const items = [];

        this.state.languages.forEach(language => {
            items.push({
                key: language.code,
                display: (
                    <LanguageItem>
                        <Flag code={language.code} />
                        {language.name}
                    </LanguageItem>
                )
            })
        });

        return items;
    };


    /**
     * Renderer
     * @return {JSX}
     */
    render() {
        const { lang } = this.props.session
        let { languages } = this.state;

        if (lang === null || languages.length <= 0) return <div />

        return (
            <Dropdown
                onSelect={this.onSelectLanguage}
                selectedItem={lang}
                items={this.getItems()}
            />
        )
    }
}

export default withSession(LanguageDropdown);

const LanguageItem = styled.div`
    display: flex;
    padding: 4px 6px;
    align-items: center;
    font-family: 'Circular Pro Book';
`;

const Flag = isServer ? null : styled(FlagIcon)`
    width: 25px;
    margin-right: 5px;
`;
