import React from 'react'
import loadable from '@loadable/component'

const LaunchTimer = loadable(() => import(/* webpackChunkName: "countdown" */'./Component'), {
  fallback: (() => <div/>)(),
});

const Component = ({...rest}) => {
  return (
    <LaunchTimer {...rest}/>
  )
}

export default Component