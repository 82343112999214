import isServer from '../../../isServer'

// import an sdk, or install it as a global module
const GoogleAnalytics = (events) => {

    if (isServer || typeof window.ga === 'undefined') return;

    events.forEach(event => {
        switch (event.type) {
            case 'analytics404':
                ga('send', 'event', '404 Errors', event.page, event.referrer);
                break;
            case 'pageView':
                ga('send', {
                    hitType: 'pageview',
                    page: event.page,
                    title: event.title,
                    // location: event.location,            This might cause sessions to be doubled.  https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
                });
                break;
            case 'productView':
                ga('ec:addProduct', {
                    id: event.id,
                    name: event.name,
                    variant: event.variant,
                });
                ga('ec:setAction', 'detail');
                ga('send', 'event', 'Ecommerce', 'View', 'Product');
                break;
            case 'addToCart':
                ga('ec:addProduct', {
                    id: event.id,
                    name: event.name,
                    variant: event.variant,
                    price: event.price,
                    quantity: 1
                });
                ga('ec:setAction', 'add');
                ga('send', 'event', 'Ecommerce', 'Add to Cart', 'Product');
                return;
            case 'removeFromCart': 
                ga('ec:removeProduct', {
                    id: event.id,
                    name: event.name,
                    variant: event.variant,
                    price: event.price,
                    quantity: 1
                });
                ga('ec:setAction', 'remove');
                ga('send', 'event', 'Ecommerce', 'Remove from Cart', 'Product');
                return;
            case 'initiateCheckout': 
                ga('send', 'event', 'Ecommerce', 'Initiate checkout', 'Product'); 
                return;
            case 'completeCheckout':
                ga('set', 'currencyCode', event.currency);

                event.items.forEach(product => {
                    ga('ec:addProduct', {
                        id: product.id,
                        name: product.name,
                        category: product.category,
                        variant: product.variant,
                        price: product.price,
                        quantity: product.quantity
                    });
                });
                
                ga('ec:setAction', 'purchase', {
                    id: event.id,
                    revenue: event.revenue,
                    tax: event.tax,
                    shipping: event.shipping,
                    coupon: event.coupon 
                });
                ga('send', 'event', 'Ecommerce', 'Complete checkout', 'Product');
                ga('send', 'pageview');

                // GTM data layer 
                window.dataLayer = window.dataLayer || []; 
                window.dataLayer.push({
                    'event': 'purchase',
                    'order': event.id,
                    'currency': event.currency, 
                    'voucher': event.coupon, 
                    'customerEmail': event.email, 
                    'products': event.items,
                    'date': Date(), 
                    'total': event.revenue,
                    'tax': event.tax,
                    'trafficSource': document.referrer,
                    'orderRaw': event.orderRaw,
                }); 
                return;
            case 'paymentFailure': 
                ga('ec:setAction', 'paymentFailure', { adyenMessage: event.adyenMessage }); 
                ga('send', 'event', 'Ecommerce', 'Payment failure', 'Product');
            case 'backToStore': 
                ga('send', 'event', 'Ecommerce', 'Back to store', 'Product');
                return;
            case 'customEvent':
                ga('send', 'event', event.category, event.action, event.label, event.value);
                return;
            default:
                break;
        }
    });
};

export default () => {
    return GoogleAnalytics;
};