import isServer from '../../../isServer'

// import an sdk, or install it as a global module
const FacebookPixel = (events) => {

    if(isServer || typeof window.fbq === 'undefined') return;

    events.forEach(event => {
        let currencyCode, price;
        switch (event.type) {
            case 'pageView':
                window.fbq('track', 'PageView');
                break;
            case 'productView':
                currencyCode = event.price.slice(-3);
                price = event.price.split(currencyCode)[0].replace(/\s/g, '');

                window.fbq('track', 'ViewContent', {
                    value: price,
                    currency: currencyCode,
                    contents: [{
                        id: event.id,
                        quantity: 1,
                        item_price: price
                    }],
                    content_type: 'product'
                });
                break;
            case 'addToCart':
                currencyCode = event.price.slice(-3);
                price = event.price.split(currencyCode)[0].replace(/\s/g, '');
                
                window.fbq('track', 'AddToCart', {
                    value: price,
                    currency: currencyCode,
                    contents: [{
                        id: event.id,
                        quantity: 1,
                        item_price: price
                    }],
                    content_type: 'product'
                });
                return;
            case 'removeFromCart':
                currencyCode = event.price.slice(-3);
                price = event.price.split(currencyCode)[0].replace(/\s/g, '');

                window.fbq('trackCustom', 'RemoveFromCart', {
                    value: price,
                    currency: currencyCode,
                    contents: [{
                        id: event.id,
                        quantity: 1,
                        item_price: price
                    }],
                    content_type: 'product'
                }); 
                return;
            case 'initiateCheckout': 
                window.fbq('track', 'InitiateCheckout'); 
                return;
            case 'completeCheckout': 
                window.fbq('track', 'Purchase', {
                    currency: event.currency, 
                    value: event.revenue,
                    contents: event.items, 
                    num_items: event.items.length, 
                }, {
                    eventID: event.id,
                });
                return; 
            case 'paymentFailure': 
                window.fbq('trackCustom', 'PaymentFailure', {
                    adyenMessage: event.adyenMessage
                }); 
            case 'backToStore': 
                window.fbq('trackCustom', 'BackToStore'); 
                return;
            case 'customEvent':
                window.fbq('trackCustom', event.action ? event.category + event.action : event.category, {
                    label: event.label,
                    value: event.value,
                });
                return;
            default:
                break;
        }
    });
};

export default () => {
    return FacebookPixel;
};