import React from 'react';
import styled from 'styled-components';

/**
 * Seperator Plugin
 */
export default class Seperator extends React.Component {

    state = {
        horizontal: this.props.node.data.get('horizontal')
    }


    /**
     * Component config
     * @return {Object}
     */
    static config() {
        return {
            // Button name. This we can change to Icon name or something.
            name: 'Seperator',

            // Is this component void? Void means no editable contents within.
            isVoid: true,

            // Should this plugin insert new block, or replace current. 
            insert: true,

            // Default data state.
            defaultState: {
                horizontal: true
            }
        }
    }
    

    /**
     * Serialize this class to HTML.
     * @return {String}
     */
    static serialize(data, children, frontend = false) {
        let horizontal

        if(frontend) {
            horizontal = typeof data.horizontal !== 'undefined' ? data.horizontal : true
        } else {
            horizontal = data.get('horizontal')
        }

        return <StyledSeperator horizontal={horizontal} />
    }


    /**
     * Toggles the direction of the seperator
     * @return {Void}
     */
    toggleDirection = () => {
        let { editor, node } = this.props;

        this.setState({ horizontal: !this.state.horizontal }, () => {
            editor.setNodeByKey(node.key, { data: { horizontal: this.state.horizontal }});
        });
    }


    /**
     * Renderer.
     * @return {JSX}
     */
    render() {
        return (
            <StyledSeperator 
                editMode={true}
                horizontal={this.state.horizontal} 
                onClick={this.toggleDirection} 
                isFocused={this.props.isFocused} 
                {...this.props.attributes} 
            />
        )
    }
}

const StyledSeperator = styled.section`
    position: relative;
    display: block;
    width: 100%;
    padding: 1em 0;
    border: 1px solid transparent;

    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 1px;
        width: 30px;
        background-color: #d2d2d2;
        content: ' ';
    }

    ${p => p.editMode ? `
        cursor: copy;
    ` : ''}

    ${p => p.isFocused ? `
        border: 1px dotted #d2d2d2;
    `:''}

    ${p => p.horizontal === false ? `
        &:after {
            transform: rotate(90deg);
            margin-left: -15px;
        }
        
        padding: 2em 0; 
    ` : ''}
`