import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import IconButton from 'cms/components/Form/IconButton';
import VideoButtons from './Video/VideoButtons';
import ImageButtons from './Image/ImageButtons';
import { FILE_TYPES, EDIT_POSITIONS } from './helpers';
import Colors from 'assets/Colors';
import Icon from 'cms/components/Icon';
import { IoMdAdd, IoMdImage } from 'react-icons/io';

const EditModeButtons = (props) => {
    const {
        entryType,
        fileType,
        editPosition = fileType === 'video' ? 'nw' : 'c',
        onToggleMute,
        onToggleNativeAspectRatio,
        onToggleLooping,
        onToggleShowControls,
        onToggleAutoplay,
        onToggleBackground,
        fill,
        onOpenFileManager,
        ...restProps
    } = props;

    return (
        <StyledButtons
            {...restProps}
            top={ EDIT_POSITIONS[editPosition].t }
            left={ EDIT_POSITIONS[editPosition].l }
            editPosition={editPosition}>

            <SpecificButtons
                onToggleMute={onToggleMute}
                onToggleNativeAspectRatio={onToggleNativeAspectRatio}
                onToggleLooping={onToggleLooping}
                onToggleShowControls={onToggleShowControls}
                onToggleAutoplay={onToggleAutoplay}
                onToggleBackground={onToggleBackground}
                {...props}
            />

            <StyledIconButton onClick={onOpenFileManager}>
                <IconWrapper>
                    <Icon name="img" width={30} height={30} stroke={Colors.grey} />
                </IconWrapper>
            </StyledIconButton>


            { entryType && (
                /* This is for future pointers if needed*/
                <StyledIconButton>
                    <IoMdAdd/>
                </StyledIconButton>
            )}
        </StyledButtons>
    )
}

const SpecificButtons = ({ fileType, onToggleMute, onToggleNativeAspectRatio, ...restProps }) => {
    switch (fileType) {
        case FILE_TYPES.VIDEO:
            return (
                <VideoButtons
                    onToggleNativeAspectRatio={onToggleNativeAspectRatio}
                    onToggleMute={onToggleMute}
                    {...restProps}
                />
            )
        case FILE_TYPES.IMAGE:
            // Can add custom buttons for images here
            return null;
        default:
           return null
    }
}


const StyledButtons = styled.div`
    z-index: 5;
    position: absolute;
    pointer-events: auto;
    opacity: 0;
    top: ${ props => props.top }%;
    left: ${ props => props.left }%;
    transform: translate(-50%, -50%);
`

const IconWrapper = styled.div`
    background-color: ${Colors.white};
    border: 1px solid ${Colors.dividers};
    width: 30px;
    height: 30px;
    margin-bottom: 6px;
`

const StyledIconButton = styled(IconButton)`
  margin-bottom: 6px;
`

export default EditModeButtons
