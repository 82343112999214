import store from '../store'
import ApiManager from "../ApiManager";

const Api = new ApiManager();

const defaultState = {
    modalOpen: false,
    callback: null,
    activeLinkGroup: null,
    linkGroups: [],
};

const OPEN_LINK_SELECTOR_MODAL = 'OPEN_LINK_SELECTOR_MODAL';
const CLOSE_LINK_SELECTOR_MODAL = 'CLOSE_LINK_SELECTOR_MODAL';
const SET_ACTIVE_LINK_GROUP = 'SET_ACTIVE_LINK_GROUP';

export const LinkSelectorReducer = (state = defaultState, action) => {
    switch (action.type) {
        case OPEN_LINK_SELECTOR_MODAL:
            return {
                ...{}, ...state,
                ...{
                    modalOpen: true,
                    callback: action.callback,
                    linkGroups: action.linkGroups,
                    activeLinkGroup: 0,
                }
            };
        case CLOSE_LINK_SELECTOR_MODAL:
            return {
                ...{}, ...state,
                ...{
                    modalOpen: false,
                    callback: null,
                }
            };
        case SET_ACTIVE_LINK_GROUP:
            return {
                ...{}, ...state,
                ...{
                    activeLinkGroup: action.key
                }
            };
        default:
            return state;
    }
};

const LinkSelectorActions = {
    openModal: (callback, images) => {
        Api.getLinkSelectorIndex().then((result) => {
            let linkGroups = typeof images !== 'undefined' && images.name === 'Images' ? result.data.concat(images) : result.data;
            store.dispatch({
                type: OPEN_LINK_SELECTOR_MODAL,
                callback: callback,
                linkGroups: linkGroups,
            });
        });
    },
    closeModal: () => {
        store.dispatch({
            type: CLOSE_LINK_SELECTOR_MODAL,
        });
    },
    setActiveLinkGroup: (key) => {
        store.dispatch({
            type: SET_ACTIVE_LINK_GROUP,
            key: key,
        });
    },
    selectLink: (link) => {
        let state = store.getState();
        if(typeof state.link_selector.callback === 'function') {
            state.link_selector.callback(link);
        }
        store.dispatch({
            type: CLOSE_LINK_SELECTOR_MODAL,
        });
    }
};

export default LinkSelectorActions;
