import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import AnalyticsActions from 'reducers/analytics';
import Head from 'cms/components/Head';
import redirects from '../../server/redirects'
import isServer from '../../isServer'

class Page404 extends React.Component {

    constructor(props) {
        super(props);

        AnalyticsActions.set404();

        this.state = {
            displayUrl: props.location.pathname,
        }
    }

    /**
     * Renderer
    */
    render() {
        const { displayUrl } = this.state

        if(!isServer && typeof redirects[this.props.location.pathname] !== 'undefined') {
            this.props.history.push(redirects[this.props.location.pathname]);
            return null
        }

        return (
            <Styled404Wrapper>
                <Head title={'Page Not Found'}/>
                <div>
                    <h2>404</h2>
                    <p>The page <strong>{ displayUrl }</strong> does not exist.</p>
                </div>
            </Styled404Wrapper>
        )
    }
}

export default withRouter(Page404);


const Styled404Wrapper = styled.div`
    margin: 100px 0;
    font-size: 28px;
    display: flex;
    align-items: center;

    h2, p {
        margin: 0 0 14px;
    }

    h2 {
        display: inline-block;
        border-bottom: 6px solid #4a494a;
        padding-bottom: 12px;
    }

    div {
        width: 100%;
        text-align: center;
    }
`
