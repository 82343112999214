import QueryString from 'query-string'
import Cookies from 'js-cookie'
import React from 'react'
import BaseModule from 'modules/DBBaseModule'
import styled from 'styled-components'
import { Settings, Globals, EditorToHTML } from 'kbcms-react'
import OptionsWrapper from 'components/OptionsWrapper'
import Colors from '../../assets/styles/Colors'
import replaceAll from '../../helpers/replaceAll'
import isServer from '../../isServer'


class SignupForm extends BaseModule {

    static getName () { return 'Signup Form' }

    constructor (props) {
        super(props)

        const { options } = props
        const ometriaListId = options.ometriaListId ? options.ometriaListId : ''
        const signedUp = Cookies.get(`dbBlackSignedUp-${ ometriaListId }`)

        this.state = {
            ue: '',
            firstname: '',
            phone_number: '',
            lastname: '',
            required: false,
            fields: {
                ...props.fields,
            },
            signedUp: signedUp
        }
    }

    /**
     * Custom module options
     * @return {Array}
     */
    static options () {
        return super.options().concat([
            {
                name: 'hidePhoneNumber',
                label: 'Hide Phone Number',
                type: 'switch',
            },
            {
                name: 'ometriaSignupSource',
                label: 'Ometria Signup Source',
                type: 'input',
            },
            {
                name: 'ometriaListId',
                label: 'Ometria List ID',
                type: 'input',
            },
            {
                name: 'overrideSuccessMessage',
                label: 'Override Success Message',
                type: 'switch',
            },
            {
                name: 'optionsSuccessMessage',
                label: 'Success message',
                type: 'wysiwyg',
            }
        ])
    }

    componentDidMount() {
        const queryString = QueryString.parse(window.location.search)

        if(queryString.ometria_form_ok && queryString.ometria_form_ok === 'Y') {

            const { options } = this.props
            const ometriaListId = options.ometriaListId ? options.ometriaListId : ''

            this.setState({
                signedUp: true,
            }, () => {
                Cookies.set(`dbBlackSignedUp-${ ometriaListId }`, true, { expires: 365 });
            })
        }
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    renderPrivacyLabel() {
        let labelText = Globals.get('signup_form_privacy_text', 'I have read and accepted the #privacy policy#.')

        labelText = labelText.replace(/#(.*?)#/g, '<a href="/privacy-db-black">$&</a>');
        labelText = replaceAll(labelText, '#', '');

        return labelText
    }

    render () {
        const { options, editMode } = this.props
        const { hidePhoneNumber, ometriaListId, ometriaSignupSource, overrideSuccessMessage, optionsSuccessMessage } = options

        const { ue, firstname, phone_number, lastname, required, signedUp } = this.state

        const successMessage = overrideSuccessMessage ? optionsSuccessMessage : Globals.get('signup_form_success_message')

        const ometriaToken = Settings.get('analytics_ometria_code')

        if(isServer) return null

        return (
            <OptionsWrapper options={ options } editMode={ editMode }>
                <Wrapper>
                    <form action="https://api.ometria.com/forms/signup" method="post">
                        <input type="hidden" name="__form_id" value="94568ae863e761546b28469174cfab37" readOnly/>
                        <input type="hidden" name="email" value="" autoComplete="off" readOnly/>
                        <input name="__email" type="email" value="" hidden autoComplete="off" readOnly/>
                        <input name="@account" type="hidden" value={ ometriaToken } readOnly/>
                        <input name="@return_url" type="hidden" value={`${window.location.href}?ometria_form=signup-module&listId=${ ometriaListId }&source=${ ometriaSignupSource }`} readOnly/>
                        <input name="@subscription_status" type="hidden" value="SUBSCRIBED" readOnly/>

                        { (ometriaSignupSource && ometriaSignupSource.length > 0) && (
                            <input name="properties.signup_source" type="hidden" value={ ometriaSignupSource } hidden readOnly/>
                        ) }

                        { (ometriaListId && ometriaListId.length > 0) && (
                            <input name="@add_to_lists[]" type="hidden" value={ ometriaListId } readOnly/>
                        ) }

                        <FieldGroup>
                            <Input name="ue" type="text" value={ ue } onChange={ e => this.handleInputChange(e) } placeholder={ Globals.get('signup_form_email', 'Email address') }/>
                        </FieldGroup>
                        <FieldGroup>
                            <Input name="firstname" type="text" value={ firstname } onChange={ e => this.handleInputChange(e) } placeholder={ Globals.get('signup_form_first_name', 'First name') }/>
                        </FieldGroup>
                        <FieldGroup>
                            <Input name="lastname" type="text" value={ lastname } onChange={ e => this.handleInputChange(e) } placeholder={ Globals.get('signup_form_last_name', 'Last name') }/>
                        </FieldGroup>

                        { hidePhoneNumber !== true && (
                            <FieldGroup>
                                <Input name="phone_number" type="text" value={ phone_number } onChange={ e => this.handleInputChange(e) } placeholder={ Globals.get('signup_form_phone_number', 'Phone number') }/>
                            </FieldGroup>
                        ) }

                        <FieldGroup>
                            <Checkbox name="required" type="checkbox" value={ required } required onChange={ e => this.handleInputChange(e) }/>
                            <label htmlFor="required" dangerouslySetInnerHTML={{ __html: this.renderPrivacyLabel() }}/>
                        </FieldGroup>

                        { signedUp && <StyledEditorToHTML data={ successMessage }/> }

                        <SubmitButton type="submit" value={ Globals.get('signup_form_submit', 'Submit') } name="submit"/>
                    </form>
                </Wrapper>
            </OptionsWrapper>
        )
    }
}

export default SignupForm

const Wrapper = styled.section`
  max-width: 500px;
  padding: 24px;
  font-family: 'Circular Pro Book', sans-serif;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  
  form {
    width: 100%;
  }
`

const FieldGroup = styled.div`
  display: flex;
  position: relative;
  width: 96%;
  padding-bottom: 3%;
  min-height: 50px;
`

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 13px 0;
  text-indent: 0;
  outline: none;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgb(232, 228, 222);
  font-family: 'Circular Pro Book', sans-serif;
`

const Checkbox = styled.input`
  display: block;
  padding: 8px 0;
  text-indent: 0;
  outline: none;
  width: 20px;
  height: 20px;
  background-color: #fff;
  margin-right: 10px;
  position: relative;
  top: 2px;
`

const StyledEditorToHTML = styled(EditorToHTML)`
  color: ${Colors.green}; 
  p {
    color: ${Colors.green};
    font-family: 'Circular Pro Book', sans-serif;
    a {
      color: ${Colors.green};
    }
  }
`

const SubmitButton = styled.input`
  padding: 6px 60px;
  height: initial;
  border-radius: 0;
  background: none;
  color: ${ Colors.black };
  border: 1px solid ${ Colors.darkGray };
  margin: auto;
  width: 100%;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
`