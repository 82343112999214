import React from 'react'
import BaseModule from 'modules/DBBaseModule';
import { Media } from 'kbcms-react';
import AddModuleImage from 'images/the-scholar.png';

class ExampleModule extends BaseModule {

    constructor(props) {
        super(props);

        // If you use the key "entryType" within a field it will try to auto populate with data.
        this.state = {
            fields: {
                ...{
                    // define your fields that should be saved here.
                    image: null

                }, ...props.fields
            }
        }
    }

    /**
     * Custom module settings. This will merge options set in the base module.
     */
    static options() {
        return super.options().concat([
            {
                name: 'exampleOption',
                label: 'Example options',
                type: 'switch'
            },
        ]);
    }



    componentDidMount() {
        // prepare data will AJAX fetch data from API if it isnt already added by the server.
        this.prepareData().then(state => {

            // do something with state here.

            this.setState(state);
        });
    }


    /**
     * Module display name
     */
    static getName() { return "Example module" }


    /**
     * Example add new module image.
     */
    static getImage() {
        return <img alt="Module image" src={AddModuleImage}/>
    }


    /**
     * It will be rendered, but not available for pickin'
     */
    static hidden() { return false }


    /**
     * If it should be deletable within the pagebuilder.
     */
    static deletable() { return true }


    render() {
        let { editMode } = this.props;
        let { title } = this.state.fields;

        return (
            <div>
                <Media module={this} field={'image'} aspectRatio={16/10} />
            </div>
        );
    }
}

export default ExampleModule
