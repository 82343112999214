import React, { Component } from 'react'
import styled from 'styled-components';
import Colors from 'assets/styles/Colors';
import { Globals, AnalyticsActions, withRouter } from 'kbcms-react';
import ButtonNormalized from './ButtonNormalized'

class AddToCartButton extends Component {

    /**
     * Constructor
     * @return {Void}
     */
    constructor(props) {
        super(props);

        this.state = this.setButtonState(props);
    }

    /**
     * React lifecycle method.
     * @param {Object} props
     * @return {Void}
     */
    componentWillReceiveProps(props) {
        this.setState(this.setButtonState(props));
    }


    /**
     * Sets the button state based on parameters.
     * @param {Object} props
     * @return {Object}
     */
    setButtonState = props => {
        if (props.item === null) return;

        let newState = {
            buttonText: '...',
            disabled: false,
            checkout: false,
            goToCheckout: false,
            outOfStock: false,
        }

        const { overrideNotifyWhenBackInStock, overrideNotifyWhenBackInStockTitle } = props

        // if the item passed is out of stock
        if (props.item.stock === 0) {
            if(overrideNotifyWhenBackInStock === true) {
                newState.buttonText = overrideNotifyWhenBackInStockTitle;
                newState.outOfStock = true;
            } else {
                newState.buttonText = Globals.get('mod_pb_notify');
                newState.outOfStock = true;
            }
        } else if(props.variation && props.variation.stock_protection && props.reservationAvailable == null) {
            newState.buttonText = Globals.get('mod_pb_add_to_cart', 'Add to cart');
            newState.disabled = true;
        } else if(props.variation && props.variation.stock_protection && props.reservationAvailable <= 0) {
            newState.buttonText = Globals.get('mod_pb_all_bags_reserved', 'All bags reserved');
            newState.disabled = true;
        } else if(props.item.stock === -1 || props.variation && props.variation.is_preorder){
            newState.buttonText = Globals.get('mod_pb_pre_order_now', 'Pre-order now');
            newState.outOfStock = false;
        } else {
            newState.buttonText = Globals.get('mod_pb_add_to_cart', 'Add to cart');
            newState.outOfStock = false;
        }

        return newState;
    }

    /**
     * Handles clicks on the button
     * @return {Void}
     */
    onClick = () => {
        const { overrideNotifyWhenBackInStock, overrideNotifyWhenBackInStockLink } = this.props

        if(overrideNotifyWhenBackInStock && overrideNotifyWhenBackInStockLink) {
            if(overrideNotifyWhenBackInStockLink.type === 'internal') {
                this.props.history.push(`/${overrideNotifyWhenBackInStockLink.url}`)
            } else {
                this.props.history.push(`${overrideNotifyWhenBackInStockLink.url}`)
            }
            return false
        }

        if (this.state.disabled) return false;
        
        if(!this.state.checkout){
            this.setLoadingState();
            this.props.onClick(this.removeLoadingState);
        } else {
            // display "Go to checkout"
            this.setState({ checkout: false }, () => {
                this.props.history.push(`/checkout`);
                AnalyticsActions.initiateCheckout(); 
            });
        }
    }
        


    /**
     * Sets loading state to the button
     * @return {Void}
     */
    setLoadingState = () => {
        this.setState({
            buttonText: Globals.get('mod_pb_adding') + '...',
            disabled: true
        });
    }


    /**
     * Removes loading state on the button
     * @return {Void}
     */
    removeLoadingState = (success = false) => {
        this.setState(this.setButtonState(this.props),() => {
            if(success === true) {
                this.checkoutState();
            }
        });
    }

    /**
     * Sets checkout state on the button 
     * @return {Void}
     */
    checkoutState = () => {
        this.setState({
            buttonText: Globals.get('mod_pb_goto_checkout'),
            checkout: true,
        });
    }

    /**
     * Renderer
     * @return {Void}
     */
    render() {
        return (
                <Button 
                    blue
                    narrow
                    outOfStock={this.state.outOfStock}
                    disabled={this.state.disabled} 
                    checkout={this.state.checkout}
                    onClick={this.onClick}>
                    {this.state.buttonText}
                </Button>
        );
    }

}

export default withRouter(AddToCartButton);

const Button = styled(ButtonNormalized)`
    text-align: center;
    padding: 12px 50px;
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    font-family: 'Circular Pro Book', sans-serif !important;
    text-transform: uppercase !important;
    display: inline-block;
    height: auto;
    margin-left: 0;
    border: 1px solid ${Colors.black};
    cursor: pointer;
    transition: opacity .3s ease;
    text-decoration: none;

    ${props => props.blue ? `
        color: #fff;
        background-color: ${Colors.blue} !important;
        border-color: ${Colors.blue};
    ` : ''}

    ${props => props.narrow ? `
        padding-left: 20px;
        padding-right: 20px;
    ` : ''}
    
    ${props => props.disabled ? `
        pointer-events: none;
        color: #fff;
        background-color: ${Colors.fadedBlue} !important;
        border-color: transparent !important;
    ` : ``}

    ${props => props.outOfStock ? `
        background-color: transparent !important;
        color: ${Colors.blue} !important;
        border-color: ${Colors.blue} !important;
    `: ''}
`