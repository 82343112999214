import React, { Component } from 'react'
import { connect } from "react-redux";
import SessionActions from 'reducers/session';
import { withRouter } from 'react-router-dom';
import { Cart } from 'kbcms-react';

class MarketPicker extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            activeMarket: props.marketId
        }
    }


    /**
     * Changes the market.
     */
    changeCountry = country => {
        

        let marketId, pricelistId;

        switch (country) {
            case 'us':
                marketId = 5;
                pricelistId = 20;
                break;

            case 'ca':
                marketId = 4;
                pricelistId = 26;
                break;
            
            case 'de':
                marketId = 3;
                pricelistId = 25;
                break;

            default:
            case 'no':
                marketId = 1;
                pricelistId = 23;
                break;
        }

        Cart.setCountry(country).finally(result => {
            this.setState({ activeMarket: marketId }, () => {
                SessionActions.setSession(marketId, pricelistId);
                window.location.reload(); 
            });
        });
    }

    render() {
        let { activeMarket } = this.state;
        let { location } = this.props;

        return (
            <React.Fragment>
                <div>
                    <span className={activeMarket == 5 ? 'active' : ''} onClick={() => this.changeCountry('us')}>us</span>
                    <span className={activeMarket == 4 ? 'active' : ''} onClick={() => this.changeCountry('ca')}>ca</span>
                    <span className={activeMarket == 3 ? 'active' : ''} onClick={() => this.changeCountry('de')}>eu</span>
                    <span className={activeMarket == 1 ? 'active' : ''} onClick={() => this.changeCountry('no')}>no</span>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.session,
        ...ownProps
    };
};

export default withRouter(connect(mapStateToProps)(MarketPicker));