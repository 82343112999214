import React, { Component } from 'react'
import loadable from '@loadable/component'
import styled, { css } from 'styled-components';
import { IoMdPlay } from 'react-icons/io';
import Thumbnail from './Thumbnail';
import media from '../../../../assets/styles/Breakpoints'
import { InView } from 'react-intersection-observer';

const ReactPlayer = loadable(() => import('react-player'), {
    fallback: (() => <div/>)(),
});

class VideoMedia extends Component {

    state = {
        playing: false,
        played: false,
        shouldRenderPlayer: true,
        videoHeight: null,
        videoWidth: null,
        inViewInitialized: false,
    }

    videoContainer = React.createRef();

    componentDidMount() {
        this.resizeVideo();
        window.addEventListener('resize', this.resizeVideo)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeVideo)
    }

    componentDidUpdate(prevProps, prevState) {
        const { muted } = this.props;
        const { muted: prevMuted } = prevProps;

        // Force reload on player to make new playback options reload
        if (muted !== prevMuted) {
            this.setState({ shouldRenderPlayer: false, playing: false, played: false }, () => {
                this.setState({ shouldRenderPlayer: true })
            })
        }
    }

    resizeVideo = (videoWidth, videoHeight) => {
        const { field, module, fill } = this.props;
        const file = { ...{}, ...this.defaultFile, ...module.getField(field) };
        const self = this;

        if (!file || !file.mime || (file.mime.includes('url/video') === false && file.mime.includes('mp4') === false)) {
            return;
        }

        let fileData = JSON.parse(file.data),
            containerWidth = this.videoContainer.current ? this.videoContainer.current.clientWidth : 0,
            containerHeight = this.videoContainer.current ? this.videoContainer.current.clientHeight : 0,
            nativeVideoWidth = null,
            nativeVideoHeight = null;

        // if we have no file data, we have to wait for the video to load its meta data, then we run again.
        if (fileData === null && !videoWidth && !videoHeight) {
            return this.videoPlayer.current.getInternalPlayer().addEventListener("loadedmetadata", function(e) {
                self.resizeVideo(this.videoWidth, this.videoHeight);
            }, false);
        } else if (fileData) {
            nativeVideoWidth = fileData.width;
            nativeVideoHeight = fileData.height;
        }

        // if we have passed in native dimensions, overwrite.
        if (videoWidth && videoHeight) {
            nativeVideoWidth = videoWidth;
            nativeVideoHeight = videoHeight;
        }

        // if the container has no aspect, set original video values.
        if (containerHeight === 0) {
            return this.setState({
                videoWidth: nativeVideoWidth,
                videoHeight: nativeVideoHeight,
            });
        }

        // background-cover-esque
        if (fill) {
            const widthScaleFactor = containerWidth / nativeVideoWidth;
            const heightScaleFactor = containerHeight / nativeVideoHeight;

            if (widthScaleFactor > heightScaleFactor) {
                return this.setState({
                    videoWidth: containerWidth,
                    videoHeight: nativeVideoHeight * widthScaleFactor + 10
                });
            } else {
                return this.setState({
                    videoWidth: nativeVideoWidth * heightScaleFactor + 10,
                    videoHeight: containerHeight
                });
            }
        }

        return this.setState({
            videoWidth: '100%',
            videoHeight: '100%'
        });
    }

    onPause = () => this.setState({ playing: false })

    onProgress = ({ played }) => {
        if (played > 0 && !this.state.played) {
           this.setState({ played: true })
        } else if (played === 0) {
            this.setState({ played: false })
        }
    }

    onInView = (inView) => {
        const { inViewInitialized } = this.state

        if(inView && !inViewInitialized) {
            this.setState({
                inViewInitialized: true,
            }, () => {
                this.resizeVideo()
            })
        }
    }

    render() {
        const {
            field,
            module,
            file,
            aspectRatio,
            forceNativeVideoAspectRatio,
            className,
            muted,
            looping,
            showControls,
            autoplay,
            background,
        } = this.props
        const { shouldRenderPlayer, playing, played, inViewInitialized } = this.state;

        const videoHeight = forceNativeVideoAspectRatio ? '100%' : `${this.state.videoHeight}px`;
        const videoWidth = forceNativeVideoAspectRatio ? '100%' : `${this.state.videoWidth}px`;

        return (
        <VideoContainer
            className={className}
            forceNativeVideoAspectRatio={forceNativeVideoAspectRatio}
            ref={this.videoContainer}
            aspectRatio={aspectRatio}>
                <InView as="div" onChange={this.onInView}>
                    {(inViewInitialized && shouldRenderPlayer) && (
                        <VideoPlayer
                            key={JSON.stringify({
                                file: file.filename, muted, looping, showControls, autoplay, background
                            })}
                            ref={ref => this.videoPlayer = ref}
                            url={file.mime === 'url/video' ? file.filename : file.original}
                            playing={playing}
                            onProgress={this.onProgress}
                            onPause={this.onPause}
                            playsinline
                            loop
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        api: true,
                                        byline: false,
                                        title: false,
                                        loop: looping,
                                        muted: muted,
                                        autoplay: autoplay,
                                        controls: showControls,
                                        background: background,
                                    },
                                    preloading: true,
                                }
                            }}
                            width={videoWidth}
                            height={videoHeight}
                        />
                    )}
                    <VideoChildContent className={className}>
                        { this.props.children }
                    </VideoChildContent>
                    {!autoplay && !played && !playing && !background && (
                        <PlayButton onClick={() => this.setState({ playing: !playing })}>
                            <StyledPlayIcon/>
                        </PlayButton>
                    )}
                </InView>
            </VideoContainer>
        )
    }
}
const VideoPlayer = styled(ReactPlayer)`
    ${p => p.forceNativeVideoAspectRatio ? `
        position: absolute;
        top: 0;
        left: 0;
    ` : `
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
    `}
`

const StyledPlayIcon = styled(IoMdPlay)`
    height: 32px;
    width: 32px;
    color: white;

    ${media.lessThan('medium') `
        width: 22px;
        height: 22px;
    `}
`

const PlayButton = styled.div`
    width: 92px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    position: relative;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    &:hover {
        background-color: rgba(255, 255, 255, 0.4);
    }

    ${media.lessThan('medium') `
        width: 72px;
        height: 42px;
    `}

    ${StyledPlayIcon}:hover & {
        color: black;
    }
`

const VideoContainer = styled.div`
    position: relative;
    overflow: hidden;

    ${p => p.aspectRatio && css`
        &:before {
            display: block;
            content: ' ';
            padding-bottom: ${p => 1 / p.aspectRatio * 100 }%;
        }
    `}
`;


const VideoChildContent = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    pointer-events: none;

    &:not(.no-style) {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        z-index: 2;
    }
`

export default VideoMedia
