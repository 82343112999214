import React from 'react'
import loadable from '@loadable/component'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import DbOpen from '../../assets/icons/db-open.svg'

const ModuleToolbar = loadable(() => import('cms/components/ModuleZone/ModuleToolbar'), {
    fallback: (() => <div/>)(),
});

/**
 * Handles the loading of modules within a zone. Basically a list of modules.
 * @extends React
 */
class ModuleLoader extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            accordionOpen: props.editMode,
            openAccordions: [],
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if(prevProps.editMode !== this.props.editMode) {
            this.setState({ accordionOpen: this.props.editMode })
        }
    }

    toggleOpen (id) {
        let { openAccordions } = this.state

        if(openAccordions.includes(id)) {
            let index = openAccordions.indexOf(id);
            if (index > -1) {
                openAccordions.splice(index, 1)
            }
            this.setState({
                openAccordions: [...openAccordions],
            })
        } else {
            this.setState({
                openAccordions: [...openAccordions, id],
            })
        }
    }

    /**
     * Creates the module, and renders it. Passing all necessary data to it. 
     */
    renderModule = (module, id) => {
        let { availableModules, editMode, entry, onModuleLoaded, currentTemplate, reservation } = this.props;

        if (availableModules[module.name]) {
            let element = React.createElement(availableModules[module.name].component, {
                id: id,
                ref: onModuleLoaded,
                template: currentTemplate,
                entry: entry,
                fields: module.fields || {},
                options: module.options || [],
                router: {
                    location: this.props.location,
                    match: this.props.match,
                    history: this.props.history
                },
                reservation: reservation,
                editMode: editMode,
            }, null);

            return (<div id={id}>{element}</div>);
        }

        // if the module does not exist anymore, or isnt installed properly, just hide it from frontend
        // and show a message in the pagebuilder.
        if (!editMode) return null;

        return (<ModuleMissing name={id} id={id}>Tried to load <i>{module.name}</i> module, but it does'nt exist anymore.</ModuleMissing>);
    }

    /**
     * Sorts the modules correctly based on sort value.
     */
    sortModules = modules => {
        let moduleIds = Object.keys(modules).sort(function(a, b) {
            return modules[a].sort - modules[b].sort;
        });

        return moduleIds.map((id, key) => {
            let module = this.props.modules[id]; 
            module.sort = key;
            return module.id; 
        })
    }

    groupAccordionModules = (moduleIds, modules) => {
        let groupedModules = []
        let skippedIds = []

        moduleIds.forEach((id, i) => {
            if(skippedIds.includes(id)) return;

            const { options } = modules[id]
            const { accordionEnabled, accordionTitle, accordionCount } = options

            skippedIds.push(id)

            if(accordionEnabled === true && typeof accordionTitle !== 'undefined' && accordionTitle !== null && accordionTitle.length > 0) {
                let ids = [id]

                if(typeof accordionCount !== 'undefined' && accordionCount > 0) {
                    for (let j = 1; j < accordionCount + 1; j++) {
                        skippedIds.push(moduleIds[i + j])
                        ids.push(moduleIds[i + j])
                    }
                }

                groupedModules.push(ids);
            } else {
                groupedModules.push(id);
            }
        })

        return groupedModules
    }

    /**
     * Renderer
     */
    render() {
        let { modules, editMode, availableModules } = this.props;

        if(editMode && Object.keys(modules).length === 0){
            return (
                <ModuleToolbar
                    modules={null}
                    currentModule={null}
                    onAddNewModule={() => this.props.onAddNewModule(null, true)}
                />
            )  
        }

        const sortedModulesIds = this.sortModules(modules)
        const groupedModuleIds = this.groupAccordionModules(sortedModulesIds, modules)

        return groupedModuleIds.map(id => {
            if(typeof id === 'string') {
                let module = modules[id];

                return (
                  <StyledModuleWrapper key={id}>
                      {editMode && <StyledModuleToolbarWrapper>
                          <ModuleToolbar
                            modules={modules}
                            currentModule={module}
                            moduleLimit={this.props.moduleLimit}
                            currentModuleType={availableModules[module.name]}
                            onModuleOptions={this.props.onModuleOptions}
                            onModuleDelete={this.props.onModuleDelete}
                            onModuleMoveUp={this.props.onModuleMoveUp}
                            onModuleMoveDown={this.props.onModuleMoveDown}
                            onAddNewModule={(atBottom) => this.props.onAddNewModule(module.id, atBottom)}
                          />
                      </StyledModuleToolbarWrapper>}

                      {this.renderModule(module, id)}
                  </StyledModuleWrapper>
                )
            } else {
                let accordionModule = modules[id[0]]
                const { options } = accordionModule
                const { accordionTitle } = options
                const accordionOpen = this.state.openAccordions.includes(id[0]) || this.state.accordionOpen

                return (
                  <AccordionWrapper key={id[0]}>
                      <AccordionTitleWrapper open={ accordionOpen }>
                          <AccordionTitle open={ accordionOpen } onClick={ () => this.toggleOpen(id[0]) }>
                              { accordionTitle }
                              <img src={ DbOpen } alt="Plus symbol"/>
                          </AccordionTitle>
                      </AccordionTitleWrapper>
                      <AccordionContent open={accordionOpen}>
                          { accordionOpen === true && (
                            id.map(i => {
                                let module = modules[i];

                                return (
                                  <StyledModuleWrapper key={i}>
                                      {editMode && <StyledModuleToolbarWrapper>
                                          <ModuleToolbar
                                            modules={modules}
                                            currentModule={module}
                                            moduleLimit={this.props.moduleLimit}
                                            currentModuleType={availableModules[module.name]}
                                            onModuleOptions={this.props.onModuleOptions}
                                            onModuleDelete={this.props.onModuleDelete}
                                            onModuleMoveUp={this.props.onModuleMoveUp}
                                            onModuleMoveDown={this.props.onModuleMoveDown}
                                            onAddNewModule={(atBottom) => this.props.onAddNewModule(module.id, atBottom)}
                                          />
                                      </StyledModuleToolbarWrapper>}

                                      {this.renderModule(module, i)}
                                  </StyledModuleWrapper>
                                )
                            })
                          )}
                      </AccordionContent>
                  </AccordionWrapper>
                )

            }
        })
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...{
            reservation: state.reservation,
        },
        ...ownProps
    };
};

export default withRouter(connect(mapStateToProps)(ModuleLoader));

const StyledModuleWrapper = styled.div`
    position: relative;
`

const ModuleMissing = styled.div`
    padding: 30px;
    background-color: red;
    color: white;
`

const StyledModuleToolbarWrapper = styled.div`
    display: none;

    ${StyledModuleWrapper}:hover & {
        display: block; 
    }
`


const AccordionWrapper = styled.section`
`

const AccordionTitleWrapper = styled.div`
  width: calc(100% - 260px);
  max-width: 1180px;
  padding: 0 10px;
  margin: 0 auto;
  transition: .3s background-color ease;
  background-color: #fff;

  @media screen and (max-width: 1260px) {
    width: calc(100% - 180px);
  }

  @media screen and (max-width: 1200px) {
    width: calc(100% - 160px);
  }
    
  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 0 25px;
  }
`

const AccordionTitle = styled.a`
    display: block;
    cursor: pointer;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
    text-transform: none;
    appearance: auto;
    background: none;
    border: none;
    text-align: left;
    font-weight: inherit;
    color: #4a494a;
    
    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }
    
    &:-moz-focusring {
        outline: 1px dotted ButtonText;
    }
    border-top: 1px solid #4a494a;
    width: 100%;
    font-family: 'Circular Pro Book', sans-serif;
    font-size: 1em;
    padding: 12px 8px;

  
  img {
    margin-right: 0;
    position: relative;
    top: 1px;
    float: right;
    transition: transform .3s ease;
  }
  
  ${p => p.open === true && css`
    background-color: #f9f9f9;
    img {
      transform: rotate(45deg);
    }
  `}
`

const AccordionContent = styled.div`
    position: relative;
    width: calc(100% - 260px);
    max-width: 1180px;
    padding: 0 10px;
    margin: 0 auto;
    
    ${p => p.open === true && css`
        padding: 20px 10px;
    `}

    @media screen and (max-width: 1260px) {
      width: calc(100% - 180px);
    }

    @media screen and (max-width: 1200px) {
        width: calc(100% - 160px);
    }

    @media screen and (max-width: 992px) {
        width: 100%;
        padding-left: 25px;
        padding-right: 25px;
    }
`