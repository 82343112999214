import Cart from '../Cart'
import store from '../store'
import ApiManager from "../ApiManager";

const Api = new ApiManager();

const defaultState = {
  modalOpen: false,
  reservations: [],
  data: {},
  protectedSizeIds: [],
  clearing: false,
  remainingSeconds: 0,
  minutes: 0,
  seconds: 0,
  minuteText: '',
  secondText: '',
  timedOut: false,
  timeoutDisplayed: false,
};

const SET_RESERVATIONS = 'SET_RESERVATION';
const CLEAR_RESERVATIONS_START = 'CLEAR_RESERVATIONS_START';
const CLEAR_RESERVATIONS_DONE = 'CLEAR_RESERVATIONS_DONE';
const ADD_PRODUCT_AVAILABILITY_DATA = 'ADD_PRODUCT_AVAILABILITY_DATA';
const ADD_PROTECTED_SIZE_ID = 'ADD_PROTECTED_SIZE_ID';
const UPDATE_RESERVATION_TIME_REMAINING = 'UPDATE_RESERVATION_TIME_REMAINING';
const RESERVATION_TIMEOUT_DISPLAYED = 'RESERVATION_TIMEOUT_DISPLAYED';
const COMPLETE_RESERVATIONS_START = 'COMPLETE_RESERVATIONS_START';
const COMPLETE_RESERVATIONS_DONE = 'COMPLETE_RESERVATIONS_DONE';
const SET_RESERVATION_MODAL_OPEN = 'SET_RESERVATION_MODAL_OPEN';

export const ReservationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_RESERVATION_MODAL_OPEN:
      return {
        ...{},
        ...state,
        ...{
          modalOpen: action.value,
        }
      }
    case SET_RESERVATIONS:
      return {
        ...{},
        ...state,
        ...{
          reservations: action.reservations,
          timedOut: false,
        }
      };
    case ADD_PRODUCT_AVAILABILITY_DATA:
      return {
        ...{},
        ...state,
        ...{
          data: {
            ...state.data,
            ...{
              [action.sizeId]: action.available,
            }
          }
        }
      };
    case RESERVATION_TIMEOUT_DISPLAYED:
      return {
        ...{},
        ...state,
        ...{
          timeoutDisplayed: true,
        }
      };
    case CLEAR_RESERVATIONS_START:
      return {
        ...{},
        ...state,
        ...{
          clearing: true,
          timedOut: typeof action.timedOut !== undefined ? action.timedOut : false,
          timeoutDisplayed: false,
        }
      };
    case CLEAR_RESERVATIONS_DONE:
      return {
        ...{},
        ...state,
        ...{
          clearing: false,
          reservations: [],
        }
      };
    case COMPLETE_RESERVATIONS_START:
      return {
        ...{},
        ...state,
        ...{
          clearing: true,
          timedOut: false,
        }
      };
    case COMPLETE_RESERVATIONS_DONE:
      return {
        ...{},
        ...state,
        ...{
          clearing: false,
          reservations: [],
        }
      };
    case ADD_PROTECTED_SIZE_ID:
      return {
        ...{},
        ...state,
        ...{
          protectedSizeIds: state.protectedSizeIds.includes(action.sizeId) ? state.protectedSizeIds : state.protectedSizeIds.concat([action.sizeId]),
        }
      };
    case UPDATE_RESERVATION_TIME_REMAINING:
      return {
        ...{},
        ...state,
        ...{
          remainingSeconds: action.remainingSeconds,
          minutes: action.minutes,
          seconds: action.seconds,
          minuteText: action.minuteText,
          secondText: action.secondText,
        }
      }
    default:
      return state;
  }
};

const ReservationActions = {
  setModalOpen: (value) => {
    store.dispatch({
      type: SET_RESERVATION_MODAL_OPEN,
      value: value,
    })
  },
  getReservations: () => {
    return store.getState().reservation.reservations;
  },
  getTimeRemaining: () => {
    let { reservation } = store.getState();

    if(reservation.reservations.length === 0) return null;

    const now = Date.now();

    const dateParts = reservation.reservations[0].expires_at.split(/[^0-9]/);
    const expiresAt = Date.UTC(dateParts[0],dateParts[1]-1,dateParts[2],dateParts[3],dateParts[4],dateParts[5]);

    const remainingSeconds = (expiresAt - now) / 1000;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = Math.floor(remainingSeconds - (minutes * 60));

    let minuteText, secondText;

    if(remainingSeconds < 0) minuteText = `00`
    else if(minutes < 10) minuteText = `0${minutes}`
    else minuteText = minutes;

    if(remainingSeconds < 0) secondText = `00`
    else if(seconds < 10) secondText = `0${seconds}`
    else secondText = seconds;

    store.dispatch({
      type: UPDATE_RESERVATION_TIME_REMAINING,
      remainingSeconds: remainingSeconds,
      minutes: minutes,
      seconds: seconds,
      minuteText: minuteText,
      secondText: secondText,
    });

    if(remainingSeconds <= 0 && !reservation.clearing) {
      ReservationActions.clearReservations();
      store.dispatch({
        type: CLEAR_RESERVATIONS_START,
        timedOut: true,
      })
    }

    return remainingSeconds;
  },
  setReservations: (reservations) => {
    store.dispatch({
      type: SET_RESERVATIONS,
      reservations: reservations,
    });
  },
  reserveItem: async (sizeId, quantity, realQuantity = false) => {
    let cartState = Cart.getState();

    // The reservation system requires that we have a cart sessions in Centra
    // initiated. So if we don't have this we need to request one.
    if(cartState.token === null){
      await Cart.selection();
      cartState = Cart.getState();
    }

    // All protected products have a limit to have many a user can have in their
    // cart, therefore we need to sum the existing items in cart.
    if(!realQuantity && cartState && cartState.data && cartState.data.selection && cartState.data.selection.items) {
      const existingAmountInCart = cartState.data.selection.items.reduce((acc, item) => {
        if(item.item === sizeId) return acc + item.quantity;
        return acc;
      }, 0)

      quantity += existingAmountInCart;
    }

    return new Promise((resolve, reject) => {
      Api.reserveItem(sizeId, quantity).then((result) => {
        store.dispatch({
          type: SET_RESERVATIONS,
          reservations: result.data.reservations,
        })
        if(result.data.status && result.data.status === "failed" && result.data.reason === "out_of_stock") {
          store.dispatch({
            type: ADD_PRODUCT_AVAILABILITY_DATA,
            sizeId: sizeId,
            available: 0,
          })
        }
        resolve(result.data)
      })
    })
  },
  getItemAvailability: (sizeId) => {
    store.dispatch({
      type: ADD_PROTECTED_SIZE_ID,
      sizeId: sizeId,
    });
    Api.getItemAvailability(sizeId).then((result) => {
      store.dispatch({
        type: ADD_PRODUCT_AVAILABILITY_DATA,
        sizeId: sizeId,
        available: parseInt(result.data.available),
      })
    })
  },
  refresh: () => {
    Api.refreshReservations().then((result) => {
      store.dispatch({
        type: SET_RESERVATIONS,
        reservations: result.data.reservations,
      })
    })
  },
  clearReservations: (clearCart = true) => {
    return new Promise((resolve, reject) => {
      store.dispatch({
        type: CLEAR_RESERVATIONS_START,
      })

      Api.clearReservations().then((result) => {
        if (clearCart) {
          Cart.clearCart()
        }

        store.dispatch({
          type: CLEAR_RESERVATIONS_DONE,
        })

        resolve()
      }).catch(e => {
        reject();
      })
    })
  },
  completeCheckout: () => {
    return new Promise((resolve, reject) => {
      store.dispatch({
        type: COMPLETE_RESERVATIONS_START,
      })

      Api.completeReservations().then((result) => {
        store.dispatch({
          type: COMPLETE_RESERVATIONS_DONE,
        })

        resolve()
      }).catch(e => {
        reject();
      })
    })
  },
  addProtectedSizeId: (sizeId) => {
    store.dispatch({
      type: ADD_PROTECTED_SIZE_ID,
      sizeId: sizeId,
    });
  },
  isSizeIdProtected: (sizeId) => {
    return store.getState().reservation.protectedSizeIds.includes(sizeId);
  },
  haveReservationForSizeId: (sizeId) => {
    let have = false;

    store.getState().reservation.reservations.forEach(r => {
      if(r.size_id === sizeId) have = true;
    });

    return have;
  },
  timeoutDisplayed: () => {
    store.dispatch({
      type: RESERVATION_TIMEOUT_DISPLAYED,
    });
  }
}

export default ReservationActions;
