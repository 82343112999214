const scrollToId = (id) => {
  const el = document.getElementById(id)

  if(el !== null && typeof el !== 'undefined') {
    el.scrollIntoView({
      behavior: 'smooth'
    });
  }
}

export default scrollToId