import ApiManager from 'ApiManager';

const Api = new ApiManager();

class Newsletter {

    /**
     * Subscribes an email to out of stock newsletter for a specific product
     * @return {Promise}
     */
    forProduct(email, product) {
        return Api.newsletterSubscription({
            email: email,
            product: product
        });
    }

    subscription(email){
        return Api.newsletterSubscription({
            email: email        
        });
    }
}

export default new Newsletter();