import React from 'react'
import axios from 'axios';
import loadable from '@loadable/component';
import { Globals } from 'kbcms-react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import BaseModule from 'modules/DBBaseModule';
import { default as media } from 'assets/styles/Breakpoints';

const Map = loadable(() => import('modules/MapOfRetailers/Map'), {
    fallback: (() => <div/>)(),
});

class MapOfRetailers extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Map of Retailers" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                ...{
                    retailers: {
                        entryType: 'retailers',
                        filter: ['*'],
                        data: []   
                    }
                    
                }, ...props.fields
            },
            searchQuery: '',
            center: [null, null],
            zoom: [11],
            activeRetailer: null,
            activeRetailers: []
        }
    }


    
    componentDidMount() {
        this.prepareData().then(state => {
            // center the map on the closest.
            state.center = [
                parseFloat(state.fields.retailers.data[0].lng),
                parseFloat(state.fields.retailers.data[0].lat)
            ];

            state.activeRetailers = state.fields.retailers.data;

            this.setState(state);
        });
    }


    


    /**
     * It will be rendered, but not available for pickin'
     */
    static hidden() { return false }


    onMarkerClick = retailerKey => {
        const { activeRetailers } = this.state;

    
        this.setState({
            activeRetailer: activeRetailers[retailerKey],
            center: [
                parseFloat(activeRetailers[retailerKey].lng), 
                parseFloat(activeRetailers[retailerKey].lat)
            ]
        });
    }

    onChange = event => {
        const searchQuery = document.querySelector('.searchField').value;

        if (searchQuery === '') {
            this.setState({
                activeRetailers: this.state.fields.retailers.data.slice(0)
            });
            return null;
        }

        axios.get(process.env.RAZZLE_API_URL + '/ajax/retailer-search/' + searchQuery, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(result => {
            this.setState({
                activeRetailers: result.data
            });
        }).catch(error => {
            console.log('error', error);
        });
    }

    /**
    * Renderer
    */
    render() {
        const { center, zoom, activeRetailer } = this.state;
        const { activeRetailers } = this.state;

        if (center[0] == null) return <div>Loading...</div>

        return (
            <RetailerMap>
                <Map
                    center={ center }
                    zoom={ zoom }
                    activeRetailer={ activeRetailer }
                    activeRetailers={ activeRetailers }
                    fncOnMarkerClick={ (key) => this.onMarkerClick(key) }
                    fncSetActiveRetailer={ (key) => this.setState({ activeRetailer: key }) }
                />
                <RetailerList>
                    <SearchBox>
                        <input  
                            className="searchField"                           
                            type="text" 
                            onChange={debounce(this.onChange, 500)}
                            placeholder={Globals.get('mod_ret_find_retailers')}
                        />
                    </SearchBox>
                    <Results>
                        {activeRetailers.map((retailer, key) => {
                            return (
                                <ResultItem key={key} 
                                    onClick={() => this.onMarkerClick(key)}>
                                    <h4>{retailer.name}</h4>
                                    <p>{retailer.address_1}, {retailer.zip} {retailer.city}</p>
                                    <p>
                                        <span>p: {retailer.phone}</span>
                                        <a
                                            target="_blank"
                                            style={ { float: 'right', color: '#666' } }
                                            href={ "http://" + retailer.web }
                                            rel="noopener noreferrer">
                                            {Globals.get('mod_ret_website')}
                                        </a>
                                    </p>
                                </ResultItem>
                            );
                        })} 
                    </Results>
                </RetailerList>
            </RetailerMap>
        );
    }
}

export default MapOfRetailers;

const Results = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
`

const ResultItem = styled.li`
    margin: 20px;
    background-color: #f8f8f8;
    padding: 20px;
    cursor: pointer;
`

const RetailerMap = styled.div`
    display: flex;

    ${media.lessThan('small')`
        flex-direction: column;
    `}
`

const SearchBox = styled.div`
    margin: 0 20px 0;
    padding: 20px 20px 14px;
    display: flex;
    align-items: center;
    background: #f8f8f8;


    input {
        width: 100%;
        font-size: 22px;
        border: 0;
        padding: 0;
        outline: none;
        border-bottom: 1px solid #d2d2d2;
        margin-bottom: 10px;
        background-color: transparent;
        flex: 1;
    }
`

const RetailerList = styled.aside`
    flex: 3;
    max-width: 380px;

    ${media.lessThan('small')`
        margin: 30px auto 0; 
    `}
`

