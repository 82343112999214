import FileManagerActions from 'reducers/files';
import CropperActions from 'reducers/cropper';

export const FILE_TYPES = {
    VIDEO: 'video',
    IMAGE: 'image'
}

export const EDIT_POSITIONS = {
    nw: { t: '10', l: '10' },
    n: { t: '10', l: '50' },
    ne: { t: '10', l: '90' },
    w: { t: '50', l: '10' },
    c: { t: '50', l: '50' },
    e: { t: '50', l: '90' },
    sw: { t: '90', l: '10' },
    s: { t: '90', l: '50' },
    se: { t: '90', l: '90' },
}

export const openFileManager = (e, { aspectRatio, field, module, types }, context) => {
    e.stopPropagation();
    e.preventDefault();

    FileManagerActions.openModal({
       fileTypes: types !== '' && types !== undefined ? types : ['images', 'videos'],
    }, (file) => {
       if (aspectRatio && file.mime.includes('image')) {
            // Crop here
            CropperActions.openModal(aspectRatio, file, (file) => {
                module.setField(field, {
                    ...{},
                    ...file
                }, () => {
                    context.forceUpdate();
                });
            });
        } else {
            module.setField(field, {
                ...{},
                ...file
            }, () => {
                context.forceUpdate();
            });
        }
    });
}

export const getFileType = (file = {}) => {
    if (file.mime) {
        if (file.mime.includes('image')) {
            return FILE_TYPES.IMAGE
        } else if(file.mime.includes('video')) {
            return FILE_TYPES.VIDEO
        }
    }
    return 'none'
}

export const getMediaDimensions = (file, fileType) => {
    if (fileType === 'image') {
        if (file) {
            const { height, width } = file;
            return { height, width }
        }
    }

    if (fileType === 'video') {
        if (file.data) {
            const { height, width } = JSON.parse(file.data);
            return { height, width };
        }
    }
    return { height: 720, width: 1280 }
}
