import React from 'react'
import { Editor } from 'kbcms-react';
import BaseModule from 'modules/DBBaseModule';
import styled, { css } from 'styled-components';
import OptionsWrapper from 'components/OptionsWrapper';
import { default as media } from 'assets/styles/Breakpoints';
import fontOptions from 'assets/styles/FontOptions'; 

class FullWidthText extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
   static getName() { return "Full Width Text" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        // If you use the key "entryType" within a field it will try to auto populate with data.
        this.state = {
            fields: {
                ...{
                    // define your fields that should be saved here.
                    text: ''
                    
                }, ...props.fields
            }
        }
    }

    
    componentDidMount() {
        // prepare data will AJAX fetch data from API if it isnt already added by the server.
        this.prepareData().then(state => {

            // do something with state here.
            this.setState(state);
        });
    }


    /**
     * It will be rendered, but not available for pickin'
     */
    static hidden() { return false }


    /**
     * If it should be deletable within the pagebuilder.
     */
    static deletable() { return true }

    /**
     * Custom module settings. This will merge options set in the base module.
     */
    static options() {
        return super.options().concat([
            {
                name: 'leftAlign',
                label: 'Left align text',
                type: 'switch'
            },
        ]);
    }

    /**
    * Renderer
    */
    render() {
        const { options, editMode } = this.props;
        const { text } = this.state.fields;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper leftAlign={options.leftAlign}>
                    <Editor 
                        field={'text'} 
                        data={text} 
                        disabled={!editMode} 
                        module={this} 
                        fonts={fontOptions}
                        defaultFont='Lyon Display'
                    />
                </Wrapper>
            </OptionsWrapper>
        );
    }
}

export default FullWidthText;

const Wrapper = styled.section`
    position: relative;
    max-width: 780px;
    margin: 0 auto;
    width: calc(100% - 260px);
    padding: 50px 0 40px;
    text-align: center;

    ${p => p.leftAlign && css`
        text-align: left; 
    `}

    ${media.lessThan('huge')`
        width: calc(100% - 180px);
    `}

    ${media.lessThan('large')`
        width: calc(100% - 160px);
    `}

    ${media.lessThan('medium')`
        width: calc(100% - 100px);
    `}

    ${media.lessThan('small')`
        width: calc(100% - 30px);
        margin: 0 auto;
    `}
`