import React from 'react';
import styled from 'styled-components';
import Colors from "../assets/styles/Colors";

export const MAIN_CONTENT_ID = "main-content";
export const FOOTER_ID = "footer";

/**
 * Use this component to add a skip to landmarks
 * Helper for accessibility
 * Supported landmarks:
 *  - main content: an element with id of ${@link MAIN_CONTENT_ID}
 *    TODO(Fidel): @Jon -> have this open the cart
 *  - cart: an element with id of ${@link CART_ID}
 *  - footer: an element with id of ${@link FOOTER_ID}
 */
class SkipToLandmarks extends React.Component {
  render() {
    return <>
      <SkipTo href={`#${MAIN_CONTENT_ID}`}>Skip to content</SkipTo>
      <SkipTo href={`#${FOOTER_ID}`}>Skip to footer</SkipTo>
    </>
  }
}

export default SkipToLandmarks;

const SkipTo = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: -100vw;
  top: -100vh;
  font-size: 11px;
  line-height: 15px;
  font-weight: normal;
  font-family: 'Circular Pro Book';
  text-transform: uppercase;
  width: 100vw;
  text-align: center;
  color: ${Colors.black};

  &:focus {
    left: 0;
    top: 0;
    z-index: 100;
    padding: 3px;
    background: ${Colors.gray};
    border: 1px solid ${Colors.black};
    outline: ${Colors.black} auto 1px;
  }
`;
