import React from 'react'
import styled, { css } from 'styled-components';
import { Globals, Newsletter } from 'kbcms-react';
import Modal from 'components/Modal';
import InputField from 'components/InputField';
import Button from 'components/Button';
import CloseIconWhite from 'images/db-close-white.svg';

class NewsletterPopup extends React.Component {

    constructor(props) {
        super(props);

        this.newsletterModal = null;

        this.state = {
            subscriptionEmail: '',
            subscriptionError: null,
        }
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        if (Globals.get('newsletter_small_text') !== '') {
            this.newsletterModal.openModal();
        }
    }

    /**
     * Start newsletter subscription
     * @return {Void}
     */
    startSubscription = () => {
        let { subscriptionEmail } = this.state;

        Newsletter.subscription(subscriptionEmail).then(result => {
            this.newsletterModal.closeModal();
            this.props.close();
            this.setState({ subscriptionError: false, subscriptionEmail: '' , newsletterActive : false});
        }).catch(error => {
            this.setState({ subscriptionError: true });
        });
    }

    /**
     * Get email from input field
     * @return {Void}
     */
    onChangeEmail = event => {
        this.setState({ subscriptionEmail: event.target.value });
    }

    render() {
        return (
            <Modal ref={ref => this.newsletterModal = ref} close={e => this.props.close()} icon={CloseIconWhite}>
                <ModalContent backgroundImg={Globals.get('newsletter_image_id').srcset}>
                    <SmallText>{Globals.get('newsletter_small_text')}</SmallText>
                    <LargeText>{Globals.get('newsletter_large_text')}</LargeText>

                    <StyledInputField
                        type="text"
                        value={this.state.subscriptionEmail}
                        onChange={this.onChangeEmail}
                        placeholder="Your email"
                    />

                    <StyledButton black onClick={this.startSubscription}>Sign me up!</StyledButton>
                    {this.state.subscriptionError && <ErrorMessage>Error registering your e-mail.</ErrorMessage>}

                </ModalContent>
            </Modal>
        )
    }
}

export default NewsletterPopup;

const ModalContent = styled.div`

    ${p => p.backgroundImg && css`
        background: url(${p.backgroundImg});
    `}

    background-size: cover ;
    background-repeat: no-repeat;
    padding: 50px;
    text-align: center;
    color: white;
    width: 384px;
    height: 524px;
    display: flex;
    flex-direction: column;
`

const StyledInputField = styled(InputField)`
    display: block;
    border-bottom: none;
    border: 1px solid white;
    padding-left: 10px;
    color: white;
    ::placeholder{
        color: white;
    }
`

const SmallText = styled.p`
    text-transform: uppercase;
    font-family: 'Circular Pro Book';
    font-size: 12px;
    line-height: 30px;
    margin-top: -1em;
`

const LargeText = styled.p`
    font-family: 'Lyon Display';
    font-size: 46px;
    font-weight: bolder;
    line-height: 55px;
    flex: 1;
`
const StyledButton = styled(Button)`
    width: 100%;
    color: white;
    background-color: black;
    font-size: 16px;
    border-color: black;
`

const ErrorMessage = styled.div`
    font-size: 14px;
    color: red;
    text-align: center;
    margin-bottom: 20px;
`

