import React from 'react'
import loadable from '@loadable/component';
import styled, { css } from 'styled-components';
import { default as media } from 'assets/styles/Breakpoints';
import AddToCartButton from 'components/AddToCartButton';
import Colors from 'assets/styles/Colors';
import { Link, Redirect } from 'react-router-dom';
import { Cart, EditorToHTML, Globals, Settings, AnalyticsActions, Newsletter, Reservation, MarketsActions } from 'kbcms-react';
import BaseModule from 'modules/DBBaseModule';
import Modal from 'components/Modal';
import InputField from 'components/InputField';
import Button from 'components/Button';
import 'react-id-swiper/src/styles/css/swiper.css';
import Drawer from 'components/Drawer/index';
import Dropdown from 'components/Dropdown';
import LeftSwipe from 'images/swiper-left.svg';
import RightSwipe from 'images/swiper-right.svg';
import Icon3D from 'images/3d_rotation.svg';
import MiniLeftSwipe from 'images/Slider-mini-left.svg';
import MiniRightSwipe from 'images/Slider-mini-right.svg';
import ButtonNormalized from '../../components/ButtonNormalized'
import Input from '../../components/Input'
import ProductLabels from '../../components/ProductLabels'
import bugsnagClient from '../../helpers/bugsnag';
import productToModels from '../../helpers/productToModels'
import stringReplaceArray from '../../helpers/stringReplaceArray'
import PreOrderInfo from './PreOrderInfo'
import ProductStockNotifier from './ProductStockNotifier'
import MulberryModal from 'components/MulberryModal';
import { Helmet } from 'react-helmet';

const PreOrderCountdown = loadable(() => import(/* webpackChunkName: "countdown" */'./PreOrderCountdown'), {
    fallback: (() => <div/>)(),
});

const Swiper = loadable(() => import('react-id-swiper'), {
    fallback: (() => <div/>)(),
});

class ProductInfo extends BaseModule {

    /**
     * Human readable name. Will be displayed in the CMS
     * @return {String}
     */
    static getName() { return "Product info"; }

    /**
    * Constructor
    * @return {Void}
    */
    constructor(props) {
        super(props);

        this.notifyStockModal = null;
        this.seeIn3DModal = null;
        this.swiperRef = null;

        this.sliderIndex = 0;

        let currentVariation, currentPurchasableItem, selectedColor, models = null, currentModel = null, currentVariationIndex = 0

        try {
            const productData = Object.keys(this.props.entry.content.json['ProductTemplate'].zones[0].modules).map(k => {
                return this.props.entry.content.json['ProductTemplate'].zones[0].modules[k]
            }).find(m => m.name === 'ProductInfo').fields.product.data

            let correctVariationData = this.getCorrectVariation(productData)

            currentVariation = correctVariationData.currentVariation
            currentPurchasableItem = correctVariationData.currentPurchasableItem
            selectedColor = correctVariationData.selectedColor
            models = correctVariationData.models
            currentModel = correctVariationData.currentModel
            currentVariationIndex = correctVariationData.currentVariationIndex

        } catch(e) {}

        this.state = {
            fields: {
                ...{
                    product: {
                        entryType: 'products',
                        filter: [props.entry.id],
                        data: []
                    }
                },
                dateText: 'Edit this text',
                ...props.fields
            },
            currentPurchasableItem: currentPurchasableItem,
            currentVariation: currentVariation,
            currentVariationIndex: currentVariationIndex,
            models: models,
            currentModel: currentModel,
            productNotFound: null,
            productIndexInView: 0,
            stickyProductShopperVisible: false,
            productSubscriptionDone: false,
            productSubscriptionEmail: '',
            productSubscriptionError: null,
            productSubscriptionAgreed: false,
            displayTechnicalSpec: false,
            isMobile: null,
            colorItems: [],
            selectedColor: selectedColor,
            darkSwiperPagination: true,
            dark3DIcon: true,
            swiperPaginationOverImage: null,
            icon3DOverImage: null,
            currentImageID: null,
            currentImageBrightness: null,
            addedToCart: false,
        }

        this.low_stock_indicator = Settings.get('low_stock_indicator')
        this.mulberryModalRef = React.createRef()
    }

    refreshYotpo = () => {
        setTimeout(function() {
            window.yotpoLoader();
        }, 1000);
    }

    /**
     * Component did mount. Lifecycle method.
     * @return {Void}
     */
    componentDidMount() {
        this.initComponent()

        // If there is a missmatch between state product and the entry we're
        // editing we need to rectify that.
        if(
          this.state.fields &&
          this.state.fields.product &&
          this.state.fields.product.filter &&
          this.state.fields.product.filter[0] !== this.props.entry.id) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    ...{
                        product: {
                            entryType: 'products',
                            filter: [this.props.entry.id],
                            data: []
                        },
                    }
                }
            }, () => {
                this.initComponent()
            })
        }

        window.addEventListener('resize', this.resizeHandler.bind(this));
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeHandler);
        window.removeEventListener('scroll', this.onScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        const { reservation } = this.props

        if(prevProps.reservation.modalOpen && !reservation.modalOpen && this.mulberryModalRef.current)
            this.mulberryModalRef.current.openModal()
    }

    initComponent() {
        var self = this;

        this.prepareData().then(state => {
            state.isMobile = window.innerWidth <= 500;

            if (state.fields.product.data.length > 0 &&
              state.fields.product.data[0].variations.length > 0) {

                const {
                    currentVariation,
                    currentPurchasableItem,
                    selectedColor,
                } = self.getCorrectVariation(state.fields.product.data)

                state.currentVariation = currentVariation;
                state.currentPurchasableItem = currentPurchasableItem;
                state.selectedColor = selectedColor;

                if (state.currentVariation.stock_protection) {
                    Reservation.getItemAvailability(
                      state.currentPurchasableItem.size_id);
                }

            }
            else {
                state.productNotFound = true;
            }

            this.setState(state, () => {
                this.preloadImages();

                if (this.props.editMode === false) {
                    AnalyticsActions.productView(
                      this.state.fields.product.data[0],
                      this.state.currentVariation,
                      this.state.currentPurchasableItem
                    );
                }

                self.refreshYotpo();
            });
        });
    }

    getCorrectVariation(data) {
        const colorItems = data[0].variations;
        let currentVariation = data[0].variations[0];
        let currentVariationIndex = 0
        let currentPurchasableItem;
        let selectedColor;
        let models = null;
        let currentModel = null;

        if (currentPurchasableItem !== null) {
            currentPurchasableItem = currentVariation.sizes[0];
            selectedColor = 0;
        }

        // pre-select a variation based on slug
        let slug = this.props.router.location.pathname.split('/').pop();
        let cleanedSlug = stringReplaceArray(slug, ['-1-', '-2-', '-sale-', '-archive-'], ['-', '-', '-', '-'])

        data[0].variations.forEach(
          (variation, key) => {
              if (
                slug === variation.slug
                || slug === variation.canonical_url
                || cleanedSlug === variation.slug
                || cleanedSlug === variation.canonical_url
              ) {
                  currentVariation = variation;
                  currentVariationIndex = key
                  currentPurchasableItem = variation.sizes[0];
                  selectedColor = variation.id;
              }
          });

        const modelData = productToModels(data[0])

        if(modelData !== null) {
            models = modelData.models

            if(selectedColor !== 0) {
                Object.keys(modelData.models).forEach(m => {
                    modelData.models[m].forEach((v, i) => {
                        if(v.id === selectedColor) {
                            currentModel = v.modelName
                            currentVariation = models[currentModel][i]
                            currentVariationIndex = i
                        }
                    })
                })
            } else {
                currentModel = modelData.firstModel
                currentVariation = models[currentModel][0]
                currentVariationIndex = 0
            }
            currentPurchasableItem = currentVariation.sizes[0]
            selectedColor = currentVariation.id
        }

        return {
            currentVariation,
            currentVariationIndex,
            currentPurchasableItem,
            selectedColor,
            models,
            currentModel,
        }
    }

    resizeHandler() {
        if(this.state.currentImageID !== null){
            this.getBrightness(this.state.currentImageID);
        }
    }

    onScroll = () => {
        if(!this.addToCartButtonRef) return

        if(this.addToCartButtonRef.getBoundingClientRect().top < 40){
            this.setState({ stickyProductShopperVisible: true });
        } else {
            this.setState({ stickyProductShopperVisible: false });
        }
    }

    /**
     * Custom module options
     * @return {Array}
     */
     static options(product) {
        let options = [
            {
                name: 'shippingInfoOverride',
                label: 'Override shipping info',
                type: 'input',
            },
            {
                name: 'url',
                label: '3D model URL',
                type: 'input',
            },
            {
                name: 'disableStickyAddToCartButton', 
                label: 'Disable sticky Add to Cart button', 
                type: 'switch',
            }, 
            {
                name: 'leftAlignedProductDescription', 
                label: 'Left align product description', 
                type: 'switch',
            },
            {
                name: 'fontProductDescription', 
                label: 'Font product description', 
                type: 'dropdown',
                options: [
                    { name: 'Lyon Text', value: 'Lyon Text' },
                    { name: 'Lyon Text Bold', value: 'Lyon Text Bold' },
                    { name: 'Circular Pro', value: 'Circular Pro'},
                    { name: 'Circular Pro Book', value: 'Circular Pro Book'},
                    { name: 'Lyon Display', value: 'Lyon Display' },
                ]
            },
            {
                name: 'overrideNotifyWhenBackInStock',
                label: 'Override back in stock button to a link',
                type: 'switch',
            },
            {
                name: 'overrideNotifyWhenBackInStockTitle',
                label: 'The title on the button when overriding the notify when back in stock button',
                type: 'input',
            },
            {
                name: 'overrideNotifyWhenBackInStockLink',
                label: 'Select link for notify when back in stock override',
                type: 'link',
            },
            {
                name: 'showPreOrder',
                label: 'Force pre-order functionality',
                type: 'switch',
            },
            {
                name: 'hidePreorderCountdown',
                label: 'Hide pre-order countdown',
                type: 'switch',
            },
            {
                name: 'hidePreorderShippingDate',
                label: 'Hide pre-order shipping date',
                type: 'switch',
            },
            {
                name: 'preorderAmount',
                label: 'Pre-order max stock',
                type: 'input',
            },
            {
                name: 'preorderSold',
                label: 'Pre-order currently sold',
                type: 'input',
            },
        ];

        let variationOptions = [];
        if(product.variations_mini && typeof product.variations_mini !== 'undefined'){
            product.variations_mini.map(variation => {
                variationOptions.push(
                    {
                        name: 'preOrder' + variation.sku,
                        label: 'Pre-order text ' + variation.name,
                        type: 'input',
                    }
                );
            })
        }

        const { markets } = MarketsActions.getMarkets()

        if(markets && product.variations_mini.find(v => v.sku === "401A01" || v.sku === "400A01")) {
            Object.keys(markets).forEach(key => {
                options.push({
                    name: 'preorderSoldOut' + markets[key].id,
                    label: 'Pre order sold out in ' + markets[key].name,
                    type: 'switch',
                })
            })
        }

        return super.options().concat(options, variationOptions);
    }

    /**
     * If the component should be visible in module selector.
     * @return {Bool}
     */
    static hidden() { return true; }


    /**
     * Should the component be deletable bool.
     * @return {Bool}
     */
    static deletable() { return false; }

    /**
     * Can not be turned into an accordion item.
     * @returns {boolean}
     */
    static accordionable() { return false; }

    /**
     * Preloads images for all variations except current.
     * @return {Void}
     */
    preloadImages = () => {
        let { data } = this.state.fields.product;

        if (data.length === 0) return;

        data[0].variations.forEach(item => {
            // skip current variation
            if (item.id === this.state.currentVariation.id) return null;

            item.images.standard.forEach(image => {
                const img = new Image();
                img.src = item.images.standard[0].url;
            });
        });
    }


    /**
     * Handles adding an item to cart
     * @return {Void}
     */
    addToCart = async (callback) => {
        let {
            currentPurchasableItem,
            currentVariation,
            fields
        } = this.state;

        const sizeId = currentPurchasableItem.size_id;

        // if product variation is out of stock, display popup notifier.
        if (currentPurchasableItem.stock === 0) {
            callback(false);
            return this.displayOutOfStockNotifier();
        }

        if(currentVariation.stock_protection) {
            let reservationResult = await Reservation.reserveItem(sizeId, 1);
            if(reservationResult.status !== "success") {
                callback(false);
                return null;
            }
        }

        Cart.add(sizeId, currentVariation).then(result => {
            callback(true);
            AnalyticsActions.addToCart(
                fields.product.data[0],
                currentVariation,
                currentPurchasableItem
            );
            this.setState({ addedToCart: true });

            if(currentVariation.warranty_products
              && !currentVariation.stock_protection
              && currentVariation.warranty_products.length
              && this.mulberryModalRef.current) {
                this.mulberryModalRef.current.openModal()
            }

        }).catch(error => {
            bugsnagClient.notify(error, {
                severity: 'error',
                metaData: {
                    product: {
                        product: fields.product.data[0],
                        currentVariation,
                        currentPurchasableItem
                    },
                    browserLocation: {
                        ...this.props.router.location
                    }
                }
            })
            callback(false);
        });
    }

    /**
     * Displays out of stock notifier
     * @return {Void}
     */
    displayOutOfStockNotifier = () => {
        this.notifyStockModal.openModal();
    }


    /**
     * Tries to subscribe to a product
     * @return {Void}
     */
    addProductSubscription = () => {
        let { productSubscriptionEmail, productSubscriptionAgreed, currentVariation } = this.state;

        if (productSubscriptionAgreed === false) return;

        Newsletter.forProduct(productSubscriptionEmail, currentVariation.centra_id).then(result => {
            this.setState({ productSubscriptionError: false, productSubscriptionEmail: '', productSubscriptionDone: true });
        }).catch(error => {
            this.setState({ productSubscriptionError: true });
        });
    }

    closeProductSubscription = () => {
        this.notifyStockModal.closeModal();
        this.setState({ productSubscriptionDone: false });
    }


    /**
     * Change handler for stock notification subscription email
     * @return {Void}
     */
    onChangeEmail = event => {
        this.setState({ productSubscriptionEmail: event.target.value });
    }


    onChangeAgree = event => {
        this.setState({ productSubscriptionAgreed: event.target.checked });
    }

    /**
     * Handles selection of a variation
     * @return {Void}
     */
    selectVariation = (variationId, variationIndex = 0, scrollTop = false) => {
        var self = this;

        let newVariation = this.state.fields.product.data[0].variations.find(v => v.id === variationId);
        let newPurchasableItem = newVariation.sizes[0];

        if(newVariation.stock_protection) Reservation.getItemAvailability(newPurchasableItem.size_id);

        this.setState({
            addedToCart: false,
            currentVariation: newVariation,
            currentVariationIndex: variationIndex,
            currentPurchasableItem: newPurchasableItem,
            selectedColor: variationId,
        }, () => {
            self.props.router.history.push(this.state.currentVariation.slug, {
                newVariation: true,
            });

            if (scrollTop !== false) {
                window.scrollTo(0, 0);
            }

            AnalyticsActions.productView(
                this.state.fields.product.data[0],
                this.state.currentVariation,
                this.state.currentPurchasableItem
            );
        });
    }

    /**
     * Handles selection of a size variation
     * @return {Void}
     */
    selectSizeVariation = key => {
        this.setState({ currentPurchasableItem: this.state.currentVariation.size_variants[key] });
    }

    selectModel = model => {
        const { models, currentVariationIndex } = this.state

        this.setState({
            currentModel: model,
        }, () => {
            try {
                this.selectVariation(models[model][currentVariationIndex].id, currentVariationIndex)
            } catch(e) {
                this.selectVariation(models[model][0].id, 0)
            }
        })
    }

    /**
     * Toggles tehcnical spec drawer
     * @return {Void}
     */
    toggleTechnicalSpec = () => {
        this.setState({ displayTechnicalSpec: !this.state.displayTechnicalSpec });
    }

    /**
     * Toggles 3D model
     * @return {Void}
     */
    toggle3DModel = () => {
        this.seeIn3DModal.openModal();
    }

    /**
     * Returns items for dropdown
     * @return {Array}
     */
    getItems = () => {
        let items = [];
        this.state.colorItems.forEach((color, key) => {
            items.push({
                key: color.id,
                display: (
                    <StickyColorVariation
                        key={color.id}
                        swatch={color.swatch}
                        active={color.id === this.state.currentVariation.id}
                    ></StickyColorVariation>
                )
            })
        });

        return items;
    }

    /**
     * Sets overlay color based on image brightness
     * @param {Int}
     * @return {Void}
     */
    getBrightness(id) {
        return null;


        // let { currentVariation, isMobile } = this.state;
        // this.setState({ currentImageID: id});
        //
        // let isOutsideWidth;
        // let isOutsideHeight;
        // if(!isMobile){
        //     let img = document.getElementById(id);
        //     isOutsideWidth = (window.innerWidth / 2 - 10) - img.width > 60;
        //     isOutsideHeight = (window.innerHeight - 90) - img.height > 50;
        //
        //     if(img.width !== 0){
        //         if(isOutsideWidth) {
        //             this.setState({
        //                 icon3DOverImage: true,
        //                 dark3DIcon: true
        //             });
        //         } else if(isOutsideHeight) {
        //             this.setState({
        //                 icon3DOverImage: true,
        //                 dark3DIcon: true,
        //                 swiperPaginationOverImage: true,
        //                 darkSwiperPagination: true,
        //             });
        //         }
        //     }
        // } else {
        //     isOutsideWidth = false;
        //     isOutsideHeight = false;
        // }
        //
        // let currentImageBrightness;
        // currentVariation.images.full.map((img, key) => {
        //     if(key === id) currentImageBrightness = JSON.parse(img.brightness);
        // });
        // if(!isOutsideHeight){
        //     this.setState({ darkSwiperPagination : currentImageBrightness.bottom_center === 0 ? true : currentImageBrightness.bottom_center > 100 })
        // }
        // if(!isOutsideWidth && !isOutsideHeight){
        //     this.setState({ dark3DIcon : currentImageBrightness.bottom_right === 0 ? true : currentImageBrightness.bottom_right > 100 })
        // }
    }

    /**
     * Renderer
     * @return {Void}
     */
    render() {
        let { editMode, options, reservation } = this.props;
        let {
            currentVariation,
            currentPurchasableItem,
            models,
            currentModel,
            productNotFound,
            stickyProductShopperVisible,
            productSubscriptionError,
            isMobile,
            darkSwiperPagination,
            dark3DIcon,
            addedToCart
        } = this.state;

        if(parseInt(options.preorderAmount) < 1) options.preorderAmount = 100
        if(parseInt(options.preorderSold) < 1 || isNaN(parseInt(options.preorderSold))) options.preorderSold = 0


        // get the entry
        let entry = this.state.fields.product.data[0];

        if (productNotFound === true && editMode === true) return <div>Products without a variation does not work</div>

        // redirect back to shop if product is not found
        if (productNotFound === true && editMode === false) return <Redirect to="/shop" />

        // if the variations are not loaded, display loading.
        // TODO: Replace with proper loading.
        if (currentVariation === null || typeof currentVariation === 'undefined') return <div>Loading..</div>


        let reservationAvailable = null;
        if(currentVariation.stock_protection && typeof reservation.data[currentPurchasableItem.size_id] !== 'undefined') {
            reservationAvailable = reservation.data[currentPurchasableItem.size_id]
        }

        const sliderParams = {
            loop: true,
            pagination: {
                el: '.swiper-pagination.custom-pagination',
                type: 'fraction'
            },
            navigation: {
                nextEl: '.swiper-button-next.custom-button-next',
                prevEl: '.swiper-button-prev.custom-button-prev'
            },
            centeredSlides: true,
            containerClass: 'swiper-container',
            rebuildOnUpdate: true,
            on: {
                imagesReady: function(){
                    //self.getBrightness(0);
                },
                slideChangeTransitionStart	: function() {
                    //self.getBrightness(this.realIndex);
                }
            },
        };

        if(!entry || !entry.name) return null

        let variations = entry.variations

        if(models !== null && currentModel !== null) {
            variations = models[currentModel]
        }

        return (
            <ProductInfoWrapper className="product-info-wrapper" currentVariation={currentVariation} darkSwiperPagination={darkSwiperPagination} >

                <Helmet>
                    <style type="text/css">{`
                        @media only screen and (max-width: 768px) {
                            .__flowai_launcher__--bubble {
                                bottom: 85px !important;
                            }
                        }
                    `}</style>
                </Helmet>

                {(reservationAvailable !== null && currentPurchasableItem.stock !== 0 && reservationAvailable === 0) && <ProductStockNotifier key={currentVariation.id} allReserved/>}
                {(reservationAvailable !== null && currentPurchasableItem.stock !== 0 && reservationAvailable > 0) && <ProductStockNotifier key={currentVariation.id} available/>}

                { (currentVariation.warranty_products && currentVariation.warranty_products.length > 0) &&
                    <MulberryModal product={currentVariation} ref={this.mulberryModalRef}/>
                }
                <Modal ref={ref => this.notifyStockModal = ref}>
                    {!this.state.productSubscriptionDone && (
                      <ModalContent>
                        <h3>{Globals.get('notify_stock_header')}</h3>
                        <p>{Globals.get('notify_stock_text')}</p>

                        <InputField
                            type="text"
                            value={this.state.productSubscriptionEmail}
                            onChange={this.onChangeEmail}
                            placeholder="E-mail"
                        />

                        <NewsletterAgreement>
                            <input type="checkbox"
                                onChange={this.onChangeAgree}
                                value={this.state.productSubscriptionAgreed}
                                id="NewsletterAgreement"
                            />
                            <label htmlFor="NewsletterAgreement">
                                {Globals.get('notify_stock_agree_text')}
                            </label>
                        </NewsletterAgreement>

                        <PrivacyStatement>
                            <Link to={'/privacy-statement'} target={'_blank'}>{`Privacy Policy`}</Link>
                        </PrivacyStatement>

                        {productSubscriptionError && <ErrorMessage>Error registering your e-mail.</ErrorMessage>}

                        <StyledButton blue disabled={this.state.productSubscriptionAgreed === false} onClick={this.addProductSubscription}>Send</StyledButton>
                    </ModalContent>
                    )}
                    {this.state.productSubscriptionDone && (
                      <ModalContent>
                        <h3>{Globals.get('notify_stock_header_complete')}</h3>
                        <p>{Globals.get('notify_stock_text_complete')}</p>
                        <StyledButton blue onClick={this.closeProductSubscription}>Close</StyledButton>
                    </ModalContent>
                    )}
                </Modal>

                <Modal ref={ref => this.seeIn3DModal = ref}>
                    <IframeWrapper>
                        <iframe title={"Iframe3D"} id="iframe" onClick={this.onClick} src={options.url} />
                    </IframeWrapper>
                </Modal>


                {(this.props.entry.meta_fields.technical_specification || this.props.entry.technical_specification) && (
                    <Drawer
                        visible={this.state.displayTechnicalSpec}
                        onClose={() => this.setState({ displayTechnicalSpec: false })}
                        header={<h3 style={{top: '10px', position: 'relative'}}>{Globals.get('mod_pi_tech_spec_header')}</h3>}
                        footer={<span>{Globals.get('mod_pi_tech_spec_text')} <a href="/contact-us">{Globals.get('mod_pi_tech_spec_contact_us')}.</a></span>}>
                        <EditorToHTML data={(typeof this.props.entry.meta_fields.technical_specification !== 'undefined' && this.props.entry.meta_fields.technical_specification !== '{}') ?
                        this.props.entry.meta_fields.technical_specification : this.props.entry.technical_specification} />
                    </Drawer>
                )}

                <SwiperWrapper>
                    {(currentVariation.sizes[0].stock > 0 && currentVariation.sizes[0].stock <= this.low_stock_indicator) &&
                    <AlmostSoldOut><span>Almost gone!!</span></AlmostSoldOut>
                    }
                    <Swiper {...sliderParams} ref={ref => this.swiperRef = ref} id="swiper">
                        {currentVariation.images.full.map((item, index) => {
                            return (
                                <SwiperImage key={index}>
                                    <img id={index} src={item.url} alt={currentVariation.name} />
                                </SwiperImage>
                            );
                        })}
                    </Swiper>
                    {(currentVariation.sizes[0].stock > 0 && currentVariation.sizes[0].stock <= this.low_stock_indicator) &&
                        <AlmostSoldOut><span>Almost gone!!</span></AlmostSoldOut>
                    }
                    {(options.url !== null && options.url !== "") &&
                        <Toggle3D src={Icon3D} onClick={this.toggle3DModel} dark3DIcon={dark3DIcon} />
                    }
                </SwiperWrapper>
                <ProductDetails>
                    <ProductDetailsInner>
                        <ProductName>{entry.name}</ProductName>
                        { (entry.slogan && entry.slogan.length > 0) ?
                          <ProductType>{ entry.slogan }</ProductType> :
                          <ProductType>{ currentVariation.meta_fields.excerpt }</ProductType>
                        }
                        <YotpoStars className="yotpo bottomLine" data-product-id={currentVariation.sku}/>
                        <Seperator />
                        <SelectStyleHeader>{!variations.find(x => x.product_size) ? Globals.get('mod_pi_select_style') : Globals.get('mod_pi_select_size', 'Select Size')}</SelectStyleHeader>
                        <VariationsWrapper>
                            {variations.map((variation, key) => {
                                if (variation.product_size) {
                                    return (
                                        <SizeVariationItem
                                            active={variation.id === currentVariation.id}
                                            outOfStock={variation.sizes[0].stock === 0}
                                            onClick={() => this.selectVariation(variation.id, key)}
                                            key={key}>
                                            {variation.product_size}
                                        </SizeVariationItem>
                                    )
                                }
                                return (
                                    <ColorVariationItem
                                        key={key}
                                        onClick={() => this.selectVariation(variation.id, key)}
                                        active={variation.id === currentVariation.id}
                                        outOfStock={variation.sizes[0].stock === 0}
                                        aria-label={ variation.name }
                                    >
                                        {variation.images.full.length && <img src={variation.images.full[0].url} alt={variation.name} />}
                                        <ProductLabels variation={variation} onlyOne/>
                                    </ColorVariationItem>
                                )
                            })}
                        </VariationsWrapper>
                        {currentVariation.size_variants !== null && <SizeVariationWrapper>
                            {currentVariation.size_variants.map((size, key) => {
                                return (
                                    <SizeVariationItem
                                        active={currentPurchasableItem.size_id === size.id ? true : false}
                                        outOfStock={size.stock === 0 ? true : false}
                                        onClick={() => this.selectSizeVariation(key)}
                                        key={key}>
                                        {size}
                                    </SizeVariationItem>
                                )
                            })}
                        </SizeVariationWrapper>}

                        {(models !== null && currentModel !== null) && <ModelWrapper>
                            <Input
                              as="select"
                              name="model"
                              options={Object.keys(models).map((m, i) => {
                                  return <option key={i} value={m}>iPhone {m}</option>
                              })}
                              onChange={e => this.selectModel(e.target.value)}
                              value={currentModel}
                              border={"square"}
                              small
                            />
                        </ModelWrapper>}

                        { (currentVariation.is_preorder === 1 || options.showPreOrder === true) && <PreOrderInfo
                          sku={ currentVariation.sku }
                          module={ this }
                          options={ options }
                          editMode={ editMode }
                          dateText={ this.state.fields.dateText }
                          counts={ {
                              original: parseInt(options.preorderAmount),
                              stock: parseInt(options.preorderAmount) - options.preorderSold,
                              sold: options.preorderSold,
                          } }
                        /> }

                        <ProductDescription 
                            dangerouslySetInnerHTML={{ __html: currentVariation.meta_fields.description}} 
                            leftAligned={options.leftAlignedProductDescription} 
                            font={options.fontProductDescription || 'Lyon Text'}
                        />
                        <TechnicalSpecAnd3DLinks>
                            {((this.props.entry.meta_fields.technical_specification && this.props.entry.meta_fields.technical_specification !== '{}')
                            || (this.props.entry.technical_specification && this.props.entry.technical_specification !== '{}')) && (
                                <TechnicalSpecAnd3DLink onClick={ this.toggleTechnicalSpec }>
                                    {Globals.get('mod_pi_see_tech_spec')} >
                                </TechnicalSpecAnd3DLink>
                            )}
                            { (options.url !== null && options.url !== "") &&
                                <TechnicalSpecAnd3DLink onClick={ this.toggle3DModel }>
                                    {Globals.get('mod_pi_see_in_3d')} >
                                </TechnicalSpecAnd3DLink>
                            }
                        </TechnicalSpecAnd3DLinks>
                        <Seperator />
                        <CartActionsAndPrice>
                            <Price preorder={currentVariation.is_preorder}>
                                { ((currentVariation.is_preorder === 1 || options.showPreOrder) && options.hidePreorderCountdown !== true) &&
                                <PreOrderCountdown discountPercent={ currentVariation.prices[0].discount_percent } variation={ currentVariation }/> }

                                {currentVariation.prices[0].on_sale === 1 && (
                                    <React.Fragment>
                                        <PreviousPrice className={'previous-price'}><span>{currentVariation.prices[0].price_before_discount}</span></PreviousPrice>
                                        <span className={'current-price'}>{currentVariation.prices[0].price}</span>
                                    </React.Fragment>
                                )}
                                {currentVariation.prices[0].on_sale === 0 && currentVariation.prices[0].price}
                            </Price>
                            <ButtonWrapper ref={ref => this.addToCartButtonRef = ref} preorder={ currentVariation.is_preorder }>
                                <AddToCartButton
                                    blue
                                    narrow
                                    onClick={this.addToCart}
                                    item={currentPurchasableItem}
                                    variation={currentVariation}
                                    reservationAvailable={reservationAvailable}
                                    overrideNotifyWhenBackInStock={options.overrideNotifyWhenBackInStock}
                                    overrideNotifyWhenBackInStockTitle={options.overrideNotifyWhenBackInStockTitle}
                                    overrideNotifyWhenBackInStockLink={options.overrideNotifyWhenBackInStockLink}
                                />
                            </ButtonWrapper>
                        </CartActionsAndPrice>
                        { currentVariation.sizes[0].stock === -1 &&
                            <PreOrderText>{options["preOrder" + currentVariation.sku]}</PreOrderText>
                        }
                        <DiscountNotice>{ ( options.shippingInfoOverride !== '' && options.shippingInfoOverride !== null ) ? options.shippingInfoOverride : Globals.get('mod_pi_discounts_added') }</DiscountNotice>
                    </ProductDetailsInner>
                </ProductDetails>
                {(!isMobile && !options.disableStickyAddToCartButton) &&
                    <StickyProductShopper hidden={editMode || (!editMode && !stickyProductShopperVisible)}>
                        <StickyPrice>{currentVariation.prices[0].price}</StickyPrice>
                        <StickyColors>
                            {variations.map((variation, key) => {
                                return (
                                    <StickyColorVariation
                                        key={key}
                                        onClick={() => this.selectVariation(variation.id, key, false)}
                                        swatch={variation.swatch}
                                        active={variation.id === currentVariation.id}
                                    />
                                )
                            })}
                        </StickyColors>
                        <StickyButtonWrapper>
                            <AddToCartButton
                                narrow
                                onClick={this.addToCart}
                                item={currentPurchasableItem}
                                reservationAvailable={reservationAvailable}
                                variation={currentVariation}
                                overrideNotifyWhenBackInStock={options.overrideNotifyWhenBackInStock}
                                overrideNotifyWhenBackInStockTitle={options.overrideNotifyWhenBackInStockTitle}
                                overrideNotifyWhenBackInStockLink={options.overrideNotifyWhenBackInStockLink}
                            />
                        </StickyButtonWrapper>
                    </StickyProductShopper>
                }
                {(isMobile && !options.disableStickyAddToCartButton) && (
                    <StickyProductShopperMobile hidden={editMode || (!editMode && !stickyProductShopperVisible)}>
                        <StickyPriceMobile>{currentVariation.prices[0].price}</StickyPriceMobile>
                        <StyledDropdown>
                            {this.state.colorItems.length > 0 &&
                            <Dropdown
                                onSelect={this.selectVariation}
                                selectedItem={this.state.selectedColor}
                                items={this.getItems()}
                            />
                            }
                        </StyledDropdown>
                        <StickyButtonWrapper>
                            <AddToCartButton
                                narrow
                                onClick={this.addToCart}
                                item={currentPurchasableItem}
                                reservationAvailable={reservationAvailable}
                                overrideNotifyWhenBackInStock={options.overrideNotifyWhenBackInStock}
                                overrideNotifyWhenBackInStockTitle={options.overrideNotifyWhenBackInStockTitle}
                                overrideNotifyWhenBackInStockLink={options.overrideNotifyWhenBackInStockLink}
                            />
                        </StickyButtonWrapper>
                    </StickyProductShopperMobile>
                )}
            </ProductInfoWrapper>
        );
    }
}

export default ProductInfo;

const NewsletterAgreement = styled.div`
    margin-bottom: 20px;

    input {
        margin-right: 10px;
    }
`

const PrivacyStatement = styled.div`
    margin-bottom: 20px;
    a {
        font-family: 'Circular Pro Book';
        color: ${Colors.blue};
        text-decoration: none;
    }
`

const YotpoStars = styled.div`
    text-align: center;
    margin: 10px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ModalContent = styled.div`
    padding: 50px;
    text-align: center;
    min-width: 500px;

    p {
        margin-bottom: 50px;
    }

    ${InputField} {
        width: 60%;
        display: block;
        margin: 0 auto 30px;
    }
`

const IframeWrapper = styled.div`
    width: 80vw;
    height: 80vh;
    overflow: hidden;

    iframe{
        width: 100%;
        height: 100%;
        z-index: 1;
        border: none;
    }
`

const SizeVariationWrapper = styled.div`
    padding: 1em 0;
    margin-top: .8em;
`

const ModelWrapper = styled.div`
    max-width: 250px;
    margin: 0 auto;
`

const SizeVariationItem = styled.div`
    display: inline-block;
    padding: 3px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin: 0 3px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Circular Pro';

    ${props => props.active ? `
        background-color: #ededed;
    `: ''}

    ${props => props.outOfStock ? `
        opacity: 0.3;
    `: ''}
`

const CartActionsAndPrice = styled.div`
    margin: 40px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
    @media screen and (max-width: 1400px) and (min-width: 1350px) {
        margin-left: -20px;
        margin-right: -20px;
    }
`

const ErrorMessage = styled.div`
    font-size: 14px;
    color: red;
    text-align: center;
    margin-bottom: 20px;
`

const Price = styled.div`
    width: 50%;
    font-size: 23px;
    line-height: 20px;
    font-family: 'Lyon Text Bold';
    margin-top: .3em;
    font-variant-numeric: lining-nums;
    
    ${props => props.preorder && css`
        margin-top: 0;
        
        @media screen and (max-width: 1350px) {
            width: 100%;
        }
    `}
`

const PreviousPrice = styled.span`
    margin-right: 7px;
    color: ${Colors.darkGray};
    font-variant-numeric: lining-nums;
    font-size: 18px;
    line-height: 20px;
    span {
        text-decoration: line-through;
    }
`

const ButtonWrapper = styled.div`
    width: 50%;
    
    ${props => props.preorder && css`
        @media screen and (max-width: 1350px) {
            width: 100%;
            margin-top: 20px;
        }
    `}
`

const DiscountNotice = styled.div`
    display: inline-block;
    margin-top: 40px;
    font-size: 11px;
    color: #4a494a;
`

const SwiperImage = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
    }
`

const ProductInfoWrapper = styled.article`
    position: relative;
    display: flex;
    height: auto;
    text-align: center;

    ${media.lessThan('medium')`
        display: block;
    `}

    .swiper-container {
        width: 100%;
        background: ${Colors.gray};
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
    }

    ${media.lessThan('mobile')`
        .swiper-container {
            height: 100%;
        }
    `}

    .custom-pagination{
        font-family: 'Circular Pro';
        color: ${Colors.darkGray};
        bottom: 12px;

        :before{
            margin-right: 10px;
            content: url(${MiniLeftSwipe});
        }
        :after{
            margin-left: 10px;
            content: url(${MiniRightSwipe});
        }
        ${p => !p.darkSwiperPagination && css`
            color: white;
            :before, :after{
                filter: brightness(0) invert(1);
            }
        ` }
    }

    .custom-button-next,
    .custom-button-prev {
        width: 22px;
        height: 46px;
        background-size: 22px 46px;
    }

    .custom-button-next {
        right: 0;
        left: auto;
        background-image: url(${RightSwipe});
    }

    .custom-button-prev {
        left: 0;
        right: auto;
        background-image: url(${LeftSwipe});
    }
`

const SwiperWrapper = styled.div`
    position: relative;
    display: flex;
    width: 50%;
    margin: 0 auto;

    ${media.lessThan('mobile')`
        width: 100%;
        margin: 0;
    `}
`

const ProductImages = styled.div`
    width: 50%;
    display: flex;
    flex-wrap: wrap;

    ${media.lessThan('medium')`
        display: block;
        width: 100%;
    `}
    ${media.lessThan('mobile')`
        display: none;
    `}
    display: none;
`

const ProductImage = styled.div`
    position: relative;
    background: ${Colors.gray};
    height: 100vh;
    width: 100%;
    &> img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        max-height: 100%;
        max-width: 100%;
    }
`

const Toggle3D = styled.img`
    position: absolute;
    bottom: 5px;
    right: 10px;
    z-index: 2;
    :hover{
        cursor: pointer;
    }
    svg{
        border: 1px solid white;
    }
    ${p => !p.dark3DIcon && css`
        filter: brightness(0) invert(1);
    ` }
`

const ProductDetails = styled.div`
    width: 50%;
    height: 100%;
    position: relative;

    ${media.lessThan('medium')`
        width: 100%;
    `}
`

const ProductName = styled.h3`
    margin-bottom: 5px !important;
`

const ProductDetailsInner = styled.div`
    width: 100%;
    padding: 2em 90px 2em 90px;
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    height: auto;

    ${media.lessThan('medium')`
        min-height: auto;
        padding: 30px 15px;
    `}
`

const ProductType = styled.div`
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-family: 'Circular Pro';
    font-weight: 500;
`



const ProductDescription = styled.div`
    max-width: 450px;
    margin: 0 auto;
    font-family: ${p => p.font};

    ${p => p.leftAligned && css`
        text-align: left;
    `}
`

const TechnicalSpecAnd3DLinks = styled.div`
    display: flex;
    justify-content: center;

    ${media.lessThan('medium')`
        flex-direction: column;
        margin: 20px 0;
    `}
`

const TechnicalSpecAnd3DLink = styled(ButtonNormalized)`
    color: ${Colors.blue};
    margin: 30px 15px 20px 15px !important;
    font-family: 'Circular Pro', sans-serif !important;
    font-weight: 500;
    display: inline-block;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    ${media.lessThan('medium')`
        margin: 10px 0;
    `}
`

const SelectStyleHeader = styled.div`
    margin-top: 30px;
    text-transform: uppercase;
    font-family: 'Circular Pro Book';
    font-size: 14px;
`

const StyledButton = styled(Button)`
    ${p => p.disabled && css`
        opacity: 0.4;
    `}
`

const VariationsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1em 0;
`

const ColorVariationItem = styled(ButtonNormalized)`
    margin: 3px;
    position: relative;
    width: 90px;
    height: auto;
    border-radius: 0;
    background-image: none;
    background-color: ${Colors.gray};
    border: 2px solid rgba(255,255,255,0);
    cursor: pointer;

    &> img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 90%;
        max-height: 90%;
    }

    &:after {
        content: ' ';
        display: block;
        width: 100%;
        padding-bottom: 100%;
    }

    ${props => props.active === true ? `
        border: 2px solid #e8e4de;
    ` : ''}

    ${props => props.outOfStock && css`
        opacity: 0.5;
    `}
    
    .product-label {
        position: absolute;
        top: -2px;
        left: -2px;
    
        img {
            height: 10px;
            margin-right: 5px;
        }
    }
`

const Seperator = styled.div`
    width: 100%;
    height: 1px;
    background: #e8e4de;
    margin: 20px 0;
`

const StickyProductShopper = styled.div`
    background: #fff;
    position: fixed;
    bottom: 0;
    right: 40px;
    z-index: 99;
    padding: 16px 20px;
    display: ${p => p.hidden ? 'none' : 'flex'};
    align-items: center;

    ${media.lessThan('medium')`
        display: none;
    `}
`

const StickyPrice = styled(Price)`
    width: auto;
`

const StickyColors = styled.div`
    display: flex;
    align-items: center;
    margin: 0 10px;
`

const StickyButtonWrapper = styled(ButtonWrapper)`
    width: auto;
`

const StickyColorVariation = styled.div`
    height: 30px;
    width: 30px;
    margin: 5px 5px;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    background-color: ${Colors.black};
    position: relative;
    vertical-align: middle;


    ${props => props.active ? `
        &:before {
            position: absolute;
            font-size: 15px;
            line-height: 17px;
            left: 8px;
            top: 6px;
            content: "✓";
            color: rgb(255, 255, 255);
            font-family: "Circular Pro";
        }
    `: ''}

    ${p => p.swatch !== null && p.swatch.color_hex === '' ? `
        background: url(${p.swatch.color_img.url});
        background-size: cover;
        background-position: center center;
    ` : ''}

    ${p => p.swatch !== null && p.swatch.color_hex !== '' ? `
        background: ${p.swatch.color_hex};
    ` : ''}

    ${p => p.swatch === null ? `
        background: black;
    ` : ''}
`

const StickyProductShopperMobile = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff;
    position: fixed;
    bottom: 0;
    z-index: 99;
    padding: 16px 20px;
`

const StyledDropdown = styled.div`
    height: 40px;
`

const StickyPriceMobile = styled(StickyPrice)`
    margin-top: 0.5em;
`
const AlmostSoldOut = styled.div`
    background-color: #315b84;
    color: white;
    font-family: 'Circular Pro Book';
    position: absolute;
    right: 0;
    width: 135px;
    height: 43px;
    display: table;
    display: none;
    z-index: 10;

    span{
        display: table-cell;
        vertical-align: middle;
    }
`

const PreOrderText = styled.p`
    padding-top: 40px;
    color: red;
`;
