import React from 'react'
import { withReservation, Reservation } from 'kbcms-react'
import styled, { css } from 'styled-components'
import Colors from '../assets/styles/Colors'

class ReservationCountdown extends React.Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    this.interval = setInterval(() => {
      Reservation.getTimeRemaining();
    }, 1000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  render () {
    const { checkoutHeading, reservation} = this.props;
    const { minuteText, secondText } = reservation;

    if(!this.props.reservation || this.props.reservation.reservations.length === 0) return null;

    return (<PillContainer className={this.props.className} checkoutHeading={checkoutHeading}>
        <Label>Countdown</Label>
        <Value>{minuteText}:{secondText}</Value>
      </PillContainer>)
  }
}

const PillContainer = styled.div`
  position: relative;
  top: -3px;
  margin-right: 10px;
  height: 28px;
  background-color: ${Colors.red};
  border-radius: 20px;
  color: #fff;
  padding: 0 13px;
  font-size: 13px;
  font-family: 'Circular Pro Book', sans-serif;
  text-transform: uppercase;
  white-space: nowrap;
  span {
    position: relative;
    top: 1px;
  }
  
  ${props => props.checkoutHeading ? `
    display: inline-block;
    margin-left: auto;
    margin-right: 0;
  ` : ``}
`

const Label = styled.span`
  display: inline-block;
`
const Value = styled.span`
  display: inline-block;
  width: 35px;
  margin-left: 5px;
`

export default withReservation(ReservationCountdown)
