import React from 'react';
import store from '../store'

const defaultState = {
    loaded: false,
    languages: {},
};

const LOADED_LANGUAGES = 'LOADED_LANGUAGES';

export const LanguagesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOADED_LANGUAGES:
            return {
                ...{},
                ...state,
                ...{
                    loaded: true,
                    languages: action.languages ? action.languages: state.languages,
                }
            };
        default:
            return state;
    }
};

const LanguagesActions = {
    setLanguages: (languages) => {
        store.dispatch({
            type: LOADED_LANGUAGES,
            loaded: true,
            languages: languages,
        });
    },
    all: () => {
        return store.getState().languages.languages;
    },
};

export default LanguagesActions;