import React from 'react';
import { Editor, Media } from '../../cms';
import styled from 'styled-components';
import BaseModule from 'modules/DBBaseModule';
import OptionsWrapper from 'components/OptionsWrapper';
import { default as media } from 'assets/styles/Breakpoints';
import fontOptions from 'assets/styles/FontOptions';

class ThreeImagesLink extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Three Image Link" }

    /**
    * Constructor
    */
    constructor(props) {
        super(props)

        this.state = {
            fields: {
                ...{}, ...{
                    title: 'Enter title...',
                    items: [
                        {
                            image: {},
                            text: 'Enter text...',
                            linkUrl: ''
                        },
                        {
                            image: {},
                            text: 'Enter text...',
                            linkUrl: ''
                        },
                        {
                            image: {},
                            text: 'Enter text...',
                            linkUrl: ''
                        },
                    ]
                },
            ...props.fields,
            }
        }
    }

    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'overlay',
                label: 'Overlay',
                type: 'dropdown',
                options: [
                    { name: 'Off', value: '0' },
                    { name: '10%', value: '.1' },
                    { name: '20%', value: '.2' },
                    { name: '30%', value: '.3' },
                    { name: '40%', value: '.4' },
                ]
            },
        ]);
    }

    /**
    * Renderer
    */
    render() {
        const { options, editMode } = this.props;
        let { fields } = this.state;

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <Wrapper>
                    <Editor
                        module={this}
                        disabled={!editMode}
                        field={'title'}
                        data={fields.title}
                        fonts={fontOptions}
                        defaultFont='Lyon Display'
                    />
                    <LinkWrapper>
                        { fields.items.map((item,key) =>

                            <StyledLinkItem key={key}>
                                <StyledMedia
                                    module={this}
                                    overlay={options.overlay}
                                    field={'items.' + key + '.image'}
                                    aspectRatio={4/3}
                                    editPosition={'sw'}
                                    fill>
                                    <StyledContentArea>
                                        <Editor
                                            module={this}
                                            disabled={!editMode}
                                            field={'items.' + key + '.text'}
                                            data={item.text}
                                            fonts={fontOptions}
                                            defaultFont='Lyon Display'
                                        />
                                    </StyledContentArea>
                                </StyledMedia>
                            </StyledLinkItem>

                        )}
                    </LinkWrapper>
                </Wrapper>
            </OptionsWrapper>
        );
    }
}

export default ThreeImagesLink


const Wrapper = styled.div`
    text-align: center;
    margin: 0 auto;
    max-width: 1180px;

    ${media.lessThan('huge')`
        width: calc(100% - 180px);
        margin: 0 auto;
    `}

    ${media.lessThan('large')`
        width: calc(100% - 160px);
    `}

    ${media.lessThan('medium')`
        width: calc(100% - 100px);
    `}

    ${media.lessThan('small')`
        width: calc(100% - 30px);
        margin: 0 auto;
    `}

    ${media.lessThan('mobile')`
        flex-direction: column;
    `}
`

const LinkWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${media.greaterThan('mobile')`
        flex-direction: row;
    `}
`

const StyledLinkItem = styled.div`
    width: 100%;
    margin-bottom: 16px;

    ${media.greaterThan('mobile')`
        width: calc(33% - 20px);
        margin: 0 10px;
    `}
`

const StyledMedia = styled(Media)`
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledContentArea = styled.div`
    z-index: 1;

    .wysiwig-content, .wysiwig-admin-content {
        h1, h2, h3, div, p {
            color: #fff;
        }
    }

    ${media.lessThan('large')`
        h1 {
            font-size: 40px;
            }
    `}

    ${media.lessThan('medium')`
        h1 {
            font-size: 36px;
        }
    `}

    ${media.lessThan('small')`
        h1 {
            font-size: 24px;
        }
    `}

    ${media.lessThan('mobile')`
        h1 {
            font-size: 40px;
        }
    `}
`

