import React from 'react'
import styled, { css } from 'styled-components';
import { Globals, withReservation, Reservation } from 'kbcms-react';
import Modal from 'components/Modal';
import Button from 'components/Button';

class ReservationModal extends React.Component {

    constructor(props) {
        super(props);

       this.modal = null;
    }

    openModal() {
        // this.modal.openModal()
        Reservation.setModalOpen(true)
    }

    onCloseModal() {
        Reservation.setModalOpen(false)
    }

    componentDidMount () {
        if(this.props.reservation.timedOut && !this.props.reservation.timeoutDisplayed) {
            Reservation.timeoutDisplayed();
            this.openModal()
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if(
          (prevProps.reservation.reservations.length > 0 && this.props.reservation.reservations.length === 0 && this.props.reservation.timedOut) ||
          (this.props.reservation.timedOut && !this.props.reservation.timeoutDisplayed)
        ) {
            Reservation.timeoutDisplayed();
            this.openModal()
        }


        if(this.props.reservation.reservations.length > prevProps.reservation.reservations.length && this.modal)
            this.openModal()
    }

    render() {
        const { minuteText, secondText, reservations } = this.props.reservation;
        let title, text, button;

        if(reservations.length > 0) {
            title = Globals.get('reserved_product_modal_title');
            text = Globals.get('reserved_product_modal_text').replace('#timeRemaining', `${minuteText}:${secondText}`);
            button = Globals.get('reserved_product_modal_button', 'Continue Shopping');
        } else {
            title = Globals.get('reserved_product_modal_timeout_title');
            text = Globals.get('reserved_product_modal_timeout_text');
            button = Globals.get('reserved_product_modal_timeout_button', 'Continue Shopping');
        }

        return (
            <Modal ref={ref => this.modal = ref} hideCloseIcon close={this.onCloseModal}>
                <ModalContent>
                    <h3>{ title }</h3>

                    <p>{ text }</p>
                    <StyledButton blue onClick={()=> this.modal.closeModal()}>{ button }</StyledButton>
                </ModalContent>
            </Modal>
        )
    }
}

export default withReservation(ReservationModal);

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    margin: 50px;
    h3 {
        text-align: center;
    }
    p {
        font-family: 'Circular Pro Book', sans-serif;
        font-size: 0.875em;
        line-height: 1.5em;
        
        max-width: 400px;
        margin: 1em auto;
        text-align: center;
    }
`

const StyledButton = styled(Button)`
    margin-top: 1.5em;
    align-self: center;
`