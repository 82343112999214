import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import LinkSelectorActions from 'cms/reducers/link_selector';
import PropTypes from 'prop-types';
import BaseModule from 'cms/components/BaseModule';
import Icon from 'cms/components/Icon';

// TODO: Comment this out.
class Linker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...{
                to: props.module.getField(props.field),
            },
            ...props,
        };

        if (typeof this.state.to === 'undefined' || this.state.to === null) this.state.to = '#';
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let url = this.props.module.state.fields[this.props.field];

        if (typeof url !== 'undefined' && url !== null && url !== prevState.to) {
            this.setState({
                to: url,
            });
        }
    }

    handleClick(e) {
        let { editMode } = this.props.module.props;
        if (editMode) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    handleButtonClick(e) {
        let { field, module } = this.props;
        e.stopPropagation();
        e.preventDefault();

        LinkSelectorActions.openModal((link) => {
            module.setField(field, '/' + link.url);
        });
    }

    renderButton() {
        return (
            <StyledModalOpener onClick={(e) => this.handleButtonClick(e)}>
                <Icon name="link" />
            </StyledModalOpener>
        );
    }

    render() {
        let { editMode } = this.props.module.props;
        let { children } = this.props;
        let { to } = this.state;

        if (editMode) {
            return (
                <StyledLinkPlaceholder onClick={(e) => this.handleClick(e)} className={this.props.className}>
                    {children}
                    {this.renderButton()}
                </StyledLinkPlaceholder>
            );
        }

        return (
            <StyledLink to={to} onClick={(e) => this.handleClick(e)} className={this.props.className}>
                {children}
            </StyledLink>
        )

    }
}

Linker.propTypes = {
    module: PropTypes.instanceOf(BaseModule).isRequired,
    field: PropTypes.string.isRequired,
};

export default Linker;

const StyledLinkPlaceholder = styled.div`
    position: relative;
    display: inline-block;
`

const StyledLink = styled(Link)`
    ${StyledLinkPlaceholder};
`

const StyledModalOpener = styled.div`
    position: absolute;
    top: 2%;
    left: 1%;
    width: 30px;
    height: 30px;
    background-color: #fff;
    /*border-radius: 4px;*/
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    transition: opacity .3s ease;

    ${StyledLinkPlaceholder}:hover & {
        pointer-events: auto;
        opacity: 1;
    }

    svg {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: #000;
    }
`
