import store from '../store';

const defaultState = {
    collapsedMenu: false,
    collapsibleMenu: false,
    entryTypes: {},
    loaded: false,
    activeMenuItem: '',
};

const SET_MENU_COLLAPSED_STATE = 'SET_MENU_COLLAPSED_STATE';
const SET_COLLAPSIBLE_MENU_STATE = 'ENABLE_COLLAPSABLE_MENU';
const LOADED_ENTRY_TYPES = 'LOADED_ENTRY_TYPES';
const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM'; 

export const SystemReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_MENU_COLLAPSED_STATE:
            return {
                ...{},
                ...state,
                ...{
                    collapsedMenu: action.isCollapsed
                }
            };
        case SET_COLLAPSIBLE_MENU_STATE:
            return {
                ...{},
                ...state,
                ...{
                    collapsibleMenu: action.isCollapsible,
                }
            };
        case LOADED_ENTRY_TYPES:
            return {
                ...{},
                ...state,
                ...{
                    loaded: action.loaded,
                    entryTypes: action.entryTypes,
                }
            };
        case SET_ACTIVE_MENU_ITEM: 
            return {
                ...{},
                ...state, 
                ...{
                    activeMenuItem: action.activeMenuItem
                }
            }
        default:
            return state;
    }
};

const SystemActions = {
    setMenuCollapsedState: isCollapsed => {
        store.dispatch({
            type: SET_MENU_COLLAPSED_STATE,
            isCollapsed: isCollapsed
        });
    },
    setCollapsibleMenu: isCollapsible => {
        SystemActions.setMenuCollapsedState(true);
        store.dispatch({
            type: SET_COLLAPSIBLE_MENU_STATE,
            isCollapsible: isCollapsible,
        });
    },
    setEntryTypes: (entryTypes) => {
        store.dispatch({
            type: LOADED_ENTRY_TYPES,
            loaded: true,
            entryTypes: entryTypes,
        });
    },
    setActiveMenuItem: (key) => {
        store.dispatch({
            type: SET_ACTIVE_MENU_ITEM,
            activeMenuItem: key
        })
    }
};

export default SystemActions;