import React from 'react'
import { SelectEntry, Editor } from 'kbcms-react';
import BaseModule from 'modules/DBBaseModule';
import styled from 'styled-components';
import ProductBox from 'components/ProductBox/index';
import OptionsWrapper from 'components/OptionsWrapper';
import { default as media } from 'assets/styles/Breakpoints';
import ArrowLeft from 'images/arrow-left.svg';
import ArrowRight from 'images/arrow-right.svg';
import fontOptions from 'assets/styles/FontOptions';

class ProductListing extends BaseModule {

    /**
    * Module name. Displayed in page builder.
    * @return {String}
    */
    static getName() { return "Product Listing" }



    /**
    * Constructor
    */
    constructor(props) {
        super(props);

        // If you use the key "entryType" within a field it will try to auto populate with data.
        this.state = {
            fields: {
                ...{
                    // define your fields that should be saved here.
                    products: {
                        entryType: 'products',
                        filter: [],
                        data: [],
                    },
                    title: '',
                    selectedVariations: {},
                    productSortOrder: null
                }, ...props.fields
            }
        }
    }

    /**
     * Custom module options
     * @return {Array}
     */
    static options() {
        return super.options().concat([
            {
                name: 'flatten',
                label: 'Flatten variations',
                type: 'switch'
            },
        ]);
    }

    /**
     * Lifecycle method
     * @return {Void}
     */
    componentDidMount() {
        // prepare data will AJAX fetch data from API if it isnt already added by the server.
        this.prepareData().then(state => {
            if (state.fields.productSortOrder === null) {
                state.fields.productSortOrder = this.createSortOrder(state.fields.products.data)
            }

            this.setState(state);
        });
    }


    /**
     * Updates the sort order
     * @return {Void}
     */
    updateSortOrder = () => {
        this.setState({
            fields: Object.assign({}, this.state.fields, {
                productSortOrder: this.createSortOrder(this.state.fields.products.data)
            })
        })
    }


    /**
     * Creates a new sort order
     * @param {Array} products
     * @return {Object}
     */
    createSortOrder = products => {
        let productSortOrder = {};

        products.forEach((product, index) => {
            productSortOrder[product.id] = index;
        });

        return productSortOrder;
    }


    /**
     * When changing a variation we save the selected variation
     * @return {Void}
     */
    onVariationChange = (productId, variationId) => {
        let newFieldsState = Object.assign({}, this.state.fields);

        newFieldsState.selectedVariations = Object.assign({}, this.state.fields.selectedVariations, { [productId]: variationId } );

        this.setState({ fields: newFieldsState });
    }

    /**
     * Moves a product in the sort order
     * @return {Void}
     */
    moveProduct = (productId, newPosition) => {
        let { fields } = this.state;

        const newSortOrder = Object.assign({}, fields.productSortOrder);

        const oldSortValue = newSortOrder[productId];
        const newSortValue = oldSortValue + newPosition;

        var productBeingReplaced;

        Object.keys(newSortOrder).forEach(key => {
            if (newSortOrder[key] === newSortValue) {
                productBeingReplaced = key;
            }
        });

        newSortOrder[productId] = newSortValue;
        newSortOrder[productBeingReplaced] = oldSortValue;

        this.setState({ fields: Object.assign({}, this.state.fields, { productSortOrder: newSortOrder })})
    }


    /**
     * Renderer
     * @return {JSX}
     */
    render() {
        let { options, editMode } = this.props;
        let { fields } = this.state;

        // Check options to see if we should flatten variations array or not
        // If flatten then loop through each product to see if it has any variations
        // If it has any variations then we should Move these up one level and list it out independently

        let prods = fields.products.data;
        if (options.flatten) {
            prods = prods.reduce((acc, val) => [...acc, ...val.variations.map(a => ({  ...val, variations: [a] }))], []);
        }

        return (
            <OptionsWrapper options={options} editMode={editMode}>
                <ProductListingWrapper>
                    <EditorWrapper>
                        <Editor
                            module={this}
                            disabled={!editMode}
                            field={'title'}
                            data={fields.title}
                            fonts={fontOptions}
                            defaultFont='Lyon Display'
                        />
                    </EditorWrapper>
                    {editMode && (
                        <SelectEntry module={this} field={'products'} multiple={true} callback={this.updateSortOrder}>
                            <SelectProductsButton>Select Products</SelectProductsButton>
                        </SelectEntry>
                    )}
                    <ProductsWrapper>
                        {prods.sort(function(a, b) {
                            if (fields.productSortOrder === null) return 0;
                            return fields.productSortOrder[a.id] - fields.productSortOrder[b.id];
                        }).map((product, index) => {
                            return (
                                <StyledProductBox
                                    key={`${product.id}-${index}`}
                                    product={product}
                                    selectedVariation={fields.selectedVariations[product.id] !== undefined ? fields.selectedVariations[product.id] : 0}
                                    onVariationChange={variationId => this.onVariationChange(product.id, variationId)}>
                                    <React.Fragment>
                                        {editMode && index !== 0 && (
                                            <MoveLeft onClick={() => this.moveProduct(product.id, -1)} />
                                        )}
                                        {editMode && (index + 1) < fields.products.data.length && (
                                            <MoveRight onClick={() => this.moveProduct(product.id, 1)} />
                                        )}
                                    </React.Fragment>
                                </StyledProductBox>
                            );
                        })}
                    </ProductsWrapper>
                </ProductListingWrapper>
            </OptionsWrapper>
        );
    }
}

export default ProductListing;

const EditorWrapper = styled.div`
    position: relative;
    max-width: 780px;
    margin: 0 auto;
    width: calc(100% - 260px);
    padding: 50px 0 40px;
    text-align: center;

    ${media.lessThan('huge')`
        width: calc(100% - 180px);
    `}

    ${media.lessThan('large')`
        width: calc(100% - 160px);
    `}

    ${media.lessThan('medium')`
        width: calc(100% - 100px);
    `}

    ${media.lessThan('small')`
        width: calc(100% - 30px);
        margin: 0 auto;
    `}
`

const ProductListingWrapper = styled.div`
    position: relative;
    width: calc(100% - 260px);
    max-width: 1180px;
    margin: 0 auto;
    text-align: center;
    padding: 4em 0;

    ${media.lessThan('huge')`
        width: calc(100% - 180px);
    `}

    ${media.lessThan('large')`
        width: calc(100% - 160px);
    `}

    ${media.lessThan('medium')`
        width: calc(100% - 100px);
    `}

    ${media.lessThan('small')`
        width: calc(100% - 30px);
    `}
`

const ProductsWrapper = styled.section`
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    justify-content: center;
`

const SelectProductsButton = styled.button``


const MoveLeft = styled.div`
    display: none;
    position: absolute;
    width: 25px;
    height: 25px;
    background: #d2d2d2 url(${ArrowLeft}) no-repeat center center;
    top: 20px;
    left: 20px;
    z-index: 1;
    cursor: pointer;
`

const MoveRight = styled.div`
    display: none;
    position: absolute;
    width: 25px;
    height: 25px;
    background: #d2d2d2 url(${ArrowRight}) no-repeat center center;
    top: 20px;
    right: 20px;
    z-index: 1;
    cursor: pointer;
`

const StyledProductBox = styled(ProductBox)`
    position: relative;

    &:hover {
        ${MoveLeft}, ${MoveRight} {
            display: block;
        }
    }
`
