import React from 'react';
import DouchebagTemplateStyles from 'components/DouchebagTemplateStyles';
import { BaseTemplate, ModuleZone } from 'kbcms-react'
import TakeOverLayout from 'components/Layouts/TakeOverLayout';
import Header from 'components/Header';
import Footer from 'components/Footer';
import styled from 'styled-components';

class TakeOverTemplate extends BaseTemplate {

    constructor(props) {
        super(props);

        this.calculateViewHeight = this.calculateViewHeight.bind(this)
    }

    /**
     * Human readable name. Will be displayed in the CMS
     */
    static getName() { return "Full page takeover template" }


    /**
     * Override the master layout with a custom layout.
     */
    static overrideMasterLayout() { return TakeOverLayout }

    componentDidMount() {
        let self = this;

        self.calculateViewHeight();

        window.addEventListener('resize', this.calculateViewHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateViewHeight);
    }

    calculateViewHeight() {
        // Calculate a css variable based on innerHeight instead of vh!
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }


    /**
     * Renderer
    */
    render() {
        const { entry, ssr, frontend, zones, editMode } = this.props

        return (
            <DouchebagTemplateStyles>
                <TakeOver className={'takeover-zone-wrapper'}>
                    <ModuleZone
                        moduleLimit={1} 
                        prependModules={['TakeOverModule']}
                        entry={entry}
                        ssr={ssr}
                        frontend={ frontend }
                        zones={zones}
                        index={0}
                        ref={this.registerZone}
                        editMode={editMode}
                    />
                </TakeOver>

                <StyledWrapper>
                    <Header 
                        withNavigation={true} 
                        sticky={true} 
                        fixed={false} 
                    />
                    
                    <ModuleZone
                      entry={this.props.entry}
                      ssr={ssr}
                      frontend={ frontend }
                      zones={zones}
                      index={1}
                      ref={this.registerZone}
                      editMode={editMode}
                    />
                    
                    <Footer />  
                </StyledWrapper>              
            </DouchebagTemplateStyles>
        )
    }
}

export default TakeOverTemplate;

const TakeOver = styled.section`
    height: calc(100vh - 89px);
    height: calc(calc(var(--vh, 1vh) * 100) - 89px);
    width: 100%;
    background: #c2c2c2;
    overflow-y: hidden;
    position: relative;
`

const StyledWrapper = styled.section`
    background: #fff;
    position: relative;
`