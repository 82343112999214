import React from 'react';
import loadable from '@loadable/component'
import Master from './components/Layouts/Master'
import { KBRouter } from 'cms';
import growsurfLoader from './helpers/growsurf-loader'
import loadPolyfills from './helpers/loadPolyfills'
import flowboxLoader from './helpers/flowbox-loader'
import yotpoLoader from './helpers/yotpo-loader'

loadPolyfills()
flowboxLoader()
yotpoLoader()
growsurfLoader()

const CheckoutPage = loadable(() => import('components/CheckoutPage/index'), {
  fallback: (() => <div/>)(),
});

const App = ({ serverData = null }) => {
  return <KBRouter
    systemPath="/cms"
    masterLayout={ Master }
    checkoutPage={ CheckoutPage }
    checkoutUri="checkout"
    serverData={ serverData }
  />
};

export default App