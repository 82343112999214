import React from "react";
import Colors from 'assets/Colors';
import styled, { css } from 'styled-components';
import Icon from 'cms/components/Icon';

import ValidationError from 'cms/components/Form/ValidationError';

class Input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: Math.random().toString(36).substr(2, 9),
            focus: false,
            error: null,
            colorRed: null,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(typeof this.props.error !== 'undefined' && prevState.error !== this.props.error) {
            this.setState({
                error: this.props.error,
            });
        }
        if(typeof this.props.colorRed !== 'undefined' && prevState.colorRed !== this.props.colorRed) {
            this.setState({
                colorRed: this.props.colorRed,
            });
        }
    }

    toggleFocus() {
        this.setState({
            focus: !this.state.focus,
        })
    }


    render() {
        let { name, label, type, value, onChange, inputRef, className, search, noValueAttribute, inputHasValue, searchClassName, colorRed } = this.props;
        let { id, focus, error } = this.state;

        

        if (typeof value === 'undefined' || value === null || value === true || value === false) value = '';

        return (
            <Wrapper>
            {!noValueAttribute &&
                <InputWrapper className={ className } focus = { focus } value={ value }>
    
                    <StyledLabel htmlFor={ name } focus={ focus } value={ value } search ={ search }>{ label }</StyledLabel>
                    <StyledInputContainer focus={ focus  } search ={ search }
    >
                        { search && 
                            <StyledIcon name="search" width={24} height={24} stroke={Colors.grey} />
                        }
                        <StyledInput
                            ref={ inputRef }
                            name={ name }
                            type={ type }
                            value={ value }
                            focus={ focus }
                            onChange={ onChange }
                            onFocus={ () => this.toggleFocus() }
                            onBlur={ () => this.toggleFocus() }
                            search={ search }
                            colorRed={ colorRed }
                        />
                        { error !== null && <ValidationError id={ id } error={ error }/> }
                    </StyledInputContainer>
                </InputWrapper>
            }   
            {noValueAttribute &&
                <InputWrapper className={ className } focus = { focus } >
  
                    <StyledLabel htmlFor={ name } focus={ focus } value={inputHasValue === true ? 'value' : ''} search ={ search }>{ label }</StyledLabel>
                    <StyledInputContainer focus={ focus } search ={ search }
    >
                        { search && 
                            <StyledIcon name="search" width={24} height={24}/>
                        }
                        <StyledInput
                            ref={ inputRef }
                            name={ name }
                            className={searchClassName}
                            type={ type }
                            focus={ focus }
                            onChange={ onChange }
                            onFocus={ () => this.toggleFocus() }
                            onBlur={ () => this.toggleFocus() }
                            search={ search }
                        />
                        { error !== null && <ValidationError id={ id } error={ error }/> }
                    </StyledInputContainer>
                </InputWrapper>
            }   
            
            {/*<InputWrapper className={ className }>
                <StyledLabel htmlFor={ name } focus={ focus } value={ value }>{ label }</StyledLabel>
                <StyledInputContainer focus={ focus }>
                    <StyledInput
                        ref={ inputRef }
                        name={ name }
                        type={ type }
                        value={ value }
                        focus={ focus }
                        onChange={ onChange}
                        onFocus={ () => this.toggleFocus() }
                        onBlur={ () => this.toggleFocus() }
                    />
                    { error !== null && <ValidationError id={ id } error={ error }/> }
                </StyledInputContainer>
        </InputWrapper>*/}          
            </Wrapper>
        )
    }
}

export default Input;

const Wrapper = styled.div``

const InputWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    position: relative;
    margin: 28px 0 20px; 
    min-width: 200px;
    width: 100%;
    backface-visibility: hidden;
    font-size: 16px; 
    line-height: 22px; 
`

const StyledLabel = styled.label`
    position: absolute;
    top: 5px;
    left: 0;
    padding: 0 15px;
    pointer-events: none;
    transform: translateY(.3em);
    transform-origin: 0 0;
    color: #999999;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1);
    
    ${props => props.search === true && css`
        padding: 0 15px 0 50px;  
    `}

    ${ props => (props.focus === true || (Number.isInteger(props.value)) || (props.value && props.value.length > 0)) && css`
        transform: translateY(-.8em) scale(.8);
        top: -18px; 
        padding: 0; 
        ${props => props.focus === true && css`
            color: ${Colors.grey};
        `}
    `}
`;

const StyledInputContainer = styled.div`
    display: inline-flex;
    height: 44px; 

    &:before {
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%; 
        content: ' ';
        position: absolute;
        transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border: 1px solid ${Colors.dividers};
        pointer-events: none;
    }

    &:hover{
        &:before{
            border: 1px solid ${Colors.lightGrey};
        }
    }

    &:after {
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%; 
        content: ' ';
        position: absolute;
        transform: scaleX(0);
        border: 1px solid ${Colors.black};
        pointer-events: none;

    }

    ${props => props.focus === true && css`
        &:before {
            border: 1px solid ${Colors.dividers};
        }
        &:after {
            transform: scaleX(1);
        }
    `}
`

const StyledInput = styled.input`
    display: block;
    width: 100%;

    margin: 0;
    padding: 0 15px;

    border: 0;
    -webkit-tap-highlight-color: transparent;
    background: none;
    outline: 0;

    ${props => props.search === true && css`
        padding: 12px; 
    `}

    &:-webkit-autofill {
        color: inherit !important;
        background: none !important;
        animation-name: onAutoFillStart;
        transition: background-color 50000s ease-in-out 0s;
        content: "\feff"
    }

    ${props => props.colorRed === true && css`
        -webkit-text-fill-color: red; 
    `}
`

const StyledIcon = styled(Icon)`
    margin-left: 15px; 
    display: flex; 
    align-self: center; 
`